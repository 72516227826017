import { Blok } from '@/bloks/Blok';
import { LinkButton, LinkButtonWithLabel } from '@/bloks/Button';
import { ISbComponentType } from '@/types/storyblok';

export function DynamicLinkButton({ blok, meta }: { blok: ISbComponentType<any>; meta: Record<string, unknown> }) {
	const linkButtonVariants: Record<string, Blok<any, any>> = {
		LinkButton,
		LinkButtonWithLabel,
	};

	const Component = linkButtonVariants[blok.component];
	if (!Component) {
		if (process.env.NODE_ENV !== 'production')
			throw new Error(`Missing DynamicLinkButton variant ${blok.component} ${Object.keys(linkButtonVariants)}`);
		return null;
	}

	return <Component {...Component.blokProps({ blok, meta })} />;
}
