import { LinkButton } from '@/bloks/Button';
import { Themes } from '@/types';
import { normalizeFullSlug } from '@/utils/storyblok';

interface Props {
	children?: string;
	href: string;
	target?: any;
	linktype?: any;
	theme?: Themes['theme'];
	anchor?: any;
	rel?: any;
	applyChildTheme?: boolean;
}

export const MarkLink: React.FC<Props> = ({ children, href, target, linktype, anchor, rel }) => {
	/**
	 * Fix problem where author accidentally linked to internal fnox url instead
	 * of relative site url
	 */
	if (href?.includes('fnox.se')) {
		const url = new URL(href);
		href = `${url.pathname}${url.search}${url.hash}`;
	}

	if (linktype === 'url') {
		target = '_blank';
		rel = 'noopener noreferrer';
	}

	if (anchor) {
		href = `${href}#${anchor}`;
	}

	if (linktype === 'story') {
		href = normalizeFullSlug(href) || '#';
	}

	if (children && href) {
		return (
			<LinkButton href={href} target={linktype === 'url' ? '_blank' : target} rel={rel} variant="marklink">
				{children}
			</LinkButton>
		);
	}

	return null;
};
