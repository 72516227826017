import { BrandLine, Heading, Text } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { LinkButtonStoryblok, StoryHeaderCommonStoryblok, ThemeStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { renderRichText } from '../RichText';
import styles from './StoryHeaderCommon.module.scss';

interface Props {
	blok: StoryHeaderCommonStoryblok;
	meta?: {
		theme?: ThemeStoryblok['theme'];
	};
}

/**
 * Header component that is frequently used in.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=293%3A9496
 */
export const StoryHeaderCommon: React.FC<Props> = ({ blok, meta = {} }) => {
	const { titlePart1, titlePart2, text, smallTitle, linkButtons } = blok;
	const theme = meta?.theme;

	return (
		<section {...editableBlok(blok)} className={cls(styles.container, styles[`theme--${theme}`])}>
			{smallTitle && (
				<>
					<BrandLine />
					<Heading as="div" size="h6" className={styles.smallTitle} title={smallTitle} marginBottom="xl" />
				</>
			)}
			<div className={styles.titles}>
				{titlePart1 && <Heading as="h2" title={titlePart1} titlePart2={titlePart2} />}
			</div>
			{text && (
				<div className={styles.text}>
					<Text as="div">{renderRichText(text)}</Text>
				</div>
			)}
			{linkButtons &&
				linkButtons.map((button: LinkButtonStoryblok) => (
					<LinkButton key={button._uid} {...LinkButton.blokProps({ blok: button })} variant="filled" />
				))}
		</section>
	);
};
