import { MultilinkStoryblok } from '@/components';
import { useLightnessTheme, useTheme } from '@/contexts/theme';
import { ThemeInfo } from '@/themes';
import { cls } from '@/utils';
import { Icon } from '../Icon';
import styles from './StyledButton.module.scss';

export type ButtonVariant = 'filled' | 'outlined' | 'text' | 'link' | 'link-underline' | 'marklink';
export type ButtonSize = 'small' | 'medium' | 'large';

export interface StyledButtonProps {
	variant?: ButtonVariant;
	className?: string;
	classNameInner?: string;
	children?: React.ReactNode;
	block?: boolean;
	active?: boolean;
	type?: MultilinkStoryblok['linkType'] | 'default';
	size?: ButtonSize;
	arrow?: boolean;
	disabled?: boolean;
	icon?: React.ReactNode;
	isLoading?: boolean;
	applyLightnessTheme?: boolean;
}

export function getVariantStyles(variant: ButtonVariant, themeInfo: ThemeInfo) {
	switch (variant) {
		case 'outlined':
			return themeInfo.styles.buttonOutline;
		case 'text':
			return themeInfo.styles.textLink;
		case 'link':
			return themeInfo.styles.textLink;
		case 'marklink':
			return themeInfo.styles.textLink;
		default:
			return themeInfo.styles.buttonFilled;
	}
}

export const StyledButton = ({
	className,
	children,
	classNameInner,
	icon,
	variant = 'filled',
	block = false,
	active = false,
	type = 'story',
	size = 'medium',
	arrow = true,
	disabled = false,
	isLoading = false,
	applyLightnessTheme = false,
}: StyledButtonProps) => {
	if (type === 'url' && variant === 'text') {
		icon = <Icon name="arrow-up-right-from-square" size="1x" className={styles.icon} />;
	}

	const lightnessTheme = useLightnessTheme();
	const theme = useTheme();
	const themeInfo = applyLightnessTheme && lightnessTheme ? lightnessTheme : theme;

	const variantStyles = getVariantStyles(variant, themeInfo);

	return (
		<span
			className={cls(
				styles.btn,
				styles[`variant--${variant}`],
				styles[`size--${size}`],
				type === 'story' && styles.internalLink,
				block && styles.block,
				active && styles.active,
				variant === 'text' && type === 'story' && arrow && styles.arrow,
				disabled && styles.disabled,
				variantStyles,
				className,
			)}
		>
			{isLoading ? (
				<span className={styles.spinner}>
					<Icon name="circle-notch" className="fa-spin" />
				</span>
			) : (
				<span className={cls(styles.inner, classNameInner)}>
					{children} {icon}
				</span>
			)}
		</span>
	);
};
