import { Icon } from '@/atoms';
import { useServerPageConfig } from '@/contexts/config';
import { useRouter } from 'next/router';
import { useState } from 'react';
import styles from './InternalVersionTag.module.scss';

export const InternalVersionTag = () => {
	const [hidden, setHidden] = useState(false);
	const config = useServerPageConfig();
	const router = useRouter();

	const handleClick: React.MouseEventHandler<HTMLInputElement> = (event) => {
		event.currentTarget.select();
	};

	const handleKeyPress: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
		if (event.key === 'Backspace') setHidden(true);
	};

	if (hidden) {
		return null;
	}

	const togglePreviewUrl = `${config.preview ? '/api/preview/exit-preview' : '/api/preview'}${router.asPath}`;
	const togglePreviewImpossible = !!router.query._storyblok; // Inside Storyblok
	const togglePreviewTitle = config.preview
		? '🚧 Stäng förhandsgranskning av ej publicerat innehåll'
		: '🚧 Starta förhandsgranskning av ej publicerat innehåll';

	return (
		<div className={styles.internalVersionTag}>
			<button
				onClick={() => window.location.assign(togglePreviewUrl)}
				title={togglePreviewImpossible ? undefined : togglePreviewTitle}
				disabled={togglePreviewImpossible}
				style={{ border: 0, background: 'transparent', position: 'relative', top: '2px' }}
			>
				<Icon name={config.preview ? 'eye' : 'eye-slash'} />
			</button>
			<input
				type="text"
				readOnly
				tabIndex={-1}
				value={config.internalVersionTag}
				size={config.internalVersionTag?.length}
				onClick={handleClick}
				onKeyDown={handleKeyPress}
				title="🚧 Tryck här och sedan på <Backspace> om du vill gömma detta fält"
			/>
		</div>
	);
};
