const VALID_KEYS = '012345-6789'.split('');
export const formatOrgNr = (orgNr: string) => {
	const currentKey = orgNr.charAt(orgNr.length - 1);

	if (!VALID_KEYS.includes(currentKey)) {
		return orgNr.replace(/\D/g, '');
	}

	// Sneak in a dash if user is typing a seventh digit or pasting a orgNo with no dash
	if (orgNr.length > 6 && !/(-)/.test(orgNr) && /^\d$/.test(currentKey)) {
		return `${orgNr.slice(0, 6)}-${orgNr.slice(6)}`;
	}

	// If an orgNo with a dash at the wrong position is pasted
	if (orgNr.length > 6 && /(-)/.test(orgNr) && orgNr.indexOf('-') !== 6) {
		const temp = orgNr.replace(/\D/g, '');
		return `${temp.slice(0, 6)}-${temp.slice(6)}`;
	}

	return orgNr;
};
