import { Icon } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { TableImageRowStoryblok } from '@/components';

export interface Props {
	blok: TableImageRowStoryblok;
	cells: string[];
	label: string;
}

export default function TableImageRow({ blok, cells, label }: Props) {
	return (
		<tr>
			<td>
				{label}
				<div>
					{blok?.link?.map((link) => {
						return <LinkButton key={link._uid} {...LinkButton.blokProps({ blok: link })} variant="text" />;
					})}
				</div>
			</td>
			{cells?.map((cell, index) => {
				return (
					<td key={`table-image-row-${index}`}>
						{cell === 'true' ? <Icon name="check" color="--primary-dark-green" /> : null}
					</td>
				);
			})}
		</tr>
	);
}
