import { ContainerFluid, Divider, Section } from '@/atoms';
import DynamicComponent from '@/bloks/DynamicComponent';
import { SectionPriceCardLargeStoryblok } from '@/components';
import styles from './SectionPriceCardLarge.module.scss';

interface Props {
	blok: SectionPriceCardLargeStoryblok;
	meta: Record<string, any>;
}

export const SectionPriceCardLarge: React.FC<Props> = ({ blok, meta }) => {
	const { header, priceCard } = blok;
	return (
		<ContainerFluid>
			<Section>
				<div className={styles.header}>
					{header?.map((header) => <DynamicComponent blok={header} key={header._uid} />)}
					<Divider className={styles.divider} />
				</div>
				{priceCard?.map((priceCard) => <DynamicComponent blok={priceCard} key={priceCard._uid} meta={meta} />)}
			</Section>
		</ContainerFluid>
	);
};
