import { ContainerFluid, Section } from '@/atoms';
import { ZendeskForm } from '@/bloks/Form';
import { HeaderCommon } from '@/bloks/Other';
import { SectionZendeskFormStoryblok, ZendeskFormStoryblok } from '@/components';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { ISbStoryData } from '@/types/storyblok';
import styles from './SectionZendeskForm.module.scss';

interface Props {
	blok: SectionZendeskFormStoryblok;
	meta?: { zone?: number };
}

export function SectionZendeskForm({ blok, meta }: Props) {
	const form = blok.form as unknown as ISbStoryData<ZendeskFormStoryblok>;
	const header = blok.header?.find((h) => h.titlePart1);
	const contentPiece = header?.titlePart2 ? `${header?.titlePart1} ${header.titlePart2}` : `${header?.titlePart1}`;

	return (
		<ImpressionTrackingProvider
			value={{ contentName: `${meta?.zone}|SectionZendeskForm`, contentPiece, contentTarget: 'Skicka' }}
		>
			<ContainerFluid>
				<Section>
					<div className={styles.container}>
						{header && (
							<HeaderCommon
								key={header._uid}
								{...HeaderCommon.blokProps({ blok: header })}
								layout="center"
								titleSize="h3"
								marginBottom="3xl"
							/>
						)}
						{form?.content && (
							<ZendeskForm {...ZendeskForm.blokProps({ blok: form.content, meta: { centerFeedbackText: true } })} />
						)}
					</div>
				</Section>
			</ContainerFluid>
		</ImpressionTrackingProvider>
	);
}
