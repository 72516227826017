import { ShortTextStoryblok, ThemeStoryblok } from '@/components';
import { cls } from '@/utils';
import React, { CSSProperties } from 'react';
import styles from './ShortText.module.scss';

interface Props {
	blok: ShortTextStoryblok;
	meta: { theme: ThemeStoryblok['theme']; maxWidth: string };
}

export function ShortText({ blok, meta }: Props) {
	const alignment = blok.alignment ?? 'right';

	const style = {
		textAlign: alignment,
		maxWidth: meta.maxWidth ?? '100%',
		float: alignment !== 'center' ? alignment : 'none',
	} as CSSProperties;

	return (
		<p style={style} className={cls(styles.text, styles[`theme--${meta?.theme ?? 'white'}`])}>
			{blok.text ?? ''}
		</p>
	);
}

export default ShortText;
