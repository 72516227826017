import { ContainerFluid, Grid, Heading, Section } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { SectionPartnersStoryblok } from '@/components';
import { ImageAsset } from '@/metabloks';
import { editableBlok } from '@/utils/storyblok';
import styles from './SectionPartners.module.scss';

interface Props {
	blok: SectionPartnersStoryblok;
}

export const SectionPartners: React.FC<Props> = ({ blok }) => {
	const maxGridColumns = blok.partners && blok.partners.length <= 6 ? blok.partners.length : 6;
	return (
		<ContainerFluid marginBottom="none">
			<Section {...editableBlok(blok)}>
				<div className={styles.container}>
					{blok?.title && (
						<Heading
							as="div"
							size="h3"
							className={styles.title}
							marginBottom="2xl"
							title={blok?.title}
							data-testid="section-partner-header-h3"
						/>
					)}
					<Grid columns={{ base: 2, xxl: maxGridColumns, xl: 3, md: 3 }} colGap="lg">
						{blok.partners?.map((partnerImage) => (
							<Grid.Item key={partnerImage.id} className={styles.imageCol}>
								<ImageAsset absolute asset={partnerImage} filters="smart" />
							</Grid.Item>
						))}
					</Grid>
					<footer className={styles.footer}>
						<div>
							{blok.linkButtons?.map((button) => (
								<LinkButton
									key={button._uid}
									{...LinkButton.blokProps({ blok: button })}
									variant="text"
									className={styles.button}
								/>
							))}
						</div>
					</footer>
				</div>
			</Section>
		</ContainerFluid>
	);
};
