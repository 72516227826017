import { ContainerFluid, Section } from '@/atoms';
import { CardFiveGroupStoryblok, ThemeStoryblok } from '@/components';
import { ThemeProvider } from '@/contexts/theme';
import { Themes } from '@/types';
import DynamicComponent from '../DynamicComponent';
import styles from './CardFiveGroup.module.scss';

interface Props {
	blok: CardFiveGroupStoryblok;
	meta: Record<string, any>;
}

export type CardTheme = Required<ThemeStoryblok['theme']> | 'lightYellow' | 'yellow';
const themes: Themes['theme'][] = ['lightYellow', 'yellow'];

/**
 * Group of three yellow cards (CardFive and/or CardFiveForm)
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=326%3A11065
 */
export const CardFiveGroup: React.FC<Props> = ({ blok, meta }) => {
	const cards = blok.cards?.map((card, index: number) => [card, themes[index % themes.length] ?? 'yellow']);

	return (
		<ContainerFluid>
			<Section className={styles.container}>
				{cards?.map(([card, theme]: any, index) => (
					<ThemeProvider key={card._uid} theme={theme}>
						<DynamicComponent
							blok={card}
							meta={{ ...meta, theme, isFirstCard: index == 0, isLastCard: index + 1 === cards.length }}
						/>
					</ThemeProvider>
				))}
			</Section>
		</ContainerFluid>
	);
};
