import { Grid, PageLayout, Section } from '@/atoms';
import { CardFiveGroupStoryblok, SinglePageStoryblok, SubMenuStoryblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { ISbStoryData } from '@/types/storyblok';
import { editableBlok } from '@/utils/storyblok';
import DynamicComponent from '../DynamicComponent';
import styles from './SinglePage.module.scss';

interface Props {
	blok: SinglePageStoryblok;
	meta: Record<string, any>;
}

export const SinglePage: React.FC<Props> = ({ blok, meta }) => {
	const contact = blok?.contact as unknown as ISbStoryData<CardFiveGroupStoryblok>;
	const subMenu = blok?.subMenu as unknown as ISbStoryData<SubMenuStoryblok>;
	const { hasSubMenuV1 } = useSubMenu();

	return (
		<PageLayout subMenu={subMenu} exits={contact} meta={meta}>
			<main {...editableBlok(blok)}>
				<Section padding={{ base: 'none' }}>
					<Grid columns={hasSubMenuV1 ? { base: 1 } : { base: '1fr', sm: '6fr 3fr' }} colGap="3xl">
						<Grid.Item className={styles.section}>
							{blok?.zone1?.map((blok) => (
								<DynamicComponent blok={blok} key={blok._uid} meta={{ zone: 1, isParent: false }} />
							))}
							{blok?.zone2?.map((blok) => (
								<DynamicComponent blok={blok} key={blok._uid} meta={{ zone: 2, isParent: false }} />
							))}
							{blok?.zone3?.map((blok) => (
								<DynamicComponent blok={blok} key={blok._uid} meta={{ zone: 3, isParent: false }} />
							))}
						</Grid.Item>
					</Grid>
				</Section>
			</main>
		</PageLayout>
	);
};
