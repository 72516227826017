import { Table } from '@/bloks';
import { LinkButton } from '@/bloks/Button';
import { TableImageStoryblok } from '@/components';
import { ImageAsset } from '@/metabloks';
import styles from './TableImage.module.scss';
import TableImageRow from './TableImageRow';

export interface Props {
	blok: TableImageStoryblok;
	dataTable: Table;
}

export function TableImageDesktop(props: Props) {
	const { blok, dataTable } = props;

	return (
		<table className={styles.table}>
			<thead>
				<tr>
					<th></th>
					{blok.columns.map((column) => {
						return (
							<th key={column._uid} className={styles.imageCol}>
								<div className={styles.imageAsset}>
									<ImageAsset
										absolute
										asset={column.image}
										fill
										resize="0x30"
										objectFit="contain"
										objectPosition="center"
										filters="smart"
									/>
								</div>
							</th>
						);
					})}
				</tr>
			</thead>
			<tbody>
				{blok.rows?.map((row, index) => {
					const rowData = dataTable.rows[index];
					if (!rowData) {
						return null;
					}
					return <TableImageRow key={row._uid} blok={row} cells={rowData?.columns} label={rowData?.name} />;
				})}
				<tr>
					<td></td>
					{blok.columns.map((column) => {
						return (
							<td key={column._uid} className={styles.imageCol}>
								{column.linkButton?.map((btn) => (
									<div key={btn._uid} className={styles.btnContainer}>
										<LinkButton {...LinkButton.blokProps({ blok: btn })} variant="text" />
									</div>
								))}
							</td>
						);
					})}
				</tr>
			</tbody>
		</table>
	);
}
