import { BrandLine, Grid, Heading, Text } from '@/atoms';
import { UspCardStoryblok } from '@/components';
import { ImageAsset } from '@/metabloks';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import DynamicComponent from '../DynamicComponent';
import { renderRichText } from '../RichText';
import styles from './UspCard.module.scss';

interface Props {
	blok: UspCardStoryblok;
	meta?: {
		showBrandline?: boolean;
	};
}

/**
 * @deprecated Will be replaced by UspLarge
 *
 * TODO: Remove GREEN-791
 *
 * UspCard component.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=91%3A3583
 */
export const UspCard: React.FC<Props> = ({ blok, meta }) => {
	const { list, text, title, image } = blok;
	const showBrandline = meta?.showBrandline ?? false;

	return (
		<Grid columns={{ base: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }}>
			<div {...editableBlok(blok)} className={cls(styles.container, list && list?.length && styles.withList)}>
				<Grid.Item>
					{image?.filename && (
						<div className={styles.image}>
							<ImageAsset absolute asset={image} objectFit="cover" />
						</div>
					)}
					{showBrandline && <BrandLine />}
					{title && (
						<div className={styles.heading}>
							<Heading as="h3" size="h5" title={title} className={styles.title} />
						</div>
					)}
					{text && (
						<Text as="div" className={styles.text}>
							{renderRichText(text)}
						</Text>
					)}
				</Grid.Item>

				{list && list?.length > 0 && (
					<Grid.Item>
						<div className={styles.listContainer}>
							<DynamicComponent blok={list[0]} meta={{ bold: false, defaultIcon: 'check' }} />
						</div>
					</Grid.Item>
				)}
			</div>
		</Grid>
	);
};
