import { BrandLine, ContainerFluid, Heading, Section } from '@/atoms';
import { LinkButtonStoryblok, StoryTextComponentStoryblok } from '@/components';
import { LinkButton } from '../Button';
import { renderRichText } from '../RichText';
import styles from './StoryTextComponent.module.scss';

interface Props {
	blok: StoryTextComponentStoryblok;
}

/**
 * Section component that renders a big title and optional image
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=293%3A9496
 */
export const StoryTextComponent: React.FC<Props> = ({ blok }) => {
	const { title, text, linkButtons } = blok;

	return (
		<ContainerFluid>
			<Section className={styles.container}>
				{title && <BrandLine />}
				{title && <Heading as="h3" size="h5" title={title} data-testid="story-text-component-header-h5" />}
				<div className={!title ? styles.withoutTitle : undefined}>{renderRichText(text)}</div>
				{linkButtons &&
					linkButtons.map((button: LinkButtonStoryblok) => (
						<LinkButton key={button._uid} {...LinkButton.blokProps({ blok: button })} variant="text" />
					))}
			</Section>
		</ContainerFluid>
	);
};
