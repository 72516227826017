/**
 * Format a date to string according to swedish ISO 8601 style.
 *
 * example: `'2022-01-04'`
 */
export const formatDate = (date: Date | string): string => new Date(date).toLocaleDateString('sv');

/**
 * Format time of a date as hours and minutes according to swedish ISO 8601.
 *
 * example: `'13:37'`
 * If you change this function, remember to enable the test in formatDate.test.ts
 */
export const formatTime = (date: Date | string): string =>
	new Date(date).toLocaleTimeString('sv', { hour: '2-digit', minute: '2-digit' });

export function formatLongDate(date: Date | string): string {
	return new Date(date).toLocaleString('SV-SE', {
		weekday: 'long',
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	});
}

export function formatDateMonthYear(date: Date | string): string {
	const newDate = new Date(date).toLocaleString('sv-SE', { month: 'long', year: 'numeric' });
	return newDate;
}
