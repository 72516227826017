import { getColor } from '@/colors';
import { cls } from '@/utils';
import { CSSProperties } from 'react';
import { Icon } from '../Icon';
import { Text } from '../Text';
import styles from './Chip.module.scss';

interface Props {
	text: string;
	type?: 'default' | 'removable';
	onRemove?: (param: any) => void;
	color?: '--accent-pink' | '--bg-beige-extra-light';
	className?: string;
}

export const Chip: React.FC<Props> = ({
	text,
	type = 'default',
	onRemove,
	color = '--bg-beige-extra-light',
	className,
}) => {
	const style = {
		'--background-color': getColor(color),
	} as CSSProperties;

	return (
		<div className={cls(styles.chip, className)} style={style}>
			<Text className={styles.chipText} marginBottom="none">
				{text}
			</Text>
			{type === 'removable' && (
				<div className={styles.iconHitBox} onClick={onRemove}>
					<Icon className={styles.icon} name="xmark" color="--border-hovered-color" size="1x" />
				</div>
			)}
		</div>
	);
};
