import {
	Button,
	Checkbox,
	ContainerFluid,
	FieldValidationProvider,
	FormValidationProvider,
	Grid,
	Heading,
	Icon,
	Select,
	SelectOption,
	Text,
	TextField,
} from '@/atoms';
import { SalaryCalculatorStoryblok } from '@/components';
import { logger } from '@/server/serverLogger';
import { fetchApi, formatMoney, isError } from '@/utils';
import { handleFormSubmitEvent } from '@/utils/piwik';
import { Validator } from '@/validation';
import { ApiErrorCode } from 'types/ApiErrorCode';
import React, { useEffect, useState } from 'react';
import { t } from 'ttag';
import calcStyles from '../CalculatorStyling.module.scss';
import counties from './Counties';
import styles from './SalaryCalculator.module.scss';
import { SalaryResult } from './SalaryResult';
import taxTables from './data/taxTables.json';

const scope = 'skatteverket';

const scopedFieldId = (fieldName: string) => `salary_calculator_field_${fieldName}`;

const isNumeric = (value: string | null): boolean => (value != null ? /[0-9 ]+/.test(value) : false);

const isEmpty = (value: string | null | undefined): boolean => ['', undefined, null].includes(value);

const grossSalaryMaxValue = 500000;

const validateGrossSalaryField: Validator = (value) => {
	if (parseInt(value!.replace(/\D/g, '')) === 0) {
		return t`Ange en lön som är högre än 0`;
	}
	if (parseInt(value!.replace(/\D/g, '')) > grossSalaryMaxValue) {
		return `${t`Fältets värde får inte överskrida`} ${formatMoney(grossSalaryMaxValue)}`;
	}
	return true;
};

const fieldIds = {
	county: scopedFieldId('county'),
	grossSalary: scopedFieldId('grossSalary'),
	memberOfChurch: scopedFieldId('memberOfChurch'),
	churchCongregation: scopedFieldId('churchCongregation'),
};

async function getTaxCalculations(taxTable: number, grossSalary: string) {
	const result = await fetchApi('taxcalculations-v1', taxTable, grossSalary);

	if (isError(result)) {
		const error: ApiErrorCode = 'failed.to.fetch.tax.calculations';
		logger.warn({ scope, error }, 'Could not fetch tax calculations');
	}

	return result;
}

interface CongregationInfo {
	name: string;
	tax: number;
}

interface CountyInfo {
	name: string;
	tax: number;
	congregations: CongregationInfo[];
}

export type NetPayInfo = {
	netPay: number | undefined;
	taxAmount: number | undefined;
	employersDeclaration: number | undefined;
	grossSalary: string | undefined;
};

const formatName = (name: string) => {
	const protectedWords = ['och', 'med', 'församling', 'domkyrkoförsamling'];

	return name
		.toLowerCase()
		.split(' ')
		.map((word) => {
			if (protectedWords.includes(word)) {
				return word;
			}

			if (word.indexOf('-') > -1) {
				return word
					.split('-')
					.map((wordPart) => {
						return wordPart.charAt(0).toUpperCase() + wordPart.substring(1);
					})
					.join('-');
			}

			return word.charAt(0).toUpperCase() + word.substring(1);
		})
		.join(' ');
};

const emptyCountyInfo = {
	name: '',
	tax: 0,
	congregations: [],
};

const useCountyInfo = (county: string) => {
	const [countyInfoMap, setCountyInfoMap] = useState<CountyInfo>(emptyCountyInfo);
	const [error, setError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (!county) {
			return;
		}
		setError(false);
		setIsLoading(true);

		const results = taxTables.filter((r) => r.kommun === county.toUpperCase());

		setCountyInfoMap((state) => ({
			...state,
			[county]: {
				name: results[0]['kommun'],
				tax: results[0]['summa, exkl. kyrkoavgift'],
				congregations: results.map((congregation: { [x: string]: any }) => ({
					name: formatName(congregation['församling']),
					tax: congregation['summa, inkl. kyrkoavgift'],
				})),
			},
		}));
		setIsLoading(false);
	}, [county, error]);

	return [(countyInfoMap as any)[county] || emptyCountyInfo, error, isLoading];
};

interface Props {
	blok: SalaryCalculatorStoryblok;
}

export const SalaryCalculator: React.FC<Props> = ({ blok }) => {
	const [county, setCounty] = useState('');
	const [grossSalary, setGrossSalary] = useState('');
	const [memberOfChurch, setMemberOfChurch] = useState(false);
	const [churchCongregation, setChurchCongregation] = useState<string | null>(null);
	const [hasCalculationError, setHasCalculationError] = useState(false);
	const isGrossSalaryTooHigh = parseInt(grossSalary.replace(/\D/g, '')) > grossSalaryMaxValue;
	const isGrossSalaryZero = parseInt(grossSalary.replace(/\D/g, '')) === 0;
	const [taxIsUndefined, setTaxIsUndefined] = useState(false);

	const [countyInfo, hasTaxError, isLoading] = useCountyInfo(county);

	const [netPayInfo, setNetPayInfo] = useState<NetPayInfo>({
		netPay: undefined,
		taxAmount: undefined,
		employersDeclaration: undefined,
		grossSalary: undefined,
	});

	const getTaxValue = () => {
		if (churchCongregation) {
			return countyInfo?.congregations?.find(
				(congregation: { name: string }) => congregation.name == churchCongregation,
			).tax;
		}
		return countyInfo.tax;
	};

	async function calculateSalary(e: { preventDefault: () => void }) {
		e.preventDefault();

		handleFormSubmitEvent({ type: 'calculator', formId: 'salary-calculator' });

		if (isLoading) {
			return;
		}

		const parsedGrossSalary = grossSalary.replace(/\D/g, '');

		setHasCalculationError(false);
		setTaxIsUndefined(false);

		const taxValue = getTaxValue();

		if (taxValue === undefined) {
			setTaxIsUndefined(true);
			return;
		}

		// Avrunda skattesatsen till heltal. Det avrundade heltalet är din skattetabell. 32:50 = tabell 32. 32:51 = tabell 33.
		// https://www.skatteverket.se/privat/skatter/arbeteochinkomst/skattetabeller.4.18e1b10334ebe8bc80005221.html#!/start
		const result = await getTaxCalculations(-Math.round(-taxValue), parsedGrossSalary);

		if (isError(result)) {
			setHasCalculationError(true);
			return;
		}

		setNetPayInfo({
			netPay: result.body.data.netPay,
			taxAmount: result.body.data.taxAmount,
			employersDeclaration: result.body.data.employersDeclaration,
			grossSalary: parsedGrossSalary,
		});
	}

	useEffect(() => {
		setChurchCongregation(null);
	}, [memberOfChurch, countyInfo.congregations]);

	const countyOptions: SelectOption[] = counties?.map((countyOption) => ({
		id: countyOption,
		label: countyOption,
		value: countyOption,
	}));

	const churchCongregationOptions: SelectOption[] = countyInfo?.congregations?.map((congregation: any) => ({
		id: congregation.name,
		label: congregation.name,
		value: congregation.name,
	}));

	return (
		<ContainerFluid marginBottom="3xl">
			<div data-testid="salary-calculator">
				<Grid columns={{ base: 1, lg: 2 }}>
					<Grid.Item className={calcStyles.calculator}>
						<div className={calcStyles.headingRow}>
							<Icon name="calculator" />
							<Heading
								marginBottom="none"
								as="div"
								size="h5"
								className={calcStyles.heading}
								title={t`Lön efter skatt`}
							/>
						</div>
						<FormValidationProvider>
							<div className={calcStyles.formContentContainer}>
								<div className={calcStyles.formContainer}>
									<form onSubmit={calculateSalary} className={calcStyles.form}>
										<Text className={styles.label} marginBottom="2xs">{t`Vilken kommun bor du i?`}</Text>
										<Select
											title={t`Välj kommun`}
											aria-label={t`Välj kommun`}
											data={countyOptions}
											onSelect={(value) => setCounty(value)}
											selected={countyOptions.filter((option) => option.value === county)?.[0] || null}
											className={styles.input}
											searchable
											required
											id={fieldIds.county}
											hideXMark
										/>

										<Text className={styles.label} marginBottom="2xs">{t`Vad har du för månadslön?`}</Text>
										<FieldValidationProvider id={fieldIds.grossSalary}>
											<TextField
												title={t`Ange månadslön`}
												aria-label={t`Ange månadslön`}
												id={fieldIds.grossSalary}
												type="number"
												value={grossSalary}
												onChange={(e) => {
													setGrossSalary(e.target.value);
												}}
												maxLength={9}
												validators={validateGrossSalaryField}
												required
												className={styles.numberInputFields}
											/>
										</FieldValidationProvider>
										{!isEmpty(grossSalary) && !isNumeric(grossSalary) && (
											<Text size="small">{t`Fältet får endast innehålla siffror`}</Text>
										)}

										<div className={styles.memberOfChurch}>
											<Checkbox
												onChecked={setMemberOfChurch}
												checked={memberOfChurch}
												label={t`Medlem i svenska kyrkan?`}
											/>
										</div>
										{memberOfChurch && (
											<>
												<Text className={styles.label} marginBottom="2xs">{t`Vilken församling tillhör du?`}</Text>
												<Select
													title={t`Välj församling`}
													aria-label={t`Välj församling`}
													data={churchCongregationOptions}
													selected={
														churchCongregationOptions.filter((option) => option.value === churchCongregation)?.[0] ||
														null
													}
													onSelect={(value) => setChurchCongregation(value)}
													onClear={() => setChurchCongregation(null)}
													id={fieldIds.churchCongregation}
													className={styles.churchSelector}
													hideXMark
												/>
											</>
										)}
										<div className={calcStyles.buttonsContainer}>
											<Button
												aria-label={t`Beräkna`}
												className={calcStyles.calculateButton}
												disabled={
													isEmpty(county) ||
													(memberOfChurch && isEmpty(churchCongregation)) ||
													(countyInfo.tax === 0 && !isLoading) ||
													hasTaxError ||
													isGrossSalaryTooHigh ||
													isGrossSalaryZero
												}
												type="submit"
												size="large"
												isLoading={isLoading}
											>{t`Beräkna`}</Button>
											<div className={calcStyles.clearFieldsBtnContainer}>
												<button
													type="button"
													className={calcStyles.clearFieldsButton}
													onClick={() => {
														setGrossSalary('');
														setCounty('');
														setMemberOfChurch(false);
														setNetPayInfo({
															employersDeclaration: 0,
															grossSalary: '',
															netPay: 0,
															taxAmount: 0,
														});
													}}
													data-test-id="employee-calculator_clear-fields"
												>{t`Rensa`}</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</FormValidationProvider>
					</Grid.Item>
					<SalaryResult netPayInfo={netPayInfo} blok={blok} />
				</Grid>
			</div>
			{(hasTaxError || taxIsUndefined) && (
				<Text
					className={styles.errorMessage}
				>{t`Ett fel inträffade, ladda om sidan och försök igen. Kontakta vår support om problemet kvarstår.`}</Text>
			)}
			{hasCalculationError && (
				<Text
					className={styles.errorMessage}
				>{t`Ett fel inträffade vid beräkning av lön, kontakta vår support om problemet kvarstår.`}</Text>
			)}
		</ContainerFluid>
	);
};
