import { BrandLine, ContainerFluid, Grid, Heading, Section, ShowMoreButton, Text } from '@/atoms';
import { renderRichText } from '@/bloks/RichText';
import { RichtextStoryblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import React from 'react';
import { t } from 'ttag';
import { ArticleLarge } from '../../bloks/Article/ArticleLarge';
import { useBlogPosts } from '../../contexts/blogposts/BlogProvider';
import styles from './ArticleGrid.module.scss';
import { ArticleGridFilter } from './ArticleGridFilter';

interface Props {
	title: string;
	intro?: RichtextStoryblok | string;
	titleAsH1?: boolean;
	meta: Record<string, any>;
}

/**
 *
 * Grid of Large Articles. Used in Blog index page.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=398%3A10641
 *
 */
export const ArticleGrid = ({ title, intro, titleAsH1, meta }: Props) => {
	const blog = useBlogPosts();
	const { hasSubMenuV1 } = useSubMenu();
	const articles = blog.posts;

	const articleGroup1 = articles
		?.slice(0, 2)
		.map((article, index) => (
			<ArticleLarge
				key={article.slug}
				article={article}
				className={`grid-article1--${index + 1}`}
				meta={{ ...meta, indexPage: true }}
				priority
			/>
		));
	const articleGroup2 = articles
		?.slice(2, 4)
		.map((article, index) => (
			<ArticleLarge
				key={article.slug}
				article={article}
				className={`grid-article2--${index + 1}`}
				meta={{ ...meta, indexPage: true }}
				priority
			/>
		));
	const articleGroup3 = articles
		?.slice(4, 7)
		.map((article, index) => (
			<ArticleLarge
				key={article.slug}
				article={article}
				className={`grid-article3--${index + 1}`}
				meta={{ ...meta, indexPage: true }}
			/>
		));
	const remainingArticles = articles
		?.slice(7)
		.map((article, index) => (
			<ArticleLarge
				key={article.slug}
				article={article}
				className={`grid-articles--${index + 1}`}
				meta={{ ...meta, indexPage: true }}
			/>
		));

	return (
		<ContainerFluid>
			<Section
				className={styles.container}
				paddingY={{ base: 'none', md: hasSubMenuV1 ? '3xl' : '5xl' }}
				paddingX={{ base: 'none', md: hasSubMenuV1 ? 'none' : '5xl' }}
			>
				<header className={styles.header}>
					<div className={styles.stripes}>
						<BrandLine marginBottom="xl" />
						{title && <Heading as={titleAsH1 ? 'h1' : 'h2'} size="h2" title={title} />}
					</div>
					{intro && <Text as="div">{renderRichText(intro)}</Text>}
				</header>

				<ArticleGridFilter />

				<Grid className={styles.group1} columns={{ base: 1, md: 4, lg: 6 }}>
					{articleGroup1}
				</Grid>
				<Grid className={styles.group2} columns={{ base: 1, md: 4, lg: 6 }}>
					{articleGroup2}
				</Grid>
				<Grid className={styles.group3} columns={{ base: 1, md: 2, lg: 3 }}>
					{articleGroup3}
					{blog.showMore && remainingArticles}
				</Grid>

				{remainingArticles.length > 0 && !blog.showMore && (
					<ShowMoreButton onClick={() => blog.setShowMore(true)}>{t`Ladda fler inlägg`}</ShowMoreButton>
				)}
				{remainingArticles.length > 6 && blog.showMore && (
					<ShowMoreButton
						onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
						icon="chevron-up"
						iconPosition="top"
					>{t`Gå till toppen`}</ShowMoreButton>
				)}
			</Section>
		</ContainerFluid>
	);
};
