import { ContainerFluid, Heading, Section } from '@/atoms';
import { LinkButton, buildLinkProps } from '@/bloks/Button/LinkButton';
import DynamicComponent from '@/bloks/DynamicComponent';
import { CardFiveGroupStoryblok, IntegrationLibraryPageStoryblok } from '@/components';
import { useIntegrations } from '@/contexts/Integration';
import { ISbStoryData } from '@/types/storyblok';
import React from 'react';
import styles from './IntegrationLibraryPage.module.scss';

interface Props {
	blok: IntegrationLibraryPageStoryblok;
}

export const IntegrationLibraryPage: React.FC<Props> = ({ blok }) => {
	const { header } = blok;
	const contact = blok?.contact as unknown as ISbStoryData<CardFiveGroupStoryblok>;

	const { integrations } = useIntegrations();

	if (!integrations) return null;

	const categories =
		integrations.length > 0 &&
		Array.from(
			new Set<string>(
				integrations.map((integration) => integration.categories.map((category) => category.categoryNameSv)).flat(),
			),
		);
	const integrationsByCategory =
		categories &&
		categories
			.map((category) => {
				return {
					name: category,
					integrations: integrations.filter((integration) => {
						const categories = integration.categories.map((category) => category.categoryNameSv);
						return categories.includes(category);
					}),
				};
			})
			.sort((a, b) => a.name.localeCompare(b.name, 'SV'));

	return (
		<ContainerFluid marginBottom="none">
			{header?.map((header) => <DynamicComponent blok={header} key={header._uid} />)}
			<Section as="div" paddingX={{ base: 'md', md: '3xl' }}>
				{integrationsByCategory &&
					integrationsByCategory.map((category, i) => (
						<div key={i} className={styles.container}>
							<Heading as="div" title={category.name} size="h5" marginBottom="sm" />
							<ul className={styles.linksContainer}>
								{category.integrations.map((integration) => {
									const { href } = buildLinkProps({
										url: `integrationer/integration/${integration.urlSlug}`,
										linktype: 'story',
									});
									return (
										<LinkButton key={integration.appId} href={href} variant="text">
											{integration.appName}
										</LinkButton>
									);
								})}
							</ul>
						</div>
					))}
			</Section>
			{contact && <DynamicComponent blok={contact.content} />}
		</ContainerFluid>
	);
};
