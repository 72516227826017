import { Button, Icon, IconButton } from '@/atoms';
import { Video, VideoProps } from '@/bloks/Other';
import { useImpressionTracking } from '@/contexts/piwik/ImpressionTrackingProvider';
import { cls } from '@/utils';
import Vimeo from '@vimeo/player';
import { useEffect, useRef, useState } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { t } from 'ttag';
import styles from './VideoButton.module.scss';

interface Props {
	video: VideoProps;
	buttonText: string;
}

export const VideoButton = ({ video, buttonText }: Props) => {
	const [showVideo, setShowVideo] = useState(false);
	const vimeoIframeRef = useRef<HTMLIFrameElement>(null);
	const playerRef = useRef<Vimeo | null>(null);
	const { setTargetInteraction } = useImpressionTracking();

	useEffect(() => {
		if (vimeoIframeRef.current) {
			const player = new Vimeo(vimeoIframeRef.current);
			playerRef.current = player;
		}
	}, []);

	const closeVideo = () => {
		playerRef?.current?.pause();
		setShowVideo(false);
	};

	return (
		<>
			<RemoveScroll enabled={showVideo}>
				<div className={cls(styles.overlay, showVideo && styles.showVideo)}>
					<div className={styles.videoWrapper}>
						<IconButton
							iconName="close"
							className={styles.closeIcon}
							onClick={closeVideo}
							aria-label={t`Stäng video`}
							iconColor="--text-dark"
							iconSize="2x"
						/>
						{video && <Video {...video} forwardedRef={vimeoIframeRef} />}
					</div>
				</div>
			</RemoveScroll>

			<Button
				onClick={() => {
					setShowVideo(true);
					setTargetInteraction({ contentTarget: 'default' });
				}}
				variant={'filled'}
			>
				<Icon name={'play'} className={styles.buttonIcon} />
				{buttonText}
			</Button>
		</>
	);
};
