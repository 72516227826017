import { Icon } from '@/atoms';
import { Table } from '@/bloks';
import { LinkButton } from '@/bloks/Button';
import { TableImageColumnStoryblok, TableImageRowStoryblok } from '@/components';
import { ImageAsset } from '@/metabloks';
import { cls } from '@/utils';
import { MouseEvent, useCallback, useState } from 'react';
import styles from './TableImage.module.scss';
import TableImageRow from './TableImageRow';

export interface Props {
	column: TableImageColumnStoryblok;
	rows: TableImageRowStoryblok[] | undefined;
	dataTable: Table;
	columnIndex: number;
}

export function TableImageMobile(props: Props) {
	const { column, rows, dataTable, columnIndex } = props;

	const [isOpen, setIsOpen] = useState<boolean>(false);

	const handleRowClick = useCallback((event: MouseEvent<HTMLTableSectionElement>) => {
		event.preventDefault();
		setIsOpen((oldState) => {
			return !oldState;
		});
	}, []);

	return (
		<div className={styles.mobileTableWrapper}>
			<table className={styles.table}>
				<thead onClick={handleRowClick}>
					<tr>
						<th key={column._uid}>
							<div className={styles.imageAsset}>
								<ImageAsset
									absolute
									asset={column.image}
									fill
									resize="0x40"
									objectFit="contain"
									objectPosition="center"
									filters="smart"
								/>
							</div>
						</th>
						<th>
							<Icon name="chevron-up" size="1x" className={cls(styles.icon, { [styles.closed]: !isOpen })} />
						</th>
					</tr>
				</thead>
				<tbody className={isOpen ? cls(styles.open) : cls(styles.closed)}>
					{rows?.map((row, index) => {
						const rowData = dataTable.rows[index];
						const cells = [rowData?.columns[columnIndex]];
						if (!rowData) {
							return null;
						}
						return <TableImageRow key={row._uid} blok={row} cells={cells} label={rowData?.name} />;
					})}

					{column.linkButton?.map((btn) => (
						<tr key={btn._uid}>
							<td>
								<LinkButton {...LinkButton.blokProps({ blok: btn })} variant="text" />
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}
