import { Grid } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { CardLargeGroupStoryblok } from '@/components';
import { CardLarge, CardLargeProps } from '../../CardLarge/CardLarge';

interface BlokProps {
	blok: CardLargeGroupStoryblok;
	meta?: { zone?: number };
}

/**
 * The layout prop in CardLargeGroupStoryblok decides layout of the first card in the group.
 * Every second of the following cards will get the oposit layout.
 */
export const getLayout = (layout: CardLargeGroupStoryblok['layout'], index: number) => {
	if (index % 2 === 0) {
		return layout;
	}

	if (layout === 'image-left') {
		return 'image-right';
	}

	return 'image-left';
};

const blokProps = ({ blok, meta }: BlokProps): Props => ({
	cards: blok.cards?.map((card) => CardLarge.blokProps({ blok: card, meta })),
	layout: blok.layout,
	compact: blok.compact,
	zone: meta?.zone,
});

interface Props {
	cards: CardLargeProps[];
	layout?: 'image-right' | 'image-left';
	compact?: boolean;
	zone?: number;
}

export const CardLargeGroup: Blok<Props, BlokProps> = ({ cards, layout = 'image-right', compact = false, zone }) => {
	return (
		<Grid rowGap="3xl">
			{cards.map((card, i) => (
				<Grid.Item key={i}>
					<CardLarge {...card} layout={getLayout(layout, i)} compact={compact} zone={zone} />
				</Grid.Item>
			))}
		</Grid>
	);
};

CardLargeGroup.blokProps = blokProps;
