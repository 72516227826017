import { Heading, Icon, LinkAnchor } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { Colors } from '@/colors';
import { IconLinkStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { IconName } from 'icons/types';
import { LinkButton, buildLinkProps } from '../LinkButton';
import styles from './IconLink.module.scss';

interface BlokProps {
	blok: IconLinkStoryblok;
}

const blokProps = ({ blok }: BlokProps): IconLinkProps => ({
	href: buildLinkProps(blok.link).href,
	text: blok.text,
	icon: blok.icon?.[0]?.name ?? 'check',
	_editable: blok?._editable,
});

export interface IconLinkProps {
	href: string;
	text: string;
	icon: IconName;
	iconColor?: Colors;
	size?: 'medium' | 'large';
	/**
	 * A short label describing what a click on this button does.
	 * Shown to screen readers and useful for end-to-end tests as well.
	 */
	'aria-label'?: string;
	iconSize?: SizeProp;
	className?: string;
	_editable?: string;
}

/**
 * A LinkButton of variant text with an icon to the left.
 */
export const IconLink: Blok<IconLinkProps, BlokProps> = ({
	href,
	text,
	icon,
	iconColor,
	size = 'medium',
	'aria-label': ariaLabel,
	iconSize,
	className,
	_editable,
}) => {
	if (size === 'medium') {
		return (
			<div className={cls(styles['size--medium'], className)} {...editableBlok({ _editable })}>
				<Icon
					name={icon}
					size={iconSize ?? '2x'}
					className={cls(styles.icon, icon === 'play' && styles.playIcon)}
					color={iconColor}
				/>
				<LinkButton href={href} variant="text">
					{text}
				</LinkButton>
			</div>
		);
	}

	return (
		<div className={cls(styles['size--large'], className)} {...editableBlok({ _editable })}>
			<LinkAnchor href={href} aria-label={ariaLabel}>
				<Icon name={icon} color={iconColor} className={styles.icon} />
			</LinkAnchor>
			<Heading as="div" size="h6" title={text} marginBottom="none" />
		</div>
	);
};

IconLink.blokProps = blokProps;
