import { ArticleCardProps } from '@/types/article';
import React, { createContext, useContext } from 'react';

/**
 * Provider of related posts (sharing tags)
 */
export const useRelatedArticles = () => {
	return useContext(Context);
};

interface RelatedArticlesState {
	relatedArticles: ArticleCardProps[];
}
const Context = createContext<RelatedArticlesState>({
	relatedArticles: [],
});

interface Props {
	children: React.ReactNode;
	relatedArticles: ArticleCardProps[];
}
export const RelatedArticlesProvider: React.FC<Props> = ({ children, relatedArticles }) => {
	const value = { relatedArticles };
	return <Context.Provider value={value}>{children}</Context.Provider>;
};
