import { BrandLine, ContainerFluid, Grid, Heading, Section, Text } from '@/atoms';
import { StoryTestimonialStoryblok, ThemeStoryblok } from '@/components';
import { ImageAsset } from '@/metabloks';
import { editableBlok } from '@/utils/storyblok';
import styles from './StoryTestimonial.module.scss';

/**
 * Testimonial comopnent for Story Page
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=158%3A6693
 */

interface Props {
	blok: StoryTestimonialStoryblok;
	meta?: {
		theme?: ThemeStoryblok['theme'];
	};
}

export const StoryTestimonial: React.FC<Props> = ({ blok, meta = {} }) => {
	const { title, shortText, quote, name, jobTitle, image } = blok;
	const theme = meta?.theme ?? 'dark-green';

	return (
		<ContainerFluid theme={theme}>
			<Section>
				<Grid
					columns={{ base: 1, lg: 2 }}
					colGap="3xl"
					rowGap="2xl"
					{...editableBlok(blok)}
					className={styles.container}
				>
					<Grid.Item className={styles.leftColumn}>
						<div>
							<BrandLine />
							{title && <Heading as="div" size="h5" title={title} data-testid="story-testimonial-header-h5" />}
						</div>
						{shortText && <Text className={styles.introduction}>{shortText}</Text>}
					</Grid.Item>

					<Grid.Item className={styles.rightColumn}>
						<div className={styles.details}>
							{quote && (
								<Text className={styles.quote} size="ingress" data-testid="story-testimonial-ingress">
									{quote}
								</Text>
							)}
							{name && <Text>{name}</Text>}
							{jobTitle && <Text>{jobTitle}</Text>}
						</div>
						{image?.filename && (
							<div className={styles.image}>
								<ImageAsset absolute asset={image} objectFit="cover" fill filters="smart" />
							</div>
						)}
					</Grid.Item>
				</Grid>
			</Section>
		</ContainerFluid>
	);
};
