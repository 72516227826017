import { ContainerFluid, Grid, Heading, Section, Text } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import DynamicComponent from '@/bloks/DynamicComponent';
import { HeaderCommon } from '@/bloks/Other';
import { renderRichText } from '@/bloks/RichText';
import {
	LinkButtonStoryblok,
	NumberedStepStoryblok,
	NumberedStepWithBlokStoryblok,
	SectionNumberedStepGroupStoryblok,
	ThemeStoryblok,
} from '@/components';
import { ImageAsset } from '@/metabloks';
import { ISbComponentType } from '@/types/storyblok';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { useGridColumns } from 'hooks/useGridColumns';
import styles from './SectionNumberedStepGroup.module.scss';

interface StepProps {
	blok: NumberedStepStoryblok | NumberedStepWithBlokStoryblok;
	index: number;
	theme?: ThemeStoryblok['theme'];
	layout: 'vertical' | 'horizontal';
	meta?: Record<string, any>;
}

const NumberedStep: React.FC<StepProps> = ({ blok, index, layout, meta = {} }) => {
	return (
		<div
			className={cls(styles.stepContainer, {
				[styles.layoutVertical]: layout === 'vertical',
				[styles.withBlok]: blok.component === 'NumberedStepWithBlok',
			})}
		>
			<div className={styles.stepIndex}>{index}</div>
			{blok.component === 'NumberedStep' ? (
				<TextBlok title={blok.title} text={blok.text} link={blok?.link} />
			) : (
				<NumberedStepWithBlok blok={blok} meta={meta} />
			)}
		</div>
	);
};

interface NumberedStepWithBlokProps {
	blok: NumberedStepWithBlokStoryblok;
	meta: Record<string, any>;
}

const NumberedStepWithBlok: React.FC<NumberedStepWithBlokProps> = ({ blok, meta }) => {
	return (
		<Grid columns={{ base: 1 }} rowGap="xl">
			{(blok.title || blok.text || blok.link) && (
				<Grid.Item className={styles.textColumn}>
					<div className={styles.text}>
						<TextBlok title={blok.title} text={blok.text} link={blok?.link} />
					</div>
					<div>{blok?.image?.filename && <ImageAsset absolute asset={blok?.image} />}</div>
				</Grid.Item>
			)}
			{blok.body?.map((blok: ISbComponentType<any>) => (
				<Grid.Item key={blok._uid}>
					<DynamicComponent blok={blok} meta={{ ...meta, layout: 'twoColumns', isParent: false }} />
				</Grid.Item>
			))}
		</Grid>
	);
};

interface TextBlokProps {
	title?: string;
	text?: string | any;
	link?: LinkButtonStoryblok[];
}

const TextBlok: React.FC<TextBlokProps> = ({ title, text, link }) => (
	<>
		<div className={styles.textContainer}>
			{title && <Heading as="div" size="h5" title={title} data-testid="section-numbered-step-header-h5" />}
			{text && typeof text === 'string' ? <Text>{text}</Text> : renderRichText(text)}
		</div>
		<div className={styles.linkContainer}>
			{link?.map((link) => <LinkButton key={link._uid} {...LinkButton.blokProps({ blok: link })} variant="text" />)}
		</div>
	</>
);

interface Props {
	blok: SectionNumberedStepGroupStoryblok;
	meta?: Record<string, any>;
}

/**
 * Section component with numbered steps
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=1851%3A29782
 */
export const SectionNumberedStepGroup: React.FC<Props> = ({ blok, meta }) => {
	const nrOfSteps = blok.steps?.length;
	const columns = useGridColumns(nrOfSteps ?? 0);
	const layout = blok?.layout ?? 'horizontal';
	const { header, additionalText } = blok;

	return (
		<ContainerFluid>
			<Section {...editableBlok(blok)}>
				{header?.map((header) => (
					<HeaderCommon {...HeaderCommon.blokProps({ blok: header, meta })} marginBottom="3xl" key={header._uid} />
				))}

				<Grid
					columns={layout === 'horizontal' ? columns : { base: 1, lg: 1, md: 1 }}
					colGap="3xl"
					rowGap="2xl"
					className={additionalText && styles.gridContainer}
				>
					{blok.steps?.map((blok: any, index: number) => (
						<Grid.Item key={blok._uid}>
							<NumberedStep blok={blok} index={index + 1} layout={layout} meta={meta} />
						</Grid.Item>
					))}
				</Grid>
				{additionalText && <div className={styles.additionalText}>{renderRichText(additionalText)}</div>}
			</Section>
		</ContainerFluid>
	);
};
