import { Card, Heading } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { LinkButton, LinkButtonProps } from '@/bloks/Button';
import { List, ListProps } from '@/bloks/Other/List';
import { CardMediumListStoryblok } from '@/components';
import { useImpressionTracking } from '@/contexts/piwik/ImpressionTrackingProvider';
import { useTheme } from '@/contexts/theme';
import { ImageAsset, ImageAssetProps } from '@/metabloks';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import cardStyles from '../CardMedium/CardMedium.module.scss';
import styles from './CardMediumList.module.scss';

interface BlokProps {
	blok: CardMediumListStoryblok;
	meta?: {
		nrOfCards?: number;
	};
}

const blokProps = ({ blok, meta }: BlokProps): CardMediumListProps => ({
	image: blok.image,
	smallTitle: blok?.smallTitle,
	title: blok.title,
	listItems: blok.list
		?.map((list) =>
			list.listItems.map((item) => ({
				text: item.text,
			})),
		)
		.flatMap((item) => item),
	linkButton: blok?.linkButton?.[0]?.link && LinkButton.blokProps({ blok: blok.linkButton[0] }),
	nrOfCards: meta?.nrOfCards,
	_editable: blok._editable,
});

export interface CardMediumListProps {
	image?: ImageAssetProps;
	smallTitle?: string;
	title: string;
	linkButton?: LinkButtonProps;
	listItems: ListProps['listItems'];
	/**
	 * Passed from parent group to determent image height
	 */
	nrOfCards?: number;
	_editable?: string;
}

/**
 * @deprecated Will be replaced by CardMedium
 */
export const CardMediumList: Blok<CardMediumListProps, BlokProps> = ({
	image,
	smallTitle,
	title,
	listItems,
	linkButton,
	nrOfCards = 1,
	_editable,
}) => {
	const themeInfo = useTheme();
	const { setTargetInteraction } = useImpressionTracking();
	return (
		<Card {...editableBlok({ _editable })} className={cardStyles.card} border={false} padding="none">
			{image?.filename && (
				<div className={cls(cardStyles.image, cardStyles[`image-height--${nrOfCards}`])}>
					<ImageAsset absolute asset={image} objectFit="cover" fill objectPosition="center center" filters="smart" />
				</div>
			)}
			<div className={cardStyles.textContainer}>
				{smallTitle && (
					<Heading
						as="div"
						size="h6"
						title={smallTitle}
						className={cardStyles.smallTitle}
						marginBottom="md"
						smallTitle
					/>
				)}
				{title && <Heading as="div" size="h5" title={title} />}

				{listItems && (
					<List
						listItems={listItems}
						iconName="check"
						iconSize="sm"
						className={styles.list}
						iconColor={themeInfo.name !== 'green' ? '--primary-green' : undefined}
						maxAmount={3}
					/>
				)}

				{linkButton && (
					<div className={cardStyles.buttonContainer}>
						<LinkButton
							{...linkButton}
							onClick={() => {
								setTargetInteraction({ contentTarget: linkButton.children as string });
							}}
						/>
					</div>
				)}
			</div>
		</Card>
	);
};

CardMediumList.blokProps = blokProps;
