import { Icon } from '@/atoms';
import { cls } from '@/utils';
import { EmailShareButton, FacebookShareButton, LinkedinShareButton } from 'next-share';
import styles from './SocialMedia.module.scss';

interface Props {
	href: string;
	title: string;
	background?: boolean;
	className?: string;
}

export function SocialMedia({ href, title, background, className }: Props) {
	const url = `https://www.fortnox.se/${href}`;

	return (
		<div className={cls(styles.container, background && styles.background, className)}>
			<div className={styles.socialMediaItem}>
				<FacebookShareButton url={url} quote={title}>
					<Icon
						className={styles.facebook}
						name="facebook-f"
						type="brand"
						color={background ? '--text-dark' : '--read-only'}
						size={background ? '1x' : undefined}
					/>
				</FacebookShareButton>
			</div>
			<div className={styles.socialMediaItem}>
				<LinkedinShareButton url={url}>
					<Icon
						name="linkedin-in"
						type="brand"
						color={background ? '--text-dark' : '--read-only'}
						size={background ? '1x' : undefined}
					/>
				</LinkedinShareButton>
			</div>
			<div className={styles.socialMediaItem}>
				<EmailShareButton url={url} subject={title}>
					<Icon
						name="envelope"
						type="solid"
						color={background ? '--text-dark' : '--read-only'}
						size={background ? '1x' : undefined}
					/>
				</EmailShareButton>
			</div>
		</div>
	);
}

export default SocialMedia;
