import { NetPayInfo } from '@/bloks/Calculator/SalaryCalculator/SalaryCalculator';
import { Table } from '@/bloks/Section/SectionComparisonTable';
import { SectionComparisonTableStoryblok } from '@/components';
import { Accountant } from '@/contexts/accountants/types';
import {
	CompanyInformation,
	Datasource,
	EducationApplication,
	KpOrder,
	ZendeskCustomField,
	ZendeskHandlerResponse,
} from '@/types';
import { SkatteverketEntryscapeResponse } from '@/types/skatteverket';
import { CampaignCode } from '../../pages/api/fortnox-website/eduadmin-v1/[campaignCode]';
import { getCurrentYear } from '../getCurrentYear';
import { fetchApiBasePath as basePath, fetchApiRaw } from './fetchApiRaw';

/**
 * Handlers for making requests to each of the pages/api/fortnox-website
 * endpoints.
 *
 * Usage:
 *
 * ```typescript
 *     import { fetchApi } from '@/utils/fetchApi';
 *
 *     fetchApi('accountants-v1', 'foobar').then(
 *       (res) => {
 *     	   // { data: AccountantStoryblok[], response: Response }
 *       },
 *       (err) => {
 *     	   // FetchApiError
 *       }
 *     )
 * ```
 *
 *	=> GET /api/fortnox-website/accountants-v1?s=foobar
 *
 *	=> ISbStoryData[]>
 */
const fetchApiHandlers = {
	'accountants-v1'(lat: number, lon: number) {
		const searchParams = new URLSearchParams();
		searchParams.set('latitude', lat.toString());
		searchParams.set('longitude', lon.toString());
		const request = new Request(`${basePath}/accountants-v1?${searchParams}`);
		return { call: () => fetchApiRaw<Accountant[]>(request) };
	},

	'cision-v1'(body: { captchaSolution?: string; data: string }, headers?: HeadersInit) {
		const request = new Request(`${basePath}/cision-v1`, {
			method: 'POST',
			headers,
			body: JSON.stringify(body),
		});
		return { call: () => fetchApiRaw(request) };
	},

	'company-lookup-v1/[...orgNumber]'(orgNumber: string) {
		const request = new Request(`${basePath}/company-lookup-v1/${orgNumber}`);
		return { call: () => fetchApiRaw<{ data: CompanyInformation }>(request) };
	},

	'eduadmin-v1/[campaignCode]'(campaignCode: string, eventId: number) {
		const searchParams = new URLSearchParams();
		searchParams.set('eventId', eventId.toString());
		const request = new Request(`${basePath}/eduadmin-v1/${campaignCode}?${searchParams}`);
		return { call: () => fetchApiRaw<{ data: CampaignCode }>(request) };
	},

	'eduadmin-v1'(
		body: { captchaSolution?: string; data: EducationApplication; postToZendesk: boolean },
		headers?: HeadersInit,
	) {
		const request = new Request(`${basePath}/eduadmin-v1`, {
			method: 'POST',
			headers,
			body: JSON.stringify(body),
		});
		return { call: () => fetchApiRaw(request) };
	},

	'kp-order-v1'(body: { captchaSolution?: string; data: KpOrder }, headers?: HeadersInit) {
		const request = new Request(`${basePath}/kp-order-v1`, {
			method: 'POST',
			headers,
			body: JSON.stringify(body),
		});
		return { call: () => fetchApiRaw(request) };
	},

	'datasources-v1'({
		segments,
		dimensions,
	}: {
		segments: SectionComparisonTableStoryblok['segments'] | ['bankcomparison'];
		dimensions: string[];
	}) {
		const searchParams = new URLSearchParams();
		searchParams.set('d', dimensions.join(','));
		searchParams.set('t', segments.join(','));
		const request = new Request(`${basePath}/datasources-v1?${searchParams}`);
		return { call: () => fetchApiRaw<{ data: Table[] }>(request) };
	},
	'datasources-v2'({ slug }: { slug: string }) {
		const request = new Request(`${basePath}/datasources-v2/${slug}`);
		return { call: () => fetchApiRaw<{ data: Datasource[] }>(request) };
	},

	'upsales-v1'(body: { captchaSolution?: string; data: string }, headers?: HeadersInit) {
		const request = new Request(`${basePath}/upsales-v1`, { method: 'POST', headers, body: JSON.stringify(body) });
		return { call: () => fetchApiRaw(request) };
	},

	'zendesk-v1/[...formId] POST'(
		body: {
			data: {
				customFormFields: ZendeskCustomField[];
				groupId: string;
				priority: string;
				subject: string;
				firstName: string;
				lastName: string;
				email: string;
				formId: string;
				description: string;
				attachments: File[];
				formTag: string;
			};
			captchaSolution?: string | undefined;
		},
		headers?: HeadersInit,
	) {
		const formData = new FormData();

		formData.append('groupId', body.data.groupId);
		formData.append('priority', body.data.priority);
		formData.append('subject', body.data.subject);
		formData.append('firstName', body.data.firstName);
		formData.append('lastName', body.data.lastName);
		formData.append('email', body.data.email);
		formData.append('formId', body.data.formId);
		formData.append('description', body.data.description);
		formData.append('captchaSolution', body.captchaSolution ?? '');
		formData.append('formTag', body.data.formTag ?? '');

		body.data.customFormFields.forEach((customField) => {
			formData.append(customField.id.toString(), customField.value.toString());
		});

		body.data.attachments.forEach((attachment) => {
			formData.append('file[]', attachment);
		});

		const request = new Request(`${basePath}/zendesk-v1/${body.data.formId}`, {
			body: formData,
			method: 'POST',
			headers,
		});

		return { call: () => fetchApiRaw(request) };
	},

	'zendesk-v1/[...formId] GET'(formId: string, locale: string) {
		const request = new Request(`${basePath}/zendesk-v1/${formId}?locale=${locale}`);
		return { call: () => fetchApiRaw<{ data: ZendeskHandlerResponse }>(request) };
	},

	'skatteverket-v1'(county: string) {
		const searchParams = new URLSearchParams();
		searchParams.set('county', county.toUpperCase());
		searchParams.set('year', getCurrentYear());
		const request = new Request(`${basePath}/skatteverket-v1?${searchParams}`);
		return { call: () => fetchApiRaw<{ data: SkatteverketEntryscapeResponse }>(request) };
	},

	'taxcalculations-v1'(taxTable: number, grossSalary: string) {
		const request = new Request(`${basePath}/taxcalculations-v1/${taxTable}/${getCurrentYear()}/${grossSalary}`);
		return { call: () => fetchApiRaw<{ data: NetPayInfo }>(request) };
	},

	'integration-category-lookup-v1'(categoryName: string) {
		const request = new Request(`${basePath}/integrations-lookup-v1/${categoryName}`);
		return { call: () => fetchApiRaw<{ data: NetPayInfo }>(request) };
	},
} as const;

export { fetchApiHandlers as _fetchApiHandlers };
