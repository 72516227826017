import { ContainerFluid, Grid, Section } from '@/atoms';
import { CardJobAd, CardJobAdProps } from '@/bloks/Card/CardJobAd';
import { HeaderCommon, HeaderCommonProps } from '@/bloks/Other';
import { SectionJobAdsStoryblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { cls } from '@/utils';
import { useGridColumns } from 'hooks/useGridColumns';
import styles from './SectionJobAds.module.scss';

interface BlokProps {
	blok: SectionJobAdsStoryblok;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	header: blok.header?.[0] && HeaderCommon.blokProps({ blok: blok.header?.[0] }),
	cards: blok.cards.map((card) => ({ ...CardJobAd.blokProps({ blok: card }) })),
});

interface Props {
	header: HeaderCommonProps;
	cards: CardJobAdProps[];
}

/**
 * @deprecated
 */
export function SectionJobAds({ header, cards }: Props) {
	const columns = useGridColumns(cards?.length ?? 0);
	const { hasSubMenuV1 } = useSubMenu();
	return (
		<ContainerFluid>
			<Section>
				{header && <HeaderCommon {...header} layout="center" buttonVariant="text" marginBottom="3xl" />}
				{!!cards.length && (
					<Grid columns={columns} className={cls(styles.gridContainer, hasSubMenuV1 && styles.hasSubMenuV1)}>
						{cards?.map((card, index) => (
							<Grid.Item key={index}>
								<CardJobAd {...card} />
							</Grid.Item>
						))}
					</Grid>
				)}
			</Section>
		</ContainerFluid>
	);
}

SectionJobAds.blokProps = blokProps;
