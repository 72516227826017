import { Grid, Heading, Icon, Section, Text } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { LinkButtonStoryblok, SectionInfoStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import styles from './SectionInfo.module.scss';

interface Props {
	blok: SectionInfoStoryblok;
	className: string;
	meta?: {
		layout?: 'twoColumns';
	};
}

export const SectionInfo: React.FC<Props> = ({ blok, meta }) => {
	const { icon, text, title, titleEmphasis, link } = blok;
	const theme = blok?.theme?.[0]?.theme;

	return (
		<Section
			{...editableBlok(blok)}
			className={cls(styles.container, styles[`theme--${theme}`])}
			padding={{ base: 'xl', md: 'xl' }}
		>
			<Grid columns={meta?.layout === 'twoColumns' ? { base: 1, lg: 2 } : { base: 1, lg: 1, xl: 1 }}>
				<Grid.Item>
					{title && (
						<div className={styles.headerWrapper}>
							{icon &&
								icon.map((i) => {
									return <Icon key={i._uid} className={styles.icon} size="3x" name={i.name || 'info-circle'} />;
								})}

							{titleEmphasis ? (
								<Heading
									className={styles.title}
									marginBottom="none"
									as="div"
									size="h5"
									title={<span className={styles.titleEmphasis}>{titleEmphasis}</span>}
									titlePart2={title}
								/>
							) : (
								<Heading className={styles.title} marginBottom="none" as="div" size="h5" title={title} />
							)}
						</div>
					)}
					<Text marginBottom="none">{text}</Text>
				</Grid.Item>
				<Grid.Item className={cls(meta?.layout === 'twoColumns' && styles.layoutTwoColumns)}>
					{link?.map((link: LinkButtonStoryblok) => (
						<div key={link._uid} className={styles.linkWrapper}>
							<LinkButton key={link._uid} {...LinkButton.blokProps({ blok: link })} className={styles.btn} />
						</div>
					))}
				</Grid.Item>
			</Grid>
		</Section>
	);
};
