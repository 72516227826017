import { Grid } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { LinkButton, LinkButtonProps } from '@/bloks/Button/LinkButton';
import { LinkToggleStoryblok } from '@/components';
import { useAnimationContext } from '@/contexts/Animations';
import { useImpressionTracking } from '@/contexts/piwik/ImpressionTrackingProvider';
import { ThemeProvider } from '@/contexts/theme';
import { getThemeInfo } from '@/themes';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { usePathname } from 'next/navigation';
import styles from './LinkToggle.module.scss';

interface BlokProps {
	blok: LinkToggleStoryblok;
}

const blokProps = ({ blok }: BlokProps): LinkToggleProps => ({
	linkButtons: blok.linkButtons.map((linkButton) => LinkButton.blokProps({ blok: linkButton })),
	theme: blok.theme ?? 'green',
	_editable: blok._editable,
});

export interface LinkToggleProps {
	linkButtons?: LinkButtonProps[];
	theme?: LinkToggleStoryblok['theme'];
	_editable?: string;
}

function useGridColumns(items: number) {
	if (items === 2) {
		return { base: 1, md: items };
	}
	return { base: 1, lg: items };
}

function getSecondaryTheme(theme: LinkToggleStoryblok['theme']) {
	switch (theme) {
		case 'green':
			return 'white';

		default:
			return 'green';
	}
}

export const LinkToggle: Blok<LinkToggleProps, BlokProps> = ({ linkButtons, _editable, theme = 'green' }) => {
	if (!theme) {
		theme = 'green';
	}
	const themeInfo = getThemeInfo(theme);
	const secondaryTheme = getSecondaryTheme(themeInfo.name as LinkToggleStoryblok['theme']);

	const secondaryThemeInfo = getThemeInfo(secondaryTheme);
	const { setTriggerAnimation } = useAnimationContext();
	const { setTargetInteraction } = useImpressionTracking();
	const pathname = usePathname();

	return (
		<ThemeProvider theme={theme}>
			<div {...editableBlok({ _editable })} className={cls(styles.linkToggle, themeInfo?.styles.linkToggleBgColor)}>
				<Grid columns={useGridColumns(linkButtons?.length ?? 1)} colGap="lg" rowGap="xs">
					{linkButtons?.map((linkButton, index) => {
						const activeLink = pathname === linkButton.href;

						return (
							<Grid.Item key={`link-button-${index}`}>
								<ThemeProvider theme={activeLink ? theme : secondaryTheme}>
									<LinkButton
										block
										href={linkButton.href}
										className={cls(
											activeLink && styles.disableHoverEffect,
											!activeLink && secondaryThemeInfo.styles.linkToggleInactive,
											!activeLink && styles.inactiveLink,
											activeLink && themeInfo.styles.linkToggleActive,
										)}
										onClick={() => {
											setTriggerAnimation(true);
											setTargetInteraction({ contentTarget: linkButton.children as string });
										}}
									>
										{linkButton.children}
									</LinkButton>
								</ThemeProvider>
							</Grid.Item>
						);
					})}
				</Grid>
			</div>
		</ThemeProvider>
	);
};
LinkToggle.blokProps = blokProps;
