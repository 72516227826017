import { fetchApi, slugifyName } from '@/utils';
import { FetchApiError } from '@/utils/fetchApi/fetchApiRaw';
import { useRouter } from 'next/router';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { SortByOptions, SortOrderOptions, sortIntegrations } from './SortIntegrations';
import { Integration, IntegrationCategory, Integrator } from './types';

/**
 * List of integrations from App Market
 */
export const useIntegrations = () => {
	return useContext(Context);
};

interface ContextProps {
	integrations?: Integration[];
	integrator?: Integrator;
	featuredIntegrations?: Integration[];
	filteredIntegrations: Integration[];
	searchInput: string | null;
	setSearchInput: (input: string | null) => void;
	setSelectedCategory: (category: string | null) => void;
	selectedCategory: string | null;
	setSelectedCategoryDescription: (categoryDescription: string) => void;
	selectedCategoryDescription: string;
	setSelectedCompany: (input: string | null) => void;
	selectedCompany: string | null;
	setSortOrder: (input: string | undefined) => void;
	sortOrder: string | undefined;
	categories: IntegrationCategory[] | undefined;
}

const Context = createContext<ContextProps>({
	searchInput: '',
	setSearchInput: () => {},
	integrations: [],
	integrator: {
		tenantId: '',
		companyName: '',
		about: '',
		successStory: '',
		contactEmail: '',
		contactPhone: '',
		contactHomepage: '',
		companyLogoKey: '',
		companyLogoUrl: '',
		companyNameSlug: '',
	},
	featuredIntegrations: [],
	filteredIntegrations: [],
	setSelectedCategory: () => {},
	selectedCategory: null,
	setSelectedCategoryDescription: () => {},
	selectedCategoryDescription: '',
	setSelectedCompany: () => {},
	selectedCompany: '',
	setSortOrder: () => {},
	sortOrder: '',
	categories: [],
});

interface Props {
	children: React.ReactNode;
	integrations?: Integration[];
	integrator?: Integrator;
	featuredIntegrations?: Integration[];
	categories?: IntegrationCategory[];
}

const fetchCategoryDescription = async (slug: string) => {
	const data = await fetchApi('integration-category-lookup-v1', slug).catch((err) => err as FetchApiError);

	return data.body as string;
};

export const IntegrationListProvider: React.FC<Props> = ({
	children,
	integrations,
	integrator,
	featuredIntegrations,
	categories,
}) => {
	const router = useRouter();
	const urlSearchParams = new URLSearchParams(`?${router.asPath.split('?')[1]}`);
	const categoryParam = urlSearchParams.get('category') ?? '';
	const companyParam = urlSearchParams.get('company') ?? '';
	const searchParam = urlSearchParams.get('search') ?? '';
	const sortParam = urlSearchParams.get('sort') ?? '';
	const sortDirectionParam = urlSearchParams.get('direction') ?? 'asc';

	const [selectedCategory, setSelectedCategory] = useState<string | null>(categoryParam);
	const [selectedCategoryDescription, setSelectedCategoryDescription] = useState('');
	const [selectedCompany, setSelectedCompany] = useState<string | null>(companyParam);
	const [searchInput, setSearchInput] = useState<string | null>(searchParam);
	const [sortOrder, setSortOrder] = useState<string | undefined>(sortParam);
	const orderDirection = useMemo(() => sortDirectionParam, [sortDirectionParam]);

	useEffect(() => {
		const getCategoryDescription = async (selectedCategory: string) => {
			const categoryDescription = await fetchCategoryDescription(selectedCategory);
			setSelectedCategoryDescription(categoryDescription ?? '');
		};

		if (selectedCategory) {
			getCategoryDescription(selectedCategory);
		}
		if (!router.isReady) {
			return;
		}

		if (
			Number(categoryParam || 0) === Number(selectedCategory) &&
			(companyParam || '') === selectedCompany &&
			(searchParam || '') === searchInput
		) {
			return;
		}
		const query = { ...router.query };

		if (selectedCompany) {
			query.company = selectedCompany;
		} else {
			delete query.company;
		}

		if (selectedCategory) {
			query.category = selectedCategory.toString();
		} else {
			delete query.category;
		}
		if (searchInput) {
			query.search = searchInput;
		} else {
			delete query.search;
		}
		if (sortOrder) {
			query.sort = sortOrder;
		} else {
			delete query.sort;
		}

		router.replace({ pathname: router.pathname, query }, undefined, { scroll: false });
	}, [selectedCategory, selectedCompany, searchInput, sortOrder]); // eslint-disable-line react-hooks/exhaustive-deps

	const filteredIntegrations = useMemo(() => {
		if (!integrations?.length) {
			return [];
		}

		const search = searchInput?.toLowerCase() ?? '';

		const searchInputFilter = (integration: Integration) =>
			integration.appName?.toLowerCase().includes(search) ||
			integration.summary?.toLowerCase().includes(search) ||
			integration.contactInformation.companyName.toLowerCase().includes(search);

		const selectedCompanyFilter = ({ contactInformation, urlSlug }: Integration) => {
			const slug = urlSlug ? urlSlug.split('/')[0] : slugifyName(contactInformation.companyName ?? '');
			return slug === selectedCompany;
		};

		const selectedCategoryFilter = (integration: Integration) => {
			return integration?.categories?.some((cat) => slugifyName(cat.categoryNameSv) === selectedCategory);
		};

		const filters = [
			...(selectedCategory ? [selectedCategoryFilter] : []),
			...(selectedCompany ? [selectedCompanyFilter] : []),
			...(searchInput ? [searchInputFilter] : []),
		];
		return sortIntegrations(
			integrations.filter((integration: Integration) => {
				return filters.every((filter) => filter(integration));
			}),
			sortOrder as SortByOptions,
			orderDirection as SortOrderOptions,
		);
	}, [integrations, selectedCompany, selectedCategory, searchInput, sortOrder, orderDirection]);

	const value = useMemo(
		() => ({
			integrations,
			integrator,
			featuredIntegrations,
			filteredIntegrations,
			setSelectedCategory,
			selectedCategory,
			setSelectedCategoryDescription,
			selectedCategoryDescription,
			searchInput,
			setSearchInput,
			selectedCompany,
			setSelectedCompany,
			setSortOrder,
			sortOrder,
			categories,
		}),
		[
			integrations,
			integrator,
			featuredIntegrations,
			filteredIntegrations,
			setSelectedCategory,
			selectedCategory,
			setSelectedCategoryDescription,
			selectedCategoryDescription,
			searchInput,
			setSearchInput,
			selectedCompany,
			setSelectedCompany,
			setSortOrder,
			sortOrder,
			categories,
		],
	);

	return <Context.Provider value={value}>{children}</Context.Provider>;
};
