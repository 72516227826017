import { BackgroundImage, BrandLine, ContainerFluid, Heading, Section, Text } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { StorySectionFourStoryblok, ThemeStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import styles from './StorySectionFour.module.scss';

interface Props {
	blok: StorySectionFourStoryblok;
	meta?: {
		theme?: ThemeStoryblok['theme'];
	};
}
export const StorySectionFour: React.FC<Props> = ({ blok, meta }) => {
	const { smallTitle, titlePart1, titlePart2, backgroundImage, text } = blok;
	const theme = meta?.theme ?? blok.theme?.[0]?.theme ?? 'white';

	return (
		<ContainerFluid theme={theme}>
			{blok?.backgroundImage?.filename && (
				<BackgroundImage
					asset={blok?.backgroundImage}
					overlay={true}
					overlayDirection="to-left"
					objectPosition="center center"
				/>
			)}
			<Section>
				<article
					{...editableBlok(blok)}
					className={cls(
						styles.container,
						blok.backgroundImage?.filename && styles.withImage,
						styles[`theme--${theme}`],
					)}
					data-testid="story-section-four-container"
				>
					{backgroundImage?.filename && <div className={styles.image} />}
					<div className={styles.content}>
						<div className={styles.inner}>
							<div className={styles.titles}>
								{smallTitle && (
									<>
										<BrandLine />
										<Heading
											as="div"
											size="h6"
											title={smallTitle}
											className={styles.smallTitle}
											data-testid="story-section-four-header-h6"
										/>
									</>
								)}
								{titlePart1 && (
									<Heading
										as="h2"
										data-testid="story-section-four-header-h2"
										title={titlePart1}
										titlePart2={titlePart2}
									/>
								)}
							</div>

							{text && <Text className={styles.text}>{text}</Text>}

							{blok.linkButtons?.map((button) => (
								<LinkButton key={button._uid} {...LinkButton.blokProps({ blok: button })} variant="text" />
							))}
						</div>
					</div>
				</article>
			</Section>
		</ContainerFluid>
	);
};
