import { Icon, LinkAnchor } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { Colors } from '@/colors';
import { SocMeLinkStoryblok } from '@/components';
import { cls } from '@/utils';
import { IconName } from 'icons/types';
import { t } from 'ttag';
import { buildLinkProps } from '../LinkButton';
import styles from './SocMeLink.module.scss';

interface BlokProps {
	blok: SocMeLinkStoryblok;
	meta?: {
		iconColor?: Colors;
	};
}

const blokProps = ({ blok, meta }: BlokProps): SocMeLinkProps => ({
	linkProps: buildLinkProps(blok.link!),
	icon: blok.icon?.[0]?.name ? blok.icon?.[0]?.name : 'facebook-f',
	iconColor: meta?.iconColor,
});

export interface SocMeLinkProps {
	linkProps: { href: string };
	icon: IconName;
	iconColor?: Colors;
	className?: string;
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

/**
 * Social media icon link.
 *
 * Used in footer.
 */
export const SocMeLink: Blok<SocMeLinkProps, BlokProps> = ({
	linkProps,
	icon,
	iconColor = '--text-light',
	className,
	onClick,
}) => {
	return (
		<div className={className}>
			<LinkAnchor
				{...linkProps}
				target="_blank"
				rel="noopener noreferrer"
				className={styles.link}
				onClick={onClick}
				title={`${t`Följ oss på`} ${linkProps.href.replace('https://', '')}`}
			>
				<Icon
					name={icon}
					type="brand"
					color={iconColor}
					className={cls(styles[`icon--${icon}`], styles.icon)}
					size="2x"
				/>
			</LinkAnchor>
		</div>
	);
};

SocMeLink.blokProps = blokProps;
