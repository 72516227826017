import { getHeadingAnchorId } from '@/atoms';
import { ExpansionPanel } from '@/atoms/ExpansionPanel/ExpansionPanel';
import { ExpansionPanelsProvider } from '@/atoms/ExpansionPanel/ExpansionPanelContext';
import { AccordionFaqStoryblok, RichtextStoryblok, ZendeskFormStoryblok } from '@/components';
import { ISbStoryData } from '@/types/storyblok';
import { isNotString } from '@/utils';
import { ZendeskForm } from '../../Form/ZendeskForm';
import { renderRichText } from '../../RichText';

interface Props {
	blok: AccordionFaqStoryblok;
	meta?: Record<string, any>;
}

/**
 * Accordion Faq component
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=158%3A6693
 */
export const AccordionFaq: React.FC<Props> = ({ blok, meta }) => {
	return (
		<ExpansionPanelsProvider>
			{blok.panels?.filter(isNotString).map((story, index) => renderPanel({ ...story.content, dataIdNr: index, meta }))}
		</ExpansionPanelsProvider>
	);
};
interface PanelProps {
	title: string;
	content?: RichtextStoryblok;
	form?: ISbStoryData<ZendeskFormStoryblok>;
	dataIdNr: number;
	meta?: Record<string, any>;
}

function renderPanel({ title, content, form, dataIdNr, meta }: PanelProps) {
	const id = getHeadingAnchorId(title);

	return (
		<ExpansionPanel key={id} panelId={id} compact={meta?.compact} theme={meta?.theme}>
			<ExpansionPanel.Header
				panelId={id}
				title={title}
				titleSize="h6"
				dataIdNr={dataIdNr}
				theme={meta?.theme}
				compact={meta?.compact}
			/>
			<ExpansionPanel.Content panelId={id} dataIdNr={dataIdNr} compact={meta?.compact}>
				{content && renderRichText(content)}
				{form && <ZendeskForm {...ZendeskForm.blokProps({ blok: form.content, meta })} />}
			</ExpansionPanel.Content>
		</ExpansionPanel>
	);
}
