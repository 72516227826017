import { Grid, Heading, RadiobuttonGroup, Text } from '@/atoms';
import { RadiobuttonProps } from '@/atoms/Radiobutton/Radiobutton';
import { ChangeCompanyItemStoryblok, CompanyItemGroupStoryblok } from '@/components';
import { cls, isNotString } from '@/utils';
import { useState } from 'react';
import { t } from 'ttag';
import { renderRichText } from '../../RichText/';
import styles from './CompanyItemGroup.module.scss';

interface Props {
	blok: CompanyItemGroupStoryblok;
}

export const CompanyItemGroup: React.FC<Props> = ({ blok }) => {
	const items = blok.item?.filter(isNotString) ?? [];

	const [selectedCompany, setSelectedCompany] = useState<ChangeCompanyItemStoryblok | null>(null);

	const handleChange = (event: any) => {
		const value = event.target.value;
		const selectedItem = items?.find((companyItem: any) => companyItem.uuid === value);
		if (selectedItem) setSelectedCompany(selectedItem.content);
	};

	const radioItems: RadiobuttonProps[] = items.map((item) => {
		return {
			name: 'company-item',
			onChange: handleChange,
			id: item.name,
			value: item.uuid,
			label: item.name,
			className: styles.radiobutton,
		};
	});

	return (
		<div className={styles.container}>
			<div className={styles.greenRow}>
				<Heading
					as="div"
					size="h6"
					title={t`Jag vill byta ifrån`}
					className={styles.headerText}
					data-testid="company-item-header-h6"
					marginBottom="none"
				/>
			</div>

			<div className={styles.radiobuttonGrid}>
				<RadiobuttonGroup options={radioItems} />
			</div>

			<Grid
				columns={{ base: 1, lg: 2 }}
				colGap="2xl"
				rowGap="2xl"
				className={cls(styles.selectedCompany, selectedCompany && styles.showSelectedCompany)}
			>
				<Grid.Item>
					<div className={styles.greenRow}>
						<Heading
							as="div"
							size="h6"
							title={t`Det här får du med dig från ` + selectedCompany?.name}
							className={styles.headerText}
							data-testid="change-company-what-you-get-header-h6"
							marginBottom="none"
						/>
					</div>
					<Text className={styles.text} as="div">
						{renderRichText(selectedCompany?.fromMoveBulletList)}
					</Text>
					<Text className={styles.text} as="div">
						{renderRichText(selectedCompany?.fromMoveText)}
					</Text>
				</Grid.Item>
				<Grid.Item>
					<div className={styles.greenRow}>
						<Heading
							as="div"
							size="h6"
							title={t`Mer om flytten`}
							className={styles.headerText}
							data-testid="change-company-more-info-header-h6"
							marginBottom="none"
						/>
					</div>
					<Text className={styles.text} as="div">
						{renderRichText(selectedCompany?.aboutMoveBulletList)}
					</Text>
					<Text className={styles.text} as="div">
						{renderRichText(selectedCompany?.aboutMoveText)}
					</Text>
				</Grid.Item>
			</Grid>
		</div>
	);
};
