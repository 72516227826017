import { Card, Heading, Link, Text } from '@/atoms';
import { LinkButton, LinkButtonProps, buildLinkProps } from '@/bloks/Button/LinkButton';
import { AssetStoryblok, CardSmallImageStoryblok } from '@/components';
import { useImpressionTracking } from '@/contexts/piwik/ImpressionTrackingProvider';
import { useThemeContext } from '@/contexts/theme';
import { ImageAsset } from '@/metabloks';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import React from 'react';
import { CardSmallIconProps } from '../CardSmallIcon';
import styles from './CardSmallImage.module.scss';

interface BlokProps {
	blok: CardSmallImageStoryblok;
	meta?: {
		textLayout?: 'left' | 'center';
		buttonVariant?: CardSmallIconProps['buttonVariant'];
		titleSize: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
		padding?: boolean;
	};
}

const blokProps = ({ blok, meta }: BlokProps): CardSmallImageProps => ({
	image: blok.image,
	title: blok.title,
	text: blok.text,
	linkButton: blok?.linkButton?.[0]?.link && LinkButton.blokProps({ blok: blok.linkButton[0] }),
	buttonVariant: meta?.buttonVariant ?? 'filled',
	textLayout: meta?.textLayout ?? 'left',
	titleSize: meta?.titleSize ?? 'h4',
	padding: meta?.padding,
	cardLink: (blok?.cardLink?.url || blok?.cardLink?.cached_url) && buildLinkProps(blok.cardLink).href,
	_editable: blok._editable,
});

export interface CardSmallImageProps {
	image?: AssetStoryblok;
	title: string;
	text?: string;
	linkButton?: LinkButtonProps;
	buttonVariant?: 'filled' | 'outlined' | 'text';
	textLayout?: 'left' | 'center';
	className?: string;
	noBackground?: boolean;
	titleSize: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	padding?: boolean;
	cardLink?: string;
	_editable?: string;
}

/**
 * @deprecated Will be replaced by CardSmall
 *
 * CardSmallImage is a simple card with a title, text and a small illustration image
 */
export function CardSmallImage({
	image,
	title,
	text,
	linkButton,
	buttonVariant = 'filled',
	_editable,
	textLayout,
	className,
	noBackground = false,
	titleSize,
	padding = false,
	cardLink,
}: CardSmallImageProps) {
	const { applyChildTheme } = useThemeContext();
	const { setTargetInteraction } = useImpressionTracking();

	const card = (
		<Card
			className={cls(
				styles.card,
				(applyChildTheme || padding) && styles.padding,
				styles[`textLayout--${textLayout}`],
				cardLink && styles.cardLink,
				className,
			)}
			padding="custom"
			{...editableBlok({ _editable })}
			noBackground={noBackground}
			border={false}
		>
			{image?.filename && (
				<div className={styles.image}>
					<ImageAsset absolute asset={image} objectFit="contain" fill />
				</div>
			)}
			<div className={styles.imageTextContainer}>
				<Heading as="div" size={titleSize} title={title} marginBottom={!text ? 'none' : 'md'} />
				{text && <Text marginBottom="none">{text}</Text>}

				{!cardLink && linkButton && (
					<div className={styles.buttonWrapper}>
						<LinkButton
							{...linkButton}
							variant={buttonVariant}
							onClick={() => {
								setTargetInteraction({ contentTarget: linkButton.children as string });
							}}
						/>
					</div>
				)}
			</div>
		</Card>
	);

	return cardLink ? (
		<Link
			href={cardLink}
			onClick={() => {
				setTargetInteraction({ contentTarget: cardLink });
			}}
		>
			{card}
		</Link>
	) : (
		card
	);
}

CardSmallImage.blokProps = blokProps;
