import { Icon, Section, Text } from '@/atoms';
import { LinkButton } from '@/bloks/Button/LinkButton';
import { CardFiveGroupStoryblok, PressReleasePostStoryblok } from '@/components';
import { CisionFile, usePost } from '@/contexts/News';
import { ISbStoryData } from '@/types/storyblok';
import { renderHtmlAsRichtext } from '@/utils';
import DynamicComponent from '../DynamicComponent';
import { HeaderArticle } from '../Header';
import styles from './ArticleLayout/ArticleLayout.module.scss';

interface Props {
	blok: PressReleasePostStoryblok;
	meta: Record<string, any>;
}

export const PressReleasePost: React.FC<Props> = ({ blok }) => {
	const { image } = blok;
	const { post } = usePost();

	if (!post) {
		return null;
	}

	const contact = blok?.contact as unknown as ISbStoryData<CardFiveGroupStoryblok>;

	const pressImage = post.Images?.length
		? {
				alt: post.Images[0].Description || post.Images[0].Title,
				copyright: post.Images[0].Photographer,
				filename: post.Images[0].UrlTo800x800ArResized,
				name: post.Images[0].Title,
				id: 0,
			}
		: image;

	return (
		<>
			<Section
				maxWidth="925px"
				paddingX={{ base: 'md', lg: 'none' }}
				paddingY={{ base: '3xl' }}
				className={styles.articleContainer}
			>
				<HeaderArticle
					h1Part1={post.Title}
					email="press@fortnox.se"
					image={pressImage ?? blok.image}
					tags={post.IsRegulatory ? ['Pressmeddelande', 'Regulatoriskt'] : ['Pressmeddelande']}
					published_at={post.LastChangeDate}
					first_published_at={post.PublishDate}
					full_slug={`om-fortnox/nyhetsrum-media/pressrelease/${post.EncryptedId}`}
				/>

				{post.Intro && (
					<div className={styles.introSection}>
						<div className={styles.introduction}>
							<Text>
								<strong>{post.Intro}</strong>
							</Text>
						</div>
					</div>
				)}

				<div className={styles.introSection}>{renderHtmlAsRichtext(post.HtmlBody)}</div>

				<div key={blok._uid} id={blok._uid}>
					{post.Files && (
						<ul className={styles.fileList}>
							{post.Files?.map((file: CisionFile, index: number) => {
								return (
									<li className={styles.listItem} key={index}>
										<Icon className={styles.icon} name={'file-pdf'} size={'1x'} />
										<LinkButton
											data-testid={file.FileName || `press-download-file-${index.toString()}`}
											href={file.Url}
											variant="text"
										>
											{file.Title || 'Ladda ner fil'}
										</LinkButton>
									</li>
								);
							})}
						</ul>
					)}
				</div>
			</Section>
			{contact && <DynamicComponent blok={contact.content} />}
		</>
	);
};
