import { ComparisonSegmentDescriptionStoryblok } from '@/components';
import React, { createContext, useContext } from 'react';

export const useSectionComparisonTableSegmentDescriptions = (): any => useContext(Context);

interface ContextProps {
	segmentDescriptions: ComparisonSegmentDescriptionStoryblok[];
	tables: any[];
}

export interface SectionComparisonSegmentDescription {
	description: string;
	availability: string[];
}

const Context = createContext<ContextProps>({
	segmentDescriptions: [],
	tables: [],
});

interface Props extends ContextProps {
	children: React.ReactNode;
	value: ContextProps;
}

export const SectionComparisonProvider: React.FC<Props> = ({ children, value }) => {
	return <Context.Provider value={value}>{children}</Context.Provider>;
};
