/* eslint-disable react-hooks/rules-of-hooks */
import { ServerPageConfig } from '@/server/config';
import { Env } from '@/server/config/types';
import { getCookie } from 'cookies-next';
import { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';

interface Props {
	children: React.ReactNode;
	config: Partial<ServerPageConfig>;
}

type ConfigState = ServerPageConfig & {
	setLocale: Dispatch<SetStateAction<ServerPageConfig['locale']>>;
	setCustomerType: Dispatch<SetStateAction<ServerPageConfig['customerType']>>;
};

/**
 * Get server config object, for current page
 */
export const useServerPageConfig = (): ConfigState => {
	return useContext(Context);
};

const initialConfig: ConfigState = {
	appsHost: '',
	preview: false,
	siteBaseUrl: '',
	captchaSiteKey: '',
	env: Env.Prod,
	englishVersion: false,
	breadcrumbsTheme: 'light',
	setLocale: () => {},
	piwikTracking: false,
	setCustomerType: () => {},
	offertaEnquiryCreateFormScriptUrl: '',
};

const Context = createContext<ConfigState>(initialConfig);

export const ConfigProvider: React.FC<Props> = ({ children, config }) => {
	const [locale, setLocale] = useState<ServerPageConfig['locale']>(config?.locale ?? 'sv');
	const [customerType, setCustomerType] = useState<ServerPageConfig['customerType']>(getCookie('customerType'));

	const value = {
		...initialConfig,
		...config,
		locale,
		setLocale,
		customerType,
		setCustomerType,
	};

	return <Context.Provider value={value}>{children}</Context.Provider>;
};
