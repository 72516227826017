import { ContainerFluid, Grid, Heading, Section } from '@/atoms';
import { HeaderCommon } from '@/bloks/Other';
import { SectionTestimonialLogosStoryblok, TestimonialGlobalStoryblok } from '@/components';
import { useGlobalMenu } from '@/contexts/menu/GlobalMenuProvider';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { ImageAsset } from '@/metabloks';
import { BigQuote } from '@/metabloks/BigQuote';
import { ISbStoryData } from '@/types/storyblok';
import { cls } from '@/utils';
import { gsap, useGSAP } from '@/utils/gsap';
import React, { useEffect, useRef, useState } from 'react';
import styles from './SectionTestimonialLogos.module.scss';

interface Props {
	blok: SectionTestimonialLogosStoryblok;
	meta?: { zone?: number };
}

export const SectionTestimonialLogos: React.FC<Props> = ({ blok, meta }) => {
	const { menuVersionV3 } = useGlobalMenu();
	const testimonials = blok?.testimonials as any as ISbStoryData<TestimonialGlobalStoryblok>[];
	const cards = [...testimonials, ...(blok.logo ?? [])];
	const nrOfLogoTypes = cards?.length - 1;
	const container = useRef<HTMLDivElement | null>(null);
	const slider = useRef<HTMLDivElement | null>(null);
	const [navBarHeight, setNavBarHeight] = useState(78);
	const { hasSubMenuV1 } = useSubMenu();

	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setNavBarHeight(Math.floor(document.querySelector('header')?.parentElement?.getBoundingClientRect().height ?? 0));
	}, []);

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsLoading(false);
		}, 500);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	useGSAP(
		() => {
			if (cards?.length === 1) {
				return;
			}

			if (!slider.current || !container.current || isLoading) {
				return;
			}

			const offset = hasSubMenuV1 ? navBarHeight + 16 : navBarHeight;

			const calculateContentWidth = () => {
				const viewportWidth = window.innerWidth;

				const mobileBreakpoint = 768;
				const ipadBreakpoint = 1024;

				if (!slider.current) {
					return 0;
				}

				if (viewportWidth <= mobileBreakpoint) {
					return slider.current.scrollWidth - slider.current.clientWidth;
				} else if (viewportWidth <= ipadBreakpoint) {
					return slider.current.scrollWidth - slider.current.clientWidth;
				} else {
					return slider.current.scrollWidth - slider.current.clientWidth;
				}
			};

			const snapTo = window.innerWidth <= 768 ? 1 / nrOfLogoTypes : 1;

			gsap.to(slider.current, {
				x: () => {
					return -calculateContentWidth();
				},
				ease: 'power4.inOut',
				scrollTrigger: {
					end: '+=1000',
					trigger: container.current,
					preventOverlaps: true,
					start: `top ${offset}`,
					toggleActions: 'restart pause resume pause',
					scrub: true,
					pin: true,
					invalidateOnRefresh: true,
					snap: {
						snapTo: snapTo,
						duration: { min: 0.02, max: 0.5 },
						delay: 0.7,
						ease: 'none',
					},
				},
			});
		},
		{ scope: container, dependencies: [cards?.length, isLoading], revertOnUpdate: true },
	);

	return (
		<ImpressionTrackingProvider
			value={{ contentName: `${meta?.zone}|SectionLogosSidescroll`, contentTarget: 'no interactions possible' }}
		>
			<div ref={container}>
				<ContainerFluid
					marginBottom="6xl"
					className={cls(styles.container, menuVersionV3 && styles.menuVersionV3)}
					theme="darkGreen"
				>
					<Section paddingX={{ base: 'none' }} paddingY={{ base: 'none' }} className={styles.section}>
						<div className={styles.headerMobile}>
							{blok?.title?.map((blok) => (
								<div className={styles.logoHeader} key={blok._uid}>
									<HeaderCommon
										{...HeaderCommon.blokProps({ blok })}
										layout="center"
										marginBottom="3xl"
										headingAs="div"
									/>
								</div>
							))}
						</div>
						<div className={styles.quoteGrid} ref={slider}>
							{testimonials?.map((testimonial) => {
								const quote = testimonial.content?.quote;
								return (
									<div key={testimonial.id} className={styles.gridItemContainer}>
										<div className={styles.testimonialTextContainer}>
											<BigQuote className={styles.bigQuote} quote={quote} quotation={true} />
											{testimonial.content?.name && testimonial.content?.jobTitle && (
												<Heading
													as="div"
													size="h6"
													title={`${testimonial.content?.name}, ${testimonial.content?.jobTitle}`}
													marginBottom="none"
												/>
											)}
										</div>
									</div>
								);
							})}

							<div className={styles.logoContainer}>
								<div className={styles.desktopHeader}>
									{blok?.title?.map((blok) => (
										<div className={styles.logoHeader} key={blok._uid}>
											<HeaderCommon {...HeaderCommon.blokProps({ blok })} layout="center" marginBottom="3xl" />
										</div>
									))}
								</div>

								<div>
									<Grid columns={{ base: nrOfLogoTypes, sm: 2 }} className={'logoGrid'} rowGap={'4xl'}>
										{blok.logo?.map((item) => (
											<Grid.Item key={item._uid} className={cls(styles.logoGridItem, 'logoGridItem')}>
												<div className={styles.image}>
													<ImageAsset asset={item.image} objectPosition={'center'} objectFit={'contain'} priority />
												</div>
											</Grid.Item>
										))}
									</Grid>
								</div>
							</div>
						</div>
					</Section>
				</ContainerFluid>
			</div>
		</ImpressionTrackingProvider>
	);
};
