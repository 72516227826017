import { ContainerFluid, Section } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { HeaderCommon, HeaderCommonProps } from '@/bloks/Other';
import { ImageGrid, ImageGridProps } from '@/bloks/Other/ImageGrid';
import { renderRichText } from '@/bloks/RichText';
import { RichtextStoryblok, SectionImageStoryblok } from '@/components';
import styles from './SectionImage.module.scss';

interface BlokProps {
	blok: SectionImageStoryblok;
	meta?: {
		buttonVariant?: 'text';
	};
}

const blokProps = ({ blok, meta }: BlokProps): SectionImageProps => ({
	header: blok.header?.[0] && HeaderCommon.blokProps({ blok: blok.header?.[0] }),
	buttonVariant: meta?.buttonVariant,
	imageGroup: blok?.imageGroup?.map((imageGroup) => ImageGrid.blokProps({ blok: imageGroup })),
	additionalText: blok.additionalText,
});

interface SectionImageProps {
	header?: HeaderCommonProps;
	buttonVariant?: 'text' | 'filled';
	imageGroup: ImageGridProps[];
	additionalText?: RichtextStoryblok;
}

export const SectionImage: Blok<SectionImageProps, BlokProps> = ({
	header,
	buttonVariant = 'text',
	imageGroup,
	additionalText,
}) => {
	return (
		<ContainerFluid>
			<Section>
				{header && <HeaderCommon {...header} buttonVariant={buttonVariant} marginBottom="xl" />}
				{imageGroup?.map((imageGroup) => (
					<div key={imageGroup._uid} className={styles.imageGroup}>
						<ImageGrid {...imageGroup} />
					</div>
				))}
				{additionalText && <div className={styles.additionalText}>{renderRichText(additionalText)}</div>}
			</Section>
		</ContainerFluid>
	);
};

SectionImage.blokProps = blokProps;
