import { ContainerFluid, Grid, Section } from '@/atoms';
import { ArticleSmall, TextLinkGroup } from '@/bloks';
import DynamicComponent from '@/bloks/DynamicComponent';
import { SectionNewsStoryblok } from '@/components';
import { useNewsfeed } from '@/contexts/News';
import { NewsPostCardData } from '@/server/newsfeed';
import React from 'react';
import styles from './SectionNews.module.scss';

interface Props {
	blok: SectionNewsStoryblok;
}

export function SectionNews({ blok }: Props) {
	const { newsfeed } = useNewsfeed();
	const { exclude } = blok;
	const header = blok.header?.[0];
	const links = blok.links ? blok.links[0] : null;
	const filteredPosts = newsfeed?.filter((obj) => obj.id !== exclude).slice(0, 4);

	return (
		<ContainerFluid>
			<Section>
				<div className={styles.headerContainer}>
					<Grid columns={{ base: 1, md: 2, lg: 2, xl: '3fr 2fr' }} colGap="3xl" rowGap="md">
						<Grid.Item>{header && <DynamicComponent blok={header} />}</Grid.Item>
						<Grid.Item>
							{links && (
								<TextLinkGroup
									title={links.title}
									linkButtons={links.linkButtons}
									_editable={links._editable}
									layout="vertical"
								/>
							)}
						</Grid.Item>
					</Grid>
				</div>
				<Grid columns={{ base: 1, md: 2, lg: 4 }} colGap="2xl" rowGap="2xl">
					{filteredPosts?.map((post: NewsPostCardData, index) => (
						<Grid.Item key={index}>
							<ArticleSmall
								article={{
									slug: post.id ? `om-fortnox/nyhetsrum-media/pressrelease/${post.id!}` : post.slug,
									title: post.title,
									shortText: post.text,
									tags: post.tags,
									firstPublishedAt: post.firstPublished,
									publishedAt: post.lastModified,
								}}
							/>
						</Grid.Item>
					))}
				</Grid>
			</Section>
		</ContainerFluid>
	);
}
