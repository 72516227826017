import { Divider, Grid, Heading } from '@/atoms';
import DynamicComponent from '@/bloks/DynamicComponent';
import sectionTabsStyles from '@/bloks/Section/SectionTabs/SectionTabs.module.scss';
import { TabProductsStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import React from 'react';
import styles from './TabProducts.module.scss';

interface Props {
	blok: TabProductsStoryblok;
	display: boolean;
}
export const TabProducts: React.FC<Props> = ({ blok, display }) => {
	return (
		<div
			className={cls(
				styles.container,
				display ? sectionTabsStyles.display : sectionTabsStyles.hidden,
				sectionTabsStyles.panel,
			)}
			{...editableBlok(blok)}
		>
			<Grid columns={{ base: 1, lg: 2 }}>
				<Grid.Item>
					<div className={styles.headerContainer}>
						{blok.header?.map((header) => <DynamicComponent blok={header} key={header._uid} />)}
					</div>
				</Grid.Item>
				<Grid.Item>
					<div className={styles.products}>
						<Heading as="h5" title={blok.title} marginBottom="xl" />

						<Grid colGap="xl" columns={{ base: 1, sm: 2, lg: 2 }} rowGap="xl" className={styles.flexContent}>
							{blok.productTexts?.map((productText) => (
								<Grid.Item key={productText._uid} className={styles.productText}>
									<DynamicComponent
										blok={productText}
										meta={{ titleSize: 'h6', titleAs: 'h6', iconName: 'angles-right', iconType: 'custom' }}
									/>
								</Grid.Item>
							))}
						</Grid>
						<Divider color="--line-color" margin="2xl" />
						<Grid colGap="xl" columns={{ base: 2, lg: 2 }} className={styles.flexContent}>
							<Grid.Item>
								{blok.productList?.map((productList) => (
									<DynamicComponent key={productList._uid} blok={productList} meta={{ bold: true }} />
								))}
							</Grid.Item>
							<Grid.Item>
								{blok.productLinks?.map((productLink) => (
									<div key={productLink._uid} className={styles.link}>
										<DynamicComponent blok={productLink} meta={{ variant: 'text' }} />
									</div>
								))}
							</Grid.Item>
						</Grid>
					</div>
				</Grid.Item>
			</Grid>
		</div>
	);
};
