import { Icon } from '@/atoms';
import { cls } from '@/utils';
import React, { useState } from 'react';
import { t } from 'ttag';
import styles from './GeolocationSearch.module.scss';

export interface Position {
	latitude: number;
	longitude: number;
}

interface Props {
	clearPositionSearch: () => any;
	geoLocationError: boolean;
	onClick: () => any;
	onSearch: (position: Position) => any;
	setGeoLocationError: (value: boolean) => void;
	setUseMyPosition: (value: boolean) => void;
	useMyPosition: boolean;
}

export const GeoLocationSearch: React.FC<Props> = ({
	clearPositionSearch,
	geoLocationError,
	onClick,
	onSearch,
	setGeoLocationError,
	setUseMyPosition,
	useMyPosition,
}) => {
	const [userPosition, setUserPosition] = useState<Position>();
	const [loading, setLoading] = useState<boolean>();

	const resolvePosition = () => {
		const successCallback = (position: GeolocationPosition) => {
			const pos: Position = {
				latitude: position.coords.latitude,
				longitude: position.coords.longitude,
			};
			setUserPosition(pos);
			onSearch(pos);
			setGeoLocationError(false);
			setLoading(false);
		};

		const errorCallback = (_error: GeolocationPositionError) => {
			setGeoLocationError(true);
			setLoading(false);
		};

		if (userPosition) {
			onSearch(userPosition);
		} else {
			setLoading(true);
			navigator.geolocation.getCurrentPosition(successCallback, (error) => errorCallback(error));
		}
	};

	const title = useMyPosition ? t`Sök nära mig aktiv` : t`Sök nära mig`;
	return (
		<button
			title={title}
			aria-label={title}
			className={cls(
				styles.button,
				useMyPosition
					? geoLocationError
						? styles.geolocationErrorButton
						: styles.geolocationButton
					: styles.geolocationSlashButton,
			)}
			onClick={() => {
				onClick();
				if (!useMyPosition) {
					setUseMyPosition(true);
					resolvePosition();
				} else {
					setUseMyPosition(false);
					clearPositionSearch();
				}
			}}
			disabled={loading}
		>
			{useMyPosition ? (
				loading ? (
					<Icon name="spinner" className="fa-spin" type={'solid'} />
				) : (
					<Icon name="location-dot" size="sm" type={'solid'} />
				)
			) : (
				<Icon name="location-dot-slash" size="2x" />
			)}
		</button>
	);
};
