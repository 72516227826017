import { useTheme } from '@/contexts/theme';
import React from 'react';
import cls from 'classnames';
import { IconName } from '../../icons/types';
import { Heading } from '../Heading';
import { Icon } from '../Icon';
import styles from './Sticker.module.scss';

export interface StickerProps {
	title: string;
	className?: string;
	icon?: IconName;
}
export const Sticker: React.FC<StickerProps> = ({ title, className, icon }) => {
	const theme = useTheme();
	return (
		<div className={cls(styles.container, className)}>
			<div className={cls(styles.sticker, theme.styles.stickerBgColor, icon && styles.stickerWithIcon)}>
				{icon && (
					<div className={cls(styles.circle, theme.styles.stickerBgColor)}>
						<Icon name={icon} color={'--text-dark'} />
					</div>
				)}
				<Heading as={'div'} title={title} marginBottom="none" className={styles.heading} />
			</div>
		</div>
	);
};

export default Sticker;
