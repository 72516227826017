import { BrandLine, ContainerFluid, Grid, Heading, Section, Text } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { LinkButtonStoryblok, RichtextStoryblok, StorySectionListStoryblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { ImageAsset, ImageAssetProps } from '@/metabloks';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { renderRichText } from '../RichText';
import styles from './StorySectionList.module.scss';

interface Props {
	blok: StorySectionListStoryblok;
}

/**
 * Section Component used on StoryPage with images and a list.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=1932%3A31170
 */
export const StorySectionList: React.FC<Props> = ({ blok }) => {
	const { title, image, list, linkButtons, additionalText, secondImage, imagePosition } = blok;
	const { hasSubMenu } = useSubMenu();

	const imageLeft = imagePosition === 'left';

	return (
		<ContainerFluid marginBottom={hasSubMenu ? '3xl' : 'none'}>
			<Section {...editableBlok(blok)} className={styles.container}>
				<Grid
					columns={imageLeft ? { base: 1, lg: '1fr 2fr' } : { base: 1, lg: '2fr 1fr' }}
					className={cls(styles.grid, imageLeft ? styles.imageLeft : styles.imageRight)}
				>
					<Grid.Item className={imageLeft ? styles.imageContainerLeft : styles.imageContainerRight}>
						{image?.filename && (
							<div className={styles.image}>
								<ImageAsset absolute asset={image} fill objectFit="cover" filters="smart" />
							</div>
						)}
					</Grid.Item>
					<Grid.Item className={cls(imageLeft ? styles.leftTextContent : styles.rightTextContent)}>
						<TextContent
							title={title}
							list={list}
							linkButtons={linkButtons}
							additionalText={additionalText}
							secondImage={secondImage}
						/>
					</Grid.Item>
				</Grid>
			</Section>
		</ContainerFluid>
	);
};

interface TextContentProps {
	title?: string;
	list?: any;
	linkButtons?: LinkButtonStoryblok[];
	additionalText?: RichtextStoryblok;
	secondImage?: ImageAssetProps;
}

const TextContent: React.FC<TextContentProps> = ({ title, list, linkButtons, additionalText, secondImage }) => (
	<div className={cls(styles.content, secondImage?.filename && styles.contentWithsecondImage)}>
		<div>
			{title && (
				<>
					<BrandLine />
					<Heading as="div" size="h5" title={title} marginBottom="xl" data-testid="story-section-list-header-h5" />
				</>
			)}
			{list && (
				<Text marginBottom="xl" as="div">
					{renderRichText(list)}
				</Text>
			)}
			{linkButtons && (
				<div className={styles.linkButtons}>
					{linkButtons?.map((button: LinkButtonStoryblok) => (
						<LinkButton {...LinkButton.blokProps({ blok: button })} key={button._uid} />
					))}
				</div>
			)}
			{additionalText && <div className={styles.additionalText}>{renderRichText(additionalText)}</div>}
		</div>

		{secondImage?.filename && (
			<div className={styles.secondImage}>
				<ImageAsset absolute asset={secondImage} />
			</div>
		)}
	</div>
);
