import { VerticalBrandLine } from '@/atoms';
import { LinkButtonStoryblok, LinksCommonStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { t } from 'ttag';
import { Blok } from '../../Blok';
import { LinkButton } from '../../Button';
import styles from './LinksCommon.module.scss';

interface BlokProps {
	blok: LinksCommonStoryblok;
	meta?: {
		isMobile?: boolean;
	};
}

const blokProps = ({ blok, meta }: BlokProps): Props => ({
	links: blok.links,
	isMobile: meta?.isMobile,
});
interface Props {
	links: LinkButtonStoryblok[];

	isMobile?: boolean;
	noPadding?: boolean;
}

export const LinksCommon: Blok<Props, BlokProps> = ({ links, isMobile = false, noPadding = false }) => {
	return (
		<div {...editableBlok({ component: 'LinksCommon', ...links })} className={styles.container}>
			<div className={styles.inner}>
				{!isMobile && <VerticalBrandLine className={styles.verticalLine} />}

				{links && (
					<ul
						role="navigation"
						aria-label={t`Sidomeny`}
						className={cls(styles.list, isMobile && styles.mobile, noPadding && styles.noPadding)}
					>
						{links.map((link: LinkButtonStoryblok, index: number) => {
							return (
								<li className={styles.listItem} key={link._uid}>
									<LinkButton
										{...LinkButton.blokProps({ blok: link })}
										variant="text"
										testID={`links-common-${index}`}
									/>
								</li>
							);
						})}
					</ul>
				)}
			</div>
		</div>
	);
};

LinksCommon.blokProps = blokProps;
