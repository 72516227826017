import { RadiobuttonGroup } from '@/atoms';
import { EmployeeCalculatorStoryblok } from '@/components';
import React from 'react';
import { t } from 'ttag';
import styles from './VacationPicker.module.scss';

interface Props {
	onChange: (event: any) => void;
	blok: EmployeeCalculatorStoryblok;
}

export const VacationPicker: React.FC<Props> = ({ onChange, blok }) => {
	return (
		<RadiobuttonGroup
			name="radio-input-vacation-picker"
			options={[
				{
					className: styles.radiobuttonContainer,
					label: t`Sammalöneregeln`,
					onChange,
					testID: 'employee-calculator_equal-pay-rule',
					value: 'Sammalöneregeln',
					id: 'employee-calculator_percentage-rule',
					tooltip: Array.isArray(blok?.equalPayRuleTooltip) ? blok?.equalPayRuleTooltip[0]?.text : '',
					checked: true,
				},
				{
					className: styles.radiobuttonContainer,
					label: t`Procentregeln`,
					onChange,
					testID: 'employee-calculator_percentage-rule',
					value: 'Procentregeln',
					id: 'radio-input-procentregeln',
					tooltip: Array.isArray(blok?.percentageRuleTooltip) ? blok?.percentageRuleTooltip[0]?.text : '',
				},
			]}
		/>
	);
};
