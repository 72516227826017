import { ContainerFluid, Section } from '@/atoms';
import { LinkButton, LinkButtonProps } from '@/bloks/Button';
import { HeaderCommon, HeaderCommonProps } from '@/bloks/Other';
import { SectionLogosStoryblok } from '@/components';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { ImageAsset, ImageAssetProps } from '@/metabloks';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { CSSProperties } from 'react';
import styles from './SectionLogos.module.scss';

interface BlokProps {
	blok: SectionLogosStoryblok;
	meta?: { zone?: number };
}

const blokProps = ({ blok, meta }: BlokProps): Props => ({
	header: blok?.header?.[0] && HeaderCommon.blokProps({ blok: blok.header[0] }),
	logos: blok.logos.map((logo) => logo.image),
	linkButton: blok?.linkButton?.[0]?.link && LinkButton.blokProps({ blok: blok.linkButton[0] }),
	multiColorLogo: blok.multiColorLogo,
	_editable: blok._editable,
	zone: meta?.zone,
});

interface Props {
	header?: HeaderCommonProps;
	logos: ImageAssetProps[];
	linkButton?: LinkButtonProps;
	multiColorLogo?: boolean;
	_editable?: string;
	zone?: number;
}

/**
 * A Section that contains logos of partners.
 *
 * If there are 6 logos or less, they will be displayed as a grid.
 * If there are 7 or more logos, they will appear in an endless scrolling animation.
 */
export function SectionLogos({ header, logos, linkButton, multiColorLogo, _editable, zone }: Props) {
	const animate = logos.length > 6;
	const isGrayscaleLogo = multiColorLogo !== true;

	const style = {
		'--nr-of-logos': logos.length,
		'--duration-time': `${(logos.length / 2) * 12}s`,
	} as CSSProperties;

	const contentPiece = header?.titlePart2 ? `${header.titlePart1} ${header.titlePart2}` : header?.titlePart1;
	const contentTarget = linkButton ? 'one interaction possible' : 'no interactions possible';

	return (
		<ImpressionTrackingProvider value={{ contentName: `${zone}|SectionLogos`, contentPiece, contentTarget }}>
			<ContainerFluid {...editableBlok({ _editable })}>
				<Section paddingX={animate ? { base: 'none' } : { base: 'md', md: '3xl' }}>
					{header && <HeaderCommon {...header} layout="center" marginBottom="3xl" />}

					<div className={cls(styles.container, animate && styles.carusel)} style={style}>
						<div className={cls(animate ? styles.caruselInner : styles.logoGridContainer)}>
							{logos.map((logo) => (
								<div
									key={logo.id}
									className={cls(styles.logo, isGrayscaleLogo && styles.grayscaleLogo, animate && styles.caruselItem)}
								>
									<ImageAsset absolute asset={logo} objectFit="contain" fill />
								</div>
							))}
							{animate &&
								logos.map((logo) => (
									<div
										key={logo.id}
										className={cls(styles.logo, isGrayscaleLogo && styles.grayscaleLogo, styles.caruselItem)}
									>
										<ImageAsset absolute asset={logo} objectFit="contain" fill />
									</div>
								))}
						</div>
					</div>

					{linkButton && (
						<div className={styles.buttonContainer}>
							<LinkButton {...linkButton} variant="text" className={styles.button} />
						</div>
					)}
				</Section>
			</ContainerFluid>
		</ImpressionTrackingProvider>
	);
}

SectionLogos.blokProps = blokProps;
