import { BackgroundImage, BrandLine, ContainerFluid, Heading, Icon, Section, Tags, Text } from '@/atoms';
import { HeaderNewsroomStoryblok } from '@/components';
import { useLatestPressRelease } from '@/contexts/News';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import React, { useEffect, useState } from 'react';
import { t } from 'ttag';
import { LinkButton } from '../../Button/LinkButton';
import styles from './HeaderNewsroom.module.scss';

interface Props {
	blok: HeaderNewsroomStoryblok;
}

export const HeaderNewsroom: React.FC<Props> = ({ blok }) => {
	const [content, setContent] = useState<Record<string, any>>({
		title: '',
		published: '',
		intro: '',
		image: null,
		link: '#',
		tags: [],
	});
	const { latestPressRelease } = useLatestPressRelease();
	const { hasSubMenuV1 } = useSubMenu();

	useEffect(() => {
		const latest = latestPressRelease?.Releases?.[0] ?? null;
		if (latest) {
			setContent({
				title: latest.Title,
				published: latest.PublishDate,
				intro: latest.Intro,
				image: {
					filename: latest.Images ? latest.Images[0].UrlTo800x800ArResized : null,
					alt: latest.Images ? latest.Images[0].Title : null,
					description: latest.Images ? latest.Images[0].Description : null,
					copyright: latest.Images ? latest.Images[0].Photographer : null,
				},
				link: `pressrelease/${latest.EncryptedId}`,
				tags: latest.IsRegulatory ? ['Pressmeddelande', 'Regulatoriskt'] : ['Pressmeddelande'],
			});
		}
	}, [latestPressRelease]);

	const image = content.image;

	return (
		<ContainerFluid theme="darkGreen">
			{image?.filename && (
				<BackgroundImage
					objectPosition="top"
					asset={image}
					overlay
					overlayDirection="full"
					borderRadius={hasSubMenuV1}
				/>
			)}
			<Section
				{...editableBlok(blok)}
				className={cls(styles.container, styles.image, hasSubMenuV1 && styles.hasSubMenuV1)}
				data-testid="header-newsroom"
				as="header"
				maxWidth="925px"
			>
				<div className={styles.content}>
					<div className={styles.inner}>
						<BrandLine marginBottom="xl" />
						<div className={styles.tags}>
							<Tags tags={content.tags} published={content.published} />
						</div>
						<Heading as="h1" title={content.title} marginBottom="xl" />

						<Text size="ingress" marginBottom="2xl">
							{content.intro}
						</Text>

						<div className={styles.linkContainer}>
							<LinkButton href={content.link} variant="outlined" className={styles.btn}>
								{t`Läs hela artikeln`}
							</LinkButton>
						</div>
					</div>
				</div>
				<div className={styles.scrollSection}>
					<button
						aria-label={t`Länk till alla nyheter`}
						onClick={() => {
							window.location.href = '#alla-nyheter';
						}}
						className={styles.scrollButton}
					>
						<Icon className={styles.scrollIcon} name="chevron-down" size="2x" />
						{t`Scrolla`}
					</button>
				</div>
			</Section>
		</ContainerFluid>
	);
};
