import { ContainerFluid, Heading, Section } from '@/atoms';
import { CodeBlock } from '@/bloks/Other';
import { CodeBlockStoryblok, SectionCodeStoryblok } from '@/components';
import { cls } from '@/utils';
import React, { useState } from 'react';
import styles from './SectionCode.module.scss';

interface BlokProps {
	blok: SectionCodeStoryblok;
}

interface SectionCodeProps {
	title?: string;
	codeBloks: CodeBlockStoryblok[];
}

const blokProps = ({ blok }: BlokProps): SectionCodeProps => ({
	title: blok.title,
	codeBloks: blok.Code,
});

export const SectionCode = ({ title, codeBloks }: SectionCodeProps) => {
	const [tabName, setTabName] = useState(codeBloks[0]?.tabLabel);
	const tabHandler = (tab: string) => {
		setTabName(tab);
	};
	return (
		<ContainerFluid theme="white">
			<Section>
				{title && <Heading as="div" size="h2" title={title} marginBottom="md" />}
				<div className={styles.codeContainer}>
					<div className={styles.labels}>
						{codeBloks.map((codeBlok) => (
							<button
								key={codeBlok._uid}
								onClick={() => tabHandler(codeBlok.tabLabel)}
								className={cls(styles.tabButton, tabName === codeBlok?.tabLabel && styles.active)}
							>
								{codeBlok?.tabLabel}
							</button>
						))}
					</div>
					<div className={cls(styles.codeContent, tabName === codeBloks[0]?.tabLabel && styles.firstItem)}>
						{codeBloks.map(
							(codeBlok) => codeBlok?.tabLabel === tabName && <CodeBlock key={codeBlok._uid} codeTab={codeBlok.code} />,
						)}
					</div>
				</div>
			</Section>
		</ContainerFluid>
	);
};

SectionCode.blokProps = blokProps;
