import { cls } from '@/utils';
import { t } from 'ttag';
import styles from './MenuIcon.module.scss';

interface Props {
	open?: boolean;
	onClick?: () => void;
	color?: 'light' | 'dark';
}

export const MenuIcon: React.FC<Props> = ({ open, color = 'light', onClick }) => {
	return (
		<button
			className={cls(styles.icon, styles[`color--${color}`], open && styles.open)}
			onClick={onClick}
			aria-label={open ? t`Stäng meny` : t`Öppna meny`}
		>
			<span className={cls(styles.line, styles.line1)}></span>
			<span className={cls(styles.line, styles.line2)}></span>
			<span className={cls(styles.line, styles.line3)}></span>
			<span className={cls(styles.line, styles.line4)}></span>
		</button>
	);
};
