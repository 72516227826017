import { ContainerFluid, Section } from '@/atoms';
import DynamicComponent from '@/bloks/DynamicComponent';
import { SectionTableLargeStoryblok } from '@/components';

interface Props {
	blok: SectionTableLargeStoryblok;
}

export const SectionTableLarge: React.FC<Props> = ({ blok }) => {
	const { header, items } = blok;
	return (
		<ContainerFluid>
			<Section paddingX={{ base: 'md', md: '3xl' }}>
				{header?.map((header) => <DynamicComponent key={header._uid} blok={header} />)}
				{items?.map((item) => <DynamicComponent key={item._uid} blok={item} />)}
			</Section>
		</ContainerFluid>
	);
};
