import { ContainerFluid, Section } from '@/atoms';
import DynamicComponent from '@/bloks/DynamicComponent';
import { SectionBankStoryblok } from '@/components';
import React from 'react';

interface Props {
	blok: SectionBankStoryblok;
}

export function SectionBank({ blok }: Props) {
	return (
		<ContainerFluid>
			<Section>
				{blok.header?.map((header) => <DynamicComponent key={header._uid} blok={header} />)}
				{blok.table?.map((tableImage) => <DynamicComponent key={tableImage._uid} blok={tableImage} />)}
			</Section>
		</ContainerFluid>
	);
}
