import { Heading, Icon, Text } from '@/atoms';
import { Integration, Integrator } from '@/contexts/Integration';
import { t } from 'ttag';
import styles from './ContactInformation.module.scss';
import { ScopesTooltip } from './ScopesTooltip';

interface Props {
	integrator?: Integrator;
	showAccessRights?: boolean;
	integration: Integration;
}
const ContactInformation: React.FC<Props> = ({ integrator, integration, showAccessRights = true }) => {
	if (!integrator) return null;

	const { contactEmail, contactPhone, contactHomepage } = integrator;

	if (!contactEmail && !contactPhone && !contactHomepage) return null;

	return (
		<div className={styles.contactInformation}>
			{showAccessRights && (
				<>
					<Heading marginBottom="xs" as="h5" title={t`Har du frågor om ${integration.appName}?`} />
					<Text size="small">{t`Kontakta supporten för att få hjälp.`}</Text>
				</>
			)}

			{contactHomepage && (
				<div className={styles.contactMethod}>
					<Icon name="globe" />
					<a href={`https://${contactHomepage}`}>{contactHomepage}</a>
				</div>
			)}
			{contactEmail && (
				<div className={styles.contactMethod}>
					<Icon name="envelope" />
					<a href={`mailto:${contactEmail}`}>{contactEmail}</a>
				</div>
			)}
			{contactPhone && (
				<div className={styles.contactMethod}>
					<Icon name="phone" />
					<a href={`phone:${contactPhone}`}>{contactPhone}</a>
				</div>
			)}
			{showAccessRights && (
				<div className={styles.showAccessRights}>
					<Heading as="h5" title={t`Behörigheter`} />
					<Text size="default">{t`För att olika funktioner i integrationen ska kunna fungera på ett visst sätt behöver integrationen åtkomst till ditt Fortnox-konto.`}</Text>
					<ScopesTooltip scopes={integration.scopes} />
				</div>
			)}
		</div>
	);
};

export default ContactInformation;
