import { Accountant, City } from '@/contexts/accountants/types';
import React, { createContext, useContext } from 'react';

/**
 * List of integrations from App Market
 */
export const useAccountants = () => {
	return useContext(Context);
};

interface ContextProps {
	accountants: Accountant[];
	cities: City[];
	searchAddress: string;
}

const Context = createContext<ContextProps>({
	accountants: [],
	cities: [],
	searchAddress: '',
});

interface Props {
	children: React.ReactNode;
	accountants: Accountant[];
	cities: City[];
	searchAddress: string;
}

export const AccountantsProvider: React.FC<Props> = ({ children, accountants, cities, searchAddress }) => {
	const value = { accountants, cities, searchAddress };
	return <Context.Provider value={value}>{children}</Context.Provider>;
};
