import { ContainerFluid, Grid, Heading, Section, Text } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { renderRichText } from '@/bloks/RichText';
import { CtaImageGuideStoryblok, LinkButtonStoryblok, SectionTextArticleStoryblok } from '@/components';
import { ImageAsset } from '@/metabloks';
import { editableBlok } from '@/utils/storyblok';
import styles from './SectionTextArticle.module.scss';

interface Props {
	blok: SectionTextArticleStoryblok;
	meta?: {
		isParent?: boolean;
	};
}

/**
 * Section component with optional CTA element
 * Only used in Company Guide Post
 *
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=398%3A11765
 */
export const SectionTextArticle: React.FC<Props> = ({ blok, meta }) => {
	const includesCTA = (blok.cta && blok.cta.length > 0) || false;

	return (
		<ContainerFluid marginBottom="none">
			<Section
				{...editableBlok(blok)}
				className={styles.container}
				paddingX={meta?.isParent === false ? { base: 'none' } : { base: 'md', md: '3xl' }}
				paddingY={{ base: 'none' }}
			>
				<Grid
					columns={includesCTA ? { base: 1, md: 2, lg: 2, xl: 2 } : { base: 1, lg: 1, xl: 1, xxl: 1 }}
					colGap="3xl"
					rowGap="md"
				>
					<Grid.Item testID="article-layout-header" className={!includesCTA ? styles.text : undefined}>
						{renderRichText(blok.text, { exo2: true })}
					</Grid.Item>
					{includesCTA &&
						blok.cta?.map((cta: CtaImageGuideStoryblok) => (
							<Grid.Item key={cta._uid}>
								<div className={styles.ctaWrapper}>
									{cta.image?.id && (
										<div className={styles.imageWrapper}>
											<ImageAsset absolute key={cta.image.id} asset={cta.image} filters="smart" />
										</div>
									)}
									<div className={styles.textWrapper}>
										<Heading as="div" size="h5" title={cta.title} data-testid="cta-header-h5" />
										<Text marginBottom="xl">{cta.text}</Text>
									</div>
									{cta.button?.map((button: LinkButtonStoryblok) => (
										<LinkButton key={button._uid} variant="filled" {...LinkButton.blokProps({ blok: button })} />
									))}
								</div>
							</Grid.Item>
						))}
				</Grid>
			</Section>
		</ContainerFluid>
	);
};
