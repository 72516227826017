/**
 * @description
 * Translated from prototype to native by DF
 * original code from:
 * http://rosettacode.org/wiki/Luhn_test_of_credit_card_numbers#JavaScript
 *
 * Used for validation of personnummer, organisationsnummer, bankgiro and postgiro
 *
 * @returns true if the check is passed
 */
const luhnCheck = (value: string): boolean => {
	if (!value) {
		return false;
	}

	const array = value.split('');
	const reversedArray = array.reverse();
	const reversedNumberArray = reversedArray.map(Number);
	const sum = reversedNumberArray.reduce(
		(nSum, digit, index) => nSum + (index % 2 === 1 ? (digit === 9 ? 9 : (digit * 2) % 9) : digit),
	);

	return sum % 10 === 0;
};

export { luhnCheck };
