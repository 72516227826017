import styles from './FormHeader.module.scss';

interface Props {
	children: any;
}

/**
 * Header used for form fields
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=398%3A10641
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=654%3A13004
 */
export const FormHeader: React.FC<Props> = ({ children }) => {
	return <div className={styles.container}>{children}</div>;
};
