import { BackgroundImage, ContainerFluid, Heading, Section, Text } from '@/atoms';
import { LinksCommon } from '@/bloks';
import { ErrorPageStoryblok, LinkButtonStoryblok } from '@/components';
import type { ImageAssetProps } from '@/metabloks';
import { errorPageFallbackStory } from '../../../fallback/global/not-found';
import { Blok } from './../../Blok';
import styles from './ErrorPage.module.scss';

interface BlokProps {
	blok: ErrorPageStoryblok;
}

const blokProps = ({ blok }: BlokProps): Props => {
	const props: Props = {
		title: blok.h1Part1,
		shortText: blok.shortText,
		linkTitle: blok.linkTitle,
		links: blok.links[0].links ?? [],
		image: blok.image,
	};

	return props;
};

interface Props {
	title: string;
	shortText: string;
	linkTitle?: string;
	links?: LinkButtonStoryblok[];
	image?: ImageAssetProps;
}

export const ErrorPage: Blok<Props, BlokProps> = ({
	title = errorPageFallbackStory.content.h1Part1,
	shortText = errorPageFallbackStory.content.shortText,
	linkTitle = errorPageFallbackStory.content.linkTitle,
	links = errorPageFallbackStory.content.links[0].links,
	image = errorPageFallbackStory.content.image,
}) => {
	return (
		<ContainerFluid theme="darkGreen">
			{image?.filename && <BackgroundImage priority objectPosition="bottom right" asset={image} overlay />}
			<Section className={styles.container}>
				<div className={styles.content}>
					<Heading
						className={styles.heading}
						marginBottom="xl"
						as="h1"
						size="h3"
						title={title}
						data-testid="error-page-header-h3"
					/>
					<Text className={styles.text} size="ingress" data-testid="error-page-ingress">
						{shortText}
					</Text>
					{links && (
						<div className={styles.linksContainer}>
							<Heading
								className={styles.heading}
								as="h2"
								size="h5"
								title={linkTitle}
								data-testid="error-page-links-header-h5"
								marginBottom="xl"
							/>
							{links && <LinksCommon links={links} />}
						</div>
					)}
				</div>
			</Section>
		</ContainerFluid>
	);
};

ErrorPage.blokProps = blokProps;
