import { useServerPageConfig } from '@/contexts/config';
import type { WidgetInstance } from 'friendly-challenge';
import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import styles from './FriendlyCaptcha.module.scss';

interface Props {
	verify: boolean;
	setCaptchaIsValid: any;
	className?: string;
}

export const FriendlyCaptcha: React.FC<Props> = ({ verify, className, setCaptchaIsValid }) => {
	const container = useRef<HTMLDivElement | null>(null);
	const widget = useRef<WidgetInstance | null>(null);

	const { captchaSiteKey } = useServerPageConfig();

	useEffect(() => {
		if (widget?.current) {
			widget?.current?.start();
		}
	}, [verify]);

	useEffect(() => {
		if (!widget.current && container.current) {
			initializeWidget();
		}

		return () => {
			widget.current?.reset();
		};

		async function initializeWidget() {
			const WidgetInstance = (await import('friendly-challenge')).WidgetInstance;
			widget.current = new WidgetInstance(container.current!, {
				startMode: 'none',
				doneCallback: () => setCaptchaIsValid(true),
				errorCallback: () => setCaptchaIsValid(false),
			});
		}
	}, [container, setCaptchaIsValid]);

	return <div ref={container} className={classnames(styles.frcContainer, className)} data-sitekey={captchaSiteKey} />;
};

/**
 *	Gets solution from friendly captcha widget
 */
export const getCaptchaSolution = (event: React.BaseSyntheticEvent) => {
	let solution = '';
	const formData = new FormData(event.target);

	formData.forEach(function (value, key) {
		if (key === 'frc-captcha-solution') {
			solution = value as string;
		}
	});
	return solution;
};
