import { Card, Divider, Grid, Heading, Icon, Section, Text } from '@/atoms';
import { ConsultantStoryblok, SectionConsultantStoryblok } from '@/components';
import { ImageAsset } from '@/metabloks';
import { ISbStoryData } from '@/types/storyblok';
import { t } from 'ttag';
import styles from './SectionConsultant.module.scss';

interface Props {
	blok: SectionConsultantStoryblok;
}

export const SectionConsultant: React.FC<Props> = ({ blok }) => {
	const consultants = blok.consultants as unknown as ISbStoryData<ConsultantStoryblok>[];

	return (
		<Section>
			<Grid columns={{ base: 1, md: 2, xl: 3 }} colGap="xl" rowGap="xl">
				{consultants?.map((consultant: ISbStoryData<ConsultantStoryblok>, index: number) => {
					const { content } = consultant;
					return (
						<Grid.Item key={index}>
							<Card className={styles.card}>
								<header className={styles.header}>
									{content.logo?.filename && (
										<>
											<div className={styles.logoContainer}>
												<ImageAsset absolute asset={content.logo} />
											</div>
											<Divider margin="xl" />
										</>
									)}

									<Heading
										as="div"
										size="h5"
										title={consultant.content.name}
										data-testid="consultant-group-header-h5"
									/>
								</header>

								<Text data-testid="consultant-group-content">{content.description}</Text>
								<Divider margin="xl" />
								<div className={styles.contactInfoContainer}>
									<ul className={styles.list}>
										{content.phone && (
											<li className={styles.listItem}>
												<span className={styles.iconContainer}>
													<Icon className={styles.icon} name="phone" size="1x" />
												</span>
												<span className={styles.text}>{content.phone}</span>
											</li>
										)}
										{content.email && (
											<li className={styles.listItem}>
												<span className={styles.iconContainer}>
													<Icon className={styles.icon} name="envelope" size="1x" />
												</span>
												<span className={styles.text}>
													<a
														href={`mailto:${content.email}`}
														title={content.email}
														rel="noopener noreferrer"
														target="_blank"
													>
														{content.email}
													</a>
												</span>
											</li>
										)}
										{content.url && (
											<li className={styles.listItem}>
												<span className={styles.iconContainer}>
													<Icon className={styles.icon} name="display" size="1x" />
												</span>
												<span className={styles.text}>
													<a
														href={content.url}
														title={content.url}
														rel="noopener noreferrer"
														target="_blank"
													>{t`Hemsida`}</a>
												</span>
											</li>
										)}
									</ul>
								</div>
							</Card>
						</Grid.Item>
					);
				})}
			</Grid>
		</Section>
	);
};
