import { Section, Text } from '@/atoms';
import { BlogPostStoryblok, CompanyGuidePostStoryblok, NewsPostStoryblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import React from 'react';
import DynamicComponent from '../../DynamicComponent';
import { renderRichText } from '../../RichText';
import styles from './ArticleLayout.module.scss';

interface Props {
	blok: BlogPostStoryblok | CompanyGuidePostStoryblok | NewsPostStoryblok;
	meta?: {
		[prop: string]: unknown;
	};
}

export const ArticleLayout: React.FC<Props> = ({ blok, meta }) => {
	const { header, intro } = blok;
	const { hasSubMenuV1 } = useSubMenu();
	let companyGuideContent;

	if (blok.component === 'CompanyGuidePost') {
		companyGuideContent = blok as unknown as CompanyGuidePostStoryblok;
	}

	return (
		<Section paddingY={hasSubMenuV1 ? { base: 'none' } : { base: 'md', md: '3xl' }} paddingX={{ base: 'none' }}>
			<article className={styles.articleContainer}>
				{header?.map((blok) => <DynamicComponent key={blok._uid} blok={blok} meta={meta} />)}

				{intro && (
					<div className={styles.introSection} data-testid="article-intro">
						<div className={styles.introduction}>
							<Text as="div">
								<strong>{renderRichText(intro, { exo2: true })}</strong>
							</Text>
						</div>
					</div>
				)}

				{blok.component !== 'CompanyGuidePost' && blok?.long_text && (
					<Text as="div" className={styles.introSection} data-testid="article-content">
						{renderRichText(blok?.long_text, { exo2: true })}
					</Text>
				)}

				{companyGuideContent?.content &&
					companyGuideContent.content.map((blok) => (
						<div key={blok._uid} className={styles.content} id={blok._uid}>
							<DynamicComponent
								key={blok._uid}
								blok={blok}
								meta={{ isParent: false, removeMarginBottom: true, layout: 'article', exo2: true }}
							/>
						</div>
					))}
			</article>
		</Section>
	);
};
