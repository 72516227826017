import { BrandLine, Grid, Section } from '@/atoms';
import { CtaThreeGroupStoryblok } from '@/components';
import { ImageAsset } from '@/metabloks';
import { editableBlok } from '@/utils/storyblok';
import DynamicComponent from '../DynamicComponent';
import styles from './CtaThreeGroup.module.scss';

interface Props {
	blok: CtaThreeGroupStoryblok;
}

/**
 * @deprecated Will be removed
 */
export const CtaThreeGroup: React.FC<Props> = ({ blok }) => {
	return (
		<Section {...editableBlok(blok)}>
			<Grid columns={{ base: 1, lg: 2 }} colGap="xl" className={styles.gridContainer}>
				<Grid.Item>
					<div className={styles.content}>
						<BrandLine />
						{blok.cta?.map((item: any) => {
							return <DynamicComponent blok={item} key={item._uid} />;
						})}
					</div>
				</Grid.Item>
				<Grid.Item>
					{blok.image?.filename && (
						<div className={styles.image}>
							<ImageAsset absolute key={blok.image?.id} asset={blok.image} />
						</div>
					)}
				</Grid.Item>
			</Grid>
		</Section>
	);
};
