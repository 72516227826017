import { BrandLine, Heading, Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { renderRichText } from '@/bloks/RichText';
import { HeaderCommonStoryblok } from '@/components';
import { useImpressionTracking } from '@/contexts/piwik/ImpressionTrackingProvider';
import { useTheme } from '@/contexts/theme';
import { ImageAsset, ImageAssetProps } from '@/metabloks';
import { cls, isRichtextEmpty } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { useEffect } from 'react';
import { Document } from 'storyblok-rich-text-react-renderer';
import { LinkButton, LinkButtonProps } from '../../Button';
import styles from './HeaderCommon.module.scss';

interface BlokProps {
	blok: HeaderCommonStoryblok;
	meta?: {
		layout?: HeaderCommonProps['layout'];
		buttonVariant?: HeaderCommonProps['buttonVariant'];
		adjustableTextWidth?: boolean;
		exo2?: boolean;
	};
}

const blokProps = ({ blok, meta }: BlokProps): HeaderCommonProps => ({
	smallTitle: blok?.smallTitle,
	titleSize: blok?.titleSize,
	titlePart1: blok?.titlePart1,
	titlePart2: blok?.titlePart2,
	text: blok?.text,
	linkButtons: blok?.linkButtons?.map((linkButtonBlok) => ({
		...LinkButton.blokProps({
			blok: linkButtonBlok,
		}),
	})),
	hideBrandline: blok?.hideBrandline,
	adjustableTextWidth: meta?.adjustableTextWidth || blok?.adjustableTextWidth,
	layout: blok?.centerContent ? 'center' : meta?.layout,
	buttonVariant: meta?.buttonVariant,
	_editable: blok._editable,
});

export interface HeaderCommonProps {
	_editable?: string;
	smallTitle?: string;
	titleSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
	titlePart1?: string;
	titlePart2?: string;
	text?: string | Document;
	linkButtons?: LinkButtonProps[];
	hideBrandline?: boolean;
	adjustableTextWidth?: boolean;
	layout?: 'left' | 'center';
	image?: ImageAssetProps;
	/**
	 * Button variant, if not set the first button will be filled and the second outlined
	 */
	buttonVariant?: 'filled' | 'outlined' | 'text';
	marginBottom?: 'lg' | 'xl' | '3xl';
	/**
	 * Inserts a linebreak between titlePart1 and titlePart2
	 */
	linebreak?: boolean;
	/**
	 * A short informative text shown beside or under the buttons
	 */
	info?: any;
	className?: string;
	applyChildTheme?: boolean;
	/**
	 * Lightness is used to decide color of elements placed on an image or video
	 */
	applyLightnessTheme?: boolean;
	exo2?: boolean;
	/**
	 * Element/component to render the title heading as
	 */
	headingAs?: 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

/**
 * Header component that is frequently used.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=158%3A6693
 */
export const HeaderCommon: Blok<HeaderCommonProps, BlokProps> = ({
	_editable,
	smallTitle,
	titleSize = 'h2',
	titlePart1,
	titlePart2,
	text,
	linkButtons,
	hideBrandline,
	adjustableTextWidth,
	layout = 'left',
	image,
	buttonVariant,
	marginBottom,
	linebreak = false,
	info,
	applyLightnessTheme = false,
	className,
	exo2 = false,
	headingAs = 'h2',
}) => {
	const themeInfo = useTheme();
	const isTextEmpty = (typeof text === 'string' && !text) || (typeof text !== 'string' && isRichtextEmpty(text));
	const { setTargetInteraction, contentPiece, setContentPiece } = useImpressionTracking();
	const title = titlePart2 ? `${titlePart1} ${titlePart2}` : titlePart1;

	useEffect(() => {
		if (contentPiece === 'no available title' && title) {
			setContentPiece(title);
		}
	}, [contentPiece, setContentPiece, title]);

	return (
		<div
			{...editableBlok({ _editable })}
			className={cls(
				className,
				styles.container,
				styles[`layout--${layout}`],
				marginBottom && styles[`margin-bottom--${marginBottom}`],
				adjustableTextWidth && styles.adjustableTextWidth,
			)}
		>
			{!hideBrandline && <BrandLine className={themeInfo.styles?.brandlineColor} marginBottom="xl" />}

			{smallTitle && (
				<Heading
					as="div"
					size="h6"
					title={smallTitle}
					className={styles.smallTitle}
					marginBottom="xl"
					smallTitle
					applyLightnessTheme={applyLightnessTheme}
				/>
			)}
			{titlePart1 && (
				<Heading
					as={headingAs}
					size={titleSize}
					className={styles.title}
					title={titlePart1}
					titlePart2={titlePart2}
					linebreak={linebreak}
					marginBottom={isTextEmpty && !linkButtons?.length ? 'none' : 'xl'}
					applyLightnessTheme={applyLightnessTheme}
					exo2={exo2}
				/>
			)}

			{image?.filename && (
				<div className={styles.image}>
					<ImageAsset absolute asset={image} objectFit="contain" fill objectPosition="center" />
				</div>
			)}

			{text && typeof text === 'string' && (
				<Text
					as="div"
					className={styles.text}
					marginBottom={!linkButtons?.length ? 'none' : '3xl'}
					applyLightnessTheme={applyLightnessTheme}
				>
					{text}
				</Text>
			)}

			{typeof text !== 'string' && !isRichtextEmpty(text) && (
				<Text
					as="div"
					className={styles.text}
					marginBottom={!linkButtons?.length ? 'none' : '3xl'}
					applyLightnessTheme={applyLightnessTheme}
				>
					{renderRichText(text, { applyLightnessTheme })}
				</Text>
			)}

			{linkButtons && (
				<footer className={cls(styles.footer, linkButtons?.length > 1 && styles.flexDirectionColumn)}>
					<div className={styles.linkButtons}>
						{linkButtons.map((linkButton, index) => (
							<LinkButton
								key={index}
								{...linkButton}
								variant={!buttonVariant && index === 0 ? 'filled' : (buttonVariant ?? 'outlined')}
								className={styles.btn}
								applyLightnessTheme={applyLightnessTheme}
								onClick={() => {
									setTargetInteraction({ contentTarget: (linkButton.children as string) ?? '' });
								}}
							/>
						))}
					</div>
					{info && <div className={styles.info}>{renderRichText(info)}</div>}
				</footer>
			)}
		</div>
	);
};

HeaderCommon.blokProps = blokProps;
