import { ContainerFluid, Datepicker, Grid, Heading, Section, Select, SelectOption, TextField } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { buildLinkProps } from '@/bloks/Button/LinkButton';
import DynamicComponent from '@/bloks/DynamicComponent';
import { CardFiveGroupStoryblok, NewsLibraryPageStoryblok } from '@/components';
import { useNewsfeed } from '@/contexts/News';
import { NewsPostCardData } from '@/server/newsfeed';
import { ISbStoryData } from '@/types/storyblok';
import { formatDateMonthYear } from '@/utils';
import React from 'react';
import { t } from 'ttag';
import styles from './NewsLibraryPage.module.scss';

interface Props {
	blok: NewsLibraryPageStoryblok;
	meta: Record<string, any>;
}

export const NewsLibraryPage: React.FC<Props> = ({ blok }) => {
	const { header, contact } = blok;
	const headerItem = header?.[0] ?? null;
	const contactExit = contact as unknown as ISbStoryData<CardFiveGroupStoryblok>;
	const {
		newsfeed,
		setSelectedDate,
		setSearchInput,
		selectedCategory,
		setSelectedCategory,
		searchInput,
		selectedDate,
		filteredNewsfeed,
	} = useNewsfeed();
	const news = filteredNewsfeed && filteredNewsfeed.length > 0 ? filteredNewsfeed : null;

	const onDateChange = (event: any) => {
		setSelectedDate(event.currentTarget.value);
	};

	const onSearch = (event: any) => {
		setSearchInput(event.currentTarget.value);
	};

	// Create categories for select
	const categories: string[] = [];
	newsfeed?.forEach((post: NewsPostCardData) => {
		post.tags.forEach((tag: string) => {
			if (!categories.includes(tag) && tag !== '') {
				categories.push(tag);
			}
		});
	});

	const categorySelectOptions: SelectOption[] = categories?.map((tag: string) => ({
		value: tag,
		label: tag,
		id: tag,
	}));

	const selectedCategorytOption =
		categorySelectOptions?.filter((option) => option.value.toLowerCase() === selectedCategory)?.[0] || null;

	const groupedByDate = news?.reduce((group: { [key: string]: NewsPostCardData[] }, item) => {
		const date = formatDateMonthYear(new Date(item.firstPublished));
		if (!group[date]) {
			group[date] = [];
		}
		group[date].push(item);
		return group;
	}, {});

	return (
		<ContainerFluid marginBottom="none">
			<DynamicComponent blok={headerItem} />
			<Section as="div" paddingX={{ base: 'md', md: '3xl' }}>
				<div id="alla-nyheter" className={styles.filterSection}>
					<Grid columns={{ base: 1, md: 2, lg: '4fr 4fr 8fr', xl: '4fr 4fr 8fr' }} colGap="xs" rowGap="xs">
						<Grid.Item>
							<Select
								name="category"
								onSelect={(value) => {
									setSelectedCategory(value.toLowerCase());
								}}
								testID="category"
								data={categorySelectOptions}
								title={t`Välj kategori`}
								aria-label={t`Välj kategori`}
								selected={selectedCategorytOption}
								onClear={() => setSelectedCategory(null)}
								id="news-post-categories"
							/>
						</Grid.Item>
						<Grid.Item>
							<Datepicker
								value={selectedDate ? selectedDate : new Date().toISOString().split('T')[0].slice(0, -3)}
								max={new Date()}
								onChange={onDateChange}
								type="month"
								bordered
								testID="datepicker"
							/>
						</Grid.Item>

						<Grid.Item>
							<TextField
								id="news-library-search"
								value={searchInput}
								onChange={onSearch}
								type="search"
								bordered
								title={t`Sök`}
							/>
						</Grid.Item>
					</Grid>
				</div>
				{groupedByDate &&
					Object.keys(groupedByDate).map((key, i) => {
						const dateTitle = key.charAt(0).toUpperCase() + key.slice(1);

						return (
							<div key={i} className={styles.container}>
								<Heading as="div" title={dateTitle} size="h5" marginBottom="sm" />
								<div className={styles.linksContainer}>
									{groupedByDate[key].map((item, i) => {
										const { href } = buildLinkProps({ url: item.slug, linktype: 'story' });
										return (
											<LinkButton key={item.id ?? i} href={href} variant="text">
												{item.title}
											</LinkButton>
										);
									})}
								</div>
							</div>
						);
					})}
			</Section>
			{contactExit && <DynamicComponent blok={contactExit.content} />}
		</ContainerFluid>
	);
};
