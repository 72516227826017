import { Card, Grid } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { HeaderCommon, HeaderCommonProps } from '@/bloks/Other';
import { CardLargeGraphicStoryblok } from '@/components';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { ImageAsset, ImageAssetProps } from '@/metabloks';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import styles from './CardLargeGraphic.module.scss';

interface BlokProps {
	blok: CardLargeGraphicStoryblok;
	meta?: { zone?: number };
}

const blokProps = ({ blok, meta }: BlokProps): CardLargeGraphicProps => ({
	header: blok.header?.[0] && HeaderCommon.blokProps({ blok: blok.header[0] }),
	image: blok.image,
	_editable: blok._editable,
	zone: meta?.zone,
});

export interface CardLargeGraphicProps {
	header: HeaderCommonProps;
	image: ImageAssetProps;
	layout?: 'image-right' | 'image-left';
	_editable?: string;
	zone?: number;
}

export const CardLargeGraphic: Blok<CardLargeGraphicProps, BlokProps> = ({
	header,
	image,
	layout,
	_editable,
	zone,
}) => {
	const title = header?.titlePart2 ? `${header?.titlePart1} ${header.titlePart2}` : header?.titlePart1;
	let contentTarget = header?.linkButtons?.length ? 'multiple interactions possible' : 'no interactions possible';
	if (header?.linkButtons?.length === 1) contentTarget = 'one interaction possible';
	return (
		<ImpressionTrackingProvider value={{ contentName: `${zone}|SectionCard`, contentPiece: title, contentTarget }}>
			<Card
				{...editableBlok({ _editable })}
				className={cls(styles[`layout--${layout}`])}
				border={false}
				padding="none"
				noBackground
			>
				<Grid columns={{ base: 1, lg: 2 }} rowGap="xl" className={styles.grid}>
					<Grid.Item className={styles.imageColumn}>
						{image?.filename && (
							<div className={styles.image}>
								<ImageAsset
									absolute
									asset={image}
									objectFit="contain"
									fill
									objectPosition="center center"
									filters="smart"
								/>
							</div>
						)}
					</Grid.Item>

					<Grid.Item className={styles.textColumn}>
						<HeaderCommon {...header} />
					</Grid.Item>
				</Grid>
			</Card>
		</ImpressionTrackingProvider>
	);
};

CardLargeGraphic.blokProps = blokProps;
