import { BlogIndexData } from '@/server/blogposts';
import { ArticleCardProps } from '@/types/article';
import { fetchApiRaw } from '@/utils/fetchApi/fetchApiRaw';
import debounce from 'lodash.debounce';
import { useRouter } from 'next/router';
import useThrottledCallback from 'beautiful-react-hooks/useThrottledCallback';
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import useSWR from 'swr';
import { BlogCategory } from './types';

/**
 * Provider of blog posts
 */
export const useBlogPosts = () => {
	return useContext(Context);
};

interface ContextProps {
	posts: ArticleCardProps[];
	searchInput: string;
	setSearchInput: (_input: string) => void;
	setSelectedCategory: (_category: string) => void;
	selectedCategory: string | null;
	setSelectedDate: (_input: string) => void;
	selectedDate: string;
	categories: BlogCategory[];
	showMore: boolean;
	setShowMore: React.Dispatch<React.SetStateAction<boolean>>;
	isLoadingMore: boolean;
}

const Context = createContext<ContextProps>({
	posts: [],
	searchInput: '',
	setSearchInput: () => {},
	setSelectedCategory: () => {},
	selectedCategory: '',
	setSelectedDate: () => {},
	selectedDate: '',
	categories: [],
	showMore: false,
	setShowMore: () => {},
	isLoadingMore: false,
});

interface Props {
	children: React.ReactNode;
	starts_with?: string;
	categoryDatasource?: string;
}

export function BlogProvider({ children, starts_with, categoryDatasource }: Props) {
	const router = useRouter();
	const categoryParam = [router.query.category].flat()[0] || '';
	const dateParam = [router.query.date].flat()[0] || '';
	const searchParam = [router.query.s].flat()[0] || '';
	const [selectedCategory, setSelectedCategory] = useState(categoryParam);
	const [selectedDate, setSelectedDate] = useState(dateParam);
	const [searchInput, setSearchInput] = useState(searchParam);
	const [showMore, setShowMore] = useState(false);

	const [debouncedSetSearchTerm] = useState(() => debounce(setSearchInput, 1000));

	useEffect(() => {
		if (!selectedDate && dateParam) {
			setSelectedDate(dateParam);
		}
	}, [dateParam]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!selectedCategory && categoryParam) {
			setSelectedCategory(categoryParam);
		}
	}, [categoryParam]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!searchInput && searchParam) {
			debouncedSetSearchTerm(searchParam);
		}
	}, [searchParam]); // eslint-disable-line react-hooks/exhaustive-deps

	/*
	 * Unset show more while filtering
	 * Which means that 8 results are fetched per request while typing, instead of
	 * all blogposts.
	 */
	useEffect(() => {
		setShowMore(false);
	}, [searchInput, selectedCategory]);

	const [query, setQueryImmediately] = useState('');
	const setQuery = useThrottledCallback(setQueryImmediately, [], 500);

	useEffect(() => {
		const q = new URLSearchParams();

		q.set('sort_by', 'published_at:desc');

		if (selectedCategory) {
			q.set('category', selectedCategory);
		}
		if (searchInput) {
			q.set('s', searchInput);
		}

		if (showMore) {
			q.set('all', '');
		}

		if (starts_with) {
			q.set('starts_with', starts_with);
		}

		if (categoryDatasource) {
			q.set('categoryDatasource', categoryDatasource);
		}

		setQuery(q.toString() ? `?${q.toString()}` : '');
	}, [setQuery, selectedCategory, selectedDate, searchInput, showMore]); // eslint-disable-line react-hooks/exhaustive-deps

	async function fetcher(path: string) {
		const request = new Request(path);
		const result = await fetchApiRaw<{ data: BlogIndexData }>(request);
		return result.body.data;
	}

	const blogIndex = useSWR<BlogIndexData>(`/api/fortnox-website/blogposts-v1${query}`, fetcher, {
		use: [laggy],
		revalidateIfStale: false,
	});
	const { posts, categories } = blogIndex.data!;

	const value: ContextProps = {
		posts,
		searchInput,
		setSearchInput,
		selectedDate,
		setSelectedDate,
		selectedCategory,
		setSelectedCategory,
		categories,
		showMore,
		setShowMore,
		isLoadingMore: blogIndex.isValidating,
	};
	return <Context.Provider value={value}>{children}</Context.Provider>;
}

// This is a SWR middleware for keeping the data even if key changes.
function laggy(useSWRNext: any) {
	return (key: any, fetcher: any, config: any) => {
		// Use a ref to store previous returned data.
		const laggyDataRef = useRef();

		// Actual SWR hook.
		const swr = useSWRNext(key, fetcher, config);

		useEffect(() => {
			// Update ref if data is not undefined.
			if (swr.data !== undefined) {
				laggyDataRef.current = swr.data;
			}
		}, [swr.data]);

		// Expose a method to clear the laggy data, if any.
		const resetLaggy = useCallback(() => {
			laggyDataRef.current = undefined;
		}, []);

		// Fallback to previous data if the current data is undefined.
		const dataOrLaggyData = swr.data === undefined ? laggyDataRef.current : swr.data;

		// Is it showing previous data?
		const isLagging = swr.data === undefined && laggyDataRef.current !== undefined;

		// Also add a `isLagging` field to SWR.
		return Object.assign({}, swr, {
			data: dataOrLaggyData,
			isLagging,
			resetLaggy,
		});
	};
}
