import { Heading, Text } from '@/atoms';
import { DownloadAppGroupStoryblok } from '@/components';
import { useImpressionTracking } from '@/contexts/piwik/ImpressionTrackingProvider';
import { ImageLink } from '../ImageLink/ImageLink';
import styles from './DownloadAppGroup.module.scss';

interface Props {
	blok: DownloadAppGroupStoryblok;
}

/**
 * Download App Group used in footer.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=249%3A9072
 */
export const DownloadAppGroup: React.FC<Props> = ({ blok }) => {
	const { title, imageLinks, shortText } = blok;
	const { setTargetInteraction } = useImpressionTracking();

	return (
		<div>
			{title && <Heading as="div" size="h5" title={title} marginBottom="lg" />}
			{shortText && <Text>{shortText}</Text>}
			<div className={styles.linkContainer}>
				{imageLinks.map((imageLink) => (
					<ImageLink
						key={imageLink._uid}
						blok={imageLink}
						target="_blank"
						className={styles.imageLink}
						onClick={() => {
							setTargetInteraction({ contentTarget: 'Image Link' });
						}}
					/>
				))}
			</div>
		</div>
	);
};
