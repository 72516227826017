import { ContainerFluid, Grid, Heading, Section } from '@/atoms';
import { CtaLargeStoryblok, LinkButtonStoryblok } from '@/components';
import { editableBlok } from '@/utils/storyblok';
import { LinkButton } from '../Button';
import styles from './CtaLarge.module.scss';

interface Props {
	blok: CtaLargeStoryblok;
}
export const CtaLarge: React.FC<Props> = ({ blok }) => {
	const { titleLeft, titleRight, linkLeft, linkRight } = blok;

	return (
		<ContainerFluid>
			<Section paddingY={{ base: 'none' }}>
				<Grid
					columns={{ base: 1, lg: 2 }}
					colGap="2xl"
					rowGap="md"
					{...editableBlok(blok)}
					className={styles.container}
				>
					<Grid.Item className={styles.section}>
						<Heading as="div" size="h6" title={titleLeft} className={styles.title} marginBottom="none" />

						<div className={styles.linkButton}>
							{linkLeft?.map((button: LinkButtonStoryblok) => (
								<LinkButton {...LinkButton.blokProps({ blok: button })} key={button._uid} variant="text" />
							))}
						</div>
					</Grid.Item>
					<Grid.Item className={styles.section}>
						<Heading as="div" size="h6" title={titleRight} className={styles.title} marginBottom="none" />
						<div className={styles.linkButton}>
							{linkRight?.map((button: LinkButtonStoryblok) => (
								<LinkButton {...LinkButton.blokProps({ blok: button })} key={button._uid} variant="text" />
							))}
						</div>
					</Grid.Item>
				</Grid>
			</Section>
		</ContainerFluid>
	);
};
