import { Text } from '@/atoms';
import { LastUpdatedTextStoryblok } from '@/components';
import { t } from 'ttag';
import { renderRichText } from '../RichText';

interface Props {
	blok: LastUpdatedTextStoryblok;
	meta: Record<string, any>;
}

export const LastUpdatedText: React.FC<Props> = ({ blok, meta }) => {
	const { text } = blok;
	const { published_at } = meta;

	return (
		<>
			<Text muted>
				{t`Sidan senast uppdaterad`} <time dateTime={published_at}>{published_at?.split('T')[0]}</time>
			</Text>
			<div>{renderRichText(text)}</div>
		</>
	);
};
