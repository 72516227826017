import { PopUpButton } from '@/bloks/Button';
import { PopUpButtonStoryblok } from '@/components';
import styles from './RichTextPopUpButton.module.scss';

interface Props {
	blok: PopUpButtonStoryblok;
}

export const RichTextPopUpButton: React.FC<Props> = ({ blok }) => {
	return (
		<div className={styles.buttonWrapper}>
			<PopUpButton {...PopUpButton.blokProps({ blok })} />
		</div>
	);
};
