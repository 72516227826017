import { Select, SelectOption, TextField } from '@/atoms';
import { FormHeader } from '@/bloks/Form/FormHeader/FormHeader';
import { useBlogPosts } from '@/contexts/blogposts/BlogProvider';
import { BlogCategory } from '@/contexts/blogposts/types';
import { t } from 'ttag';

/**
 * Blog post filter. Used in Blog index page.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=398%3A10641
 */
export const ArticleGridFilter: React.FC = () => {
	const { setSelectedCategory, selectedCategory, setSearchInput, categories } = useBlogPosts();

	const handleSearch = (e: React.BaseSyntheticEvent) => {
		setSearchInput(e.target.value);
	};

	const categoryOptions: SelectOption[] = categories?.map((cat: BlogCategory) => ({
		value: cat.value,
		label: cat.name,
		id: cat.id.toString(),
	}));

	const selectedCategoryOption = categoryOptions.find((option) => option.value === selectedCategory) || null;

	return (
		<FormHeader>
			{categoryOptions && (
				<Select
					onSelect={(value) => setSelectedCategory(value)}
					selected={selectedCategoryOption}
					onClear={() => setSelectedCategory('')}
					data={categoryOptions}
					title={t`Välj kategori`}
					aria-label={t`Välj kategori`}
					id="blog-categories-select"
				/>
			)}

			<TextField id="article-grid-search" title={t`Sök`} type="search" bordered onChange={handleSearch} />
		</FormHeader>
	);
};
