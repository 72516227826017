/* eslint-disable jsx-a11y/alt-text */
import { Blok } from '@/bloks/Blok';
import { ImageGridStoryblok } from '@/components';
import { cls } from '@/utils';
import { Image, ImageProps } from '../Image/Image';
import styles from './ImageGrid.module.scss';

interface BlokProps {
	blok: ImageGridStoryblok;
}

const blokProps = ({ blok }: BlokProps): ImageGridProps => ({
	images: blok.images.map((image) => Image.blokProps({ blok: image })),
	containImageSize: blok.containImageSize,
	_uid: blok._uid,
});

export interface ImageGridProps {
	images: ImageProps[];
	containImageSize?: boolean;
	_uid?: string;
}

export const ImageGrid: Blok<ImageGridProps, BlokProps> = ({ images, containImageSize }) => {
	const objectFitContain = containImageSize && images.length === 1;

	return (
		<div
			className={cls(
				styles.gridContainer,
				styles[`images--${images?.length}`],
				objectFitContain && styles.objectFitContain,
			)}
		>
			{images?.map((image, index) => (
				<div key={image._uid} className={styles[`item--${index + 1}`]}>
					<Image {...image} objectFit={objectFitContain ? 'contain' : 'cover'} />
				</div>
			))}
		</div>
	);
};

ImageGrid.blokProps = blokProps;
