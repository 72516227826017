import { useEffect, useRef } from 'react';

export interface HasEventListener {
	addEventListener(name: string, handler: (event?: any) => void, ...args: any[]): any;
	removeEventListener(name: string, handler: (event?: any) => void, ...args: any[]): any;
}

export type EventTarget = HasEventListener;

export const useEvent = (eventName: string, handler?: null | ((event?: any) => void), element = global?.window) => {
	const savedHandler = useRef<typeof handler>();

	useEffect(() => {
		savedHandler.current = handler;
	}, [handler]);

	useEffect(() => {
		const isSupported = element && element.addEventListener;
		if (!isSupported) return;

		const eventListener = (event: any) => savedHandler?.current?.(event);

		element.addEventListener(eventName, eventListener);

		return () => {
			element.removeEventListener(eventName, eventListener);
		};
	}, [eventName, element]);
};
