import { Icon } from '@/atoms';
import { ArticleSubmenuDynamicStoryblok } from '@/components';
import { cls } from '@/utils';
import { ClickOutsideAlerter } from 'hooks/useClickOutsideAlerter';
import { t } from 'ttag';
import styles from './ArticleSubmenuDynamic.module.scss';

export type SubMenuItem = {
	linkedTo: string;
	text: string;
};

interface Props {
	blok: ArticleSubmenuDynamicStoryblok;
	meta: {
		items: SubMenuItem[];
		open?: any;
		setOpen?: any;
	};
}
/**
 * Autogenerated menu based on page h2 elements
 */
export const ArticleSubmenuDynamic: React.FC<Props> = ({ blok, meta }) => {
	const { title } = blok;
	const { items, open, setOpen } = meta;

	return (
		<ClickOutsideAlerter
			callback={() => {
				setOpen(false);
			}}
		>
			<div className={styles.menuContainer}>
				<button
					aria-label={open ? t`Öppna submeny` : t`Stäng submeny`}
					onClick={() => {
						setOpen(open ? false : true);
					}}
					data-testid="company-guide-submenu"
				>
					{title} <Icon name="chevron-right" size="1x" className={styles.icon} />
				</button>
				<ul className={open ? cls(styles.links, styles.open) : cls(styles.links, styles.closed)}>
					{items?.map((item, index) => {
						return (
							<li key={index}>
								<a href={`#${item.linkedTo}`} data-testid={`submenu-item-${index}`}>
									{item.text}
								</a>
							</li>
						);
					})}
				</ul>
			</div>
		</ClickOutsideAlerter>
	);
};
