import { renderRichText } from '@/bloks/RichText';
import { markdownToRichtext } from 'storyblok-markdown-richtext';
import { Document } from 'storyblok-rich-text-react-renderer';
import TurndownService from 'turndown';

const turndownService = new TurndownService();

export const htmlToMarkdownToRichtext = (htmlText: string | null): Document | undefined => {
	if (!htmlText) return;
	const markdownText = turndownService.turndown(htmlText);
	return markdownToRichtext(markdownText);
};

export const renderHtmlAsRichtext = (htmlText: string) => {
	return renderRichText(htmlToMarkdownToRichtext(htmlText));
};
