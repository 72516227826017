import { logger } from '../../server/serverLogger';
import { ApiErrorCode } from '../../types';
import { isError } from '../isError';

const scope = 'http';
export const fetchApiBasePath = `/api/fortnox-website`;

interface RequestOptions {
	controller?: AbortController;
	/** Timeout in milliseconds before long running request is aborted automatically. */
	timeout?: number;
}

/**
 * The raw fetch wrapper for making requests to our /api/fortnox-website
 *
 * Prefer using the fetchApi helper.
 *
 * Normalizes errors, checks status code and parse json response
 *
 * Supports recorded mocks when MOCK environment variable is set.
 */
export async function fetchApiRaw<T = undefined>(
	request: Request,
	{ controller = new AbortController(), timeout = 30000 }: RequestOptions = {},
): Promise<FetchApiResult<T>> {
	const timeBefore = Date.now();
	try {
		const timeoutId = setTimeout(() => controller.abort(), timeout);
		const { signal } = controller;

		/**
		 * When server-rendering and mock recording is enabled, then change url to
		 * proxy request to mock server and pass a FORWARDED header with which host it
		 * should forward real requests to.
		 */
		if (!global.window && process.env.MOCK) {
			const [url, proto, host] = buildMockUrl(new URL(request.url));
			request = new Request(url.toString(), request);
			request.headers.append('Forwarded', `by=fetchApi;proto=${proto};host=${host}`);
		}

		if (
			request.method !== 'GET' &&
			(!request.headers.has('Content-Type') || request.headers.get('Content-Type') === 'text/plain;charset=UTF-8')
		) {
			request.headers.set('Content-Type', 'application/json');
		}

		let response;

		// Make the request and handle network error
		try {
			response = await fetch(request, {
				signal,
				credentials: 'same-origin',
			});
			clearTimeout(timeoutId);
		} catch (fetchError: any) {
			clearTimeout(timeoutId);
			const error = fetchError.name === 'AbortError' ? 'http.abort.error' : 'http.network.error';

			throw new FetchApiError(null, error, fetchError.message);
		}

		const [responseText, responseBody] = (await Promise.all([
			response
				.clone()
				.text()
				.catch((err) => err),
			response.json().catch((err) => err),
		])) as [Error | string, Error | T | { data: T }];

		// Handle response NOT OK status 200..299
		if (!response.ok) {
			let errorCode: ApiErrorCode = 'unknown.error';
			const responseWithError = responseBody as typeof responseBody & { error?: unknown };
			if (typeof responseWithError?.error === 'string') {
				errorCode = responseWithError.error as any;
			}

			let errorMessage: string = errorCode;
			let body: typeof responseBody | string = responseBody;
			if (isError(responseBody) && !isError(responseText)) {
				errorMessage = responseText.trim();
				body = errorMessage;
			}

			throw new FetchApiError(response.status, errorCode, `${errorMessage} ${response.statusText}`, body);
		}

		const timeAfter = Date.now();
		const duration = timeAfter - timeBefore;
		const url = request.url.split('?')[0];

		if (!global.window || process.env.NODE_ENV !== 'production') {
			const level = duration > 500 ? 'info' : 'debug';
			logger[level]({ scope, duration, url }, `Request success time ${duration}ms url ${url}`);
		}

		return new FetchApiResult(response.status, responseBody as T, Object.fromEntries(response.headers));
	} catch (err) {
		const timeAfter = Date.now();
		const duration = timeAfter - timeBefore;
		const url = request.url.split('?')[0];
		if (!global.window || process.env.NODE_ENV !== 'production') {
			logger.info({ scope, duration, url, err }, `Request failed time ${duration}ms url ${url}`);
		}
		if (err instanceof FetchApiError) throw err;
		const message = typeof err === 'string' ? err : ((err as any)?.message ?? 'unknown.error');
		throw new FetchApiError(null, 'unknown.error', message);
	}
}

export class FetchApiResult<T> {
	status: number;
	headers: Record<string, string>;
	body: T;

	constructor(status: number, body: T, headers: Record<string, string>) {
		this.status = status;
		this.headers = headers;
		this.body = body;
	}

	toJSON() {
		const { headers, ...rest } = this;
		return rest;
	}
}

export class FetchApiError<T = unknown> extends Error {
	status: number | null;
	error: ApiErrorCode;
	headers?: Headers;
	body?: T;

	constructor(
		status: number | null,
		error: ApiErrorCode,
		message: string,
		body?: T,
		headers?: Headers | undefined,
		fields: { [key: string]: unknown } = {},
	) {
		super();

		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, FetchApiError);
		}

		this.name = 'FetchApiError';
		this.error = error;
		this.status = status;
		this.message = message;
		this.body = body;
		this.headers = headers;
		Object.assign(this, fields);
	}

	toJSON() {
		const { headers, message: errorMessage, ...rest } = this;
		return { ...rest, errorMessage };
	}
}

/**
 * If the original request was meant to be like this:
 *
 *     curl https://jsonplaceholder.typicode.com/posts
 *
 * Then this will change it to be more like this:
 *
 *     curl -H "Forwarded: by=fetchApi;proto=https;host=jsonplaceholder.typicode.com" http://localhost:3000/posts
 *
 * And that server will make the original request, record it and replay it.
 */
export function buildMockUrl(target: URL, mockPort: string = process.env.MOCK_PORT || '3003'): [URL, string, string] {
	const mockUrl = new URL(`http://localhost:${mockPort}`);
	mockUrl.pathname = target.pathname;
	mockUrl.search = target.search;
	mockUrl.hash = target.hash;

	return [mockUrl, target.protocol.replace(':', ''), target.host];
}
