import { ThemeLightnessStoryblok } from '@/components';
import { FullThemeName, ThemeInfo, ThemeName, getChildTheme, getThemeInfo } from '@/themes';
import { createContext, useContext } from 'react';

const defaultTheme: ThemeName = 'white';
const defaultLightness: ThemeName = 'dark';

interface Props {
	children: React.ReactNode;
	theme?: ThemeName;
	lightness?: ThemeLightnessStoryblok['lightness'];
	applyChildTheme?: boolean;
}

interface ContextProps {
	theme: ThemeName;
	lightness?: ThemeLightnessStoryblok['lightness'];
	applyChildTheme?: boolean;
}

const Context = createContext<ContextProps>({ theme: defaultTheme, lightness: defaultLightness });

export const useThemeContext = () => useContext(Context);

export const useTheme = (): ThemeInfo => {
	const { applyChildTheme, theme } = useContext(Context);
	return applyChildTheme ? getThemeInfo(getChildTheme(theme as FullThemeName)) : getThemeInfo(theme);
};

export const useLightnessTheme = (): ThemeInfo | undefined => {
	const { lightness } = useContext(Context);
	return getThemeInfo(lightness);
};

export const ThemeProvider: React.FC<Props> = ({
	children,
	theme = defaultTheme,
	lightness = defaultLightness,
	applyChildTheme = false,
}) => {
	const value = {
		theme,
		lightness,
		applyChildTheme,
	};

	return <Context.Provider value={value}>{children}</Context.Provider>;
};
