import AddSearchClient, { Hit } from 'addsearch-js-client';
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

export const useSearchV2 = () => {
	return useContext(Context);
};

interface AddSearchContextNotReady {
	ready: false;
	initialize: () => void;
	isSearchOpen: boolean;
	setSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
	searchTerm: string;
	setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
	addSearchClient: AddSearchClient | null;
	search: () => void;
	searchResult: Hit[];
	suggestions: string[];
	animation: 'slideIn' | 'slideOut' | null;
	setAnimation: React.Dispatch<React.SetStateAction<'slideIn' | 'slideOut' | null>>;
}

export interface AddSearchContextReady {
	ready: true;
	initialize: () => void;
	isSearchOpen: boolean;
	setSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
	addSearchClient: AddSearchClient;
	searchTerm: string;
	setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
	search: () => void;
	searchResult: Hit[];
	suggestions: string[];
	animation: 'slideIn' | 'slideOut' | null;
	setAnimation: React.Dispatch<React.SetStateAction<'slideIn' | 'slideOut' | null>>;
}

export type AddSearchContext = AddSearchContextReady | AddSearchContextNotReady;

const initialContext: AddSearchContext = {
	ready: false,
	initialize: () => {},
	isSearchOpen: false,
	addSearchClient: null,
	setSearchOpen: () => {},
	searchTerm: '',
	setSearchTerm: () => {},
	search: () => {},
	searchResult: [],
	suggestions: [],
	animation: null,
	setAnimation: () => {},
};

const Context = createContext<AddSearchContext>(initialContext);

interface Props {
	children: React.ReactNode;
}

export const AddSearchProviderV2 = ({ children }: Props) => {
	const [ready, setReady] = useState(false);
	const [isSearchOpen, setSearchOpen] = useState(false);
	const [animation, setAnimation] = useState<'slideIn' | 'slideOut' | null>(null);
	const [searchTerm, setSearchTerm] = useState('');
	const [searchResult, setSearchResult] = useState<Hit[]>([]);
	const [suggestions, setSuggestions] = useState<string[]>([]);

	const addSearchClient = useRef<AddSearchClient | null>(null);

	const initialize = useCallback(() => {
		if (ready) {
			return;
		}

		if (!addSearchClient.current) {
			addSearchClient.current = new AddSearchClient('c0925c3c5d699c8506c71c7a9f7b5c3f');
			addSearchClient.current.setSuggestionsSize(7);
		}

		setReady(true);
	}, [ready]);

	useEffect(() => {
		if (isSearchOpen && !ready) {
			initialize();
		}
	}, [isSearchOpen, ready, initialize]);

	useEffect(() => {
		if (!searchTerm || searchTerm === '') {
			setSuggestions([]);
			return;
		}

		// Perform search when user has stopped typing for 500ms
		const timer = setTimeout(() => {
			addSearchClient.current!.suggestions(searchTerm, (res) => {
				setSuggestions(res?.suggestions ?? []);
			});
		}, 200);

		return () => clearTimeout(timer);
	}, [searchTerm, addSearchClient]);

	const search = useCallback(() => {
		if (!ready) {
			return;
		}

		if (!searchTerm || searchTerm === '') {
			return;
		}

		if (searchTerm.length >= 3) {
			addSearchClient.current!.search(searchTerm, (res: any) => {
				setSearchResult(res.hits);
			});

			addSearchClient.current!.suggestions(searchTerm, (res: any) => {
				setSuggestions(res?.suggestions ?? []);
			});
		}
	}, [searchTerm, ready]);

	const common = {
		initialize,
		isSearchOpen,
		setSearchOpen,
		searchTerm,
		setSearchTerm,
		searchResult,
		search,
		suggestions,
		animation,
		setAnimation,
	};

	const value = !ready
		? { ...initialContext, ...common, ready: false as const }
		: {
				...initialContext,
				...common,
				ready: true,
				addSearchClient: addSearchClient.current!,
			};

	return <Context.Provider value={value}>{children}</Context.Provider>;
};
