import {
	ContainerPersonalizationHeaderStoryblok,
	ContainerPersonalizationSectionStoryblok,
	ContainerPersonalizationStoryblok,
} from '@/components';
import { useServerPageConfig } from '@/contexts/config';
import { ISbComponentType } from '@/types';
import { useEffect, useState } from 'react';
import DynamicComponent from '../DynamicComponent';

interface Props {
	blok: ContainerPersonalizationStoryblok;
	meta: Record<string, unknown>;
}

export function ContainerPersonalization({ blok, meta }: Props) {
	const sections = [...(blok.header || []), ...(blok.sections || [])];
	const { customerType } = useServerPageConfig();
	const segments = sections.map((section) => section.segments).flat();
	const hasVariant = Boolean(
		(customerType && segments.includes(customerType)) || (customerType && segments.includes('KUNDTYP_ALLA')),
	);

	const [inClient, setInClient] = useState(false);

	useEffect(() => {
		setInClient(true);
	}, []);

	if (!inClient) return null;

	return hasVariant ? (
		<>
			{sections?.map((section) => {
				return <ContainerContent key={section._uid} blok={section} meta={{ ...meta }} />;
			})}
		</>
	) : (
		<DynamicComponent blok={blok.default[0]} meta={{ ...meta }} />
	);
}

interface ContentProps {
	blok: ContainerPersonalizationSectionStoryblok | ContainerPersonalizationHeaderStoryblok;
	meta: Record<string, unknown>;
}
function ContainerContent({ blok, meta }: ContentProps) {
	const contentBlok = blok.component === 'ContainerPersonalizationHeader' ? blok.header[0] : blok.section[0];
	const { customerType } = useServerPageConfig();
	const showBlok = Boolean(
		blok.segments.includes(customerType ?? '') || (customerType && blok.segments.includes('KUNDTYP_ALLA')),
	);

	return showBlok ? (
		<DynamicComponent key={blok._uid} blok={contentBlok as ISbComponentType<any>} meta={{ ...meta }} />
	) : null;
}
