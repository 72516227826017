import { Grid, Section } from '@/atoms';
import { UspCardGroupStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { useGridColumns } from 'hooks/useGridColumns';
import DynamicComponent from '../DynamicComponent';
import styles from './UspCardGroup.module.scss';

interface Props {
	blok: UspCardGroupStoryblok;
	meta?: {
		theme?: string;
	};
}

/**
 * @deprecated Will be replaced by UspLargeGroup
 *
 * TODO: Remove GREEN-791
 *
 * Group component for UspCard.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=91%3A3583
 */
export const UspCardGroup: React.FC<Props> = ({ blok, meta }) => {
	const { usp, showBrandline } = blok;
	const theme = meta?.theme ?? blok?.theme?.[0]?.theme;

	const columns = useGridColumns(usp?.length ?? 0);

	return (
		<Section {...editableBlok(blok)} padding={{ base: 'none' }}>
			<Grid columns={columns} {...editableBlok(blok)} className={cls(styles.container, styles[`theme--${theme}`])}>
				{usp?.map((item) => {
					return (
						<Grid.Item key={item._uid}>
							<DynamicComponent blok={item} meta={{ showBrandline, theme }} />
						</Grid.Item>
					);
				})}
			</Grid>
		</Section>
	);
};
