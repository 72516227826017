import { ProductBox } from '@/atoms';
import { ThemeGreenStoryblok, UspMediumProductStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import styles from './UspMedium.module.scss';

interface Props {
	blok: UspMediumProductStoryblok;
	meta?: {
		theme?: ThemeGreenStoryblok['theme'];
	};
}

/**
 * UspMediumProduct component
 *
 * Uses product information from the product catalog in storyblok.
 *
 */
export function UspMediumProduct({ blok, meta }: Props) {
	const { product, linkButtons } = blok;
	const theme = meta?.theme ?? 'white';

	return (
		<div {...editableBlok(blok)} className={cls(styles[`theme--${theme}`])}>
			<ProductBox product={product} linkButtons={linkButtons} theme={meta?.theme} />
		</div>
	);
}
