import { Button, Image, Modal } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { OffertaForm } from '@/bloks/Form/OffertaForm/OffertaForm';
import { OffertaPopUpButtonStoryblok } from '@/components';
import { getPiwikTrackingSlug } from '@/trackers/piwik';
import { cls } from '@/utils';
import { usePiwikPro } from '@piwikpro/next-piwik-pro';
import { usePathname } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import { t } from 'ttag';
import styles from './OffertaPopUpButton.module.scss';

interface BlokProps {
	blok: OffertaPopUpButtonStoryblok;
}

const blokProps = ({ blok }: BlokProps): OffertaPopUpButtonProps => {
	const props: OffertaPopUpButtonProps = {
		buttonText: blok?.buttonText,
		entryUrl: blok?.entryUrl,
		offertaFormId: blok?.offertaFormId,
	};

	return props;
};

export interface OffertaPopUpButtonProps {
	buttonText: string;
	offertaFormId: string;
	entryUrl: string;
	onClick?: () => void;
}

export const OffertaPopUpButton: Blok<OffertaPopUpButtonProps, BlokProps> = ({
	buttonText,
	offertaFormId,
	entryUrl,
	onClick,
}) => {
	const [showModal, setShowModal] = useState(false);
	// PIWIK IMPRESSION TRACKING
	const { CustomEvent } = usePiwikPro();
	const pathname = usePathname();
	const slug = getPiwikTrackingSlug(pathname);
	const category = 'Form - Leads|SQL';
	const action = 'form - submit';
	const name = `${slug}-offerta|popup`;

	useEffect(() => {
		function formSubmitted() {
			CustomEvent.trackEvent(category, action, name);
		}
		const handleOffertaFormSubmitted = () => {
			formSubmitted();
		};
		window.addEventListener('handleIsDone', handleOffertaFormSubmitted as EventListener);
		return () => {
			window.removeEventListener('handleIsDone', handleOffertaFormSubmitted as EventListener);
		};
	}, [CustomEvent, name]);

	return (
		<>
			<Button
				className={styles.offertaButton}
				onClick={() => {
					onClick && onClick();
					setShowModal(true);
				}}
			>
				{buttonText}
			</Button>

			<Modal
				className={cls(styles.offertaModal, 'piwikTrackContent')}
				show={showModal}
				onClose={() => setShowModal(false)}
				dataContentName={'Offerta form'}
			>
				<div className={styles.image}>
					<Image src={'/offerta-logo.svg'} alt={'Offerta logotyp'} loading={'eager'} width={66} height={32} />
					<div className={styles.text}>{t`en del av Fortnox`}</div>
				</div>
				<div className={styles.formWrapper}>
					<OffertaForm categoryId={offertaFormId} entryUrl={entryUrl} />
				</div>
			</Modal>
		</>
	);
};

OffertaPopUpButton.blokProps = blokProps;
