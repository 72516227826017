import { ContainerFluid, Grid, GridProps, Heading, Section } from '@/atoms';
import { ArticleGroupStoryblok } from '@/components';
import { buildArticleCardProps } from '@/contexts/articles';
import { isNotString } from '@/utils';
import styles from './ArticleGroupSmall.module.scss';
import { ArticleSmall } from './ArticleSmall';

const useGridColumns = (itemCount: number) => {
	const columns: GridProps['columns'] = { base: 1 };

	if (itemCount % 4 === 0) {
		columns.xxl = 4;
	}
	if (itemCount % 3 === 0) {
		columns.md = 2;
		columns.lg = 3;
		columns.xl = 4;
	}
	if (itemCount % 2 === 0) {
		columns.md = 2;
	}
	return columns;
};

interface Props {
	blok: ArticleGroupStoryblok;
}

export const ArticleGroupSmall: React.FC<Props> = ({ blok }) => {
	const columns = useGridColumns(blok.posts?.length ?? 0);
	const articles = blok.posts?.filter(isNotString).map(buildArticleCardProps);

	return (
		<ContainerFluid>
			<Section className={styles.container}>
				{blok?.title && (
					<div className={styles.title} data-testid="article-group-small-header">
						<Heading as="h3" title={blok?.title} marginBottom="2xl" />
					</div>
				)}
				<Grid columns={columns} colGap="3xl" rowGap="xl" className={styles.grid}>
					{articles?.map((article, index) => <ArticleSmall article={article} key={index} />)}
				</Grid>
			</Section>
		</ContainerFluid>
	);
};
