import { ContainerFluid, Grid, GridProps, Heading, Section } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { LinkButtonStoryblok, StorySectionCommonStoryblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import DynamicComponent from '../DynamicComponent';
import styles from './StorySectionCommon.module.scss';

const useGridColumns = (itemCount: number) => {
	const columns: GridProps['columns'] = { base: 1 };

	if (itemCount % 4 === 0) {
		columns.xxl = 4;
	}
	if (itemCount % 3 === 0) {
		columns.xxl = 3;
		columns.xl = 3;
	}
	if (itemCount % 2 === 0) {
		columns.md = 2;
	}
	return columns;
};

interface Props {
	blok: StorySectionCommonStoryblok;
}

/**
 * General Section component that sets columns based on number of items. Used for example Usp's but can be used for any blok.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=184%3A10432
 */
export const StorySectionCommon: React.FC<Props> = ({ blok }) => {
	const { items, title, dividingBorder, linkButtons } = blok;
	const numberOfItems = items?.length;
	const theme = blok?.theme?.[0]?.theme ?? 'white';
	const { hasSubMenu } = useSubMenu();

	const columns = useGridColumns(items?.length ?? 0);

	return (
		<ContainerFluid theme={theme} marginBottom={hasSubMenu ? '3xl' : 'none'}>
			<Section>
				<div
					{...editableBlok(blok)}
					className={cls(styles.container, styles[`theme--${theme}`], {
						[styles[`divider`]]: dividingBorder,
					})}
				>
					<div className={styles.header}>
						<div className={styles.textContainer}>
							{title && <Heading as="div" size="h4" className={styles.title} title={title} marginBottom="3xl" />}
						</div>
						{linkButtons?.map((button: LinkButtonStoryblok) => (
							<LinkButton
								{...LinkButton.blokProps({ blok: button })}
								key={button._uid}
								variant="text"
								className={styles.linkButton}
							/>
						))}
					</div>

					<Grid columns={columns} rowGap="md" className={styles.grid}>
						{items?.map((item) => {
							return (
								<Grid.Item key={item._uid} className={cls(numberOfItems === 1 && styles.singleItem)}>
									<DynamicComponent blok={item} meta={{ theme }} />
								</Grid.Item>
							);
						})}
					</Grid>
				</div>
			</Section>
		</ContainerFluid>
	);
};
