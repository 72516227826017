import { BrandLine, ContainerFluid, Heading, Section, Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { CardSmallGroup } from '@/bloks/Card';
import { CardSmallGroupStoryblok, HeaderCareerDepartmentStoryblok, ThemeCareerStoryblok } from '@/components';
import { editableBlok } from '@/utils/storyblok';
import styles from './HeaderCareerDepartment.module.scss';

interface BlokProps {
	blok: HeaderCareerDepartmentStoryblok;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	smallTitle: blok.smallTitle,
	titlePart1: blok.titlePart1,
	titlePart2: blok.titlePart2,
	text: blok.text,
	cardSmallGroup: blok.cardSmallGroup,
	theme: blok.theme?.[0]?.theme,
	_editable: blok._editable,
});

interface Props {
	smallTitle?: string;
	titlePart1: string;
	titlePart2?: string;
	text?: string;
	cardSmallGroup: CardSmallGroupStoryblok[];
	theme?: ThemeCareerStoryblok['theme'];
	_editable?: string;
}

export const HeaderCareerDepartment: Blok<Props, BlokProps> = ({
	smallTitle,
	titlePart1,
	titlePart2,
	text,
	cardSmallGroup,
	theme = 'white',
	_editable,
}) => {
	return (
		<ContainerFluid theme={theme} marginBottom="none">
			<Section {...editableBlok({ _editable })} padding={{ base: '3xl', md: '5xl' }} maxWidth="1296px">
				<div className={styles.header}>
					<BrandLine className={styles.brandline} />
					{smallTitle && <Heading as="div" size="h6" title={smallTitle} marginBottom="xl" />}
					<Heading as="h1" title={titlePart1} titlePart2={titlePart2} marginBottom="3xl" />
					<Text marginBottom="3xl" className={styles.text}>
						{text}
					</Text>
				</div>
				{cardSmallGroup?.map((blok) => (
					<CardSmallGroup {...CardSmallGroup.blokProps({ blok })} groupGrid key={blok._uid} />
				))}
			</Section>
		</ContainerFluid>
	);
};

HeaderCareerDepartment.blokProps = blokProps;
