import * as Sentry from '@sentry/nextjs';
import { useEffect, useState } from 'react';

/**
 * Exports a boolean value reporting whether is client side or server side by checking on the window object
 */
const isClient = typeof window === 'object';

/**
 * Exports a boolean value reporting whether the given API is supported or not
 */
const isApiSupported = (api: string) => (typeof window !== 'undefined' ? api in window : false);

/**
 * NOTE: **fortnox-website project guidelines**
 * DO NOT useMediaQuery for conditional rendering, it will mess up server-rendering.
 * It's fine to use for interactive code that is specific to mobile or desktop.
 *
 * Accepts a media query string then uses the
 * [window.matchMedia](https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia) API to determine if it
 * matches with the current document.
 *
 * It also monitors the document changes to detect when it matches or stops matching the media query.
 * Returns the validity state of the given media query.
 *
 */
export const useMediaQuery = (mediaQuery: any) => {
	/* eslint-disable react-hooks/rules-of-hooks */
	if (!isClient || !isApiSupported('matchMedia')) {
		return null;
	}

	const [isVerified, setIsVerified] = useState(!!window.matchMedia(mediaQuery).matches);

	useEffect(() => {
		const mediaQueryList = window.matchMedia(mediaQuery);
		const documentChangeHandler = () => setIsVerified(!!mediaQueryList.matches);
		try {
			mediaQueryList.addEventListener('change', documentChangeHandler);
		} catch (error) {
			Sentry.captureException(error);
			mediaQueryList.addEventListener('change', documentChangeHandler);
		}
		documentChangeHandler();
		return () => {
			try {
				mediaQueryList.removeEventListener('change', documentChangeHandler);
			} catch (error) {
				//Safari isn't supporting mediaQueryList.removeEventListener
				Sentry.captureException(error);
				mediaQueryList.removeEventListener('change', documentChangeHandler);
			}
		};
	}, [mediaQuery]);

	return isVerified;
};
