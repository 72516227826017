import { BrandLine, Heading, Text } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { CardThreeStoryblok } from '@/components';
import { ImageAsset } from '@/metabloks';
import { editableBlok } from '@/utils/storyblok';
import React, { CSSProperties } from 'react';
import { renderRichText } from '../RichText';
import styles from './CardThree.module.scss';

interface Props {
	blok: CardThreeStoryblok;
	meta?: {
		containImageSize?: boolean;
	};
}

/**
 * @deprecated Will be removed
 */
export const CardThree: React.FC<Props> = ({ blok, meta }) => {
	const objectFit = meta?.containImageSize ? 'contain' : 'cover';
	const style = {
		'--image-height': objectFit === 'contain' ? '18rem' : '22rem',
	} as CSSProperties;

	return (
		<article {...editableBlok(blok)} className={styles.container} style={style}>
			<div className={styles.textContainer}>
				<BrandLine />
				{blok?.smallTitle && (
					<Heading as="div" size="h6" title={blok.smallTitle} className={styles.smallTitle} marginBottom="xl" />
				)}
				{blok?.title && (
					<Heading
						as="div"
						size="h3"
						title={blok.title}
						className={styles.title}
						marginBottom="3xl"
						data-testid="card-three-header"
					/>
				)}
				{blok.image?.filename && (
					<div className={styles.image}>
						<ImageAsset absolute asset={blok.image} objectFit={objectFit} fill objectPosition="right" />
					</div>
				)}
				{blok.text && (
					<Text as="div" className={styles.description}>
						{renderRichText(blok.text)}
					</Text>
				)}
				<footer>
					{blok.linkButtons?.map((button) => (
						<LinkButton key={button._uid} {...LinkButton.blokProps({ blok: button })} variant={blok?.buttonVariant} />
					))}
				</footer>
			</div>
		</article>
	);
};
