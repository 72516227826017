import { useRouter } from 'next/router';
import React, { createContext, useContext, useEffect, useState } from 'react';

interface AnimationContextProps {
	addAnimation: boolean;
	setAddAnimation: (addAnimation: boolean) => void;
	setTriggerAnimation: (triggerAnimation: boolean) => void;
}

const AnimationContext = createContext<AnimationContextProps>({
	addAnimation: false,
	setAddAnimation: () => {},
	setTriggerAnimation: () => {},
});

export const useAnimationContext = () => useContext(AnimationContext);

interface Props {
	children: React.ReactNode;
}

export const AnimationProvider: React.FC<Props> = ({ children }: Props) => {
	const [addAnimation, setAddAnimation] = useState(false);
	const [triggerAnimation, setTriggerAnimation] = useState(false);
	const router = useRouter();

	useEffect(() => {
		if (triggerAnimation) {
			const handleRouteChangeStart = () => {
				setAddAnimation(false);
			};
			const handleRouteChangeComplete = () => {
				setAddAnimation(true);
			};
			router.events.on('routeChangeStart', handleRouteChangeStart);
			router.events.on('routeChangeComplete', handleRouteChangeComplete);
			return () => {
				router.events.off('routeChangeStart', handleRouteChangeStart);
				router.events.off('routeChangeComplete', handleRouteChangeComplete);
			};
		}
	}, [router.events, triggerAnimation]);

	return (
		<AnimationContext.Provider value={{ addAnimation, setAddAnimation, setTriggerAnimation }}>
			{children}
		</AnimationContext.Provider>
	);
};
