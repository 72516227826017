import { Blok } from '@/bloks/Blok';
import { CardSmallIconGroupStoryblok } from '@/components';
import { CardSmallIcon, CardSmallIconMeta } from '../CardSmall/CardSmallIcon';
import styles from './CardSmallGroup.module.scss';
import { CardSmallGroupCommon, CardSmallGroupCommonProps } from './CardSmallGroupCommon';

type Props = CardSmallGroupCommonProps;

interface BlokProps {
	blok: CardSmallIconGroupStoryblok;
	meta?: CardSmallIconMeta;
}

const blokProps = ({ blok, meta }: BlokProps): Props => ({
	cards: blok?.cards?.map((card) => (
		<CardSmallIcon
			key={card._uid}
			{...CardSmallIcon.blokProps({ blok: card, meta: { ...meta, titleSize: blok.titleSize } })}
		/>
	)),
	_editable: blok._editable,
});

/**
 * @deprecated Will be replaced by CardSmallGroup
 */
export const CardSmallIconGroup: Blok<Props, BlokProps> = (props) => {
	return <CardSmallGroupCommon className={styles.gridContainer} {...props} />;
};
CardSmallIconGroup.blokProps = blokProps;
