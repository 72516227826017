import { cls } from '@/utils';
import styles from './BrandLine.module.scss';

interface Props {
	className?: string;
}

export const BrandStripes: React.FC<Props> = ({ className }) => {
	return (
		<div className={cls(styles.container, className)}>
			<div className={styles.line1} />
			<div className={styles.line2} />
			<div className={styles.line3} />
		</div>
	);
};
