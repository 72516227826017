import { Text, TextProps } from '@/atoms';
import { ImageAsset } from '@/metabloks';
import { Children, isValidElement } from 'react';
import styles from './Paragraph.module.scss';

interface Props {
	children: React.ReactNode;
	size?: 'default' | 'small' | 'ingress';
	marginBottom?: TextProps['marginBottom'];
	applyLightnessTheme?: boolean;
}

const renderImage = (child: any, index: number) => {
	return (
		<div key={index} className={styles.imageContainer}>
			<ImageAsset asset={{ id: 0, filename: child.props.src, alt: child.props.alt, title: child.props.title }} />
		</div>
	);
};

export const Paragraph: React.FC<Props> = ({ children, size, marginBottom, applyLightnessTheme }) => {
	const imageElements = Children.toArray(children).filter((elem) => isValidElement(elem) && elem.type === 'img');
	const filteredElements = Children.toArray(children).filter((elem) => !isValidElement(elem) || elem.type !== 'img');
	return (
		<>
			<Text size={size ?? 'default'} marginBottom={marginBottom} applyLightnessTheme={applyLightnessTheme}>
				{filteredElements}
			</Text>
			{imageElements?.map((imageElem, index) => renderImage(imageElem, index))}
		</>
	);
};
