import { RadiobuttonGroup } from '@/atoms';
import React from 'react';
import { t } from 'ttag';
import styles from './GrowthSupport.module.scss';

interface Props {
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	hasGrowthSupport: boolean;
}

export const GrowthSupport: React.FC<Props> = ({ onChange, hasGrowthSupport }) => {
	return (
		<div className={styles.growthSupportContainer}>
			<fieldset className={styles.radiobuttons}>
				<RadiobuttonGroup
					name="growthSupport"
					options={[
						{
							className: styles.radiobutton,
							label: t`Nej`,
							onChange,
							testID: 'employee-calculator_no-growth-support',
							value: 'Nej',
							id: 'employee-calculator_no-growth-support',
							checked: !hasGrowthSupport,
						},
						{
							className: styles.radiobutton,
							label: t`Ja`,
							onChange,
							testID: 'employee-calculator_yes-growth-support',
							value: 'Ja',
							id: 'employee-calculator_yes-growth-support',
							checked: hasGrowthSupport,
						},
					]}
				/>
			</fieldset>
		</div>
	);
};
