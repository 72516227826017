import { ContainerFluid, Grid, Section } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { HeaderCommon, HeaderCommonProps, Video, VideoProps } from '@/bloks/Other';
import { SectionVideoNewStoryblok, ThemeFullStoryblok } from '@/components';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { getCorrespondingLegacyTheme } from '@/themes';
import { editableBlok } from '@/utils/storyblok';
import React from 'react';
import styles from './SectionVideoNew.module.scss';

interface BlokProps {
	blok: SectionVideoNewStoryblok;
	meta?: { zone?: number };
}

const blokProps = ({ blok, meta }: BlokProps): SectionVideoNewProps => {
	const theme = (getCorrespondingLegacyTheme(blok?.theme?.[0]?.theme) ?? 'white') as ThemeFullStoryblok['theme'];
	const { backgroundPattern, sideBySide } = blok;

	return {
		header: blok.header?.[0] && HeaderCommon.blokProps({ blok: blok.header[0] }),
		video: blok.video?.[0] && Video.blokProps({ blok: blok.video[0] }),
		theme,
		backgroundPattern,
		sideBySide,
		_editable: blok._editable,
		zone: meta?.zone,
	};
};

interface SectionVideoNewProps {
	header?: HeaderCommonProps;
	video: VideoProps;
	theme: ThemeFullStoryblok['theme'];
	backgroundPattern?: boolean;
	sideBySide?: boolean;
	_editable?: string;
	zone?: number;
}

export const SectionVideoNew: Blok<SectionVideoNewProps, BlokProps> = ({
	header,
	video,
	theme = 'white',
	backgroundPattern = false,
	sideBySide = true,
	_editable,
	zone,
}) => {
	const columns = header ? { base: 1, lg: sideBySide ? 2 : 1 } : { base: 1 };
	const contentName = `${zone}|SectionVideoNew`;
	const contentPiece = header && header?.titlePart2 ? `${header?.titlePart1} ${header.titlePart2}` : header?.titlePart1;
	const contentTarget = video.hideControls ? 'no interactions possible' : 'one interaction available';
	return (
		<ImpressionTrackingProvider value={{ contentName, contentPiece, contentTarget }}>
			<ContainerFluid theme={theme} backgroundPattern={backgroundPattern}>
				<Section {...editableBlok({ _editable })} className={!header && !sideBySide ? styles.section : ''}>
					<Grid columns={columns} colGap="3xl">
						{sideBySide && (
							<>
								<Grid.Item className={styles.textColumn}>
									{header && <HeaderCommon {...header} marginBottom="3xl" />}
								</Grid.Item>
								<Grid.Item className={styles.videoColumn}>
									<Video {...video} width="100" />
								</Grid.Item>
							</>
						)}

						{!sideBySide && header && (
							<Grid.Item>
								<HeaderCommon {...header} marginBottom="3xl" layout="center" />
							</Grid.Item>
						)}
						<Grid.Item className={styles.gridItemVideo}>{!sideBySide && <Video {...video} width="60" />}</Grid.Item>
					</Grid>
				</Section>
			</ContainerFluid>
		</ImpressionTrackingProvider>
	);
};

SectionVideoNew.blokProps = blokProps;
