import { ProductCatalogItemStoryblok, ProductCatalogPackageItemStoryblok } from '@/components';
import { ISbStoryData } from '@/types/storyblok';
import React, { createContext, useContext } from 'react';

/**
 * Price information about a product in Fortnox Product Catalog
 */
export const useProduct = (id: number | string | undefined | null): Product | null => {
	const productCatalog = useContext(Context) ?? [];

	if (!id) {
		return null;
	}

	return productCatalog.find((product) => product.id == id) || null;
};

export const useGetAllProducts = (): Product[] | null => {
	const productCatalog = useContext(Context) ?? [];
	return productCatalog;
};

export const useMultipleProducts = (ids: number[] | string[] | null): Product[] | null => {
	const productCatalog = useContext(Context) ?? [];

	if (!ids) {
		return null;
	}

	return productCatalog.filter((product) => ids?.map(Number).includes(Number(product.id)));
};

export const usePackages = (): Product[] => {
	const productCatalog = useContext(Context) ?? [];

	return productCatalog.filter((product) => {
		if (!product.packageProducts) return false;
		return Object.keys(product.packageProducts).length > 0;
	});
};
interface ProductInfoProps {
	product: ISbStoryData<ProductCatalogItemStoryblok | ProductCatalogPackageItemStoryblok>;
}

const getProductIds = (product: ProductInfoProps['product']) => {
	return [product.slug];
};

/**
 *
 * @param product ISbStoryData<
		| ProductCatalogItemStoryblok
		| ProductCatalogPackageItemStoryblok
	>
 */
export const useProductPrice = (product: ProductInfoProps['product'], interval: 3 | 12 = 12): number => {
	const productIds: string[] | null = getProductIds(product);
	const productPriceStructures = useMultipleProducts(productIds);

	return (
		productPriceStructures?.reduce((acc: any, product: Product) => acc + product?.priceStructure[interval]?.[0], 0) ??
		null
	);
};

export type PriceStructure = {
	3?: {
		0: number;
	};
	12: {
		0: number;
	};
};

export interface ProductResponse {
	productCatalog: Product[];
}

export interface Product {
	id: number | string;
	name: string;
	description: string;
	priceStructure: PriceStructure;
	legacyLicenseCode: null | string;
	maxAmount: number | null;
	dependsOn: number | null;
	allowedUsers: string[] | null;
	packageProducts?: Product[];
	amountExtraProducts?: number | null;
	availableExtraProducts?: number[] | null;
	financeCampaignId?: number | null;
	mutuallyExclusiveProducts?: number[];
	addOnProducts?: AddOnProduct[];
	orderChannels: string[] | null;
}

export interface AddOnProduct {
	id: number;
	name: string;
	description: string;
	priceStructure: { [key: string]: number };
	legacyLicenseCode: string;
	maxAmount: number;
	dependsOn: number;
	allowedUsers: null;
	addOnProducts: null;
}

const Context = createContext<Product[]>([]);

interface Props {
	children: React.ReactNode;
	value: Product[];
}

export const ProductsProvider: React.FC<Props> = ({ children, value }) => {
	return <Context.Provider value={value}>{children}</Context.Provider>;
};
