import { ContainerFluid, Grid, GridProps, Heading, Section } from '@/atoms';
import { CardTwoGroupStoryblok } from '@/components';
import DynamicComponent from '../DynamicComponent';
import styles from './CardTwoGroup.module.scss';

const useGridColumns = (itemCount: number) => {
	const columns: GridProps['columns'] = { base: 1 };

	if (itemCount % 4 === 0) {
		columns.xl = 4;
		columns.xxl = 4;
	}
	if (itemCount % 3 === 0) {
		columns.xl = 3;
	}
	if (itemCount % 2 === 0) {
		columns.xl = 2;
		columns.lg = 2;
	}
	return columns;
};
interface Props {
	blok: CardTwoGroupStoryblok;
	meta?: {
		isParent?: boolean;
	};
}

/**
 * @deprecated Will be replaced by CardMediumGroup
 */
export const CardTwoGroup: React.FC<Props> = ({ blok, meta }) => {
	const nrOfCards = blok.cards?.length;
	const columns = useGridColumns(nrOfCards ?? 0);
	const cardTitleSize = blok.cardTitleSize ?? 'h4';
	const buttonVariant = blok?.buttonVariant ?? 'filled';

	return (
		<ContainerFluid marginBottom={meta?.isParent === false ? 'none' : '3xl'}>
			<Section
				className={styles.container}
				paddingX={meta?.isParent === false ? { base: 'none' } : { base: 'none', md: '3xl' }}
			>
				{blok.title && <Heading as="h3" title={blok.title} className={styles.title} marginBottom="custom" />}
				<Grid columns={columns} className={styles.grid}>
					{blok.cards?.map((card) => (
						<Grid.Item key={card._uid}>
							<DynamicComponent
								blok={card}
								meta={{ titleSize: cardTitleSize, buttonVariant: buttonVariant, nrOfCards: nrOfCards }}
							/>
						</Grid.Item>
					))}
				</Grid>
			</Section>
		</ContainerFluid>
	);
};
