import { BrandLine, ContainerFluid, Grid, Heading, Section, Text, VerticalBrandLine } from '@/atoms';
import { renderRichText } from '@/bloks/RichText';
import { CampaignSectionLargeStoryblok, RichtextStoryblok } from '@/components';
import { ImageAsset, ImageAssetProps } from '@/metabloks';
import styles from './CampaignSectionLarge.module.scss';

interface BlokProps {
	blok: CampaignSectionLargeStoryblok;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	title: blok.title,
	text: blok.text,
	image: blok.image,
});

interface Props {
	title: string;
	text: RichtextStoryblok | string;
	image: ImageAssetProps;
}

export function CampaignSectionLarge({ title, text, image }: Props) {
	return (
		<ContainerFluid theme="light-green">
			<Section>
				<Grid columns={{ base: 1, lg: 2 }} className={styles.gridContainer}>
					<Grid.Item className={styles.textColumn}>
						<div className={styles.textContent}>
							<VerticalBrandLine className={styles.verticalBrandLine} />
							<BrandLine className={styles.brandLine} />
							<div className={styles.text}>
								<Heading as="div" size="h4" title={title} />
								<Text as="div">{renderRichText(text)}</Text>
							</div>
						</div>
					</Grid.Item>
					<Grid.Item className={styles.imageColumn}>
						{image?.filename && (
							<ImageAsset absolute asset={image} fill objectFit="cover" objectPosition="center" filters="smart" />
						)}
					</Grid.Item>
				</Grid>
			</Section>
		</ContainerFluid>
	);
}

CampaignSectionLarge.blokProps = blokProps;
