import { t } from 'ttag';
import { luhnCheck } from './luhncheck';

/**
 * Check that only valid chars are used.
 */
const hasValidChars = (chars: string): boolean => !/[^\d]/.test(chars);

/**
 * Remove the hyphen.
 */
const removeHyphen = (value: string | null): string => (value || '').toString().replace(/[-]/gi, '');

/**
 * Check if the length is valid, both with and without hyphen.
 */
const hasValidLength = (value: string | null): boolean =>
	[10, 11, 12, 13].includes((value || '').length) && [10, 12].includes(removeHyphen(value).length);

/**
 * Remove the hundreds digit in the year, if any.
 */
const removeHundredsDigit = (value: string): string => {
	const hasHundredsDigit = value.length === 12 && (value.substr(0, 2) === '19' || value.substr(0, 2) === '20');

	if (hasHundredsDigit) {
		return value.substr(2);
	}
	return value;
};

/**
 * Check if the value is a valid organization number.
 * The same validation is used for personal identity
 * numbers in Sweden.
 *
 * For mor information see:
 * http://en.wikipedia.org/wiki/Personal_identity_number_(Sweden)
 */
export const isValidOrgNumber = (value: string | null): boolean => {
	const cleanNumber = removeHyphen(value);
	const noHundredNumber = removeHundredsDigit(cleanNumber);
	return hasValidChars(cleanNumber) && hasValidLength(value!) && luhnCheck(noHundredNumber);
};

/**
 * Validate org. number and return true if valid,
 * otherwise return a validation message.
 */
export const orgNumberValidator = (value: string | null): string | true => {
	const cleanNumber = removeHyphen(value);
	const noHundredNumber = removeHundredsDigit(cleanNumber);
	const invalidCharsMessage = t`Org-/personnumret innehåller andra tecken än 0-9 och -`;
	const invalidNumberMessage = t`${value} är inte ett giltigt org-/personnummer`;
	if (!hasValidChars(cleanNumber)) {
		return invalidCharsMessage;
	}
	if (!hasValidLength(value)) {
		return invalidNumberMessage;
	}
	if (!luhnCheck(noHundredNumber)) {
		return invalidNumberMessage;
	}
	return true;
};
