import { BrandLine, ContainerFluid, Grid, Heading, Section, Text } from '@/atoms';
import { StorySectionColumnsStoryblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { ImageAsset } from '@/metabloks';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import styles from './StorySectionColumns.module.scss';

interface Props {
	blok: StorySectionColumnsStoryblok;
}

/**
 * Section component with columns that is used in story.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=1932%3A31170
 */
export const StorySectionColumns: React.FC<Props> = ({ blok }) => {
	const { title, textColumn1, textColumn2, image } = blok;
	const { hasSubMenu } = useSubMenu();

	return (
		<ContainerFluid marginBottom={hasSubMenu ? '3xl' : 'none'}>
			<Section>
				<div {...editableBlok(blok)} className={cls(styles.container, image?.filename && styles.withImage)}>
					{image?.filename && (
						<div className={styles.image}>
							<ImageAsset absolute asset={image} fill objectFit="cover" />
						</div>
					)}
					<Grid columns={image?.filename ? { base: 1 } : { base: 1, xl: '4fr 8fr' }} colGap="2xl">
						<Grid.Item>
							<BrandLine />
							{title && <Heading as="h2" size="h5" title={title} data-testid="story-section-columns-header-h2" />}
						</Grid.Item>
						<Grid.Item className={cls(styles.columns, hasSubMenu && styles.hasSubMenu)}>
							<Text className={styles.column1}>{textColumn1}</Text>
							<Text className={styles.column2}>{textColumn2}</Text>
						</Grid.Item>
					</Grid>
				</div>
			</Section>
		</ContainerFluid>
	);
};
