import { ContainerFluid, Grid, Heading, Icon, LargeSticker, Section, Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { LinkButton } from '@/bloks/Button';
import {
	LargeStickerStoryblok,
	LinkButtonStoryblok,
	ProductNewsStoryblok,
	SectionProductNewsStoryblok,
} from '@/components';
import { getStoryContent } from '@/utils/storyblok';
import { StoryblokStory } from 'storyblok-generate-ts';
import React from 'react';
import styles from './SectionProductNews.module.scss';

interface BlokProps {
	blok: SectionProductNewsStoryblok;
}

interface Props {
	product: StoryblokStory<ProductNewsStoryblok> | string;
	largeSticker: LargeStickerStoryblok[] | undefined;
	_editable?: string | undefined;
}

const blokProps = ({ blok }: BlokProps): Props => {
	const props: Props = {
		product: blok.product,
		_editable: blok._editable,
		largeSticker: blok.sticker,
	};
	return props;
};

export const SectionProductNews: Blok<Props, BlokProps> = ({ product, largeSticker }) => {
	const productsNews = getStoryContent(product);

	if (!productsNews) {
		return null;
	}

	return (
		<ContainerFluid className={styles.container}>
			<Section>
				<div data-test-id="product-news-test-id">
					<Grid columns={{ base: 1, md: 3 }} className={styles.newsContainer} colGap="xl">
						{largeSticker && largeSticker?.length > 0 && (
							<Grid.Item colStart={{ base: 1 }} colEnd={{ base: 2 }} className={styles.stickerGrid}>
								{largeSticker.map((sticker) => {
									const icon = sticker.icon?.[0];
									return (
										<LargeSticker
											key={sticker._uid}
											title={sticker.text}
											icon={icon?.name}
											className={styles.sticker}
										/>
									);
								})}
							</Grid.Item>
						)}
						<Grid.Item colStart={{ base: 1, md: 2 }} colEnd={{ base: 2, md: 4 }} className={styles.news}>
							<Heading title={productsNews.title} as="h3" size="h4" marginBottom="md" className={styles.title} />
							{productsNews?.news.slice(0, 3).map((news, index) => (
								<div className={styles.row} key={index} data-test-id={`product-news-test-${index}-id`}>
									<div>
										<Icon color="--action-color" size="1x" name="check" className={styles.checkIcon} />
									</div>
									<div>
										<Text as="div" marginBottom="none" className={styles.newsTitle}>
											{news.title}
										</Text>
										<Text marginBottom="md" as="div" className={styles.newsContent}>
											{news.content}
										</Text>
									</div>
								</div>
							))}
							{productsNews.linkButtons &&
								productsNews.linkButtons?.map((button: LinkButtonStoryblok) => (
									<LinkButton {...LinkButton.blokProps({ blok: button })} key={button._uid} variant="text" />
								))}
						</Grid.Item>
					</Grid>
				</div>
			</Section>
		</ContainerFluid>
	);
};

SectionProductNews.blokProps = blokProps;
