import { BackgroundImage, BrandStripes, ContainerFluid, Heading, Section } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import DynamicComponent from '@/bloks/DynamicComponent';
import { renderRichText } from '@/bloks/RichText';
import { HeaderConversionStoryblok, LinkButtonStoryblok } from '@/components';
import { editableBlok } from '@/utils/storyblok';
import styles from './HeaderConversion.module.scss';

interface Props {
	blok: HeaderConversionStoryblok;
	meta: Record<string, any>;
}

export const HeaderConversion: React.FC<Props> = ({ blok, meta }) => {
	const { label, shortText, linkButtons, cards } = blok;
	const theme = 'darkGreen';

	return (
		<>
			<ContainerFluid theme={theme}>
				{blok.backgroundImage?.filename && (
					<BackgroundImage
						asset={blok.backgroundImage}
						objectPosition="center top"
						overlay
						className={styles.image}
						isHeader
						priority
					/>
				)}
				<Section>
					<div {...editableBlok(blok)} className={styles.container} data-testid="section-conversion-header">
						<div className={styles.content}>
							<div className={styles.inner}>
								<BrandStripes />
								{label && <Heading as="div" size="h5" title={label} marginBottom="lg" />}
								{blok?.h1Part1 && <Heading as="h1" title={blok?.h1Part1} titlePart2={blok.h1Part2} />}
								<div className={styles.shortText}>
									{renderRichText(shortText, { textSize: 'ingress', textMarginBottom: '2xl' })}
								</div>
								{!!linkButtons?.length && (
									<div className={styles.linkButtons}>
										{linkButtons?.map((btn: LinkButtonStoryblok, index: number) => (
											<LinkButton
												key={btn._uid}
												{...LinkButton.blokProps({ blok: btn })}
												variant={index === 0 ? 'filled' : 'outlined'}
											/>
										))}
									</div>
								)}
							</div>
						</div>
					</div>
				</Section>
			</ContainerFluid>
			<ContainerFluid>
				<Section
					paddingX={{
						base: 'xl',
						md: '3xl',
						lg: '5xl',
					}}
					paddingY={{ base: '3xl' }}
				>
					{cards && cards?.length > 0 && (
						<div className={styles.cards}>
							{cards?.map((card) => {
								return (
									<DynamicComponent
										key={card._uid}
										blok={card}
										meta={{ ...meta, layout: 'threeColumns', isParent: false }}
									/>
								);
							})}
						</div>
					)}
				</Section>
			</ContainerFluid>
		</>
	);
};
