import { BrandLine, ContainerFluid, Divider, Grid, Heading, Section, Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { IconLink, IconLinkProps } from '@/bloks/Button/IconLink/IconLink';
import { SectionSupportContactStoryblok } from '@/components';
import { editableBlok } from '@/utils/storyblok';
import styles from './SectionSupportContact.module.scss';

interface BlokProps {
	blok: SectionSupportContactStoryblok;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	title: blok.title,
	iconLinks: blok.iconLinks.map((iconLink) => ({ ...IconLink.blokProps({ blok: iconLink }) })),
	textPart1: blok.textPart1,
	textPart2: blok.textPart2,
	textPart3: blok.textPart3,
	_editable: blok._editable,
});

interface Props {
	title: string;
	iconLinks: IconLinkProps[];
	textPart1?: string;
	textPart2?: string;
	textPart3?: string;
	_editable?: string;
}

export const SectionSupportContact: Blok<Props, BlokProps> = ({
	title,
	iconLinks,
	textPart1,
	textPart2,
	textPart3,
	_editable,
}) => {
	return (
		<ContainerFluid>
			<Section {...editableBlok({ _editable })}>
				<div className={styles.content}>
					<BrandLine className={styles.brandline} />
					<Heading as="h2" title={title} marginBottom="3xl" />
					<Grid columns={{ base: 1, md: 3 }} className={styles.iconGrid} rowGap="3xl">
						{iconLinks.map((iconLink, i) => (
							<Grid.Item key={i}>
								<IconLink {...iconLink} iconColor="--accent-pink" size="large" aria-label={iconLink.text} />
							</Grid.Item>
						))}
					</Grid>
					<Divider color="--disabled-color" />
					<Text muted className={styles.text}>
						<span>{textPart1}</span>
						<span className={styles.verticalLine}>|</span>
						<span>{textPart2}</span>
						<span className={styles.verticalLine}>|</span>
						<span>{textPart3}</span>
					</Text>
				</div>
			</Section>
		</ContainerFluid>
	);
};

SectionSupportContact.blokProps = blokProps;
