export const customIconNames = [
	'angles-right',
	'arpc',
	'customer-number',
	'customers',
	'home-darkgreen',
	'home-white',
	'net-sales',
	'operating-margin',
	'operating-profit',
	'rule-of-fortnox',
	'share-result',
	'tax-result',
	'plus_fistbump',
	'plus_collaboratepuzzle',
	'plus_companygroup',
	'plus_handshake',
	'plus_lightbulb',
	'plus_smallbighouse',
	'plus_support',
	'plus_users',
	'plus_calendar',
	'plus_cloudwifi',
	'plus_bankconnect',
	'plus_cardreceipt',
	'plus_digitalmailbox',
	'plus_fortnoxapp',
	'plus_hammersuitcase',
	'plus_invoicedigital',
	'plus_computormobile',
	'plus_islandcheck',
	'plus_wandform',
	'plus_uploadfile',
	'plus_star',
	'plus_chainconnect',
	'plus_barchart',
	'plus_appcard',
	'plus_cogwheel',
	'plus_hammerbroom',
	'plus_invoicehand',
	'plus_money',
	'plus_checklist',
	'plus_checkmark',
	'plus_envelope',
	'plus_network',
	'plus_paperplane',
	'plus_personagreement',
	'plus_plant',
	'plus_stackedboxes',
	'plus_securitycloud',
	'plus_usercheck',
];
