import {
	Button,
	CaptchaForm,
	ContainerFluid,
	Grid,
	Heading,
	Icon,
	RadiobuttonGroup,
	Section,
	Text,
	TextField,
} from '@/atoms';
import { FieldValidationProvider, FormValidationProvider } from '@/atoms/CaptchaForm/FormValidationContext';
import { getCaptchaSolution } from '@/atoms/CaptchaForm/FriendlyCaptcha';
import gridStyles from '@/atoms/Grid/Grid.module.scss';
import { LinkButton } from '@/bloks/Button';
import { CardFiveGroupStoryblok, EducationApplicationPageStoryblok, HeaderArticleStoryblok } from '@/components';
import { EducationTypesWithOccasion, Event, useCourse } from '@/contexts/eduadmin';
import { logger } from '@/server/serverLogger';
import { EducationApplication } from '@/types/eduadmin';
import { ISbStoryData } from '@/types/storyblok';
import { cls, fetchApi, formatOrgNr, initLocales } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { validateEmailField, validateOrgNumberField, validatePhoneField, validateZipField } from '@/validation';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { t } from 'ttag';
import DynamicComponent from '../../DynamicComponent';
import styles from './EducationApplicationPage.module.scss';

interface Props {
	blok: EducationApplicationPageStoryblok;
	meta: Record<string, any>;
}

const initialApplicationState: EducationApplication = {
	firstName: '',
	lastName: '',
	email: '',
	phone: '',
	orgNumber: '',
	companyName: '',
	address: '',
	zip: '',
	city: '',
	eventId: undefined,
	sessions: [],
	specialDiet: undefined,
	campaignCode: undefined,
};

const date = (event: Event) => {
	const startDate = new Date(event.StartDate);
	const endDate = new Date(event.EndDate);
	const oneDayEvent = startDate.toLocaleDateString() == endDate.toLocaleDateString();
	return oneDayEvent
		? startDate.toLocaleDateString()
		: startDate.toLocaleDateString() + ' - ' + endDate.toLocaleDateString();
};

const time = (event: Event) => {
	const options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' };
	return (
		new Date(event.StartDate).toLocaleTimeString([], options) +
		'-' +
		new Date(event.EndDate).toLocaleTimeString([], options)
	);
};

const isLastApplicationDatePassed = (event: Event) => {
	return event.LastApplicationDate && new Date(event.LastApplicationDate) < new Date();
};
const isFullyBooked = (event: Event) => {
	return event.ParticipantNumberLeft != null && event.ParticipantNumberLeft < 1;
};

const price = (event: Event) => {
	return event.PriceNames?.find((p) => p.Price)?.Price || 0;
};

const integrityMessage = () => (
	<Text size="small">
		{t`Genom att kontakta Fortnox kommer dina personuppgifter behandlas enligt `}
		<LinkButton
			href="/utbildning/villkor"
			variant="text"
			className={styles.integrityMsgBtn}
			arrow={false}
			target="_blank"
		>
			{t`Fortnox utbildningsvillkor`}
		</LinkButton>
		{t` och `}
		<LinkButton
			href="/om-fortnox/integritet-och-sakerhet/"
			variant="text"
			className={styles.integrityMsgBtn}
			arrow={false}
			target="_blank"
		>
			{t`Fortnox Integritetsmeddelande`}
		</LinkButton>
	</Text>
);

enum State {
	EVENT_NOT_FOUND = 'EVENT_NOT_FOUND',
	EVENT_LAST_APPLICATION_DATE_PASSED = 'EVENT_LAST_APPLICATION_DATE_PASSED',
	EVENT_BOOKABLE = 'EVENT_BOOKABLE',
	EVENT_FULLY_BOOKED = 'EVENT_FULLY_BOOKED',
	EVENT_BOOKED_SUCCESSFULLY = 'EVENT_BOOKED_SUCCESSFULLY',
	EVENT_BOOKING_FAILED = 'EVENT_BOOKING_FAILED',
	EVENT_BOOKING_FAILED_BOOKED_UP = 'EVENT_BOOKING_FAILED_BOOKED_UP',
	EVENT_BOOKING_FAILED_ALREADY_BOOKED = 'EVENT_BOOKING_FAILED_ALREADY_BOOKED',
}

export const EducationApplicationPage: React.FC<Props> = ({ blok, meta }) => {
	const contact = blok?.contact as unknown as ISbStoryData<CardFiveGroupStoryblok>;
	const courseProps = useCourse();
	let initialState: State;
	if (!courseProps?.event) {
		initialState = State.EVENT_NOT_FOUND;
	} else if (isLastApplicationDatePassed(courseProps.event)) {
		initialState = State.EVENT_LAST_APPLICATION_DATE_PASSED;
	} else if (isFullyBooked(courseProps.event)) {
		initialState = State.EVENT_FULLY_BOOKED;
	} else {
		initialState = State.EVENT_BOOKABLE;
	}

	const [state, setState] = useState<State>(initialState);
	const [application, setApplication] = useState<EducationApplication>(initialApplicationState);
	const [isLoadingCompanyInfo, setIsLoadingCompanyInfo] = useState(false);
	const [companyInformationError, setCompanyInformationError] = useState<string | null>(null);
	const [isCampaignCodeValid, setCampaignCodeValid] = useState<boolean | null>(null);
	const [specialDietSelected, setSpecialDietSelected] = useState<boolean | null>(null);
	const course = courseProps?.course;
	const router = useRouter();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, [state]);

	useEffect(() => {
		const campaignCodeVal = application.campaignCode;
		const eventIdVal = courseProps?.event?.EventId;
		if (!campaignCodeVal || !eventIdVal) {
			setCampaignCodeValid(null);
			return;
		}

		async function validateCampaignCode(campaignCode: string, eventId: number) {
			const result = await fetchApi('eduadmin-v1/[campaignCode]', campaignCode, eventId);
			const isValid = result?.body?.data?.isValid || false;
			setCampaignCodeValid(isValid);
		}

		const timeout = setTimeout(() => {
			validateCampaignCode(campaignCodeVal, eventIdVal).catch((err) => {
				logger.warn('Failed to check campaign code', err);
				setCampaignCodeValid(false);
			});
		}, 1000);

		return () => clearTimeout(timeout);
	}, [application.campaignCode, courseProps?.event?.EventId]);

	if (router?.locale === 'en' || course?.TranslateToEnglish) {
		initLocales(router.locale);
	}

	const sendApplication = (e: React.BaseSyntheticEvent) => {
		application.eventId = courseProps?.event?.EventId;
		application.sessions = courseProps?.event?.Sessions || [];
		const postToZendesk = Boolean(courseProps?.postToZendesk);

		return async function postEduAdminForm(): Promise<void> {
			const headers = new Headers();
			headers.append('Content-Type', 'application/json');

			const body = {
				data: {
					...application,
					time: courseProps?.event ? time(courseProps.event) : '',
					date: courseProps?.event ? date(courseProps.event) : '',
				},
				postToZendesk,
				captchaSolution: getCaptchaSolution(e),
			};

			let result;
			try {
				result = await fetchApi('eduadmin-v1', body, headers);
			} catch (err: any) {
				if (process.env.NODE_ENV !== 'production') {
					console.warn('EduAdmin Booking Failed', err);
				}

				if (err.message == 'booking.failed.already.booked') {
					setState(State.EVENT_BOOKING_FAILED_ALREADY_BOOKED);
				} else if (err.message == 'booking.failed.fully.booked') {
					setState(State.EVENT_BOOKING_FAILED_BOOKED_UP);
				} else {
					setState(State.EVENT_BOOKING_FAILED);
				}
				return;
			}

			if (result?.status !== 201) {
				if (process.env.NODE_ENV !== 'production') {
					console.warn('EduAdmin Booking Failed', result);
				}
				setState(State.EVENT_BOOKING_FAILED);
				return;
			}

			setState(State.EVENT_BOOKED_SUCCESSFULLY);
			setApplication(initialApplicationState);
		};
	};

	const fetchCompanyInformation = async () => {
		setCompanyInformationError(null);

		if (!application.orgNumber) {
			setCompanyInformationError(t`Ange organisationsnummer.`);
			return;
		}

		if (!validateOrgNumberField(application.orgNumber) || application.orgNumber.length < 11) {
			setCompanyInformationError(t`Organisationsnumret är inte giltigt.`);
			return;
		}

		setIsLoadingCompanyInfo(true);

		let result;
		try {
			result = await fetchApi('company-lookup-v1/[...orgNumber]', application.orgNumber);
		} catch (err) {
			logger.warn('Failed to fetch company information', err);
		}

		const companyInformation = result?.body?.data;

		setIsLoadingCompanyInfo(false);

		setApplication({
			...application,
			companyName: companyInformation?.companyName ?? '',
			address: companyInformation?.address ?? '',
			zip: companyInformation?.zip ?? '',
			city: companyInformation?.city ?? '',
		});

		if (!companyInformation) {
			setCompanyInformationError(t`Hittade inga uppgifter för angivet organisationsnummer.`);
		}
	};

	const handleOrgNumberChange = (e: React.ChangeEvent<any>) => {
		const formattedOrgNumber = formatOrgNr(e.target.value);
		setApplication({ ...application, orgNumber: formattedOrgNumber });
	};

	const courseHref = course?.CourseTemplateId ? `/utbildning/${course?.CourseTemplateId}` : '/utbildningar';

	return (
		<ContainerFluid>
			<Section
				{...editableBlok(blok)}
				maxWidth="925px"
				paddingX={{ base: 'xl' }}
				key={blok._uid}
				className={styles.sectionHeader}
			>
				{blok.header &&
					blok.header?.map((blok) => {
						const educationType = course?.ShowOnWeb ? course.EducationType : '';
						const subjects = course?.Subjects?.join(', ');
						const meta = {
							tags: [educationType, subjects],
						};
						return (
							<DynamicComponent
								key={blok._uid}
								blok={{ ...blok, h1Part1: t`Anmälan` } as HeaderArticleStoryblok}
								meta={meta}
							/>
						);
					})}

				{State.EVENT_NOT_FOUND == state && (
					<div className={styles.textSection}>
						<Heading as="div" size="h4" title={t`Utbildningstillfället finns inte`} />
						<Text>{t`Det kan bero på att det har flyttats eller är borttaget.`}</Text>
					</div>
				)}

				{State.EVENT_LAST_APPLICATION_DATE_PASSED == state && (
					<div className={styles.textSection}>
						<Heading as="div" size="h4" title={t`Anmälningstiden till utbildningstillfället har passerat`} />

						<Text>
							{t`Det går inte längre att anmäla sig till utbildningen. Se om det finns något annat `}
							<LinkButton href={courseHref} variant="text" arrow={false}>
								{t`tillfälle.`}
							</LinkButton>
						</Text>
					</div>
				)}

				{State.EVENT_FULLY_BOOKED == state && (
					<div className={styles.textSection}>
						<div className={styles.iconContainer}>
							<Icon name="star" size="4x" />
						</div>
						<Heading as="div" size="h4" title={t`Utbildningen är fullbokad!`} />
						<Text>
							{t`Detta tillfälle har blivit fullbokat. Se om det finns något annat `}
							<LinkButton href={courseHref} variant="text" arrow={false}>
								{t`tillfälle.`}
							</LinkButton>
						</Text>
					</div>
				)}

				{State.EVENT_BOOKED_SUCCESSFULLY == state && (
					<div className={styles.textSection}>
						<div className={styles.iconContainer}>
							<Icon name="star" size="4x" />
						</div>
						<Heading as="div" size="h4" title={t`Tack för din anmälan!`} />
						<Text>{t`Om en liten stund kommer det ett mejl till dig med uppgifter kring din utbildning. Skulle du inte se mejlet i din inkorg är det bra om du kikar i din skräp- eller kampanjpost.`}</Text>
						<Text>{t`Har du inte fått ett mejl inom 30 minuter hör du av dig till utbildning@fortnox.se så hjälper vi dig.`}</Text>
					</div>
				)}

				{State.EVENT_BOOKING_FAILED == state && (
					<div className={styles.textSection}>
						<Heading as="div" size="h4" title={t`Något gick fel`} />
						<Text>{t`Tillfälligt fel, försök igen lite senare. Om problemen kvarstår, hör av dig till utbildning@fortnox.se så hjälper vi dig med din bokning.`}</Text>
					</div>
				)}

				{State.EVENT_BOOKING_FAILED_BOOKED_UP == state && (
					<div className={styles.textSection}>
						<Heading as="div" size="h4" title={t`Något gick fel`} />
						<Text>
							{t`Detta tillfälle har blivit fullbokat. Se om det finns något annat `}
							<LinkButton href={courseHref} variant="text" arrow={false}>
								{t`tillfälle.`}
							</LinkButton>
						</Text>
					</div>
				)}

				{State.EVENT_BOOKING_FAILED_ALREADY_BOOKED == state && (
					<div className={styles.textSection}>
						<Heading as="div" size="h4" title={t`Något gick fel`} />
						<Text>{t`Det gick inte att genomföra bokningen då du redan är anmäld till detta tillfälle. Vid frågor, hör av dig till utbildning@fortnox.se.`}</Text>
					</div>
				)}
			</Section>

			{State.EVENT_BOOKABLE == state && (
				<>
					<Section maxWidth="925px" paddingX={{ base: 'xl' }} paddingY={{ base: 'lg' }}>
						<div className={styles.aboutSection}>
							<Heading as="div" size="h5" data-testid="about-education-header-h5" title={t`Om din utbildning`} />
							<Grid className={gridStyles.row} columns={{ base: '1fr', md: '1fr 6fr' }}>
								<Grid.Item>
									<Heading as="div" size="h6" data-testid="education-header-h6" title={t`Utbildning:`} />
								</Grid.Item>
								<Grid.Item>
									<Text>{courseProps?.course?.CourseName}</Text>
								</Grid.Item>
								{courseProps?.course?.EducationType &&
									EducationTypesWithOccasion.includes(courseProps.course.EducationType) && (
										<>
											<Grid.Item>
												<Heading as="div" size="h6" data-testid="education-date" title={t`Datum:`} />
											</Grid.Item>
											<Grid.Item>
												<Text>{courseProps?.event && date(courseProps.event)}</Text>
											</Grid.Item>
											<Grid.Item>
												<Heading as="div" size="h6" data-testid="education-time" title={t`Tid:`} />
											</Grid.Item>
											<Grid.Item>
												<Text>{courseProps?.event && time(courseProps.event)}</Text>
											</Grid.Item>
										</>
									)}
								<Grid.Item>
									<Heading as="div" size="h6" data-testid="education-price" title={t`Pris:`} />
								</Grid.Item>
								<Grid.Item>
									<Text>{courseProps?.event && price(courseProps.event) + t` kr exklusive moms`}</Text>
								</Grid.Item>
							</Grid>
						</div>
					</Section>

					<Section maxWidth="925px" paddingX={{ base: 'xl' }} paddingY={{ base: 'lg' }}>
						<Grid columns={{ base: '1fr', lg: '8fr 3fr' }}>
							<Grid.Item>
								<FormValidationProvider>
									<CaptchaForm
										className={styles.form}
										onSubmit={sendApplication}
										buttonText={t`Skicka din anmälan`}
										integrityMessage={integrityMessage()}
										meta={{ ...meta, type: 'eduadmin' }}
									>
										<div className={styles.formSection}>
											<Heading as="div" size="h4" title={t`Deltagare`} />
											<div className={styles.textFieldContainer}>
												<FieldValidationProvider id="eduadmin-form-first-name">
													<TextField
														id="eduadmin-form-first-name-input"
														title={t`Förnamn`}
														name="Application.firstName"
														autoComplete="given-name"
														type="text"
														required={true}
														value={application.firstName}
														bordered={true}
														onChange={(e) => setApplication({ ...application, firstName: e.target.value })}
													/>
												</FieldValidationProvider>
											</div>
											<div className={styles.textFieldContainer}>
												<FieldValidationProvider id="eduadmin-form-last-name">
													<TextField
														id="eduadmin-form-last-name-input"
														title={t`Efternamn`}
														name="Application.lastName"
														autoComplete="family-name"
														type="text"
														required={true}
														value={application.lastName}
														bordered={true}
														onChange={(e) => setApplication({ ...application, lastName: e.target.value })}
													/>
												</FieldValidationProvider>
											</div>
											<div className={styles.textFieldContainer}>
												<FieldValidationProvider id="eduadmin-form-mail">
													<TextField
														id="eduadmin-form-email-input"
														title={t`E-post`}
														name="Application.email"
														autoComplete="email"
														type="text"
														required={true}
														value={application.email}
														bordered={true}
														onChange={(e) => setApplication({ ...application, email: e.target.value })}
														validators={validateEmailField}
													/>
												</FieldValidationProvider>
											</div>
											<div className={styles.textFieldContainer}>
												<FieldValidationProvider id="eduadmin-form-phone">
													<TextField
														id="eduadmin-form-phon-input"
														title={t`Mobiltelefon`}
														name="Application.phone"
														autoComplete="tel-national"
														type="text"
														required={true}
														value={application.phone}
														bordered={true}
														onChange={(e) => setApplication({ ...application, phone: e.target.value })}
														validators={validatePhoneField}
													/>
												</FieldValidationProvider>
											</div>
											{course?.IsSpecialDietAvailable && (
												<div className={styles.textFieldContainer}>
													<div className={styles.radioButtonContainer}>
														<Text>{t`Matallergi *`}</Text>
														<RadiobuttonGroup
															required
															name="Application.specialDietSelected"
															options={[
																{
																	className: styles.radiobutton,
																	id: 'no-special-diet',
																	label: t`Nej`,
																	onChange: () => {
																		setApplication({ ...application, specialDiet: undefined });
																		setSpecialDietSelected(false);
																	},
																	value: 'false',
																},
																{
																	className: styles.radiobutton,
																	id: 'special-diet',
																	label: t`Ja`,
																	onChange: () => {
																		setApplication({ ...application, specialDiet: undefined });
																		setSpecialDietSelected(true);
																	},
																	value: 'true',
																},
															]}
														/>
													</div>
													{specialDietSelected && (
														<div id="eduadmin-form-special-diet">
															<TextField
																id="eduadmin-form-special-diet-input"
																name={'Application.specialDietPreferences'}
																title={t`Matallergi`}
																type="textarea"
																required={true}
																requiredMessage={t`Ange matallergi`}
																value={application.specialDiet}
																bordered={true}
																onChange={(e) =>
																	setApplication({
																		...application,
																		specialDiet: e.target.value,
																	})
																}
															/>
														</div>
													)}
												</div>
											)}
										</div>

										<div className={styles.formSection}>
											<Heading as="div" size="h4" title={t`Företagsuppgifter`} />
											<div className={styles.orgNrContainer}>
												<div className={cls(styles.textFieldContainer, styles.orgNrField)}>
													<FieldValidationProvider id="eduadmin-form-org-nr">
														<TextField
															id="eduadmin-form-org-no-input"
															title={t`Organisationsnummer`}
															name="orgNumber"
															type="text"
															required={true}
															value={application.orgNumber}
															bordered={true}
															onChange={(e) => handleOrgNumberChange(e)}
															validators={validateOrgNumberField}
															maxLength={11}
														/>
													</FieldValidationProvider>
												</div>
												<div className={styles.companyInfoBtnContainer}>
													<Button
														onClick={fetchCompanyInformation}
														disabled={isLoadingCompanyInfo}
														variant="text"
														className={styles.companyInfoBtn}
													>{t`Hämta företagsuppgifter`}</Button>
													{companyInformationError && (
														<Text size="small" className={styles.companyInformationErrorMsg} marginBottom="none">
															{companyInformationError}
														</Text>
													)}
												</div>
											</div>
											<div className={styles.textFieldContainer}>
												<FieldValidationProvider id="eduadmin-form-company-name">
													<TextField
														id="eduadmin-form-company-name-input"
														title={t`Företagsnamn`}
														name="companyName"
														autoComplete="off"
														type="text"
														required={true}
														value={application.companyName}
														bordered={true}
														onChange={(e) =>
															setApplication({
																...application,
																companyName: e.target.value,
															})
														}
													/>
												</FieldValidationProvider>
											</div>
											<div className={styles.textFieldContainer}>
												<FieldValidationProvider id="eduadmin-form-adress">
													<TextField
														id="eduadmin-form-address-input"
														title={t`Adress`}
														name="address"
														autoComplete="address-line1"
														type="text"
														required={true}
														value={application.address}
														bordered={true}
														onChange={(e) =>
															setApplication({
																...application,
																address: e.target.value,
															})
														}
													/>
												</FieldValidationProvider>
											</div>
											<div className={styles.textFieldContainer}>
												<FieldValidationProvider id="eduadmin-form-zip-code">
													<TextField
														id="eduadmin-form-zip-input"
														title={t`Postnummer`}
														name="zip"
														autoComplete="postal-code"
														type="text"
														required={true}
														value={application.zip}
														bordered={true}
														onChange={(e) =>
															setApplication({
																...application,
																zip: e.target.value,
															})
														}
														validators={validateZipField}
													/>
												</FieldValidationProvider>
											</div>
											<div className={styles.textFieldContainer}>
												<FieldValidationProvider id="eduadmin-form-city">
													<TextField
														id="eduadmin-form-city-input"
														title={t`Ort`}
														name="city"
														autoComplete="address-level2"
														type="text"
														required={true}
														value={application.city}
														bordered={true}
														onChange={(e) =>
															setApplication({
																...application,
																city: e.target.value,
															})
														}
													/>
												</FieldValidationProvider>
											</div>
										</div>
										<div>
											{courseProps?.eventHasCoupon?.HasCoupon && (
												<>
													<Heading as="div" size="h4" title={t`Eventuell rabattkod`} />
													<div className={styles.textFieldContainer}>
														<TextField
															id="eduadmin-form-campaign-code-input"
															title={t`Rabattkod`}
															name="campaignCode"
															type="text"
															required={false}
															value={application.campaignCode}
															bordered={true}
															onChange={(e) =>
																setApplication({
																	...application,
																	campaignCode: e.target.value,
																	eventId: e.target.value,
																})
															}
														/>

														{isCampaignCodeValid === true && (
															<div className={styles.campaignCodeValidationOk}>
																<Icon name="check" size="sm" color={'--primary-green'} />
																{t`Giltig rabattkod`}
															</div>
														)}
														{isCampaignCodeValid === false && (
															<div className={styles.campaignCodeValidationError}>
																<Icon name="xmark" size="sm" color={'--danger-color'} />
																{t`Ogiltig rabattkod`}
															</div>
														)}
													</div>
												</>
											)}
										</div>
									</CaptchaForm>
								</FormValidationProvider>
							</Grid.Item>
						</Grid>
					</Section>
				</>
			)}
			{contact && <DynamicComponent blok={contact.content} />}
		</ContainerFluid>
	);
};
