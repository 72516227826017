import { ContainerFluid, Grid, Heading, Icon, Section, Text } from '@/atoms';
import DynamicComponent from '@/bloks/DynamicComponent';
import { renderRichText } from '@/bloks/RichText';
import { CampaignSectionCtaStoryblok, RichtextStoryblok } from '@/components';
import React, { ReactNode } from 'react';
import styles from './CampaignSectionCta.module.scss';

interface BlokProps {
	blok: CampaignSectionCtaStoryblok;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	title: blok.title,
	text: blok.text,
	icon: blok?.icon?.map((icon) => <Icon name={icon.name!} key={icon._uid} size="7x" color="--text-light" />),
	button: blok?.button?.map((button) => (
		<DynamicComponent key={button._uid} blok={button} meta={{ theme: 'purple' }} />
	)),
});

interface Props {
	title: string;
	text?: RichtextStoryblok | string;
	icon?: ReactNode;
	button: ReactNode;
}

export function CampaignSectionCta({ title, text, icon, button }: Props) {
	const theme = 'purple';

	return (
		<ContainerFluid theme={theme}>
			<Section paddingX={{ base: 'md', md: 'xl' }} maxWidth="925px">
				<Grid columns={{ base: 1, md: 2 }} className={styles.container} colGap="3xl" rowGap="3xl">
					<Grid.Item className={styles.textColumn}>
						<Heading as="div" size="h4" title={title} />
						{text && <Text as="div">{renderRichText(text)}</Text>}
					</Grid.Item>
					<Grid.Item className={styles.ctaColumn}>
						{icon && <div className={styles.iconContainer}>{icon}</div>}
						<div className={styles.buttonContainer}>{button}</div>
					</Grid.Item>
				</Grid>
			</Section>
		</ContainerFluid>
	);
}

CampaignSectionCta.blokProps = blokProps;
