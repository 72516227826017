import { Card, Heading } from '@/atoms';
import { LinkButton, LinkButtonProps } from '@/bloks';
import { Blok } from '@/bloks/Blok';
import { AssetStoryblok, PackageLinkCardStoryblok } from '@/components';
import { ThemeProvider } from '@/contexts/theme';
import { ImageAsset } from '@/metabloks';
import React from 'react';
import styles from './PackageLinkCard.module.scss';

interface BlokProps {
	blok: PackageLinkCardStoryblok;
}

const blokProps = ({ blok }: BlokProps): Props => {
	const linkButton = blok?.linkButton?.[0];
	return {
		image: blok.image,
		linkButton: linkButton && LinkButton.blokProps({ blok: linkButton }),
		smallTitle: blok.smallTitle,
		title: blok.title,
	};
};

interface Props {
	title?: string;
	smallTitle?: string;
	image?: AssetStoryblok;
	linkButton?: LinkButtonProps;
}

export const PackageLinkCard: Blok<Props, BlokProps> = ({ image, title, smallTitle, linkButton }) => {
	return (
		<ThemeProvider theme="secondary">
			<div className={styles.container}>
				<div className={styles.stickyBox}>
					<Card className={styles.card} border={false}>
						<div>
							{smallTitle && <Heading size="h6" as="div" title={smallTitle} />}
							{title && <Heading size="h4" as="div" title={title} />}
						</div>
						{image && (
							<div className={styles.imageWrapper}>
								<div className={styles.image}>
									<ImageAsset asset={image} fill objectFit="cover" />
								</div>
							</div>
						)}
						{linkButton && <LinkButton className={styles.button} {...linkButton} />}
					</Card>
				</div>
			</div>
		</ThemeProvider>
	);
};

PackageLinkCard.blokProps = blokProps;
