import { Blok } from '@/bloks/Blok';
import { CardSmallBrandlineGroupStoryblok } from '@/components';
import { CardSmallBrandline, CardSmallBrandlineMeta } from '../CardSmall/CardSmallBrandline';
import styles from './CardSmallGroup.module.scss';
import { CardSmallGroupCommon, CardSmallGroupCommonProps } from './CardSmallGroupCommon';

type Props = CardSmallGroupCommonProps;

interface BlokProps {
	blok: CardSmallBrandlineGroupStoryblok;
	meta?: CardSmallBrandlineMeta;
}

const blokProps = ({ blok, meta }: BlokProps): Props => ({
	cards: blok?.cards?.map((card) => (
		<CardSmallBrandline key={card._uid} {...CardSmallBrandline.blokProps({ blok: card, meta })} />
	)),
	_editable: blok._editable,
});

/**
 * @deprecated Will be replaced by CardSmallGroup
 */
export const CardSmallBrandlineGroup: Blok<Props, BlokProps> = (props) => {
	return <CardSmallGroupCommon className={styles.grid} {...props} />;
};
CardSmallBrandlineGroup.blokProps = blokProps;
