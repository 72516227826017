import { useRouter } from 'next/router';
import React, { Children, createContext, isValidElement, useContext, useEffect, useState } from 'react';

interface ContextProps {
	closeAll(): void;
	closeOnly(panelId: string): void;
	isOpen(panelId: string): boolean;
	openAll(): void;
	openOnly(panelId: string): void;
	toggleOpen(panelId: string): void;
}

const ExpansionPanelContext = createContext<ContextProps>({
	closeAll: () => {},
	closeOnly: () => {},
	isOpen: () => false,
	openAll: () => {},
	openOnly: () => {},
	toggleOpen: () => {},
});

interface Props {
	children: React.ReactNode;
	multiple?: boolean;
}

const ExpansionPanelsProvider: React.FC<Props> = ({ children, multiple }) => {
	const router = useRouter();
	const [state, setState] = useState<Record<string, true>>({});

	const panelIds: string[] = [];
	Children.forEach(children, (child) => {
		if (!isValidElement(child)) return;
		const panelId = child.props.panelId;
		if (!panelId) return;
		panelIds.push(panelId);
	});
	panelIds.sort();
	const panelIdsJoined = panelIds.join('|');

	useEffect(() => {
		const panelIds = panelIdsJoined.split('|');
		const anchorId = router.asPath?.split('#')?.[1];
		if (!anchorId) return;
		if (!panelIds.includes(anchorId)) return;
		setState({ [anchorId]: true });
	}, [router.asPath, panelIdsJoined, setState]);

	function closeAll() {
		setState({});
	}
	function closeOnly(panelId: string) {
		setState(({ [panelId]: _, ...rest }) => rest);
	}
	function isOpen(panelId: string) {
		return !!state[panelId];
	}
	function openAll() {
		setState(Object.fromEntries(panelIds.map((id) => [id, true])));
	}
	function openOnly(panelId: string) {
		setState({ [panelId]: true });
	}
	function toggleOpen(panelId: string, shouldBeOpen?: boolean) {
		setState(({ [panelId]: open, ...rest }) => {
			shouldBeOpen ||= !open;
			if (!multiple) rest = {};
			return shouldBeOpen ? { ...rest, [panelId]: true } : rest;
		});
	}

	const value = {
		closeAll,
		closeOnly,
		isOpen,
		openAll,
		openOnly,
		toggleOpen,
	};

	return <ExpansionPanelContext.Provider value={value}>{children}</ExpansionPanelContext.Provider>;
};

const useExpansionPanels = () => {
	const context = useContext(ExpansionPanelContext);

	if (context === undefined) {
		throw new Error('Expansion panel is missing a wrapping provider.');
	}

	return context;
};

export { ExpansionPanelsProvider, useExpansionPanels };
