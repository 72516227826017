import { ContainerFluid, Heading, Section, Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { LinkButton, LinkButtonProps } from '@/bloks/Button';
import { HeaderSupportStoryblok } from '@/components';
import { getThemeInfo } from '@/themes';
import styles from './HeaderSupport.module.scss';

interface BlokProps {
	blok: HeaderSupportStoryblok;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	titlePart1: blok.titlePart1,
	titlePart2: blok.titlePart2,
	text: blok.text,
	linkButtons: blok.linkButton?.map((btn) => ({ ...LinkButton.blokProps({ blok: btn }) })),
});

interface Props {
	titlePart1: string;
	titlePart2: string;
	text?: string;
	linkButtons?: LinkButtonProps[];
}

export const HeaderSupport: Blok<Props, BlokProps> = ({ titlePart1, titlePart2, text, linkButtons }) => {
	const themeInfo = getThemeInfo('support');
	return (
		<ContainerFluid theme="white">
			<Section as="header">
				<div className={styles.content}>
					<Heading
						className={themeInfo.styles.titleColor}
						as="h1"
						title={titlePart1}
						titlePart2={titlePart2}
						marginBottom="2xl"
					/>
					<Text>{text}</Text>
					{linkButtons?.map((btn, i) => <LinkButton {...btn} key={i} />)}
				</div>
			</Section>
		</ContainerFluid>
	);
};

HeaderSupport.blokProps = blokProps;
