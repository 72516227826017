import { Heading } from '@/atoms';
import { CtaTwoStoryblok } from '@/components';
import { ImageAsset } from '@/metabloks';
import { editableBlok } from '@/utils/storyblok';
import { LinkButton } from '../Button';
import { renderRichText } from '../RichText';
import styles from './CtaTwo.module.scss';

interface Props {
	blok: CtaTwoStoryblok;
}

/**
 * @deprecated Will be removed and replaced by CardMedium
 */
export const CtaTwo: React.FC<Props> = ({ blok }) => {
	return (
		<article {...editableBlok(blok)} className={styles.container}>
			{blok.image?.filename && (
				<div className={styles.image}>
					<ImageAsset absolute asset={blok.image} objectFit="cover" fill />
				</div>
			)}
			<div>
				{blok.title && <Heading as="div" size="h5" title={blok.title} data-testid="cta-two-header-h5" />}
				{blok.text && renderRichText(blok.text)}

				{blok.linkButtons && (
					<footer className={styles.footer}>
						{blok.linkButtons?.map((button) => (
							<LinkButton key={button._uid} {...LinkButton.blokProps({ blok: button })} variant="text" />
						))}
					</footer>
				)}
			</div>
		</article>
	);
};
