import { Price } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { getPriceStructure, getProductIds } from '@/bloks/PriceCard/PriceCardV2/PriceCardV2';
import { LinkButtonStoryblok, ProductCatalogItemStoryblok, ProductCatalogPackageItemStoryblok } from '@/components';
import { useImpressionTracking } from '@/contexts/piwik/ImpressionTrackingProvider';
import { PriceStructure, useMultipleProducts, useProduct } from '@/contexts/products';
import { cls } from '@/utils';
import { handlePriceCardClickEvent } from '@/utils/piwik';
import { usePathname } from 'next/navigation';
import { StoryblokStory } from 'storyblok-generate-ts';
import React from 'react';
import styles from './SectionComparisonTable.module.scss';

interface PriceBlokProps {
	product: StoryblokStory<ProductCatalogItemStoryblok> | StoryblokStory<ProductCatalogPackageItemStoryblok> | any;
	meta?: { zone: number | undefined };
	showOrderButtons?: boolean;
	theme?: any;
	primary: boolean;
	headerHeight?: string;
	linkButton?: LinkButtonStoryblok;
}

export const PriceBox: React.FC<PriceBlokProps> = ({
	product,
	meta,
	showOrderButtons,
	primary,
	headerHeight,
	linkButton,
}) => {
	const productLinkButton = product?.content?.linkButtons?.[0];
	const button = linkButton ? linkButton : productLinkButton;
	const productCatalogData = useProduct(product?.slug);
	const productCatalogDataMultipleProducts = useMultipleProducts(getProductIds(product?.content));
	const pathname = usePathname();

	const customPrice = product?.content?.price ?? '';

	const { setTargetInteraction } = useImpressionTracking();

	return (
		<div
			className={cls(
				headerHeight == 'small' && styles.stickyPriceBoxContainer,
				styles.priceBoxContainer,
				!button && styles.adjustPrice,
			)}
		>
			<Price
				priceStructure={
					customPrice
						? { 3: undefined, 12: { 0: parseFloat(customPrice) } }
						: (productCatalogDataMultipleProducts?.length ?? 0 > 0)
							? getPriceStructure(productCatalogDataMultipleProducts)
							: (productCatalogData?.priceStructure as PriceStructure)
				}
				priceText={product.content?.customPriceText}
				unit={product.content?.priceUnit ? product.content?.priceUnit : 'kr'}
				interval={product.content?.priceInterval ? product.content?.priceInterval : 'mån'}
				className={cls(styles.price, headerHeight == 'small' && styles.stickyPrice)}
				showFrom={!product?.content?.hideFrom}
				size={headerHeight == 'small' ? 'h6' : primary ? 'h4' : 'h5'}
			/>

			{showOrderButtons && button && (
				<LinkButton
					onClick={() => {
						handlePriceCardClickEvent({
							productName: product.name,
							productId: product.slug,
							zone: meta?.zone,
							buttonVariant: 'filled',
							buttonText: button.text,
						});
						setTargetInteraction({ contentTarget: button.text });
					}}
					key={button._uid}
					{...LinkButton.blokProps({ blok: button })}
					variant={primary ? 'filled' : 'outlined'}
					size={'small'}
					className={cls(styles.priceButton, primary && headerHeight == 'small' && styles.primaryPriceButton)}
					queryString={`pageToCheckout=${pathname}`}
				/>
			)}
		</div>
	);
};
