/**
 * Turns a name into a url-friendly slug
 */
export const slugifyName = (name: string) => {
	return name
		.toLowerCase()
		.replace(/[åä]/g, 'a')
		.replace(/[ö]/g, 'o')
		.split(/[^a-z0-9]+/g)
		.filter((s) => s)
		.join('-');
};
