import { Grid } from '@/atoms';
import DynamicComponent from '@/bloks/DynamicComponent';
import sectionTabsStyles from '@/bloks/Section/SectionTabs/SectionTabs.module.scss';
import { TabUspsStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import styles from './TabUsps.module.scss';

interface Props {
	blok: TabUspsStoryblok;
	display: boolean;
}
export const TabUsps: React.FC<Props> = ({ blok, display }) => {
	return (
		<div
			className={cls(
				styles.container,
				display ? sectionTabsStyles.display : sectionTabsStyles.hidden,
				sectionTabsStyles.panel,
			)}
			{...editableBlok(blok)}
		>
			<Grid columns={{ base: 1, lg: 3 }} rowGap="xl">
				{blok.usps.map((usp) => {
					return (
						<Grid.Item key={usp._uid} className={styles.gridItem}>
							<DynamicComponent blok={usp} meta={{ layout: 'center', theme: 'white', buttonVariant: 'text' }} />
						</Grid.Item>
					);
				})}
			</Grid>
		</div>
	);
};
