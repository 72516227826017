export const CategoryIcons = new Map([
	[1, 'notebook'],
	[2, 'file-chart-pie'],
	[3, 'chart-column'],
	[4, 'handshake'],
	[5, 'mail-bulk'],
	[6, 'scanner-touchscreen'],
	[7, 'file-signature'],
	[8, 'globe'],
	[9, 'money-bill-transfer'],
	[10, 'store'],
	[11, 'file-chart-column'],
	[12, 'receipt'],
	[13, 'address-card'],
	[14, 'industry-windows'],
	[15, 'business-time'],
	[16, 'house-building'],
	[17, 'envelope'],
	[18, 'cubes'],
	[19, 'badge-check'],
	[20, 'building-columns'],
	[21, 'people-line'],
	[22, 'hand-holding-dollar'],
	[23, 'chart-tree-map'],
	[24, 'share-nodes'],
	[25, 'tree-deciduous'],
	[26, 'calendar-heart'],
	[30, 'chart-line-up'],
]);
