import { ThemeProvider } from '@/contexts/theme';
import { getThemeInfo } from '@/themes';
import { cls } from '@/utils';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { t } from 'ttag';
import { Icon } from '../Icon';
import { Text } from '../Text';
import styles from './Tooltip.module.scss';

interface Props {
	/**
	 * Text inside tooltip
	 */
	text: string | ReactElement;

	/**
	 * In what direction the tooltip is displayed relative to the icon.
	 */
	position?: 'left' | 'right';

	/**
	 * Sets background and text color of the Tooltip
	 */
	theme?: 'white' | 'darkGreen';

	className?: string;
}

/**
 * Tooltip provides an icon that display informative text when users tap it.
 */
export const Tooltip: React.FC<Props> = ({ text, position = 'right', theme = 'white', className }) => {
	const ref = useRef<HTMLDivElement>(null);
	const [show, setShow] = useState(false);

	const handleClickOutside = (event: MouseEvent) => {
		const element = event.target as HTMLUnknownElement;
		if (ref.current && !ref.current?.contains(element)) {
			setShow(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	const themeInfo = getThemeInfo(theme);

	return (
		<ThemeProvider theme={theme}>
			<div
				data-testid="tooltip-container"
				ref={ref}
				className={styles.container}
				aria-label="tooltip"
				onClick={() => {
					setShow(!show);
				}}
				role="tooltip"
			>
				<Icon
					aria-label={t`Öppna informationsruta`}
					className={styles.icon}
					name="circle-question"
					color="--text-primary-green"
					size="sm"
				/>

				<div
					data-testid="tooltip-toggle"
					className={cls(
						!show && styles.hidden,
						styles.tooltip,
						styles[`position--${position}`],
						styles[`theme--${theme}`],
						themeInfo.styles.bgColor,
						className,
					)}
					onClick={() => {
						setShow(!show);
					}}
				>
					<Text as="div" marginBottom="none">
						{text}
					</Text>
				</div>
			</div>
		</ThemeProvider>
	);
};
