import { PageLayout } from '@/atoms';
import DynamicComponent from '@/bloks/DynamicComponent';
import { CampaignPageStoryblok, SubMenuStoryblok } from '@/components';
import { ISbStoryData } from '@/types/storyblok';

interface Props {
	blok: CampaignPageStoryblok;
	meta: Record<string, any>;
}

export function CampaignPage({ blok, meta }: Props) {
	const { zone1, zone2, zone3, zone4, zone5 } = blok;
	const subMenu = blok?.subMenu as unknown as ISbStoryData<SubMenuStoryblok>;

	return (
		<PageLayout subMenu={subMenu} meta={meta}>
			{zone1?.map((blok: any) => <DynamicComponent blok={blok} key={blok._uid} meta={{ zone: 1 }} />)}

			{zone2?.map((blok: any) => <DynamicComponent blok={blok} key={blok._uid} meta={{ zone: 2 }} />)}

			{zone3?.map((blok: any) => (
				<DynamicComponent blok={blok} key={blok._uid} meta={{ zone: 3, buttonVariant: 'text' }} />
			))}

			{zone4?.map((blok: any) => <DynamicComponent blok={blok} key={blok._uid} meta={{ zone: 4, layout: 'center' }} />)}

			{zone5?.map((blok: any) => (
				<DynamicComponent blok={blok} key={blok._uid} meta={{ zone: 5, buttonVariant: 'text' }} />
			))}
		</PageLayout>
	);
}
