import { LinkAnchor } from '@/atoms/LinkAnchor/LinkAnchor';
import { ImageLinkStoryblok } from '@/components';
import { ImageAsset } from '@/metabloks';
import { buildLinkProps } from '../../Button/LinkButton';

interface Props {
	blok: ImageLinkStoryblok;
	className?: string;
	meta?: {
		fill: boolean;
	};
	target?: '_blank';
	rel?: string;
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

/**
 * Image with a link.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=249%3A9072
 */
export const ImageLink: React.FC<Props> = ({ blok, className, meta, target, rel, onClick }) => {
	const { image, link } = blok;
	const linkProps = buildLinkProps(link);

	if (!rel && target === '_blank') {
		rel = 'noopener noreferrer';
	}

	return image.filename ? (
		<LinkAnchor
			{...linkProps}
			target={target}
			rel={rel || ''}
			data-testid={`download-app-link-${image.alt?.replace(/ /g, '-')}`}
			className={className}
			onClick={onClick}
		>
			<ImageAsset asset={image} fill={meta?.fill} />
		</LinkAnchor>
	) : null;
};
