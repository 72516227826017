import { ContainerFluid, Section } from '@/atoms';
import DynamicComponent from '@/bloks/DynamicComponent';
import { HeaderCommon } from '@/bloks/Other';
import { SectionCalculatorStoryblok } from '@/components';
import React from 'react';

interface Props {
	blok: SectionCalculatorStoryblok;
	meta?: {
		removeMarginBottom: boolean;
		exo2?: boolean;
	};
}

export const SectionCalculator: React.FC<Props> = ({ blok, meta }) => {
	return (
		<ContainerFluid marginBottom={meta?.removeMarginBottom ? 'none' : '3xl'}>
			<Section maxWidth="925px">
				{blok.header?.map((header) => (
					<HeaderCommon {...HeaderCommon.blokProps({ blok: header })} key={header._uid} exo2={meta?.exo2} />
				))}
				{blok.calculator?.map((calculator) => <DynamicComponent blok={calculator} key={calculator._uid} />)}
			</Section>
		</ContainerFluid>
	);
};
