import { Integration } from './types';

export type SortByOptions = 'name' | 'rating' | 'reviews' | undefined;
export type SortOrderOptions = 'asc' | 'desc';

export const sortIntegrations = (
	integrations: Integration[],
	sortBy?: SortByOptions,
	sortOrder: SortOrderOptions = 'asc',
): Integration[] => {
	const sortedArray = [...integrations];
	if (!sortBy) return integrations;

	const compareName = (a: Integration, b: Integration): number => {
		const nameA = a.appName.toUpperCase();
		const nameB = b.appName.toUpperCase();
		return sortOrder === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
	};

	const compareAverageScore = (a: Integration, b: Integration): number => {
		return b.ratings.averageScore - a.ratings.averageScore;
	};

	const compareRatingCount = (a: Integration, b: Integration): number => {
		return b.ratings.numberOfRatings - a.ratings.numberOfRatings;
	};

	let sortFunction;

	switch (sortBy) {
		case 'name':
			sortFunction = compareName;
			break;
		case 'rating':
			sortFunction = compareAverageScore;
			break;
		case 'reviews':
			sortFunction = compareRatingCount;
			break;
		default:
			sortFunction = undefined;
	}

	if (sortFunction) {
		sortedArray.sort(sortFunction);
	}

	return sortedArray;
};
