import { Divider, Grid, GridProps, Heading, ProductBoxWithPrice } from '@/atoms';
import { PriceCardLargeStoryblok } from '@/components';
import { isNotString } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import DynamicComponent from '../../DynamicComponent';
import styles from './PriceCardLarge.module.scss';
import { PriceCardMainProduct } from './PriceCardMainProduct';

interface Props {
	blok: PriceCardLargeStoryblok;
	meta?: { zone: number };
}

const useGridColumns = (itemCount: number) => {
	const columns: GridProps['columns'] = { base: 1 };

	if (itemCount % 4 === 0) {
		columns.xl = 4;
		columns.md = 2;
	}
	if (itemCount % 3 === 0) {
		columns.xxl = 3;
		columns.xl = 3;
	}
	if (itemCount % 3 === 0) {
		columns.lg = 3;
	}
	if (itemCount % 2 === 0) {
		columns.md = 2;
	}
	return columns;
};

export const PriceCardLarge: React.FC<Props> = ({ blok, meta }) => {
	const { extraProductsTitle, textLinkGroup, title, subTitle, list, description, product } = blok;
	const extraProducts = blok.extraProducts.filter(isNotString);
	const mainProducts = blok.mainProducts;
	const columns = useGridColumns(extraProducts?.length ?? 0);

	return (mainProducts || product) !== null ? (
		<div {...editableBlok(blok)} className={styles.priceCardLarge} data-testid={`price-card-large-${blok._uid}`}>
			{mainProducts &&
				mainProducts.map((product) => {
					return (
						<PriceCardMainProduct
							key={product._uid}
							zone={meta?.zone}
							title={product.title}
							subTitle={product.subTitle}
							description={product.description}
							list={product.list}
							product={product.product}
						/>
					);
				})}
			{product && (
				<PriceCardMainProduct
					zone={meta?.zone}
					title={title}
					subTitle={subTitle}
					description={description}
					list={list ?? []}
					product={product}
				/>
			)}
			<div className={styles.additionalProductsContainer}>
				{extraProductsTitle && <Heading as="div" size="h6" title={extraProductsTitle} marginBottom="3xl" />}
				<Grid className={styles.additionalProductsRowHeader} colGap="2xl" rowGap="2xl" columns={columns}>
					{extraProducts?.map((extraProduct) => (
						<ProductBoxWithPrice
							key={extraProduct.slug}
							product={extraProduct}
							testID={`price-card-large-extra-product-${extraProduct.slug}`}
							meta={meta}
						/>
					))}
				</Grid>
				{!!textLinkGroup?.length && (
					<div>
						<Divider margin="3xl" />
						{textLinkGroup?.map((linkGroup) => (
							<DynamicComponent key={linkGroup._uid} blok={linkGroup} meta={{ theme: 'light-green' }} />
						))}
					</div>
				)}
			</div>
		</div>
	) : null;
};
