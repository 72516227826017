import { Heading, Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { PromotorbubbleStoryblok } from '@/components';
import { editableBlok } from '@/utils/storyblok';
import styles from './PromotorBubble.module.scss';

interface BlokProps {
	blok: PromotorbubbleStoryblok;
}

export interface PromotorBubbleProps {
	title: string;
	text: string;
	text2?: string;
	_editable?: string;
}

const blokProps = ({ blok }: BlokProps): PromotorBubbleProps => ({
	title: blok.title,
	text: blok.text,
	text2: blok.text2,
	_editable: blok._editable,
});

export const PromotorBubble: Blok<PromotorBubbleProps, BlokProps> = ({ title, text, text2, _editable }) => {
	return (
		<div className={styles.promotorBubble} {...editableBlok({ _editable })}>
			<Heading as="h2" title={title} className={styles.title} marginBottom="none" />
			<Text marginBottom="none">{text}</Text>
			<Text marginBottom="none">{text2}</Text>
		</div>
	);
};

PromotorBubble.blokProps = blokProps;
