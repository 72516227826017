import { ContainerFluid, Grid, Section } from '@/atoms';
import { UspGroupStoryblok, UspStoryblok } from '@/components';
import { editableBlok } from '@/utils/storyblok';
import { useGridColumns } from 'hooks/useGridColumns';
import { Blok } from '../Blok';
import { Usp } from './Usp';
import styles from './UspGroup.module.scss';

interface BlokProps {
	blok: UspGroupStoryblok;
	meta?: {
		isParent?: boolean;
		layout?: 'horizontal' | 'vertical';
	};
}

const blokProps = ({ blok, meta }: BlokProps): Props => {
	const layout = meta?.layout ?? blok?.layout ?? 'horizontal';
	return { _editable: blok._editable, isParent: meta?.isParent, layout, usp: blok.usp };
};

interface Props {
	_editable?: string;
	isParent?: boolean;
	layout: 'horizontal' | 'vertical';
	usp?: UspStoryblok[];
}

/**
 * @deprecated Will be replaced by UspMediumGroup
 *
 * TODO: Remove GREEN-791
 *
 * Group component for Usp.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=2021%3A32815
 */
export const UspGroup: Blok<Props, BlokProps> = ({ _editable, layout, isParent, usp }) => {
	const columns = useGridColumns(usp?.length ?? 0);

	return (
		<ContainerFluid marginBottom={isParent === false || layout === 'vertical' ? 'none' : '3xl'}>
			<Section
				{...editableBlok({ _editable })}
				paddingX={isParent === false ? { base: 'none' } : { base: 'md', md: '3xl' }}
				paddingY={{ base: 'none' }}
			>
				<Grid
					columns={layout === 'horizontal' ? columns : { base: 1, md: 1, lg: 1, xl: 1 }}
					colGap="3xl"
					rowGap="3xl"
					className={styles.container}
				>
					{usp?.map((item) => (
						<Usp
							key={item._uid}
							{...Usp.blokProps({
								blok: item,
								meta: { isInsideUspGroup: true },
							})}
						/>
					))}
				</Grid>
			</Section>
		</ContainerFluid>
	);
};

UspGroup.blokProps = blokProps;
