import { Heading } from '@/atoms';
import { TextWrapImageStoryblok } from '@/components';
import { ImageAsset } from '@/metabloks';
import { cls } from '@/utils';
import { renderRichText } from '../../RichText';
import styles from './TextWrapImage.module.scss';

interface Props {
	blok: TextWrapImageStoryblok;
	meta?: {
		exo2?: boolean;
	};
}

export const TextWrapImage: React.FC<Props> = ({ blok, meta }) => {
	const { text, image, imagePlacement, title, titleSize } = blok;

	return (
		<div className={styles.container}>
			{title && <Heading title={title} as={titleSize ?? 'h2'} exo2={meta?.exo2} />}
			<div data-testid="text-wrap-image-content">
				<div className={cls(styles.image, styles[`float--${imagePlacement}`])}>
					{image?.filename && <ImageAsset asset={image} objectFit="contain" testID="image" />}
				</div>
				{renderRichText(text, { exo2: true })}
			</div>
		</div>
	);
};
