import { Heading, Icon, IconType } from '@/atoms';
import { TextFooterStoryblok } from '@/components';
import { useImpressionTracking } from '@/contexts/piwik/ImpressionTrackingProvider';
import { IconName } from 'icons/types';
import { LinkButton } from '../../Button';
import { renderRichText } from '../../RichText';
import styles from './TextFooter.module.scss';

interface Props {
	blok: TextFooterStoryblok;
	meta?: {
		titleSize?: 'h5' | 'h6';
		titleAs?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
		iconName?: IconName;
		iconType?: IconType;
	};
}

export const TextFooter: React.FC<Props> = ({ blok, meta }) => {
	const { title, text, linkButtons } = blok;
	const { setTargetInteraction } = useImpressionTracking();

	return (
		<div>
			{title && (
				<div className={styles.headerContainer}>
					{meta?.iconName && <Icon type={meta?.iconType} name={meta.iconName} className={styles.icon} />}
					<Heading as={meta?.titleAs ?? 'div'} size={meta?.titleSize ?? 'h5'} title={title} marginBottom="lg" />
				</div>
			)}
			{text && <div className={styles.textContainer}>{renderRichText(text, { textMarginBottom: 'none' })}</div>}
			{linkButtons?.map((button) => (
				<LinkButton
					key={button._uid}
					{...LinkButton.blokProps({ blok: button })}
					variant="text"
					onClick={() => {
						setTargetInteraction({ contentTarget: button.text ?? '' });
					}}
				/>
			))}
		</div>
	);
};
