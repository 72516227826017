import { ZendeskFormField } from '@/types';
import { hostnameWithoutSubdomain } from '@/utils';

const hostname = global.window?.location?.hostname;
const domain = hostnameWithoutSubdomain(hostname);

const DROPZONE_ID_DEV = 'R3CYKW2BjbR4vQDIFoaS_BspTmI6HZgscJYlbAb1v5c';
const DROPZONE_ID_PROD = 'P96Fzmwna7UaxbW0Qpze3xISr3SCYq9KVMgH6FBaFJc';

export const getEditableFields = (fields: ZendeskFormField[]) =>
	fields.filter((field: ZendeskFormField) => field.editable) ?? [];

export const getFieldType = (type: string): 'textarea' | 'number' | 'tel' | 'email' | 'text' | 'select' => {
	switch (type) {
		case 'description':
			return 'textarea';
		case 'tagger':
			return 'select';
		default:
			return 'text';
	}
};

/* SendSafely DropZone Id */
export const getDropZone = () => {
	return domain === 'fortnox.se' ? DROPZONE_ID_PROD : DROPZONE_ID_DEV;
};
