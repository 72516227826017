import { getColor } from '@/colors';
import React from 'react';
import style from './IntegrationCardDecoration.module.scss';

interface Props {
	text: string;
	testID?: string;
}

export const IntegrationCardDecoration: React.FC<Props> = ({ text, testID }) => {
	return (
		<div className={style.appCardDecoration} data-test-id={testID}>
			<span>{text}</span>
		</div>
	);
};
