import { BrandLine, ContainerFluid, Heading, Section, Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { CareerButton, CareerButtonProps } from '@/bloks/Button/CareerButton';
import { LinkButton } from '@/bloks/Button/LinkButton';
import { renderRichText } from '@/bloks/RichText';
import { HeaderCareerStoryblok, LinkButtonStoryblok } from '@/components';
import { editableBlok } from '@/utils/storyblok';
import React from 'react';
import styles from './HeaderCareer.module.scss';

interface BlokProps {
	blok: HeaderCareerStoryblok;
}

interface Props {
	smallTitle: string;
	title: string;
	text: any;
	buttonsTitle?: string;
	careerButtons?: CareerButtonProps[];
	linkButton?: LinkButtonStoryblok[];
	_editable?: string;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	smallTitle: blok.smallTitle,
	title: blok.title,
	text: blok.text,
	buttonsTitle: blok.buttonsTitle,
	careerButtons: blok.careerButtons?.map((btn) => CareerButton.blokProps({ blok: btn })),
	linkButton: blok.linkButton,
	_editable: blok._editable,
});

export const HeaderCareer: Blok<Props, BlokProps> = ({
	smallTitle,
	title,
	text,
	buttonsTitle,
	careerButtons,
	linkButton,
	_editable,
}) => {
	const buttons = careerButtons && careerButtons.length > 0 ? careerButtons : null;
	const link = linkButton && linkButton.length > 0 ? linkButton[0] : null;

	return (
		<ContainerFluid theme="white">
			<Section as="header" {...editableBlok({ _editable })}>
				<div className={styles.content}>
					<div className={styles.inner}>
						{smallTitle && (
							<div className={styles.smallTitleContainer}>
								<BrandLine />
								<Heading as="div" size="h6" title={smallTitle} marginBottom="xl" />
							</div>
						)}
						{title && <Heading as="h1" title={title} marginBottom="2xl" />}
						{text && (
							<Text as="div" size="small" marginBottom="3xl">
								{renderRichText(text)}
							</Text>
						)}
						{buttons && (
							<div className={styles.buttonsContainer}>
								{buttonsTitle && <Heading as="div" size="h6" title={buttonsTitle} marginBottom="xl" />}
								<div className={styles.buttons}>
									{buttons.map((btn) => (
										<CareerButton key={btn.text} {...btn} />
									))}
								</div>
							</div>
						)}
						{link && <LinkButton {...LinkButton.blokProps({ blok: link })} variant="text" className={styles.link} />}
					</div>
				</div>
			</Section>
		</ContainerFluid>
	);
};

HeaderCareer.blokProps = blokProps;
