import { Divider } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { GlobalMenuLinkGroupStoryblok } from '@/components';
import { useGlobalMenu } from '@/contexts/menu/GlobalMenuProvider';
import { useSearchV2 } from '@/contexts/search/AddSearchProviderV2';
import { ThemeProvider } from '@/contexts/theme';
import { SearchWidgetV2 } from '@/metabloks';
import { SearchField } from '@/metabloks/SearchWidgetV2/components/SearchField';
import { getThemeInfo } from '@/themes';
import { cls } from '@/utils';
import styles from './MenuContentDesktop.module.scss';

interface Props {
	linkGroups?: GlobalMenuLinkGroupStoryblok[];
	className?: string;
}

export const MenuContentDesktop: React.FC<Props> = ({ linkGroups, className }) => {
	const search = useSearchV2();
	const { isMenuOpen } = useGlobalMenu();
	const theme = 'menuContent';
	const themeInfo = getThemeInfo(theme);
	const tabIndex = isMenuOpen && !search.isSearchOpen ? 0 : -1;

	return (
		<ThemeProvider theme={theme}>
			<div className={cls(styles.menuContent, themeInfo.styles.bgColor, isMenuOpen && styles.isMenuOpen, className)}>
				<SearchField />

				<div className={styles.inner}>
					<div className={styles.groupWrapper}>
						{linkGroups?.map((group, index) => (
							<div className={styles[`group${index + 1}`]} key={group._uid}>
								<div className={styles.linkGroup}>
									<span className={styles.title}>{group.title}</span>
									<Divider color="--border-color-70" margin="custom" className={styles.divider} />
									<div className={styles.mainLinks}>
										{group.mainLinks?.map((link) => (
											<LinkButton
												key={link._uid}
												{...LinkButton.blokProps({ blok: link })}
												variant="link"
												size="large"
												tabIndex={tabIndex}
											/>
										))}
									</div>
									<div className={styles.subLinks}>
										{group.links?.map((item) => {
											if (item.component === 'GlobalMenuSpace') {
												return <div className={styles.space} key={item._uid} />;
											}

											return (
												<LinkButton
													key={item._uid}
													{...LinkButton.blokProps({ blok: item })}
													variant="link"
													tabIndex={tabIndex}
												/>
											);
										})}
									</div>
								</div>
							</div>
						))}
					</div>
					<div className={styles.searchWidgetWrapper}>{search.ready && <SearchWidgetV2 />}</div>
				</div>
			</div>
		</ThemeProvider>
	);
};
