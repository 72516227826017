import {
	ContainerFluid,
	ExpansionPanelsProvider,
	Grid,
	ExpansionPanel as Panel,
	Section,
	getHeadingAnchorId,
} from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { City } from '@/contexts/accountants/types';
import { capitalizeEachWord } from '@/utils';
import React, { useState } from 'react';
import styles from './AccountantCityList.module.scss';

interface Props {
	cities: City[];
	onLinkClick: any;
}

export const AccountantCityList: React.FC<Props> = ({ cities, onLinkClick }) => {
	const alphabet = 'abcdefghijklmnopqrstuvwxyzåäö'.toUpperCase().split('');
	const [selectedLetter, setSelectedLetter] = useState('');
	const headerLetters = cities
		?.map((city) => city.name.charAt(0))
		.sort()
		.filter((firstLetter, index, arr) => arr.indexOf(firstLetter) == index);

	const uniqueCities = [...new Set(cities.map((city) => capitalizeEachWord(city.name)))];
	const citiesByLetter = uniqueCities.filter((name) => name.startsWith(selectedLetter));

	const panelTitle = 'Se alla orter';
	const panelId = getHeadingAnchorId(panelTitle);

	return (
		<ContainerFluid>
			<Section className={styles.cityFooter} paddingY={{ base: '2xl' }} id={'cities'}>
				<div onClick={() => (selectedLetter === '' ? setSelectedLetter('A') : undefined)} className={styles.accordion}>
					<ExpansionPanelsProvider>
						<Panel panelId={panelId} className={styles.cities} theme="lightGreen">
							<Panel.Header panelId={panelId} title={panelTitle} theme="lightGreen" />
							<Panel.Content panelId={panelId}>
								<div className={styles.alphabetContainer}>
									<div className={styles.alphabet}>
										<Grid columns={{ base: 8, sm: 10, lg: 15, xxl: 25 }} rowGap={'sm'}>
											{alphabet.map((char, index) => {
												const citiesExists = headerLetters?.indexOf(char) === -1;
												return (
													<a
														href={citiesExists ? undefined : '#cities'}
														key={index}
														className={citiesExists ? styles.char : styles.link}
														onClick={citiesExists ? undefined : () => setSelectedLetter(char)}
													>
														{char}
													</a>
												);
											})}
										</Grid>
									</div>

									{headerLetters?.map((letter, index) => (
										<div key={index} id={letter}>
											<Grid columns={{ base: 1, xs: 2, sm: 3, md: 4, lg: 5, xl: 6 }} colGap={'md'} rowGap={'sm'}>
												{citiesByLetter &&
													citiesByLetter
														.filter((name) => name.startsWith(letter))
														.sort()
														.map((city) => {
															const searchParams = new URLSearchParams();
															searchParams.set('address', city);
															return (
																<LinkButton
																	key={city}
																	href={`/hitta-redovisningsbyra?${searchParams}`}
																	rel={'canonical'}
																	variant={'text'}
																	onClick={() => onLinkClick()}
																>
																	{city}
																</LinkButton>
															);
														})}
											</Grid>
										</div>
									))}
								</div>
							</Panel.Content>
						</Panel>
					</ExpansionPanelsProvider>
				</div>
			</Section>
		</ContainerFluid>
	);
};
