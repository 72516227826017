import { RefObject, useEffect, useState } from 'react';

export default function useOnScreen(ref: RefObject<HTMLElement>, threshold = 0.4) {
	const [isIntersecting, setIntersecting] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver(([entry]) => setIntersecting(entry?.isIntersecting ?? false), {
			threshold,
		});
		if (!ref.current) return () => {}; // Updated return value to an empty function
		observer.observe(ref.current);
		return () => observer.disconnect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return isIntersecting;
}
