import { Icon } from '@/atoms';
import { LinkButton, buildLinkProps } from '@/bloks/Button';
import { SubMenuGroupStoryblok, SubMenuItemStoryblok, SubMenuV2Storyblok } from '@/components';
import { useGlobalMenu } from '@/contexts/menu/GlobalMenuProvider';
import { useSearch } from '@/contexts/search';
import { cls } from '@/utils';
import React, { useEffect, useRef, useState } from 'react';
import styles from './SubMenuDesktop.module.scss';

interface Props {
	items: SubMenuV2Storyblok['items'];
	hideGlobalMenu?: boolean;
	activeItem?: SubMenuGroupStoryblok | SubMenuItemStoryblok | null;
	className?: string;
}

export const SubMenuDesktop: React.FC<Props> = ({ items, hideGlobalMenu, activeItem, className }) => {
	const { showFirstPanel, isMenuOpen } = useGlobalMenu();
	const { open } = useSearch();
	const subMenuRef = useRef<HTMLDivElement | null>(null);
	const [isTopPosition, setIsTopPosition] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			if (subMenuRef.current) {
				const topZero = subMenuRef.current.getBoundingClientRect().top === 0;
				setIsTopPosition(topZero);
			}
		};

		window.addEventListener('scroll', handleScroll);
		handleScroll();

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div
			className={cls(
				styles.container,
				hideGlobalMenu && styles.hideGlobalMenu,
				isTopPosition && styles.boxShadow,
				(showFirstPanel || open || isMenuOpen) && styles.zIndex,
				className,
			)}
			ref={subMenuRef}
		>
			<nav className={styles.subMenuDesktop}>
				{items.map((item) => {
					const hasSubItems = !!item.items?.length;
					return (
						<div key={item._uid} className={styles.item}>
							<div className={cls(styles.mainItemWrapper, hasSubItems && styles.hasSubItems)}>
								<LinkButton
									href={buildLinkProps(item.link).href}
									variant="link"
									className={cls(activeItem?._uid === item._uid && styles.active)}
								>
									{item.text} {hasSubItems && <Icon name="chevron-down" className={styles.icon} />}
								</LinkButton>
							</div>

							{hasSubItems && (
								<div className={styles.linkGroup}>
									{item.items?.map((subItem) => (
										<LinkButton
											key={subItem._uid}
											href={buildLinkProps(subItem.link).href}
											variant="link"
											className={cls(styles.subItem, activeItem?._uid === subItem._uid && styles.active)}
										>
											{subItem.text}
										</LinkButton>
									))}
								</div>
							)}
						</div>
					);
				})}
			</nav>
		</div>
	);
};
