import { ProductPageStoryblok } from '@/components';
import { editableBlok } from '@/utils/storyblok';
import DynamicComponent from '../DynamicComponent';

interface Props {
	blok: ProductPageStoryblok;
}

export const ProductPage: React.FC<Props> = ({ blok }) => {
	const { zone1, zone2, zone3, zone4, zone5 } = blok;
	const sections = [...(blok.header || []), ...(blok.sections || [])];

	return (
		<main {...editableBlok(blok)}>
			{sections?.map((blok, index) => <DynamicComponent blok={blok} key={blok._uid} meta={{ zone: index + 1 }} />)}

			{/** Zones is deprecated and will be removed */}
			{zone1?.map((blok) => <DynamicComponent blok={blok} key={blok._uid} meta={{ zone: 1 }} />)}

			{zone2?.map((blok) => <DynamicComponent blok={blok} key={blok._uid} meta={{ zone: 2 }} />)}

			{zone3?.map((blok) => <DynamicComponent blok={blok} key={blok._uid} meta={{ zone: 3 }} />)}

			{zone4?.map((blok) => <DynamicComponent blok={blok} key={blok._uid} meta={{ zone: 4 }} />)}

			{zone5?.map((blok) => <DynamicComponent blok={blok} key={blok._uid} meta={{ zone: 5 }} />)}
		</main>
	);
};
