import { TextGroupStoryblok, TextStoryblok } from '@/components';
import React from 'react';
import { Text } from '../Text';

interface BlokProps {
	blok: TextGroupStoryblok;
}

interface TextGroupProps {
	group: TextStoryblok[];
}

const blokProps = ({ blok }: BlokProps): TextGroupProps => ({
	group: blok.text,
});

export const TextGroup = ({ group }: TextGroupProps) => {
	return (
		<>
			{group?.map((text) => (
				<Text
					key={text._uid}
					title={text.title}
					text={text.text}
					linkButton={text.linkButton}
					buttonVariant={text.buttonVariant}
					brandline={text.brandline}
				/>
			))}
		</>
	);
};

TextGroup.blokProps = blokProps;
