import React, { createContext, useContext } from 'react';

export const useSubMenu = (): ContextProps => useContext(Context);

interface ContextProps {
	hasSubMenu: boolean;
	hasSubMenuV1: boolean;
	hasSubMenuV2: boolean;
}

const Context = createContext<ContextProps>({
	hasSubMenu: false,
	hasSubMenuV1: false,
	hasSubMenuV2: false,
});

interface Props {
	children: React.ReactNode;
	storyContent: any;
}

export const SubMenuProvider: React.FC<Props> = ({ children, storyContent }) => {
	const hasSubMenu = typeof storyContent?.subMenu === 'object';
	const subMenuVersion = hasSubMenu && storyContent?.subMenu?.content?.component === 'SubMenu' ? 'v1' : 'v2';

	const value = {
		hasSubMenu,
		hasSubMenuV1: hasSubMenu && subMenuVersion === 'v1',
		hasSubMenuV2: hasSubMenu && subMenuVersion === 'v2',
	};
	return <Context.Provider value={value}>{children}</Context.Provider>;
};
