import {
	Button,
	CaptchaForm,
	FieldValidationProvider,
	FormValidationProvider,
	Grid,
	Heading,
	Select,
	SelectOption,
	Text,
	TextField,
	getCaptchaSolution,
} from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { KpOrderFormStoryblok } from '@/components';
import { CompanyInformation, KpOrder } from '@/types';
import { FetchApiResult, fetchApi, formatOrgNr } from '@/utils';
import { validateEmailField, validateOrgNumberField, validatePhoneField, validateZipField } from '@/validation';
import React, { useState } from 'react';
import classNames from 'classnames';
import { t } from 'ttag';
import styles from './KpOrderForm.module.scss';

interface Props {
	blok: KpOrderFormStoryblok;
	orderType: string;
	feedbackTitle: string;
	feedbackText: string;
	meta?: {
		theme?: string;
		layout?: string;
		zone?: number | undefined;
	};
}

interface License {
	field: string;
	label: string;
}

const useLicenses = (orderType: string) => {
	switch (orderType) {
		case 'SCHOOL':
			return [{ field: 'antaledu', label: t`Skolstöd` }];
		case 'UF':
			return [
				{ field: 'antalbf', label: t`Bokföring` },
				{ field: 'antalkf', label: t`Fakturering` },
				{ field: 'antaloof', label: t`Offert & Order` },
				{ field: 'antalda', label: t`Arkivplats` },
				{ field: 'antaltime', label: t`Tid` },
				{ field: 'antallon', label: t`Lön` },
			];
		default:
			return [];
	}
};

export const KpOrderForm: React.FC<Props> = ({ blok, meta }) => {
	const { feedbackTitle, feedbackText, orderType } = blok;
	const licenseFields: License[] = useLicenses(orderType);
	const [order, setOrder] = useState<KpOrder>({
		fnamnkperson: '',
		enamnkperson: '',
		epostkperson: '',
		orderType,
	});
	const [isLoadingCompanyInfo, setIsLoadingCompanyInfo] = useState(false);
	const [companyInformationError, setCompanyInformationError] = useState<string>('');
	const showAddressFields = orderType === 'SCHOOL';
	const showPhoneField = orderType === 'SCHOOL' || orderType === 'TRIAL';

	const handleOrgNumberChange = (e: React.ChangeEvent<any>) => {
		const formattedOrgNumber = formatOrgNr(e.target.value);
		setOrder({ ...order, orgnr: formattedOrgNumber });
	};

	const integrityMessage = () => (
		<Text className={styles.consentText} size="small">
			{t`Jag har tagit del av `}
			<LinkButton
				href="/om-fortnox/integritet-och-sakerhet/avtal-och-villkor/tjansteavtal/"
				variant="text"
				className={styles.integrityMsgBtn}
				arrow={false}
				target="_blank"
			>
				{t`Avtalet`}
			</LinkButton>
			{`, `}
			<LinkButton
				href="/om-fortnox/integritet-och-sakerhet/"
				variant="text"
				className={styles.integrityMsgBtn}
				arrow={false}
				target="_blank"
			>
				{t`Integritetsmeddelandet`}
			</LinkButton>
			{t` och är införstådd med att Fortnox förbehåller sig rätten att kunna neka eller annullera min beställning.`}
		</Text>
	);

	const setCompanyInformation = async () => {
		if (!order.orgnr) {
			setCompanyInformationError(t`Ange organisationsnummer.`);
			return;
		}
		if (!validateOrgNumberField(order.orgnr) || order.orgnr.length < 11) {
			setCompanyInformationError(t`Organisationsnumret är inte giltigt.`);
			return;
		}

		setCompanyInformationError('');
		setIsLoadingCompanyInfo(true);

		let result: FetchApiResult<{ data: CompanyInformation }> | undefined;

		try {
			result = await fetchApi('company-lookup-v1/[...orgNumber]', order.orgnr);
		} catch (e) {
			if (process.env.NODE_ENV !== 'production') {
				console.warn(e);
			}
		}

		setIsLoadingCompanyInfo(false);

		if (!result?.body) {
			setCompanyInformationError(t`Hittade inga uppgifter för angivet organisationsnummer.`);
			return;
		}

		setOrder({
			...order,
			ftgnamn: result.body.data.companyName ?? '',
			postadress: result.body.data.address ?? '',
			postnr: Number(result.body.data.zip) ?? null,
			postort: result.body.data.city ?? '',
		});
	};

	const handleSubmit = (event: React.BaseSyntheticEvent) => {
		event.preventDefault();
		return async function postKpOrderForm() {
			const body = {
				data: order,
				captchaSolution: getCaptchaSolution(event),
			};

			try {
				await fetchApi('kp-order-v1', body);
			} catch (err) {
				if (process.env.NODE_ENV !== 'production') {
					console.warn('KP Order - Order type is invalid', err);
				}
			}
		};
	};

	const getFieldValue = (field: string) => {
		switch (field) {
			case 'antaledu':
				return order.antaledu;
			case 'antalbf':
				return order.antalbf;
			case 'antalkf':
				return order.antalkf;
			case 'antaloof':
				return order.antaloof;
			case 'antalda':
				return order.antalda;
			case 'antaltime':
				return order.antaltime;
			case 'antallon':
				return order.antallon;
			default:
				return undefined;
		}
	};

	const customerTypeSelectOptions: SelectOption[] = [
		{ value: '', label: t`Organisation`, id: 'organization' },
		{ value: '12', label: t`Redovisningsbyrå`, id: 'accountant' },
		{ value: '10', label: t`Företag`, id: 'company' },
		{ value: '11', label: t`Skola`, id: 'school' },
	];

	return (
		<div className={styles.form}>
			<FormValidationProvider>
				<CaptchaForm
					onSubmit={handleSubmit}
					formId={blok?._uid}
					feedbackTitle={feedbackTitle}
					feedbackText={feedbackText}
					buttonText={t`Genomför beställning`}
					integrityMessage={integrityMessage()}
					meta={{ ...meta, type: 'kp' }}
				>
					{licenseFields.length > 0 ? (
						<>
							<div className={styles.licenseHeader}>
								<Heading as="h3" size="h6" title={t`Välj eventuella extralicenser`} />
							</div>
							<Grid className={styles.licensesContainer} columns={{ base: 1, md: 3, lg: 4 }} colGap="md" rowGap="sm">
								{licenseFields.map((license: License, index: number) => {
									const selectOptions: SelectOption[] = Array.of('0', '1', '2', '3', '4', '5', '6', '7', '8', '9').map(
										(numberOfLicenses) => ({
											id: numberOfLicenses,
											value: numberOfLicenses,
											label: numberOfLicenses,
										}),
									);

									return (
										<Grid.Item key={index} className={classNames(styles.textContainer, styles.selectNumberOfLicenses)}>
											<Select
												title={license.label}
												aria-label={t`Välj antal licenser`}
												name={getFieldValue(license.field)?.toString()}
												onSelect={(value) => setOrder({ ...order, [license.field]: value })}
												data={selectOptions}
												id={`kp-order-form-${license.field}`}
											/>
										</Grid.Item>
									);
								})}
							</Grid>
							<div className={styles.numberOfLicensesInfoContainer}>
								<Text muted>{t`Extralicenser kostar 0 kr/mån.`}</Text>
							</div>
						</>
					) : (
						<div />
					)}

					<div className={styles.headingContainer}>
						<Heading as="div" size="h6" title={t`Kontaktuppgifter`} />
					</div>
					<Grid columns={{ base: 1, lg: 2 }} colGap="md" rowGap="sm">
						<Grid.Item className={styles.textFieldContainer}>
							<FieldValidationProvider id="kp-form-first-name">
								<TextField
									id="kp-form-first-name"
									title={t`Förnamn`}
									autoComplete="given-name"
									type="text"
									required
									bordered={meta?.theme === 'white' || !meta?.theme}
									value={order.fnamnkperson}
									onChange={(e) => setOrder({ ...order, fnamnkperson: e.target.value })}
								/>
							</FieldValidationProvider>
						</Grid.Item>
						<Grid.Item className={styles.textFieldContainer}>
							<FieldValidationProvider id="kp-form-last-name">
								<TextField
									id="kp-form-last-name"
									title={t`Efternamn`}
									autoComplete="family-name"
									type="text"
									required
									bordered={meta?.theme === 'white' || !meta?.theme}
									value={order.enamnkperson}
									onChange={(e) => setOrder({ ...order, enamnkperson: e.target.value })}
								/>
							</FieldValidationProvider>
						</Grid.Item>
						<Grid.Item className={styles.textFieldContainer}>
							<FieldValidationProvider id="kp-form-mail">
								<TextField
									id="kp-form-mail"
									title={t`E-post`}
									autoComplete="email"
									type="email"
									required
									bordered={meta?.theme === 'white' || !meta?.theme}
									value={order.epostkperson}
									onChange={(e) => setOrder({ ...order, epostkperson: e.target.value })}
									validators={validateEmailField}
								/>
							</FieldValidationProvider>
						</Grid.Item>
						{showPhoneField && (
							<Grid.Item className={styles.textFieldContainer}>
								<FieldValidationProvider id="kp-form-phone">
									<TextField
										id="kp-form-phone"
										title={t`Telefon`}
										autoComplete="tel-national"
										type="tel"
										required
										bordered={meta?.theme === 'white' || !meta?.theme}
										value={order.telefon ? order.telefon : ''}
										onChange={(e) => setOrder({ ...order, telefon: e.target.value })}
										validators={validatePhoneField}
									/>
								</FieldValidationProvider>
							</Grid.Item>
						)}
					</Grid>
					<div className={styles.headingContainer}>
						<Heading as="div" size="h6" title={t`Företagsuppgifter`} />
					</div>

					{orderType === 'TRIAL' ? (
						<Grid className={styles.trialFields} columns={{ base: 1, lg: 2 }} colGap="md" rowGap="sm">
							<Grid.Item className={classNames(styles.textFieldContainer, styles.orgNrContainer)}>
								<FieldValidationProvider id="kp-form-org-nr">
									<TextField
										id="kp-form-org-nr"
										title={t`Organisationsnummer`}
										type="text"
										required
										bordered={meta?.theme === 'white' || !meta?.theme}
										value={order.orgnr ? order.orgnr : ''}
										onChange={(e) => handleOrgNumberChange(e)}
										validators={validateOrgNumberField}
										maxLength={11}
									/>
								</FieldValidationProvider>
								<Button
									onClick={setCompanyInformation}
									disabled={isLoadingCompanyInfo}
									variant="text"
									className={styles.companyInfoBtn}
								>{t`Hämta företagsuppgifter`}</Button>
								{companyInformationError && (
									<Text size="small" className={styles.companyInformationErrorMsg}>
										{companyInformationError}
									</Text>
								)}
							</Grid.Item>
							<Grid.Item className={styles.textFieldContainer}>
								<Select
									required
									className={styles.selectCompanyType}
									onSelect={(value) => setOrder({ ...order, customerType: parseInt(value) as KpOrder['customerType'] })}
									data={customerTypeSelectOptions}
									defaultValue={customerTypeSelectOptions[0]}
									title={t`Kundtyp`}
									aria-label={t`Välj kundtyp`}
									id="kp-order-form-customer-type"
								/>
							</Grid.Item>
						</Grid>
					) : (
						<div />
					)}
					<Grid columns={{ base: 1, lg: 2 }} colGap="md" rowGap="sm">
						<Grid.Item className={styles.textFieldContainer}>
							<FieldValidationProvider id="kp-form-company-name">
								<TextField
									id="kp-form-company-name"
									title={t`Företagsnamn`}
									type="text"
									required
									bordered={meta?.theme === 'white' || !meta?.theme}
									value={order.ftgnamn ? order.ftgnamn : ''}
									onChange={(e) => setOrder({ ...order, ftgnamn: e.target.value })}
								/>
							</FieldValidationProvider>
						</Grid.Item>
						{showAddressFields ? (
							<>
								<Grid.Item className={styles.textFieldContainer}>
									<FieldValidationProvider id="kp-form-adress">
										<TextField
											id="kp-form-adress"
											title={t`Adress`}
											type="text"
											required
											bordered={meta?.theme === 'white' || !meta?.theme}
											value={order.postadress ? order.postadress : ''}
											onChange={(e) => setOrder({ ...order, postadress: e.target.value })}
										/>
									</FieldValidationProvider>
								</Grid.Item>
								<Grid.Item className={styles.textFieldContainer}>
									<FieldValidationProvider id="kp-form-zip-code">
										<TextField
											id="kp-form-zip-code"
											title={t`Postnummer`}
											type="text"
											required
											bordered={meta?.theme === 'white' || !meta?.theme}
											value={order.postnr ? order.postnr : ''}
											onChange={(e) => setOrder({ ...order, postnr: e.target.value })}
											validators={validateZipField}
											maxLength={5}
										/>
									</FieldValidationProvider>
								</Grid.Item>
								<Grid.Item className={styles.textFieldContainer}>
									<FieldValidationProvider id="kp-form-city">
										<TextField
											id="kp-form-city"
											title={t`Ort`}
											type="text"
											required
											bordered={meta?.theme === 'white' || !meta?.theme}
											value={order.postort ? order.postort : ''}
											onChange={(e) => setOrder({ ...order, postort: e.target.value })}
										/>
									</FieldValidationProvider>
								</Grid.Item>
							</>
						) : (
							<div />
						)}
					</Grid>
				</CaptchaForm>
			</FormValidationProvider>
		</div>
	);
};
