import { Heading, RoundedSquare, Text } from '@/atoms';
import { LinkButton, LinkButtonProps } from '@/bloks/Button';
import { CardJobAdStoryblok } from '@/components';
import styles from './CardJobAd.module.scss';

interface BlokProps {
	blok: CardJobAdStoryblok;
}

export const blokProps = ({ blok }: BlokProps): CardJobAdProps => ({
	title: blok.title,
	department: blok?.department,
	location: blok?.location,
	description: blok.description,
	button: blok.button?.[0] && { ...LinkButton.blokProps({ blok: blok.button?.[0] }) },
});

export interface CardJobAdProps {
	title: string;
	department?: string;
	location?: string;
	description: string;
	button: LinkButtonProps;
	id?: string;
}

/**
 * @deprecated
 */
export function CardJobAd({ title, department, location, description, button }: CardJobAdProps) {
	return (
		<div className={styles.jobContainer}>
			<div className={styles.jobTitleRow}>
				<RoundedSquare height={2} className={styles.roundedSquare} color="--text-yellow" />
				{title && <Heading as="div" size="h5" marginBottom="none" title={title} data-testid="job-title-header-h5" />}
			</div>
			{department && (
				<Heading
					as="div"
					size="h6"
					className={styles.department}
					marginBottom="sm"
					data-testid="department-header-h6"
					title={department}
				/>
			)}
			{location && <Text>{location}</Text>}
			{description && (
				<Text data-testid="job-content" className={styles.description}>
					{description}
				</Text>
			)}
			{button && (
				<div className={styles.btnContainer}>
					<LinkButton {...button} />
				</div>
			)}
		</div>
	);
}

CardJobAd.blokProps = blokProps;
