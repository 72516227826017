import { Heading } from '@/atoms';
import { useIntegrationCurrent } from '@/contexts/Integration';
import React from 'react';
import styles from '../IntegrationPage.module.scss';

const UniqueSellingPoints: React.FC = () => {
	const integration = useIntegrationCurrent();

	return (
		<div className={styles.uniqueSellingPoints}>
			<Heading as="h3" size="h4" title={integration.uniqueSellingPoints.title} />
			<ul>
				{integration.uniqueSellingPoints.points
					.filter((point) => point)
					.map((point, index) => (
						<li key={`point-${index}`}>{point}</li>
					))}
			</ul>
		</div>
	);
};

export default UniqueSellingPoints;
