import { Grid, ShowMoreButton } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { CardMediumListGroupStoryblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { ThemeProvider } from '@/contexts/theme';
import { getCorrespondingLegacyTheme } from '@/themes';
import { Themes } from '@/types';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { ReactNode, useState } from 'react';
import { t } from 'ttag';
import { CardMediumList } from '../CardMediumList';
import styles from './CardMediumGroup.module.scss';
import { useCardMediumGridColumns } from './useCardMediumGridColumns';

interface BlokProps {
	blok: CardMediumListGroupStoryblok;
	meta?: {
		nrOfCards?: number;
		noBackground?: boolean;
		theme?: Themes['theme'];
	};
}

interface Props {
	cards: ReactNode[];
	className?: string;
	nrOfCards?: number;
	noBackground?: boolean;
	theme?: Themes['theme'];
	_editable?: string;
}

const blokProps = ({ blok, meta }: BlokProps): Props => ({
	cards: blok?.cards?.map((card) => (
		<CardMediumList key={card._uid} {...CardMediumList.blokProps({ blok: card, meta })} />
	)),
	_editable: blok._editable,
	theme: meta?.theme,
});

/**
 * @deprecated Will be replaced by CardMediumGroup
 */

export const CardMediumListGroup: Blok<Props, BlokProps> = ({ cards, theme, _editable }) => {
	const { hasSubMenuV1 } = useSubMenu();
	const nrOfCards = cards?.length ?? 0;
	const columns = useCardMediumGridColumns(nrOfCards, false, true);
	const cardsPerRow = nrOfCards === 5 || nrOfCards % 3 === 0 ? 3 : 4;
	const nrOfRows = nrOfCards / cardsPerRow;
	const [showRows, setShowRows] = useState(1);

	const onShowMore = () => {
		setShowRows(showRows < nrOfRows ? showRows + 1 : showRows);
	};

	return (
		<div className={cls(nrOfCards === 2 && hasSubMenuV1 === false && styles.center)}>
			<Grid columns={columns} colGap="2xl" rowGap="2xl" {...editableBlok({ _editable })}>
				{cards?.slice(0, cardsPerRow * showRows)?.map((card, index) => <Grid.Item key={index}>{card}</Grid.Item>)}
			</Grid>
			{nrOfCards > 4 && showRows < nrOfRows && (
				<ThemeProvider theme={getCorrespondingLegacyTheme(theme)}>
					<ShowMoreButton onClick={onShowMore}>{t`Visa fler`}</ShowMoreButton>
				</ThemeProvider>
			)}
		</div>
	);
};

CardMediumListGroup.blokProps = blokProps;
