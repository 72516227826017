import { Grid, Heading, Text } from '@/atoms';
import { List, ListProps } from '@/bloks/Other/List';
import { ThemeGreenStoryblok, UspLargeStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import styles from './UspLarge.module.scss';

interface BlokProps {
	blok: UspLargeStoryblok;
	meta?: {
		theme?: ThemeGreenStoryblok['theme'];
	};
}

const blokProps = ({ blok, meta }: BlokProps): UspLargeProps => ({
	title: blok.title,
	text: blok.text,
	list: blok.list?.[0] && List.blokProps({ blok: blok.list?.[0] }),
	theme: meta?.theme,
	_editable: blok._editable,
});

export interface UspLargeProps {
	title: string;
	text: string;
	list?: ListProps;
	theme?: ThemeGreenStoryblok['theme'];
	_editable?: string;
}

export function UspLarge({ title, text, list, theme = 'white', _editable }: UspLargeProps) {
	return (
		<Grid columns={{ base: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }} {...editableBlok({ _editable })}>
			<div className={cls(styles.container, styles[`theme--${theme}`], list && styles.withList)}>
				<Grid.Item>
					<Heading as="h3" size="h5" title={title} className={styles.title} />
					{text && <Text>{text}</Text>}
				</Grid.Item>

				{list && (
					<Grid.Item>
						<div className={styles.listContainer}>
							<List
								{...list}
								iconName="check"
								iconSize="sm"
								iconColor={theme === 'dark-green' ? '--primary-yellow' : '--primary-green'}
								maxAmount={3}
							/>
						</div>
					</Grid.Item>
				)}
			</div>
		</Grid>
	);
}

UspLarge.blokProps = blokProps;
