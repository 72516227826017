import { logger as getLogger } from '../../next-logger.config';

/**
 * Server-side logger instance
 *
 * Usage:
 *
 * ```typescript
 * import { logger } from '../logger';
 *
 * // Trace debugging (start with LOG_LEVEL=trace to see these)
 * logger.trace('Something')
 *
 * // Debugging (will not show up in Kibana)
 * logger.debug('Something')
 *
 * // Normal logging
 * logger.info('Something')
 *
 * // Warning
 * logger.warn('Something')
 *
 * // Avoid using error and fatal.
 * logger.error('Something')
 * logger.fatal('Something')
 *
 * // Extra fields can be sent to Kibana like this
 * logger.trace({ foo: 123 }, 'Something')
 * logger.debug({ foo: 123 }, 'Something')
 * logger.info({ foo: 123 }, 'Something')
 * logger.warn({ foo: 123 }, 'Something')
 * logger.error({ foo: 123 }, 'Something')
 * logger.fatal({ foo: 123 }, 'Something')
 * ```
 *
 * ---
 *
 * Start server with `make LOG_LEVEL=trace` or `make LOG_LEVEL=info` to see
 * more or less logs.
 */
export const logger = getLogger({}, true);

interface ServerApiLog {
	message: string;
	[key: string]: any;
}

export class ServerApiLogger {
	_logs: ServerApiLog[] = [];

	debug(obj: Record<string, any>, message: string) {
		if (process.env.NODE_ENV !== 'production') this._logs.push({ message, level: 'debug' });
		logger.debug(obj, message);
	}

	info(obj: Record<string, any>, message: string) {
		if (process.env.NODE_ENV !== 'production') this._logs.push({ message, level: 'info' });
		logger.info(obj, message);
	}

	warn(obj: Record<string, any>, message: string) {
		if (process.env.NODE_ENV !== 'production') this._logs.push({ message, level: 'warn' });
		logger.warn(obj, message);
	}

	toJSON() {
		return process.env.NODE_ENV === 'production' ? undefined : this._logs;
	}
}
