import { ContainerFluid, Grid, Heading, Section, Text } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { CardMediumVideo } from '@/bloks/Card';
import { HeaderArticle } from '@/bloks/Header';
import { VideoProps } from '@/bloks/Other';
import { CardFiveGroupStoryblok, EducationPageStoryblok } from '@/components';
import { Course, EducationType, EducationTypesWithOccasion, useCourse } from '@/contexts/eduadmin';
import { ISbStoryData } from '@/types/storyblok';
import { htmlToMarkdownToRichtext, initLocales, renderHtmlAsRichtext, richTextToString } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { useRouter } from 'next/router';
import React from 'react';
import { t } from 'ttag';
import DynamicComponent from '../../DynamicComponent';
import { EducationEventTable, priceRowWithCourseName, signUpButtonRow } from './EducationEventTable';
import styles from './EducationPage.module.scss';

interface Props {
	blok: EducationPageStoryblok;
	meta: {
		full_slug: string;
	};
}

const textSection = (title: string, text: string) => {
	return (
		<div className={styles.text}>
			<Heading as="div" size="h3" title={title} data-testid="education-page-header-h3" />
			{renderHtmlAsRichtext(text)}
		</div>
	);
};

const imageText = (text: string) => {
	return (
		<div className={styles.imageText} data-testid="image-text">
			{renderHtmlAsRichtext(text)}
		</div>
	);
};

const getTags = (course: Course | undefined): string[] => {
	const educationType = course?.EducationType ?? '';
	const subjects = course?.Subjects?.join(', ') || '';
	return [educationType, subjects];
};

const videoHeader = (course: Course, video: VideoProps, full_slug: string) => {
	const tags = getTags(course);
	const videoImageFallback = 'https://a.storyblok.com/f/122440/864x388/e8876e4c8b/blog-image-one.png';

	return (
		<>
			<HeaderArticle h1Part1={course.CourseName} tags={tags} full_slug={full_slug} />
			<CardMediumVideo
				image={{
					id: course.CourseTemplateId,
					filename: course.ImageUrl || videoImageFallback,
				}}
				video={video}
				title={course.CourseName || ''}
				text={course.Quote ? richTextToString(htmlToMarkdownToRichtext(course.Quote)) : ''}
			/>
		</>
	);
};

const articleHeader = (course: Course, full_slug: string) => {
	const fallbackImageUrl = 'https://a.storyblok.com/f/122440/864x388/e8876e4c8b/blog-image-one.png';
	const tags = getTags(course);

	return (
		<HeaderArticle
			h1Part1={course.CourseName}
			image={{
				id: course.CourseTemplateId,
				filename: course.ImageUrl ? course.ImageUrl : fallbackImageUrl,
				title: undefined,
				name: '',
			}}
			tags={tags}
			full_slug={full_slug}
		/>
	);
};

export const EducationPage: React.FC<Props> = ({ blok, meta }) => {
	const contact = blok?.contact as unknown as ISbStoryData<CardFiveGroupStoryblok>;
	const courseProps = useCourse();
	const course = courseProps?.course;
	const events = courseProps?.events?.value?.filter((event) => event.StatusId == 1);
	const router = useRouter();

	if (router?.locale === 'en' || course?.TranslateToEnglish) {
		initLocales('en');
	}

	const getBoxEvent = () => {
		const hasEvents = events && events.length > 0;
		if (hasEvents && course?.EducationType === EducationType.Package) {
			return events[0];
		}
		if (hasEvents && course?.EducationType === EducationType.Recorded) {
			return events?.find((event) => event.OnDemand === true);
		}
		return events?.find((event) => event.OnDemand === true);
	};

	const getFreeRecordedVideo = (): VideoProps | undefined => {
		if (course && course.VimeoId !== undefined && EducationType.Recorded === course.EducationType) {
			return { id: course.VimeoId };
		}
		return undefined;
	};

	const boxEvent = getBoxEvent();
	const tableEvents = events?.filter((event) => event.OnDemand === false);
	const freeRecordedVideo = getFreeRecordedVideo();
	const isEducationTypeWithOccasions =
		course?.EducationType && EducationTypesWithOccasion.includes(course.EducationType);

	return freeRecordedVideo ? (
		<>
			<ContainerFluid {...editableBlok(blok)}>
				<Section maxWidth="925px" paddingX={{ base: 'xl' }} className={styles.sectionHeader}>
					{course && videoHeader(course, freeRecordedVideo, meta.full_slug)}
				</Section>
			</ContainerFluid>
			{contact && <DynamicComponent blok={contact.content} />}
		</>
	) : (
		<>
			<ContainerFluid {...editableBlok(blok)}>
				<Section maxWidth="925px" paddingX={{ base: 'xl' }} className={styles.sectionHeader}>
					{course && articleHeader(course, meta.full_slug)}
					{course?.CourseDescriptionShort && imageText(course.CourseDescriptionShort)}
					{course?.Prerequisites && textSection(t`Kunskaper och erfarenhet`, course.Prerequisites)}
					{course?.CourseDescription && textSection(t`Innehåll`, course.CourseDescription)}
					{course?.CourseGoal && textSection(t`Mål`, course.CourseGoal)}
					{course?.Notes && textSection(t`Bra att veta`, course.Notes)}
				</Section>
				<Section maxWidth="925px" paddingX={{ base: 'xl' }} paddingY={{ base: 'lg' }}>
					<Heading as="div" size="h3" data-testid="course-header-h3" title={t`Utbildningstillfällen`} />
					{course && boxEvent && (
						<Grid columns={{ base: 2 }} className={styles.recordedCoursePriceBox}>
							<Grid.Item className={styles.priceCol} data-testid="course-price">
								{priceRowWithCourseName(boxEvent)}
							</Grid.Item>
							<Grid.Item className={styles.signUpButtonCol}>
								{signUpButtonRow(boxEvent, router?.locale, course.TranslateToEnglish)}
							</Grid.Item>
						</Grid>
					)}
					{tableEvents && tableEvents.length > 0 && isEducationTypeWithOccasions && (
						<EducationEventTable
							events={tableEvents}
							translateToEnglish={course?.TranslateToEnglish}
							isRemote={course?.EducationType === EducationType.Remote}
						/>
					)}
					{(!tableEvents || tableEvents.length < 1) && isEducationTypeWithOccasions && (
						<>
							<Text>
								{t`Inga planerade utbildningstillfällen just nu. Se våra andra `}
								<LinkButton href="/utbildningar/" variant="text" arrow={false}>
									{t`utbildningar.`}
								</LinkButton>
							</Text>
						</>
					)}
				</Section>
			</ContainerFluid>
			{contact && <DynamicComponent blok={contact.content} />}
		</>
	);
};
