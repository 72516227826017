import { ISbComponentType } from '@/types/storyblok';
import { logger } from '../server/serverLogger';
import * as Components from './';

interface Props {
	blok: ISbComponentType<any>;
	meta?: Record<string, unknown>;
}

/**
 * Accepts a blok and renders it using the corresponding blok component defined
 * in src/bloks/*. Extra props may be passed in the meta={{ something }} object.
 */
const DynamicComponent: React.FC<Props> = ({ blok, meta = {} }) => {
	const Component = blok?.component && (Components as any)[blok.component];

	if (Component?.blokProps) {
		return <Component {...Component.blokProps({ blok, meta })} />;
	}
	if (Component) {
		return <Component blok={blok} meta={meta} />;
	}
	if (process.env.NODE_ENV !== 'production') {
		logger.info({ scope: 'storyblok', component: blok?.component }, 'component.does.not.exist');
	}

	return null;
};

export default DynamicComponent;
