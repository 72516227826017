import { BrandLine, Grid, Heading, Text } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { renderRichText } from '@/bloks/RichText';
import {
	AssetStoryblok,
	LinkButtonStoryblok,
	RichtextStoryblok,
	TextImageStoryblok,
	ThemeGreenStoryblok,
} from '@/components';
import { ImageAsset } from '@/metabloks';
import { cls } from '@/utils';
import React from 'react';
import styles from './TextImage.module.scss';

interface BlokProps {
	blok: TextImageStoryblok;
	meta: {
		theme: ThemeGreenStoryblok['theme'];
	};
}

interface TextImageProps {
	title?: string;
	text: RichtextStoryblok;
	image: AssetStoryblok;
	linkButton?: LinkButtonStoryblok[];
	theme: ThemeGreenStoryblok['theme'];
	buttonVariant?: boolean;
}

const blokProps = ({ blok, meta }: BlokProps): TextImageProps => ({
	title: blok.title,
	text: blok.text,
	image: blok.image,
	linkButton: blok.linkButton,
	theme: meta?.theme,
	buttonVariant: blok.buttonVariant,
});

export const TextImage = ({ title, text, image, linkButton, theme, buttonVariant = false }: TextImageProps) => {
	return (
		<div className={styles.contentContainer}>
			<Grid
				className={cls(styles.textGrid, styles[`theme--${theme}`])}
				columns={{ base: 1, lg: 2 }}
				colGap="4xl"
				rowGap="2xl"
			>
				<Grid.Item className={styles.gridText}>
					{title && (
						<>
							<BrandLine className={styles.brandLine} />
							<Heading as="div" size="h4" title={title} marginBottom="xl" />
						</>
					)}
					<Text as="div" marginBottom={buttonVariant ? 'lg' : 'md'}>
						{renderRichText(text)}
					</Text>
					{linkButton?.map((link) => (
						<LinkButton
							key={link._uid}
							{...LinkButton.blokProps({ blok: link })}
							variant={buttonVariant ? 'filled' : 'text'}
						>
							{link.text}
						</LinkButton>
					))}
				</Grid.Item>
				<Grid.Item className={styles.gridImage}>
					<ImageAsset asset={image} objectPosition="center center" objectFit="contain" />
				</Grid.Item>
			</Grid>
		</div>
	);
};

TextImage.blokProps = blokProps;
