import { ContainerFluid, Section } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import DynamicComponent from '@/bloks/DynamicComponent';
import { HeaderCommon, HeaderCommonProps } from '@/bloks/Other';
import { SectionCardLinksStoryblok } from '@/components';
import { editableBlok } from '@/utils/storyblok';
import { ReactNode } from 'react';

interface BlokProps {
	blok: SectionCardLinksStoryblok;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	header: blok.header?.[0] && HeaderCommon.blokProps({ blok: blok.header?.[0] }),
	cardGroup: blok?.cardGroup?.map((cardGroup) => <DynamicComponent key={cardGroup._uid} blok={cardGroup} />),
});

interface Props {
	header?: HeaderCommonProps;
	cardGroup?: ReactNode[];
	_editable?: string;
}

export const SectionCardLinks: Blok<Props, BlokProps> = ({ header, cardGroup, _editable }) => {
	return (
		<ContainerFluid>
			<Section {...editableBlok({ _editable })}>
				{header && <HeaderCommon {...header} marginBottom="3xl" />}
				{cardGroup}
			</Section>
		</ContainerFluid>
	);
};

SectionCardLinks.blokProps = blokProps;
