import { Grid } from '@/atoms';
import DynamicComponent from '@/bloks/DynamicComponent';
import { ThemeGreenStoryblok, UspMediumGroupStoryblok } from '@/components';
import { editableBlok } from '@/utils/storyblok';
import { useGridColumns } from 'hooks/useGridColumns';
import { ReactNode } from 'react';

interface BlokProps {
	blok: UspMediumGroupStoryblok;
	meta?: {
		theme?: ThemeGreenStoryblok['theme'];
	};
}

const blokProps = ({ blok, meta }: BlokProps): UspMediumGroupProps => ({
	usps: blok.usps?.map((usp) => <DynamicComponent blok={usp} key={usp._uid} meta={meta} />),
	_editable: blok._editable,
});

export interface UspMediumGroupProps {
	usps: ReactNode[];
	_editable?: string;
}

export function UspMediumGroup({ usps, _editable }: UspMediumGroupProps) {
	const columns = useGridColumns(usps?.length ?? 0);

	return (
		<div {...editableBlok({ _editable })}>
			<Grid columns={columns} colGap="3xl" rowGap="3xl">
				{usps}
			</Grid>
		</div>
	);
}

UspMediumGroup.blokProps = blokProps;
