import { ContainerFluid, Grid, Section } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { LinkButtonStoryblok, SectionTextLinkContentStoryblok, SectionTextLinkStoryblok } from '@/components';
import { ISbStoryData } from '@/types/storyblok';
import { editableBlok } from '@/utils/storyblok';
import { renderRichText } from '../../RichText';
import styles from './SectionTextLink.module.scss';

interface Props {
	blok: SectionTextLinkStoryblok;
}

export const SectionTextLink: React.FC<Props> = ({ blok }) => {
	const storyContent = blok.content as unknown as ISbStoryData<SectionTextLinkStoryblok>;
	const content = storyContent as unknown as SectionTextLinkContentStoryblok;

	return (
		<ContainerFluid theme="light-green">
			<Section
				{...editableBlok(blok)}
				className={styles.container}
				maxWidth="1440px"
				paddingX={{ base: 'xl', md: '5xl' }}
			>
				<Grid columns={{ base: 1, lg: '2fr 2fr' }} colGap="3xl">
					<Grid.Item className={styles.textColumn}>{content?.text && renderRichText(content.text)}</Grid.Item>
					<Grid.Item>
						<Grid columns={{ base: 1, lg: 2 }} data-testid="btn-container">
							{content?.linkButtons?.map((btn: LinkButtonStoryblok) => (
								<Grid.Item key={btn._uid} className={styles.btnColumn}>
									<LinkButton {...LinkButton.blokProps({ blok: btn })} variant="text" className={styles.btn} />
								</Grid.Item>
							))}
						</Grid>
					</Grid.Item>
				</Grid>
			</Section>
		</ContainerFluid>
	);
};
