import { ContainerFluid, Grid, Section } from '@/atoms';
import { CardEducation } from '@/bloks/Page/NavigationPageEducation/CardEducation/CardEducation';
import { toCardEducation } from '@/bloks/Page/NavigationPageEducation/CardEducation/EduAdminCardEducation';
import { CardFiveGroupStoryblok, EducationCategoryPageStoryblok, SectionTextLinkStoryblok } from '@/components';
import { useCategories, useCategory } from '@/contexts/eduadmin';
import { ISbStoryData } from '@/types/storyblok';
import { richTextToString } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import React from 'react';
import { t } from 'ttag';
import DynamicComponent from '../../DynamicComponent';
import { SectionTextLink } from '../../Section';

interface Props {
	blok: EducationCategoryPageStoryblok;
}

export const EducationCategoryPage: React.FC<Props> = ({ blok }) => {
	const sectionTextLink = blok?.globalTextSection?.length
		? (blok?.globalTextSection[0] as any as ISbStoryData<SectionTextLinkStoryblok>)
		: (blok?.globalTextSection as any as ISbStoryData<SectionTextLinkStoryblok>);
	const { courses } = useCategories();
	const category = useCategory(courses?.value);
	const contact = blok?.contact as unknown as ISbStoryData<CardFiveGroupStoryblok>;
	const educationCards = courses?.value?.map((course) => toCardEducation(course)) || [];

	return (
		<div {...editableBlok(blok)}>
			{blok.header?.map((blok) => {
				const headerBlok = { ...blok };
				headerBlok.shortText = category?.CategoryNotes || richTextToString(blok.shortText);
				headerBlok.h1Part1 = category?.CategoryName ? '' : blok.h1Part1;
				headerBlok.h1Part2 = category?.CategoryName || blok.h1Part2;
				headerBlok.label = blok.label || t`Utbildningar`;
				return <DynamicComponent key={headerBlok._uid} blok={headerBlok} />;
			})}

			<ContainerFluid>
				<Section>
					{educationCards && (
						<Grid columns={{ base: 1, xl: 3, lg: 2 }} colGap="2xl" rowGap="2xl">
							{educationCards?.map((card) => {
								return (
									<Grid.Item key={card._uid}>
										<CardEducation blok={card} />
									</Grid.Item>
								);
							})}
						</Grid>
					)}
				</Section>
			</ContainerFluid>

			{contact && <DynamicComponent blok={contact.content} />}
			{sectionTextLink?.content && <SectionTextLink blok={sectionTextLink.content} />}
		</div>
	);
};
