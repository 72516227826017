import React, { createContext, useContext } from 'react';
import { Integration, IntegrationClientType } from './types';

/**
 * An Integration from App Market
 */
export const useIntegrationCurrent = () => {
	return useContext(Context);
};

const Context = createContext<Integration>({
	appId: '',
	redirectUri: '',
	appName: '',
	summaryTitle: '',
	tenantId: '',
	summary: '',
	about: '',
	howTo: '',
	readMoreUrl: '',
	priceModel: { clientType: IntegrationClientType.BuyableApp, price: '' },
	urlSlug: '',
	images: [],
	scopes: null,
	categories: [],
	ratings: {
		averageScore: 0,
		numberOfRatings: 0,
	},
	contactInformation: {
		companyName: '',
		email: '',
		phone: '',
	},
	uniqueSellingPoints: {
		title: '',
		points: [],
	},
	customerReviews: [{ companyName: '', review: '', role: '' }],
	insights: {
		numberOfUsersRange: '',
	},
	tags: [],
});

interface Props {
	children: React.ReactNode;
	integration: Integration;
}

export const IntegrationCurrentProvider: React.FC<Props> = ({ children, integration }) => {
	return <Context.Provider value={integration}>{children}</Context.Provider>;
};
