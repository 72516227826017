import { Text } from '@/atoms';
import { TestimonialGlobalStoryblok, TestimonialLargeGroupStoryblok } from '@/components';
import { BigQuote } from '@/metabloks/BigQuote';
import { StoryblokStory } from 'storyblok-generate-ts';
import React from 'react';
import styles from './TestimonialLargeGroup.module.scss';

interface BlokProps {
	blok: TestimonialLargeGroupStoryblok;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	testimonial: blok.testimonial as unknown as StoryblokStory<TestimonialGlobalStoryblok>,
});

interface Props {
	testimonial: StoryblokStory<TestimonialGlobalStoryblok>;
}

export function TestimonialLargeGroup({ testimonial }: Props) {
	return testimonial ? (
		<div className={styles.container}>
			<BigQuote quote={testimonial?.content?.quote} quotation />
			<Text className={styles.nameAndTitle} marginBottom="none">
				{testimonial?.content?.name}
				{testimonial?.content?.jobTitle ? `, ${testimonial?.content?.jobTitle}` : ''}
			</Text>
		</div>
	) : null;
}

TestimonialLargeGroup.blokProps = blokProps;
