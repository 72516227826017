import { WordPost } from '@/bloks';
import { BookkeepingPostStoryblok } from '@/components';

interface Props {
	blok: BookkeepingPostStoryblok;
	meta: Record<string, any>;
}

export const BookkeepingPost: React.FC<Props> = ({ blok, meta }) => {
	return <WordPost blok={blok} meta={meta} />;
};
