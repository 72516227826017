import { Grid } from '@/atoms';
import { CardLargeLinksHigh } from '@/bloks/Card';
import { CardLargeLinksHighGroupStoryblok } from '@/components';
import { ThemeProvider } from '@/contexts/theme';

interface Props {
	blok: CardLargeLinksHighGroupStoryblok;
}

export const CardLargeLinksHighGroup: React.FC<Props> = ({ blok }) => {
	const { cards } = blok;

	return (
		<Grid columns={{ base: 1 }} rowGap="xl">
			{cards?.map((card) => {
				const props = { blok: card };
				return (
					<Grid.Item key={card._uid}>
						<ThemeProvider theme="darkGreen">
							<CardLargeLinksHigh {...CardLargeLinksHigh.blokProps(props)} />
						</ThemeProvider>
					</Grid.Item>
				);
			})}
		</Grid>
	);
};
