import { Icon } from '@/atoms/Icon';
import { LinkAnchor } from '@/atoms/LinkAnchor/LinkAnchor';
import { buildLinkProps } from '@/bloks/Button/LinkButton';
import {
	GlobalMenuItemGroupStoryblok,
	GlobalMenuItemStoryblok,
	GlobalMenuItemWithSubLinksStoryblok,
} from '@/components';
import { cls } from '@/utils';
import { t } from 'ttag';
import styles from './GlobalMenuItem.module.scss';

interface Props {
	blok: GlobalMenuItemStoryblok | GlobalMenuItemGroupStoryblok;
	className?: string;
	onClick?: () => void;
}

interface WithSubLinksProps {
	blok: GlobalMenuItemWithSubLinksStoryblok;
	className?: string;
	onClick?: () => void;
}

/*
 * Menu item without sublinks
 */
export const GlobalMenuItem: React.FC<Props> = ({ blok, className }) => {
	return (
		<LinkAnchor {...buildLinkProps(blok.link!)} hoverEffect className={cls(styles.link, className)}>
			{blok.title}
		</LinkAnchor>
	);
};

/*
 * Menu item without sublinks that opens in new panel
 */
export const GlobalMenuGroupItem: React.FC<Props> = ({ blok, onClick }) => {
	return (
		<div className={styles.groupLink} onClick={onClick}>
			<div className={styles.effectWrapper}>
				<button aria-label={t`Öppna submeny ${blok.title}`} className={cls(styles.button, styles.effectWrapper)}>
					{blok.title}
				</button>
			</div>
			<span className={styles.iconWrapper}>
				<Icon className={styles.icon} name="arrow-right" size="1x" color="--text-light" />
			</span>
		</div>
	);
};

/*
 * Menu item without sublinks that opens in new panel
 */
export const GlobalMenuItemWithSubLinks: React.FC<WithSubLinksProps> = ({ blok }) => {
	return (
		<>
			<div className={styles.linkWrapper}>
				<LinkAnchor
					{...buildLinkProps(blok.link!)}
					hoverEffect
					className={cls(styles.link, styles.linkWithSublinkList)}
				>
					{blok.title}
				</LinkAnchor>
			</div>
			<ul className={styles.sublinkList}>
				{blok.items?.map((item) => (
					<li key={item._uid}>
						<LinkAnchor {...buildLinkProps(item?.link)} hoverEffect className={styles.link}>
							{item.title}
						</LinkAnchor>
					</li>
				))}
			</ul>
		</>
	);
};
