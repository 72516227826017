import { CodeBlockStoryblok, CodeStoryblok } from '@/components';
import React from 'react';
import { Code } from '../Code/Code';

interface BlokProps {
	blok: CodeBlockStoryblok;
}

interface CodeBlockProps {
	codeTab?: CodeStoryblok[];
}

const blokProps = ({ blok }: BlokProps): CodeBlockProps => ({
	codeTab: blok.code,
});

export const CodeBlock = ({ codeTab }: CodeBlockProps) => {
	return (
		<div>
			{codeTab &&
				codeTab.map((code) => <Code key={code._uid} codeDescription={code.codeDescription} code={code.code} />)}
		</div>
	);
};

CodeBlock.blokProps = blokProps;
