import { ContainerFluid, Heading, Section } from '@/atoms';
import { LinkButton, buildLinkProps } from '@/bloks/Button/LinkButton';
import DynamicComponent from '@/bloks/DynamicComponent';
import { BlogLibraryPageStoryblok, CardFiveGroupStoryblok, HeaderImageStoryblok, HeaderStoryblok } from '@/components';
import { useBlogPosts } from '@/contexts/blogposts/BlogProvider';
import { ArticleGridFilter } from '@/metabloks/ArticleGrid/ArticleGridFilter';
import { ISbStoryData } from '@/types/storyblok';
import { formatDateMonthYear } from '@/utils/formatDate';
import { StoryblokStory } from 'storyblok-generate-ts';
import React from 'react';
import styles from './BlogLibraryPage.module.scss';

interface BlokProps {
	blok: BlogLibraryPageStoryblok;
}

interface BlogLibraryPageProps {
	header: (HeaderStoryblok | HeaderImageStoryblok)[];
	contact?: StoryblokStory<CardFiveGroupStoryblok> | string;
}

function blokProps({ blok }: BlokProps): BlogLibraryPageProps {
	return {
		header: blok.header,
		contact: blok.contact,
	};
}
export function BlogLibraryPage({ header, contact }: BlogLibraryPageProps) {
	const headerItem = header?.[0] ?? null;
	const contactExit = contact as unknown as ISbStoryData<CardFiveGroupStoryblok>;
	const { posts } = useBlogPosts();
	const dates =
		posts &&
		posts
			.map((post) => post.publishedAt && formatDateMonthYear(new Date(post.publishedAt)))
			.filter((uniqueDate, i, self) => {
				return i === self.indexOf(uniqueDate);
			});

	return (
		posts && (
			<ContainerFluid>
				<DynamicComponent blok={headerItem} />
				<Section as="div" paddingX={{ base: 'md', md: '3xl' }}>
					<ArticleGridFilter />
					{dates.map((date, i) => {
						const dateString = date ? date?.charAt(0).toUpperCase() + date?.slice(1) : '';
						return (
							<div key={i} className={styles.container}>
								<Heading as="div" title={dateString} size="h5" marginBottom="sm" />
								<div className={styles.linksContainer}>
									{posts
										.filter((post) => post.publishedAt && formatDateMonthYear(post.publishedAt) === date)
										.map((post) => {
											const { href } = buildLinkProps({ url: post.slug, linktype: 'story' });
											return (
												<LinkButton key={post.title} href={href} variant="text">
													{post.title}
												</LinkButton>
											);
										})}
								</div>
							</div>
						);
					})}
				</Section>
				{contactExit && <DynamicComponent blok={contactExit.content} />}
			</ContainerFluid>
		)
	);
}

BlogLibraryPage.blokProps = blokProps;
