import { Grid, Heading, ShowMoreButton } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { CardSmallImageGroupStoryblok } from '@/components';
import { useImpressionTracking } from '@/contexts/piwik/ImpressionTrackingProvider';
import { ThemeProvider } from '@/contexts/theme';
import { getCorrespondingLegacyTheme } from '@/themes';
import { Themes } from '@/types';
import { cls } from '@/utils';
import { isOdd } from '@/utils/isOdd';
import { editableBlok } from '@/utils/storyblok';
import { useState } from 'react';
import { t } from 'ttag';
import { LinkButton, LinkButtonProps } from '../../Button';
import { CardSmallImage, CardSmallImageProps } from '../CardSmall/CardSmallImage';
import styles from '../CardSmallGroup/CardSmallGroup.module.scss';
import { useCardSmallGridColumns } from '../CardSmallGroup/useCardSmallGridColumns';

interface BlokProps {
	blok: CardSmallImageGroupStoryblok;
	meta?: {
		layout?: 'center';
		buttonVariant?: 'filled' | 'outlined' | 'text';
		padding?: boolean;
		theme?: Themes['theme'];
	};
}

const blokProps = ({ blok, meta }: BlokProps): Props => {
	const nrOfCards = blok?.cards?.length ?? 0;
	const cardsWithLongText = blok.cards.some((card) => card.text && card.text.length > 135);
	const textLayout = cardsWithLongText && nrOfCards !== 2 && !blok.textAlignCenter ? 'left' : 'center';

	return {
		cards: blok?.cards?.map((card) =>
			CardSmallImage.blokProps({
				blok: card,
				meta: {
					...meta,
					textLayout,
					buttonVariant: blok?.buttonVariant ?? meta?.buttonVariant,
					titleSize: blok?.titleSize,
				},
			}),
		),
		linkButton: blok.linkButton?.map((btn) => LinkButton.blokProps({ blok: btn })),
		smallTitle: blok.smallTitle,
		group: blok.layout === 'vertical',
		_editable: blok._editable,
		titleSize: blok.titleSize,
		theme: meta?.theme,
	};
};

interface Props {
	cards: CardSmallImageProps[];
	linkButton?: LinkButtonProps[];
	smallTitle?: string;
	className?: string;
	group?: boolean;
	buttonVariant?: 'filled' | 'outlined' | 'text';
	titleSize: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	theme?: Themes['theme'];
	textAlignCenter?: boolean;
	_editable?: string;
}

/**
 * @deprecated Will be replaced by CardSmallGroup
 */
export const CardSmallImageGroup: Blok<Props, BlokProps> = ({
	cards,
	linkButton,
	smallTitle,
	className,
	group = false,
	theme,
	_editable,
}) => {
	const { setTargetInteraction } = useImpressionTracking();
	const nrOfCards = cards?.length ?? 0;
	const columns = useCardSmallGridColumns(nrOfCards, group);
	const cardsPerRow = 4;
	const nrOfRows = nrOfCards / cardsPerRow;
	const [showRows, setShowRows] = useState(2);

	const onShowMore = () => {
		setShowRows(showRows < nrOfRows ? showRows + 1 : showRows);
	};

	return (
		<div>
			{smallTitle && (
				<div className={styles.smallTitleWrapper}>
					<Heading as="div" size="h5" marginBottom="xl" title={smallTitle} />
					<hr className={styles.hr} />
				</div>
			)}
			<Grid
				columns={columns}
				rowGap="2xl"
				className={cls(
					(nrOfCards === 2 || group) && styles.center,
					isOdd(nrOfCards) && styles.oddNrOfCards,
					styles[`nr-of-grid-items--${nrOfCards}`],
					nrOfCards === 4 && group && styles.group,
					styles.grid,
					className,
				)}
				{...editableBlok({ _editable })}
			>
				{cards?.slice(0, cardsPerRow * showRows).map((card, index) => (
					<Grid.Item key={index} className={styles.gridItem}>
						<CardSmallImage {...card} />
					</Grid.Item>
				))}
			</Grid>
			{nrOfCards > 8 && showRows < nrOfRows && (
				<ThemeProvider theme={getCorrespondingLegacyTheme(theme)}>
					<ShowMoreButton onClick={onShowMore}>{t`Visa fler`}</ShowMoreButton>
				</ThemeProvider>
			)}
			{linkButton && linkButton.length > 0 && (
				<ThemeProvider theme={getCorrespondingLegacyTheme(theme)}>
					<div className={styles.btnPosition}>
						{linkButton?.map((btn, index) => (
							<LinkButton
								key={index}
								{...btn}
								variant="filled"
								onClick={() => {
									setTargetInteraction({ contentTarget: btn.children as string });
								}}
							/>
						))}
					</div>
				</ThemeProvider>
			)}
		</div>
	);
};

CardSmallImageGroup.blokProps = blokProps;
