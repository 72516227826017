import { LinkButton } from '@/bloks/Button';
import { LinkButtonStoryblok } from '@/components';
import styles from './RichTextLinkButton.module.scss';

interface Props {
	blok: LinkButtonStoryblok;
}

export const RichTextLinkButton: React.FC<Props> = ({ blok }) => {
	return (
		<div className={styles.buttonWrapper}>
			<LinkButton {...LinkButton.blokProps({ blok })} />
		</div>
	);
};
