import { Heading, Text } from '@/atoms';
import { cls, formatMoney } from '@/utils';
import React from 'react';
import { t } from 'ttag';
import styles from './CalculatorStyling.module.scss';

interface CalculationRowProps {
	text: string;
	amount: string | number | undefined;
	className?: string;
	size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	decimals?: boolean;
	lineColor?: 'pink' | 'purple' | 'yellow' | 'blue' | 'grey' | 'lightpurple';
	fontWight?: 'bold' | 'default';
}

export const CalculationResultRow: React.FC<CalculationRowProps> = ({
	text,
	amount,
	className,
	size = 'h5',
	decimals,
	lineColor = 'grey',
	fontWight = 'default',
}) => {
	const formattedAmount = amount ? formatMoney(amount, decimals ? 2 : 0) : 0;

	return (
		<div className={styles.resultRow}>
			<div className={cls(styles.line, styles[lineColor], size && size < 'h4' && styles.bigResult, className)} />
			<div>
				<Text marginBottom="2xs" className={styles.resultText}>
					{text}
				</Text>
				<Heading
					exo2
					as="div"
					size={size ? size : 'h4'}
					marginBottom="none"
					className={fontWight == 'default' ? styles.resultAmount : styles.boldResultAmount}
					title={formattedAmount + t` kr`}
				/>
			</div>
		</div>
	);
};

export default CalculationResultRow;
