import { Icon, IconButton } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { renderRichText } from '@/bloks/RichText';
import { GlobalInfoBannerStoryblok, RichtextStoryblok } from '@/components';
import { useGlobalMenu } from '@/contexts/menu/GlobalMenuProvider';
import { ThemeProvider } from '@/contexts/theme';
import { getThemeInfo } from '@/themes';
import { cls } from '@/utils';
import { t } from 'ttag';
import styles from './GlobalInfoBannerV2.module.scss';

interface BlokProps {
	blok: GlobalInfoBannerStoryblok;
}

interface Props {
	severity?: GlobalInfoBannerStoryblok['severity'];
	text: RichtextStoryblok;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	severity: blok.severity,
	text: blok.text,
});

export function getBannerIcon(severity: string) {
	switch (severity) {
		case 'major':
			return 'exclamation-triangle';
		default:
			return 'circle-info';
	}
}

export function getBannerTheme(severity: string) {
	switch (severity) {
		case 'minor':
			return 'infoBannerMinor';
		case 'major':
			return 'infoBannerMajor';
		default:
			return 'infoBannerInfo';
	}
}

export const GlobalInfoBannerV2: Blok<Props, BlokProps> = ({ severity = 'info', text }) => {
	const themeInfo = getThemeInfo(getBannerTheme(severity));
	const { toggleInfoBanner, showInfoBanner } = useGlobalMenu();

	return (
		<ThemeProvider theme={getBannerTheme(severity)}>
			<div className={cls(styles.container, themeInfo.styles.bgColor, showInfoBanner ? styles.open : styles.close)}>
				<div className={styles.content}>
					<Icon name={getBannerIcon(severity)} className={styles.icon} />
					{renderRichText(text, { textMarginBottom: 'none' })}
				</div>
				<IconButton
					iconName="xmark-large"
					iconSize="sm"
					aria-label={t`Stäng informationsmeddelande`}
					iconColor={severity === 'major' ? '--text-light' : '--text-dark'}
					onClick={toggleInfoBanner}
				/>
			</div>
		</ThemeProvider>
	);
};

GlobalInfoBannerV2.blokProps = blokProps;
