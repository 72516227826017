import { BrandLine, ContainerFluid, Heading, Section, Text } from '@/atoms';
import { DividingHeaderStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import styles from './DividingHeader.module.scss';

interface Props {
	blok: DividingHeaderStoryblok;
	meta?: { maxTextWidth: '100%'; isParent: boolean; theme: string };
}

export const DividingHeader: React.FC<Props> = ({ blok, meta }) => {
	const theme = meta?.theme ?? 'white';

	return (
		<ContainerFluid marginBottom="3xl">
			<Section
				{...editableBlok(blok)}
				className={styles.container}
				paddingX={meta?.isParent === false ? { base: 'none' } : { base: 'md', md: '3xl' }}
				paddingY={theme === 'white' ? { base: 'none' } : { base: '3xl' }}
			>
				<BrandLine className={styles.brandLine} />
				<div className={cls(meta?.maxTextWidth !== '100%' && styles.adjustableTextWidth)}>
					{blok?.smallTitle && (
						<Heading
							as="div"
							size="h6"
							className={styles.smallTitle}
							marginBottom="md"
							data-testid="dividing-header-h6"
							title={blok.smallTitle}
						/>
					)}
					{blok?.title && (
						<Heading
							as="h2"
							size={blok.titleSize}
							className={styles.title}
							marginBottom="custom"
							data-testid="dividing-header-h2"
							title={blok.title}
						/>
					)}
					{blok?.text && <Text>{blok.text}</Text>}
				</div>
			</Section>
		</ContainerFluid>
	);
};
