import { Text } from '@/atoms';
import { useTheme } from '@/contexts/theme';
import { cls, useDateString } from '@/utils';
import { t } from 'ttag';
import styles from './Tags.module.scss';

interface Props {
	tags: string[];
	published?: string;
	modified?: string;
	testID?: string;
	className?: string;
}

/**
 * Tags contains article tags and date for when the article was first published and updated
 * Used in HeaderArticle, HeaderNewsroom, ArticleLarge and NewsCard
 *
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=91%3A3583
 */
export const Tags: React.FC<Props> = ({ tags, published, modified, testID, className }) => {
	const themeInfo = useTheme();
	const publishedDate = useDateString(published);
	let modifiedDate = useDateString(modified);

	if (modifiedDate === publishedDate) {
		modifiedDate = null;
	}

	const publishedStr = t`Publicerad` + `: ${publishedDate}`;
	const modifiedStr = modifiedDate && t`Senast uppdaterad` + `: ${modifiedDate}`;

	return (
		<>
			<div className={cls(className, !published && styles.marginBottom, styles.tagContainer)} data-testid={testID}>
				{tags?.filter(Boolean)?.map((tag, index) => {
					const lastTag = tags.length > 0 && index === tags.length - 1;
					return (
						<span key={tag} className={cls(styles.tag, themeInfo.styles.smallTitleColor)}>
							<span>{tag}</span>
							{!lastTag && <span className={styles.verticalSeparator}>|</span>}
						</span>
					);
				})}
			</div>
			{published && (
				<Text muted={themeInfo.name === 'white'}>
					{publishedStr}
					{modifiedStr && (
						<>
							<span className={styles.verticalSeparator}>|</span>
							{modifiedStr}
						</>
					)}
				</Text>
			)}
		</>
	);
};
