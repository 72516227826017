import { BrandLine, Grid, Heading } from '@/atoms';
import { CardMiniGroupStoryblok } from '@/components';
import { ReactNode } from 'react';
import { CardMini } from '../CardMini/CardMini';
import styles from './CardMiniGroup.module.scss';

interface BlokProps {
	blok: CardMiniGroupStoryblok;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	cards: blok?.cards?.map((card) => (
		<CardMini
			{...CardMini.blokProps({
				blok: card,
			})}
			key={card._uid}
		/>
	)),
	header: blok.header,
});

interface Props {
	cards?: ReactNode[];
	header: string;
}

export function CardMiniGroup({ cards, header }: Props) {
	return (
		<div>
			<BrandLine className={styles.brandLine} />
			<Heading as="h2" size="h4" title={header} marginBottom="xl" data-testid="card-mini-header-h4" />
			<Grid columns={{ base: 1, lg: 1 }} rowGap="xl">
				{cards?.map((card, index) => <Grid.Item key={index}>{card}</Grid.Item>)}
			</Grid>
		</div>
	);
}

CardMiniGroup.blokProps = blokProps;
