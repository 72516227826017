export interface CustomFieldResponse {
	data: {
		id: string;
		attributes: { name: string };
	};
}

export enum JobStatus {
	Archived = 'archived',
	Published = 'published',
}

export interface JobResponseData {
	id: string;
	attributes: {
		title: string;
		body: string;
		'apply-button-text': string;
		'recruiter-email': string;
		picture: { original: string };
		pitch: string;
		'human-status': JobStatus | string;
	};
	links: {
		'careersite-job-apply-url': string;
	};
	relationships: {
		department: {
			data?: { id: string };
			links: { self: string; related: string };
		};
		locations: {
			data: { id: string; type: string }[];
		};
	};
}

export interface JobsResponse {
	data: JobResponseData[];
	meta: {
		'page-count': string;
	};
}

export interface JobResponse {
	data: JobResponseData;
	customFieldData: {
		id: string;
		value: string;
		name: string;
	}[];
	included: {
		type: 'custom-field-values';
		attributes: { value: string };
		relationships: {
			'custom-field': {
				links: { self: string; related: string };
			};
		};
	}[];
	meta: unknown;
}

export interface DepartmentResponse {
	data: DepartmentResponseData[];
}

export interface DepartmentResponseData {
	id: string;
	attributes: { name: string };
}

export interface LocationResponse {
	data: LocationResponseData[];
}

export interface LocationResponseData {
	id: string;
	attributes: { city: string };
}

export interface TeamtailorData {
	jobs: JobAdvertisement[];
	departments: Department[];
	locations: JobLocation[];
}

export interface JobAdvertisement {
	id: string;
	title: string;
	body: string;
	pitch: string;
	applyButtonText: string;
	recruiterEmail: string;
	locationIds?: string[] | null;
	departmentId?: string | null;
	picture: string;
	careersiteJobApplyUrl: string;
	customFields: CustomField[] | [];
}

export interface Department {
	name: string;
	id: string;
}

export interface JobLocation {
	name: string;
	id: string;
}

export interface CustomField {
	id: string;
	name?: string;
	value: string;
}

export const CONTACT_PERSON_FIELD_ID = '10156';

export const DepartmentInitialState: Department = {
	name: '',
	id: '',
};

export const JobAdvertisementInitialState: JobAdvertisement = {
	id: '',
	title: '',
	body: '',
	pitch: '',
	applyButtonText: '',
	recruiterEmail: '',
	locationIds: null,
	departmentId: null,
	picture: '',
	careersiteJobApplyUrl: '',
	customFields: [],
};
