import { SearchIntegationsProvider } from '@/contexts/Integration/searchIntegrationsContext';
import React, { ComponentType } from 'react';

export const withSearchIntegrations = <P extends object>(Component: ComponentType<P>): React.FC<P> => {
	const WrappedComponent: React.FC<P> = (props: P) => {
		return (
			<SearchIntegationsProvider>
				<Component {...props} />
			</SearchIntegationsProvider>
		);
	};

	WrappedComponent.displayName = `withSearchIntegrations(${Component.displayName || Component.name || 'Component'})`;

	return WrappedComponent;
};
