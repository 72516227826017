import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { cls } from '@/utils';
import styles from './Section.module.scss';

export type Padding =
	| 'none'
	| '3xs'
	| '2xs'
	| 'xs'
	| 'sm'
	| 'md'
	| 'lg'
	| 'xl'
	| '2xl'
	| '3xl'
	| '4xl'
	| '5xl'
	| '6xl'
	| '7xl';
const sizes = ['xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'base'] as const;

interface SectionProps {
	children: React.ReactNode;
	/** Optionally render as another element than `<section>` */
	as?: 'section' | 'div' | 'header' | 'footer' | 'article';
	className?: string;
	id?: string;
	maxWidth?: '1920px' | '1440px' | '1296px' | '1040px' | '925px' | '832px' | '640px' | '2561px' | 'custom';
	lang?: 'sv' | 'en';

	/**
	 * Equal padding to all four sides of an element.
	 */
	padding?: {
		base: Padding;
		xs?: Padding;
		sm?: Padding;
		md?: Padding;
		lg?: Padding;
		xl?: Padding;
		xxl?: Padding;
	};

	/**
	 * Padding to left and right of element.
	 */
	paddingX?: {
		base: Padding;
		xs?: Padding;
		sm?: Padding;
		md?: Padding;
		lg?: Padding;
		xl?: Padding;
		xxl?: Padding;
	};

	/**
	 * Padding to top and bottom of element.
	 */
	paddingY?: {
		base: Padding;
		xs?: Padding;
		sm?: Padding;
		md?: Padding;
		lg?: Padding;
		xl?: Padding;
		xxl?: Padding;
	};
}

/**
 * A section of the page. By default restricted to a max width 1920px, keeping
 * to the center, add a maxWidth prop if for example 1440px maxWidth is wanted instead.
 */
export const Section: React.FC<SectionProps> = ({
	as: Component = 'section',
	children,
	id,
	className,
	maxWidth: maxWidthRaw = '1920px',
	padding,
	paddingX,
	paddingY,
	...rest
}) => {
	const maxWidth = maxWidthRaw !== 'custom' ? parseInt(maxWidthRaw) : undefined;
	const style: Record<string, string | number | null | undefined> = {};
	const { hasSubMenuV1 } = useSubMenu();

	if (!padding && !paddingX) {
		paddingX = hasSubMenuV1 ? { base: 'xl', md: '3xl' } : { base: 'xl', md: '3xl', lg: '5xl' };
	}

	if (!padding && !paddingY) {
		paddingY = hasSubMenuV1 ? { base: '3xl', md: '3xl' } : { base: '3xl', md: '4xl', lg: '5xl' };
	}

	for (const size of sizes) {
		if (size) {
			if (padding) {
				style[`--padding-${size}`] = `var(--space-inset-${padding[size]!})`;
			}

			if (!padding && paddingX) {
				style[`--padding-x-${size}`] = `var(--space-inset-${paddingX[size]!})`;
			}

			if (!padding && paddingY) {
				style[`--padding-y-${size}`] = `var(--space-inset-${paddingY[size]!})`;
			}
		}
	}

	return (
		<Component
			id={id}
			className={cls(
				styles.section,
				!padding && paddingX && styles['padding-x'],
				!padding && paddingY && styles['padding-y'],
				className,
			)}
			style={{ ...style, maxWidth }}
			{...rest}
		>
			{children}
		</Component>
	);
};
