import { Grid, Heading } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import {
	LinkButtonStoryblok,
	PriceCardProductListItemStoryblok,
	PriceCardProductListStoryblok,
	ProductCatalogItemStoryblok,
	ProductCatalogPackageItemStoryblok,
} from '@/components';
import { useProduct } from '@/contexts/products';
import { ISbStoryData } from '@/types/storyblok';
import { handlePriceCardClickEvent } from '@/utils/piwik';
import { editableBlok } from '@/utils/storyblok';
import { t } from 'ttag';
import styles from './PriceCardProductList.module.scss';

interface PriceCardProductListItemProps {
	blok: PriceCardProductListItemStoryblok;
	meta?: { zone: number | undefined };
}

const PriceCardProductListItem: React.FC<PriceCardProductListItemProps> = ({ blok, meta }) => {
	const product = blok.product as any as
		| ISbStoryData<ProductCatalogItemStoryblok>
		| ISbStoryData<ProductCatalogPackageItemStoryblok>;
	const productCatalogData = useProduct(product?.slug);

	return (
		<div {...editableBlok(blok)} className={styles.productItemcontainer}>
			<Grid
				columns={{ base: 2, sm: '4fr 8fr', md: '5fr 8fr', lg: '4fr 8fr', xl: '5fr 8fr' }}
				colGap="md"
				rowGap="md"
				className={styles.gridContainer}
			>
				<Grid.Item className={styles.listItemHeader}>{blok?.text}</Grid.Item>
				<Grid.Item className={styles.priceColumn}>
					<div className={styles.priceContainer}>
						{t`Från`} {productCatalogData?.priceStructure[12][0]} {t`kr/mån`}
					</div>
					{blok?.link?.map((link: LinkButtonStoryblok) => (
						<LinkButton
							key={link._uid}
							className={styles.link}
							{...LinkButton.blokProps({ blok: link })}
							variant="text"
							onClick={() => {
								handlePriceCardClickEvent({
									productName: product.name,
									productId: product.slug,
									zone: meta?.zone,
									buttonVariant: 'text',
									buttonText: link.text,
								});
							}}
						/>
					))}
				</Grid.Item>
			</Grid>
		</div>
	);
};

interface PriceCardProductListProps {
	blok: PriceCardProductListStoryblok;
	meta?: { zone: number | undefined };
}

export const PriceCardProductList: React.FC<PriceCardProductListProps> = ({ blok, meta }) => {
	const { link, list, title } = blok;
	return (
		<div {...editableBlok(blok)} className={styles.container} data-testid={`price-card-list-${title}`}>
			{title && <Heading as="div" size="h4" title={title} />}
			<Grid columns={{ base: 1, lg: 2 }} colGap="xl" rowGap="lg">
				{list &&
					list.map((listItem: PriceCardProductListItemStoryblok, index: number) => (
						<Grid.Item key={index}>
							<PriceCardProductListItem blok={listItem} meta={meta} />
						</Grid.Item>
					))}
			</Grid>
			{!!link?.length && (
				<div className={styles.linkContainer}>
					{link?.map((linkButton: LinkButtonStoryblok) => (
						<LinkButton
							className={styles.link}
							key={linkButton._uid}
							{...LinkButton.blokProps({ blok: linkButton })}
							variant="text"
						/>
					))}
				</div>
			)}
		</div>
	);
};
