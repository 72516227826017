import { useLightnessTheme, useTheme } from '@/contexts/theme';
import classNames from 'classnames';
import styles from './Text.module.scss';

export type TextProps = Pick<
	React.DetailedHTMLProps<React.HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>,
	'title'
> & {
	children: React.ReactNode;
	size?: 'default' | 'small' | 'ingress';
	className?: string;
	muted?: boolean;
	justify?: boolean;
	bold?: boolean;

	/**
	 * Optional marginBottom override escape hatch
	 * Can set marginBottom="custom" and then provide className instead
	 *
	 * 		none	0px		0rem
	 * 		2xs		4px		0.25rem
	 * 		xs		8px		0.5rem
	 * 		sm		12px	0.75rem
	 * 		md		16px	1rem
	 * 		lg		24px	1.5rem
	 * 		xl		32px	2rem
	 * 		2xl		48px	3rem
	 * 		3xl		64px	4rem
	 *
	 *
	 */
	marginBottom?: 'none' | '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | 'custom';
	/**
	 * Lightness is used to decide color of elements placed on an image or video
	 */
	applyLightnessTheme?: boolean;
} & (
		| { as?: 'p' }
		| { as: 'div' }
		| {
				as: 'label';
				htmlFor: string;
		  }
	);

export const Text: React.FC<TextProps> = ({
	as = 'p',
	children,
	className,
	size = 'default',
	justify = false,
	muted = false,
	marginBottom = 'md',
	applyLightnessTheme = false,
	bold = false,
	...rest
}) => {
	const Component = as;
	const lightnessTheme = useLightnessTheme();
	const theme = useTheme();
	const themeInfo = applyLightnessTheme && lightnessTheme ? lightnessTheme : theme;
	return (
		<Component
			className={classNames(
				styles.common,
				{ [styles[`size--${size}`]]: size },
				muted && styles.muted,
				justify && styles.justify,
				{ [styles[`margin-bottom--${marginBottom}`]]: marginBottom && marginBottom !== 'custom' },
				bold && styles.bold,
				themeInfo.styles.textColor,
				className,
			)}
			{...rest}
		>
			{children}
		</Component>
	);
};
