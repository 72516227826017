import { BrandStripes, ContainerFluid, Grid, Heading, Section } from '@/atoms';
import DynamicComponent from '@/bloks/DynamicComponent';
import { renderRichText } from '@/bloks/RichText';
import { HeaderProductStoryblok } from '@/components';
import { ImageAsset } from '@/metabloks';
import { editableBlok } from '@/utils/storyblok';
import styles from './HeaderProduct.module.scss';

interface Props {
	blok: HeaderProductStoryblok;
	meta: Record<string, any> & { containerFoldoutButton?: JSX.Element };
}

export const HeaderProduct: React.FC<Props> = ({ blok, meta }) => {
	return (
		<ContainerFluid theme="white">
			<Section paddingX={{ base: 'none' }} {...editableBlok(blok)}>
				<Grid className={styles.headerContainer} columns={{ base: 1, lg: 2 }} colGap="2xl" rowGap="2xl">
					<Grid.Item>
						<BrandStripes />
						{blok?.headlineSmall && <Heading as="div" size="h5" title={blok?.headlineSmall} marginBottom="lg" />}
						<Heading
							as="h1"
							className={styles.title}
							title={blok.h1Part1}
							titlePart2={blok.headlineEmphasis}
							marginBottom="2xl"
						/>
						{blok?.ingress && renderRichText(blok?.ingress, { textSize: 'ingress', textMarginBottom: '2xl' })}
					</Grid.Item>
					<Grid.Item>
						{blok?.image?.filename && (
							<div className={styles.image}>
								<ImageAsset asset={blok?.image} objectFit="cover" />
							</div>
						)}
					</Grid.Item>
				</Grid>

				{blok.cards && (
					<div className={styles.cards}>
						{blok.cards.map((card) => {
							return (
								<DynamicComponent
									key={card._uid}
									blok={card}
									meta={{ ...meta, padding: false, layout: 'threeColumns' }}
								/>
							);
						})}
					</div>
				)}
				<div className={styles.foldoutButton}>{meta?.containerFoldoutButton && meta?.containerFoldoutButton}</div>
			</Section>
		</ContainerFluid>
	);
};
