import { Blok } from '@/bloks/Blok';
import { ImageStoryblok } from '@/components';
import { ImageAsset, ImageAssetProps } from '@/metabloks';

interface BlokProps {
	blok: ImageStoryblok;
	meta?: {
		fill?: boolean;
		objectFit?: 'cover';
		objectPosition?: 'center';
	};
}

const blokProps = ({ blok, meta }: BlokProps): ImageProps => {
	const props: ImageProps = {
		image: blok.image,
		_uid: blok._uid,
	};

	if (meta) {
		const { fill, objectFit, objectPosition } = meta;
		Object.assign(props, { fill, objectFit, objectPosition });
	}

	return props;
};

export interface ImageProps {
	image: ImageAssetProps;
	fill?: boolean;
	objectFit?: 'cover' | 'contain';
	objectPosition?: 'center';
	_uid?: string;
}

/**
 * Image component.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=249%3A9072
 */
export const Image: Blok<ImageProps, BlokProps> = ({
	image,
	fill = true,
	objectFit = 'cover',
	objectPosition = 'center',
}) => {
	return (
		<>
			{image?.filename && (
				<ImageAsset absolute fill={fill} asset={image} objectFit={objectFit} objectPosition={objectPosition} />
			)}
		</>
	);
};

Image.blokProps = blokProps;
