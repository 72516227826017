import { PageLayout } from '@/atoms';
import { CardFiveGroupStoryblok, StoryPostStoryblok, SubMenuStoryblok } from '@/components';
import { ISbStoryData } from '@/types/storyblok';
import { editableBlok } from '@/utils/storyblok';
import DynamicComponent from '../DynamicComponent';

interface Props {
	blok: StoryPostStoryblok;
	meta: Record<any, any>;
}

export const StoryPost: React.FC<Props> = ({ blok, meta }) => {
	const contact = blok?.contact as unknown as ISbStoryData<CardFiveGroupStoryblok>;
	const subMenu = blok?.subMenu as unknown as ISbStoryData<SubMenuStoryblok>;
	return (
		<PageLayout subMenu={subMenu} exits={contact} meta={meta}>
			<main {...editableBlok(blok)}>
				{blok?.zone1?.map((blok) => <DynamicComponent blok={blok} key={blok._uid} meta={{ isStoryPage: true }} />)}

				{blok?.zone2?.map((blok) => <DynamicComponent blok={blok} key={blok._uid} />)}

				{blok?.zone3?.map((blok) => <DynamicComponent blok={blok} key={blok._uid} />)}

				{blok?.zone4?.map((blok) => <DynamicComponent blok={blok} key={blok._uid} />)}

				{blok?.zone5?.map((blok) => <DynamicComponent key={blok._uid} blok={blok} />)}
			</main>
		</PageLayout>
	);
};
