import {
	ContainerFluid,
	Datepicker,
	Grid,
	Heading,
	PageLayout,
	Section,
	Select,
	SelectOption,
	ShowMoreButton,
	Text,
	TextField,
} from '@/atoms';
import { CardFiveGroupStoryblok, NavigationPageNewsroomStoryblok, SubMenuStoryblok } from '@/components';
import { useNewsfeed } from '@/contexts/News';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { NewsPostCardData } from '@/server/newsfeed';
import { ISbStoryData } from '@/types/storyblok';
import { cls } from '@/utils';
import React, { useEffect, useState } from 'react';
import { t } from 'ttag';
import DynamicComponent from '../../DynamicComponent';
import styles from './NavigationPageNewsroom.module.scss';
import { NewsCard } from './NewsCard/NewsCard';

interface Props {
	blok: NavigationPageNewsroomStoryblok;
	meta: Record<string, any>;
}

export const NavigationPageNewsroom: React.FC<Props> = ({ blok, meta }) => {
	const subMenu = blok?.subMenu as unknown as ISbStoryData<SubMenuStoryblok>;
	const contact = blok?.contact as unknown as ISbStoryData<CardFiveGroupStoryblok>;

	const {
		newsfeed,
		filteredNewsfeed,
		setSelectedCategory,
		selectedDate,
		setSelectedDate,
		searchInput,
		setSearchInput,
		selectedCategory,
	} = useNewsfeed();
	const [showMore, setShowMore] = useState(false);
	const [paginatedNewsfeed, setPaginatedNewsfeed] = useState<NewsPostCardData[] | null>(null);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const { hasSubMenuV1 } = useSubMenu();

	const pageSize = 6;

	useEffect(() => {
		if (!filteredNewsfeed) return;
		setTotalPages(Math.ceil(filteredNewsfeed.length / pageSize) ?? 1);
	}, [filteredNewsfeed]);

	useEffect(() => {
		if (!currentPage) setCurrentPage(1);
	}, [currentPage, setCurrentPage]);

	// create category data for select field using tags
	const categories: string[] = [];
	newsfeed?.forEach((post: NewsPostCardData) => {
		post.tags.forEach((tag: string) => {
			if (!categories.includes(tag) && tag !== '') {
				categories.push(tag);
			}
		});
	});

	useEffect(() => {
		if (!filteredNewsfeed) return;
		if (filteredNewsfeed.length <= pageSize) {
			setShowMore(false);
			setPaginatedNewsfeed(filteredNewsfeed);
			return;
		}
		const endIndex = pageSize * currentPage;
		setShowMore(true);
		setPaginatedNewsfeed(filteredNewsfeed.slice(0, endIndex));
	}, [currentPage, filteredNewsfeed]);

	const onDateChange = (event: any) => {
		setSelectedDate(event.currentTarget.value);
	};

	const onSearch = (event: any) => {
		setSearchInput(event.currentTarget.value);
	};

	const onShowMore = () => {
		setCurrentPage(currentPage < totalPages ? currentPage + 1 : currentPage);
	};

	const categorySelectOptions: SelectOption[] = categories?.map((tag: string) => ({
		value: tag,
		label: tag,
		id: tag,
	}));

	const selectedCategorytOption =
		categorySelectOptions?.filter((option) => option.value.toLowerCase() === selectedCategory)?.[0] || null;

	return (
		<PageLayout subMenu={subMenu} exits={contact} meta={meta}>
			{blok.header?.map((header, index: number) => {
				return <DynamicComponent key={index} blok={header} meta={meta} />;
			})}

			<ContainerFluid marginBottom="3xl">
				<Section paddingX={{ base: 'md', md: '3xl' }}>
					<Grid colGap="xl" rowGap="xl" columns={!hasSubMenuV1 ? { base: 1, xl: '66fr 33fr' } : { base: 1 }}>
						<Grid.Item>
							{blok.subHeader?.map((blok: any, index: number) => (
								<DynamicComponent key={index} blok={blok} meta={{ ...meta, maxTextWidth: '100%', isParent: false }} />
							))}

							<div id="alla-nyheter" className={styles.filterSection}>
								<div className={styles.filterHeading}>
									<Heading as="h3" title={t`Alla nyheter`} />
									<Text>{t`Filtrera nyheter på kategori eller tidpunkt.`}</Text>
								</div>
								<Grid columns={{ base: 1, md: 2, lg: '4fr 4fr 8fr', xl: '4fr 4fr 8fr' }} colGap="xs" rowGap="xs">
									<Grid.Item>
										<Select
											className={styles.input}
											name="category"
											onSelect={(value) => {
												setSelectedCategory(value.toLowerCase());
												setCurrentPage(1);
											}}
											testID="category"
											data={categorySelectOptions}
											title={t`Välj kategori`}
											aria-label={t`Välj kategori`}
											selected={selectedCategorytOption}
											onClear={() => setSelectedCategory(null)}
											id="news-post-categories"
										/>
									</Grid.Item>
									<Grid.Item>
										<Datepicker
											className={styles.input}
											value={selectedDate ? selectedDate : new Date().toISOString().split('T')[0].slice(0, -3)}
											max={new Date()}
											onChange={onDateChange}
											type="month"
											bordered
											testID="datepicker"
										/>
									</Grid.Item>

									<Grid.Item>
										<TextField
											id="newsroom-search"
											className={cls(styles.input, styles.search)}
											value={searchInput}
											onChange={onSearch}
											type="search"
											bordered
											title={t`Sök`}
										/>
									</Grid.Item>
								</Grid>
							</div>
							<div>
								{paginatedNewsfeed && paginatedNewsfeed.length > 0 ? (
									<Grid rowGap="lg" colGap="lg" columns={{ base: 1, xl: 1 }}>
										{paginatedNewsfeed?.map((post: NewsPostCardData, index: number) => {
											return (
												<Grid.Item key={index}>
													<NewsCard
														post={post}
														fallbackImage={{ ...blok.pressImage, filename: `${blok.pressImage.filename}` }}
													/>
												</Grid.Item>
											);
										})}
									</Grid>
								) : (
									<div className={styles.notFoundText} data-testid="not-found-text">
										<Text>{t`Tyvärr hittade vi inga nyheter som matchar din sökning.`}</Text>
									</div>
								)}
							</div>
							{showMore && <ShowMoreButton onClick={onShowMore}>{t`Ladda in fler nyheter`}</ShowMoreButton>}
						</Grid.Item>
					</Grid>
				</Section>
			</ContainerFluid>
		</PageLayout>
	);
};
