import { Grid, Heading, Section, Text } from '@/atoms';
import { Video } from '@/bloks';
import { LinkButton } from '@/bloks/Button';
import { TabVideoStoryblok, VideoStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import sectionTabsStyles from './SectionTabs.module.scss';
import styles from './TabVideo.module.scss';

interface Props {
	blok: TabVideoStoryblok;
	display: boolean;
}

export const TabVideo: React.FC<Props> = ({ blok, display }) => {
	return (
		<Section
			className={cls(display ? sectionTabsStyles.display : sectionTabsStyles.hidden, sectionTabsStyles.panel)}
			{...editableBlok(blok)}
			paddingX={{ base: 'md', md: '4xl' }}
		>
			{blok.video?.map((video: VideoStoryblok) => <Video key={video._uid} {...Video.blokProps({ blok: video })} />)}
			<div className={styles[`width--${blok.video[0]?.width}`]}>
				<Grid columns={{ base: 1, md: 2 }}>
					<Grid.Item>
						{blok.header && <Heading as="div" size="h5" title={blok.header} className={styles.header} />}
					</Grid.Item>
					{blok.linkButton?.[0] && (
						<Grid.Item>
							<div className={styles.link}>
								<LinkButton
									className={styles.linkButton}
									href={blok.linkButton?.[0]?.link?.url}
									variant="text"
									arrow={true}
									size={'large'}
								>
									{blok.linkButton?.[0]?.text}
								</LinkButton>
							</div>
						</Grid.Item>
					)}
				</Grid>
				<Grid columns={{ base: 1 }}>
					<Grid.Item>
						{blok.copy && (
							<div className={styles.textContent}>
								<Text className={styles.copy}>{blok.copy}</Text>
							</div>
						)}
					</Grid.Item>
				</Grid>
			</div>
		</Section>
	);
};
