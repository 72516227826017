import { GlobalInfoBannerStoryblok } from '@/components';
import React, { createContext, useContext, useEffect, useState } from 'react';

export const useGlobalMenu = () => {
	return useContext(Context);
};

interface ContextProps {
	isMenuOpen: boolean;
	setIsMenuOpen: (isMenuOpen: boolean) => void;
	hasInfoBanner: boolean;
	infoBannerSeverity?: GlobalInfoBannerStoryblok['severity'];
	showInfoBanner: boolean;
	toggleInfoBanner: () => void;
	menuVersionV3: boolean;

	/**
	 * Old props, can be removed when menu V3 is live
	 */
	showFirstPanel: boolean;
	setShowFirstPanel: (showFirstPanel: boolean) => void;
	showSecondPanel: boolean;
	setShowSecondPanel: (showSecondPanel: boolean) => void;
	mobileView: boolean;
	setMobileView: (mobileView: boolean) => void;
}

const Context = createContext<ContextProps>({
	isMenuOpen: false,
	setIsMenuOpen: () => {},
	hasInfoBanner: false,
	infoBannerSeverity: 'info',
	showInfoBanner: false,
	toggleInfoBanner: () => {},
	menuVersionV3: false,
	showFirstPanel: false,
	setShowFirstPanel: () => {},
	showSecondPanel: false,
	setShowSecondPanel: () => {},
	mobileView: false,
	setMobileView: () => {},
});

interface Props {
	children: React.ReactNode;
	menuStory: any;
	infoBannerStory?: any;
}
export const GlobalMenuProvider: React.FC<Props> = ({ children, menuStory, infoBannerStory }) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [showFirstPanel, setShowFirstPanel] = useState(false);
	const [showSecondPanel, setShowSecondPanel] = useState(false);
	const [mobileView, setMobileView] = useState(false);

	const hasInfoBanner = !!infoBannerStory;
	const infoBannerSeverity = infoBannerStory?.content?.severity;
	const [showInfoBanner, setShowInfoBanner] = useState(hasInfoBanner);

	const menuVersionV3 = menuStory?.content?.component === 'GlobalMenuV3';

	const toggleInfoBanner = () => {
		setShowInfoBanner(!showInfoBanner);
	};

	useEffect(() => {
		if (!showFirstPanel) {
			setShowSecondPanel(false);
		}
	}, [showFirstPanel]);

	const value = {
		isMenuOpen,
		setIsMenuOpen,
		hasInfoBanner,
		infoBannerSeverity,
		showInfoBanner,
		toggleInfoBanner,
		menuVersionV3,

		// Old props
		showFirstPanel,
		setShowFirstPanel,
		showSecondPanel,
		setShowSecondPanel,
		mobileView,
		setMobileView,
	};
	return <Context.Provider value={value}>{children}</Context.Provider>;
};
