import { Card, Divider, Grid, Heading, Icon, Text } from '@/atoms';
import { Integrator } from '@/contexts/Integration';
import { useServerPageConfig } from '@/contexts/config';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import styles from './IntegratorCard.module.scss';

interface Props {
	integrator: Integrator;
}

const IntegratorAbout: React.FC<Props> = ({ integrator }) => {
	return (
		<Grid.Item colSpan={{ base: integrator.companyLogoUrl ? 3 : 5 }}>
			<Text as="p">{integrator.about}</Text>
		</Grid.Item>
	);
};

const ContactInfo: React.FC<Props> = ({ integrator }) => {
	if (!integrator.contactPhone && !integrator.contactEmail && !integrator.contactHomepage) return null;

	return (
		<Grid.Item colSpan={{ base: 5 }}>
			<Divider />
			{integrator.contactPhone && (
				<Grid.Item className={styles.integratorContactIcon}>
					<Icon name="phone" size="sm" />
					{integrator.contactPhone}
				</Grid.Item>
			)}
			{integrator.contactEmail && (
				<Grid.Item className={styles.integratorContactIcon}>
					<Icon name="envelope" size="sm" />
					<a href={`mailto: ${integrator.contactEmail}`}>{integrator.contactEmail}</a>
				</Grid.Item>
			)}
			{integrator.contactHomepage && (
				<Grid.Item className={styles.integratorContactIcon}>
					<Icon name="desktop" size="sm" />
					<a href={integrator.contactHomepage} target="_blank" rel="noreferrer">
						{integrator.contactHomepage}
					</a>
				</Grid.Item>
			)}
		</Grid.Item>
	);
};

export const IntegratorCard: React.FC<Props> = ({ integrator }) => {
	const { appsHost: host } = useServerPageConfig();

	return (
		<Card border rounded className={styles.integratorCard}>
			<Link href={`/integrationer/integration/${integrator.companyNameSlug}`}>
				<Heading as="h5" title={integrator.companyName} />

				{integrator.companyLogoUrl && (
					<Image
						src={`${host}${integrator.companyLogoUrl}`}
						alt={`${integrator.companyName} logotyp`}
						layout="responsive"
						height={100}
						width={300}
						className={styles.integratorLogo}
					/>
				)}

				<IntegratorAbout integrator={integrator} />
			</Link>
			<ContactInfo integrator={integrator} />
		</Card>
	);
};
