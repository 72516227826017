import { ImageProps } from '@/atoms';
import { CSSProperties } from 'react';

export type ImageAssetFilters = 'smart' | string[];

interface Options extends Pick<CSSProperties, 'objectFit' | 'objectPosition'> {
	resize?: string;
	fill?: boolean;
	filters?: ImageAssetFilters;
	focus?: string | null;
	style?: CSSProperties;
}

type Result = Pick<ImageProps, 'style' | 'src' | 'width' | 'height' | 'fill'>;

/**
 * Manipulate a storyblok image asset url to resize and apply filters to image
 * https://www.storyblok.com/docs/image-service
 */
export const resizeStoryBlokImage = (
	url: string,
	{ resize, fill, filters, focus, objectFit = 'contain', objectPosition = 'center', style }: Options = {},
): Result => {
	const isStoryBlokImage = url.includes('a.storyblok.com');
	const isCisionImage = url.includes('mb.cision.com');
	const { pathname } = new URL(url);
	let [originalWidth, originalHeight] = pathname.split('/')[3]!.split('x').map(Number) as [number, number];

	if (isCisionImage) {
		const splits = pathname.split(/(_)/)[2].split('x');
		const heightSplits = splits[1].match(/\d+/) || [];

		originalWidth = Number(splits[0]);
		originalHeight = Number(heightSplits[0]);
	}

	const result: Result = {
		src: isStoryBlokImage ? `${url}/m/` : url,
		style: { width: '100%', height: '100%', objectFit, objectPosition },
	};
	if (fill) result.fill = true;

	if (!isStoryBlokImage && !isCisionImage) {
		originalWidth = 400;
		originalHeight = 400;
		const { width, height } = getNewSize();
		return { ...result, width, height };
	}

	if (!resize && (!filters?.length || filters === 'smart')) {
		if (focus) {
			const objectPosition = determineObjectPositionBasedOnFocusPointAndImageDimensions(
				focus,
				originalWidth,
				originalHeight,
			);
			result.style = { ...result.style, objectPosition };
		}

		if (!fill) Object.assign(result, { width: originalWidth, height: originalHeight });

		return result;
	}

	function getNewSize() {
		if (style?.width || style?.height) {
			return { width: Number(style.width) ?? '100%', height: Number(style.height) ?? '100%' };
		}
		if (!resize) {
			return { width: originalWidth, height: originalHeight };
		}
		let [newWidth, newHeight] = resize.split('x').map(Number);

		if (newWidth && newHeight) {
			return { width: newWidth, height: newHeight };
		}

		if (newWidth) {
			newHeight = (originalHeight / originalWidth) * newWidth;
		}

		if (newHeight) {
			newWidth = (originalWidth / originalHeight) * newHeight;
		}

		return { width: newWidth, height: newHeight };
	}

	if (filters === 'smart' && focus) {
		filters = [`focal(${focus})`];
	}

	const { width, height } = getNewSize();

	const filter = Array.isArray(filters) ? `filters:${filters.join(':')}` : filters;

	const suffix = [resize, filter].filter(Boolean).join('/');

	if (!fill) Object.assign(result, { width, height });

	return { ...result, src: `${url}/m/${suffix}` };
};

function determineObjectPositionBasedOnFocusPointAndImageDimensions(
	focus: string,
	originalWidth: number,
	originalHeight: number,
): string | undefined {
	const [part1, part2] = focus.split(':');
	if (!part1 || !part2) return;
	const [x1, y1, x2, y2] = [part1, part2].flatMap((s) => s.split('x')).map(Number);
	if (x1 === undefined || y1 === undefined || x2 === undefined || y2 === undefined) return;

	const focusX = (x1 + x2) / 2;
	const focusY = (y1 + y2) / 2;
	const posX = `${((100 * focusX) / originalWidth).toFixed(2)}%`;
	const posY = `${((100 * focusY) / originalHeight).toFixed(2)}%`;

	return `${posY} ${posX}`;
}
