import { Grid, GridProps } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { IconLinkGroupStoryblok } from '@/components';
import { editableBlok } from '@/utils/storyblok';
import { IconLink, IconLinkProps } from '../IconLink/IconLink';

interface BlokProps {
	blok: IconLinkGroupStoryblok;
}

const blokProps = ({ blok }: BlokProps): IconLinkGroupProps => ({
	iconLinks: blok.iconLinks.map((iconLink) => ({ ...IconLink.blokProps({ blok: iconLink }) })),
	_editable: blok._editable,
});

export interface IconLinkGroupProps {
	iconLinks: IconLinkProps[];
	_editable?: string;
}

const useGridColumns = (itemCount: number) => {
	const columns: GridProps['columns'] = { base: 1 };

	if (itemCount === 5 || itemCount === 6) {
		columns.lg = 3;
		columns.md = 2;
	}

	if (itemCount === 4) {
		columns.lg = 4;
		columns.md = 2;
	}

	if (itemCount === 3) {
		columns.lg = 3;
		columns.md = 2;
	}

	return columns;
};

export const IconLinkGroup: Blok<IconLinkGroupProps, BlokProps> = ({ iconLinks, _editable }) => {
	const nrOfLinks = iconLinks.length;
	const columns = useGridColumns(nrOfLinks);
	return (
		<Grid columns={columns} colGap="3xl" rowGap="xl" {...editableBlok({ _editable })}>
			{iconLinks.map((iconLink, i) => (
				<Grid.Item key={i}>
					<IconLink {...iconLink} />
				</Grid.Item>
			))}
		</Grid>
	);
};

IconLinkGroup.blokProps = blokProps;
