import { useRouter } from 'next/router';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { NewsPostCardData } from '../../server/newsfeed';

export const useNewsfeed = () => {
	return useContext(NewsfeedContext);
};

interface ContextProps {
	newsfeed: NewsPostCardData[] | null;
	filteredNewsfeed: NewsPostCardData[] | null;
	setSelectedCategory: (category: string | null) => void;
	selectedCategory: string | null;
	setSelectedDate: (input: string) => void;
	selectedDate: string;
	searchInput: string;
	setSearchInput: (input: string) => void;
}

const NewsfeedContext = createContext<ContextProps>({
	newsfeed: null,
	filteredNewsfeed: null,
	setSelectedCategory: () => {},
	selectedCategory: '',
	setSelectedDate: () => {},
	selectedDate: '',
	searchInput: '',
	setSearchInput: () => {},
});

interface Props {
	children: React.ReactNode;
	value: NewsPostCardData[] | null;
}

export const NewsfeedProvider: React.FC<Props> = ({ children, value: newsfeed }) => {
	const router = useRouter();

	const categoryParam = [router.query.category].flat()[0] || '';
	const dateParam = [router.query.date].flat()[0] || '';
	const searchParam = [router.query.s].flat()[0] || '';

	const [selectedCategory, setSelectedCategory] = useState<string | null>(categoryParam);
	const [selectedDate, setSelectedDate] = useState(dateParam);
	const [searchInput, setSearchInput] = useState(searchParam);

	useEffect(() => {
		if (!selectedCategory && categoryParam) {
			setSelectedCategory(categoryParam.toString().toLowerCase());
		}
	}, [categoryParam]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!selectedDate && dateParam) {
			setSelectedDate(dateParam.toString());
		}
	}, [dateParam]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!searchInput && searchParam) {
			setSearchInput(searchParam.toString().toLowerCase());
		}
	}, [searchParam]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!router.isReady) {
			return;
		}

		const query = { ...router.query };

		if (selectedCategory) {
			query.category = selectedCategory;
		}
		if (selectedDate) {
			query.date = selectedDate.toString();
		}

		if (searchInput) {
			query.s = searchInput;
		}

		router.replace({ pathname: router.pathname, query }, undefined, { scroll: false });
	}, [selectedCategory, selectedDate, searchInput]); // eslint-disable-line react-hooks/exhaustive-deps

	const filteredNewsfeed = useMemo(() => {
		if (!newsfeed || !newsfeed.length) {
			return [];
		}

		const selectedDateFilter = ({ firstPublished }: NewsPostCardData) => {
			const date = new Date(selectedDate);
			const lastDateOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
			const firstDayString = `${selectedDate}-01`;
			const lastDayString = `${selectedDate}-${lastDateOfMonth}`;
			const firstDayTimestamp = new Date(firstDayString).getTime();
			const lastDayTimestamp = new Date(lastDayString).getTime();
			const firstPublishedTimestamp = new Date(firstPublished).getTime();

			return firstPublishedTimestamp >= firstDayTimestamp && firstPublishedTimestamp <= lastDayTimestamp;
		};

		const selectedCategoryFilter = (post: NewsPostCardData) =>
			selectedCategory === 'all' ? true : post.tags.find((tag: string) => selectedCategory === tag.toLowerCase());

		const search = searchInput.toLowerCase();

		const searchInputFilter = (post: NewsPostCardData) =>
			post.title.toLowerCase().includes(search) || post.text.toLowerCase().includes(search);

		const filters = [
			...(selectedCategory ? [selectedCategoryFilter] : []),
			...(selectedDate ? [selectedDateFilter] : []),
			...(searchInput ? [searchInputFilter] : []),
		];
		return newsfeed.filter((post: NewsPostCardData) => {
			return filters.every((filter) => filter(post));
		});
	}, [newsfeed, selectedCategory, selectedDate, searchInput]);

	const value = {
		newsfeed,
		filteredNewsfeed,
		selectedCategory,
		setSelectedCategory,
		selectedDate,
		setSelectedDate,
		searchInput,
		setSearchInput,
	};

	return <NewsfeedContext.Provider value={value}>{children}</NewsfeedContext.Provider>;
};
