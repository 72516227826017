import { Icon } from '@/atoms';
import { Table } from '@/bloks';
import { TableImageStoryblok } from '@/components';
import { fetchApi } from '@/utils/fetchApi';
import * as Sentry from '@sentry/nextjs';
import { useEffect, useRef, useState } from 'react';
import styles from './TableImage.module.scss';
import { TableImageDesktop } from './TableImageDesktop';
import { TableImageMobile } from './TableImageMobile';

const DATASOURCE_SLUG = 'bankcomparison';

export interface Props {
	blok: TableImageStoryblok;
}

export function TableImage(props: Props) {
	const { blok } = props;
	const [dataTable, setDataTable] = useState<Table>();
	const dimensionsRef = useRef<string[]>(blok.columns.map((column) => column.dataSourceDimension));

	useEffect(() => {
		const getResult = async () => {
			try {
				const result = await fetchApi('datasources-v1', {
					segments: [DATASOURCE_SLUG],
					dimensions: dimensionsRef.current,
				});
				if (!Array.isArray(result?.body?.data)) {
					return;
				}
				setDataTable(result.body.data[0]);
			} catch (error) {
				Sentry.captureException(error);
			}
		};
		getResult();
	}, []);

	return blok && dataTable ? (
		<>
			<div className={styles.wrapperDesktop}>
				<TableImageDesktop blok={blok} dataTable={dataTable} />
			</div>

			<div className={styles.wrapperMobile}>
				{blok.columns?.map((column, index) => {
					return (
						<TableImageMobile
							key={column._uid}
							column={column}
							rows={blok.rows}
							dataTable={dataTable}
							columnIndex={index}
						/>
					);
				})}
			</div>
		</>
	) : (
		<Icon name="spinner" className="fa-spin" type={'solid'} />
	);
}
