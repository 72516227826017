import { useTheme } from '@/contexts/theme';
import { cls } from '@/utils';
import classnames from 'classnames';
import { IconName } from '../../icons/types';
import { Icon } from '../Icon';
import styles from './ShowMoreButton.module.scss';

interface Props {
	children: React.ReactNode;
	onClick?: () => void;
	className?: string;
	testID?: string;
	icon?: IconName;
	iconPosition?: 'bottom' | 'top';
}

/**
 * A button to use for showing or loading more content
 */
export const ShowMoreButton: React.FC<Props> = ({
	children,
	onClick,
	className,
	testID,
	icon = 'chevron-down',
	iconPosition = 'bottom',
}) => {
	const themeInfo = useTheme();
	return (
		<button
			className={classnames(styles.showMoreButton, themeInfo.styles.textLink, className)}
			onClick={onClick}
			data-testid={testID}
		>
			{iconPosition === 'top' && (
				<Icon className={cls(styles.showMoreIcon, themeInfo.styles.textLink)} name={icon} size="sm" />
			)}
			<span>{children}</span>
			{iconPosition === 'bottom' && (
				<Icon className={cls(styles.showMoreIcon, themeInfo.styles.textLink)} name={icon} size="sm" />
			)}
		</button>
	);
};
