import { RichtextStoryblok } from '@/components';
import { useTheme } from '@/contexts/theme';
import { cls } from '@/utils';
import React from 'react';
import styles from './BigQuote.module.scss';

interface Props {
	quote: RichtextStoryblok | string;
	className?: string;
	quotation?: boolean;
}

const getQuoteHtml = (quote: RichtextStoryblok, accentTextColorClass: string | undefined) => {
	return (
		<>
			{quote?.content?.map((paragraph: any) =>
				paragraph?.content?.map((content: any, index: number) => {
					if (content.marks) {
						return (
							<span key={`${content.text}-${index}`} className={accentTextColorClass}>
								{content.text}
							</span>
						);
					}
					return content.text;
				}),
			)}
		</>
	);
};

export function BigQuote({ quote, className, quotation = false }: Props) {
	const themeInfo = useTheme();
	const longText = typeof quote === 'string' && quote.length > 75;
	let longWord = false;

	if (typeof quote === 'string') {
		const words = quote.split(' ');
		longWord = words.some((word) => word.length > 12);
	} else {
		quote = quote as unknown as RichtextStoryblok;

		if (quote?.content?.[0]?.content) {
			const words: string[] = quote.content?.[0]?.content
				?.map((content: Record<string, any>) => content.text.split(' '))
				?.flat();
			longWord = words.some((word) => word.length > 10);
		}
	}

	return (
		<div
			className={cls(
				className,
				styles.container,
				themeInfo.styles.titleColor,
				longText && styles.longText,
				quotation && styles.quotation,
				longWord && styles.longWord,
			)}
		>
			{typeof quote === 'string' ? quote : getQuoteHtml(quote, themeInfo.styles.accentTextColor)}
		</div>
	);
}
