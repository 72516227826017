import { ExpansionPanelsProvider, ExpansionPanel as Panel, getHeadingAnchorId } from '@/atoms';
import { renderRichText } from '@/bloks/RichText';
import { AccordionRichtextPanelStoryblok, AccordionRichtextStoryblok, ThemeV2Storyblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import styles from './AccordionRichtext.module.scss';

interface BlokProps {
	blok: AccordionRichtextStoryblok;
	meta?: {
		layout?: 'article';
		compact?: boolean;
		titleSize?: 'h2' | 'h3' | 'h4' | 'h5';
		theme: ThemeV2Storyblok['theme'];
		inSection?: boolean;
	};
}

const blokProps = ({ blok, meta }: BlokProps): Props => ({
	panels: blok?.panels,
	_editable: blok?._editable,
	layout: meta?.layout,
	compact: meta?.compact,
	component: blok.component,
	theme: meta?.theme,
	inSection: meta?.inSection,
});

interface Props {
	panels: AccordionRichtextPanelStoryblok[];
	_editable?: string;
	layout?: 'article';
	compact?: boolean;
	component?: string;
	theme?: ThemeV2Storyblok['theme'];
	inSection?: boolean;
}
/**
 * Grouping Accordion panels for RichText - primarily used in Comapany Guide posts.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=398%3A11765
 */
export function AccordionRichtext({ panels, _editable, layout, compact, theme, inSection }: Props) {
	theme = inSection ? theme : 'green';
	return (
		<div {...editableBlok({ _editable })} className={cls(layout === 'article' && styles.container)}>
			<ExpansionPanelsProvider>
				{panels?.map((panel, index) => {
					const panelId = getHeadingAnchorId(panel.title);
					return (
						<Panel key={panel._uid} panelId={panelId} compact={compact} theme={theme}>
							<Panel.Header
								panelId={panelId}
								title={panel.title}
								dataIdNr={index}
								theme={theme}
								compact={compact}
								titleSize={compact ? 'h6' : 'h5'}
							/>
							<Panel.Content panelId={panelId} dataIdNr={index} compact={compact}>
								{renderRichText(panel.content)}
							</Panel.Content>
						</Panel>
					);
				})}
			</ExpansionPanelsProvider>
		</div>
	);
}

AccordionRichtext.blokProps = blokProps;
