import React, { useState } from 'react';
import cls from 'classnames';
import { t } from 'ttag';
import { IconButton } from '../Button';
import styles from './Incrementer.module.scss';

interface IncrementerProps {
	defaultValue?: number;
	disabled?: boolean;
	invalid?: boolean;
	max?: number;
	min?: number;
	size?: 'small' | 'medium' | 'large';
	onValueChange?: (value: number) => void;
	value: number;
}

/**
 * Incrementer component - used to step up and down within a predefined set of numerical values.
 * The numerical display also functions as an input field for the cases where the user wants to input a numerical value directly without using the +/- buttons.
 */
export const Incrementer: React.FC<IncrementerProps> = ({
	disabled = false,
	invalid = false,
	max,
	min = 0,
	size = 'medium',
	onValueChange,
	value,
}) => {
	const decrementDisabled = disabled || value === min;
	const incrementDisabled = disabled || value === max;

	const [inputValue, setInputValue] = useState<string | null>(null);

	const inputClassNames = cls(styles.input, {
		[styles[size]]: size,
	});

	const containerClassNames = cls(styles.container, {
		[styles.disabled]: disabled,
		[styles.invalid]: invalid,
	});

	const handleChange = (value: any) => {
		onValueChange?.(value);
	};

	const handleBlur = () => {
		if (value <= min) {
			return handleChange(min);
		}
		if (max && value >= max) {
			return handleChange(max);
		}
	};
	return (
		<div className={containerClassNames}>
			<IconButton
				testID="incremeter-button-decrease"
				iconName="minus"
				iconColor="--action-color"
				className={cls(styles.icon, { [styles.disabled]: decrementDisabled })}
				disabled={decrementDisabled}
				aria-label={t`Minska antal`}
				onClick={() => {
					if (!decrementDisabled) handleChange(value - 1);
				}}
			/>
			<div className={styles.inputContainer}>
				<input
					data-testid="incremeter-input"
					type="number"
					max={max}
					min={min}
					className={inputClassNames}
					onChange={(event) => {
						if (event.target.value == '') {
							handleChange(event.target.value);
						} else {
							handleChange(event.target.valueAsNumber);
							setInputValue(event.target.value);
						}
					}}
					onBlur={handleBlur}
					value={value ?? inputValue}
					disabled={disabled}
				/>
			</div>
			<IconButton
				testID="incremeter-button-increase"
				iconName="plus"
				iconColor="--action-color"
				aria-label={t`Öka antal`}
				className={cls(styles.icon, { [styles.disabled]: incrementDisabled })}
				disabled={incrementDisabled}
				onClick={() => {
					if (!incrementDisabled) handleChange(value + 1);
				}}
			/>
		</div>
	);
};
