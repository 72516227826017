import { useLightnessTheme, useTheme } from '@/contexts/theme';
import classNames from 'classnames';
import styles from './BrandLine.module.scss';

interface Props {
	className?: string;

	marginBottom?: 'md' | 'xl';

	/**
	 * Lightness is used to decide color of elements placed on an image or video
	 */
	applyLightnessTheme?: boolean;
}

export const BrandLine: React.FC<Props> = ({ className, marginBottom = 'md', applyLightnessTheme }) => {
	const theme = useTheme();
	const lightnessTheme = useLightnessTheme();
	let themeInfo = theme;

	if (applyLightnessTheme && lightnessTheme) {
		themeInfo = lightnessTheme;
	}

	return (
		<div
			className={classNames(
				styles.line,
				styles[`margin-bottom--${marginBottom}`],
				themeInfo.styles.brandlineColor,
				className,
			)}
		/>
	);
};
