import { CaptchaForm, Checkbox, Grid, Heading, RadiobuttonGroup, Text, TextField } from '@/atoms';
import { getCaptchaSolution } from '@/atoms/CaptchaForm';
import { FieldValidationProvider, FormValidationProvider } from '@/atoms/CaptchaForm/FormValidationContext';
import { CardFiveCisionFormStoryblok, ThemeStoryblok } from '@/components';
import { useServerPageConfig } from '@/contexts/config';
import { cls } from '@/utils';
import { fetchApi } from '@/utils/fetchApi';
import { editableBlok } from '@/utils/storyblok';
import { validateEmailField } from '@/validation';
import { t } from 'ttag';
import styles from './CardFive.module.scss';

interface Props {
	blok: CardFiveCisionFormStoryblok;
	meta?: {
		theme?: ThemeStoryblok['theme'];
		zone?: number | undefined;
	};
}

/**
 * CardFiveForm component (The one with a cision form)
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=326%3A11065
 */
export const CardFiveCisionForm: React.FC<Props> = ({ blok, meta }) => {
	const { smallTitle, title, text } = blok;
	const theme = meta?.theme ?? 'dark-yellow';
	const feedbackTitle = blok.feedbackTitle ? blok.feedbackTitle : t`Tack!`;
	const feedbackText = blok.feedbackText
		? blok.feedbackText
		: t`Vi har tagit emot din prenumeration. Du kommer att få ett email för att verifera din emailadress och aktivera prenumerationen.`;

	const { locale } = useServerPageConfig();

	const handleSubmit = (e: React.BaseSyntheticEvent) => {
		const formData = new FormData(e.target);

		const urlSearchParams = new URLSearchParams();
		const body = {
			data: '',
			captchaSolution: getCaptchaSolution(e),
		};

		formData.forEach((value, prop) => {
			if (prop !== 'frc-captcha-solution') {
				urlSearchParams.append(prop, value as string);
			}
		});

		body.data = urlSearchParams.toString();

		return async function postCisionForm() {
			await fetchApi('cision-v1', body);
		};
	};

	return (
		<div
			{...editableBlok(blok)}
			className={cls(styles.container, styles.cisionFormContainer, styles.card, styles[`theme--${theme}`])}
		>
			{smallTitle && (
				<Heading as="div" size="h6" title={smallTitle} marginBottom="xl" data-testid="card-five-form-small-title" />
			)}
			{title && <Heading as="div" size="h4" title={title} marginBottom="xl" data-testid="card-five-form-header" />}

			{text && <Text marginBottom="lg">{text}</Text>}
			<FormValidationProvider>
				<CaptchaForm
					className={styles.cisionForm}
					onSubmit={handleSubmit}
					feedbackText={feedbackText}
					feedbackTitle={feedbackTitle}
					meta={{ ...meta, type: 'cision', inCard: true }}
				>
					<input type="hidden" name="subscriptionUniqueIdentifier" value="afbd63e970" />
					<input type="hidden" name="replylanguage" value={locale} />
					<Grid columns={{ base: 1, sm: 2 }} className={styles.fieldsetContainer}>
						<Grid.Item>
							<RadiobuttonGroup
								name="language"
								options={[
									{
										checked: locale === 'sv',
										className: styles.option,
										id: 'radio-input-language-sv',
										label: t`Svenska`,
										testID: 'swedish-language-option',
										value: 'sv',
									},
									{
										checked: locale === 'en',
										className: styles.option,
										id: 'radio-input-language-en',
										label: t`Engelska`,
										testID: 'english-language-option',
										value: 'en',
									},
								]}
							/>
						</Grid.Item>

						<Grid.Item>
							<fieldset className={styles.fieldset}>
								<Checkbox checked testID="press-checkbox" name="informationtype" label={t`Pressreleaser`} value="prm" />
								<Checkbox testID="interim-checkbox" name="informationtype" label={t`Rapporter`} value="kmk,rpt,rdv" />
							</fieldset>
						</Grid.Item>
					</Grid>
					<FieldValidationProvider id="cision-form-name">
						<TextField id="cision-form-name" className={styles.textField} title={t`Namn`} type="text" name="name" />
					</FieldValidationProvider>
					<FieldValidationProvider id="cision-form-mail">
						<TextField
							id="cision-form-email"
							className={styles.textField}
							required
							type="email"
							name="email"
							title={t`E-post`}
							autoComplete="email"
							validators={validateEmailField}
						/>
					</FieldValidationProvider>
				</CaptchaForm>
			</FormValidationProvider>
		</div>
	);
};
