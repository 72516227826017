import { LinkButton } from '@/bloks/Button/LinkButton';
import { ProductCatalogItemStoryblok, ProductCatalogPackageItemStoryblok } from '@/components';
import { ISbStoryData } from '@/types/storyblok';
import { SBPackageProduct, SBProduct } from '../types/product/types';

export const buildSBProduct = (
	product: ISbStoryData<ProductCatalogItemStoryblok | ProductCatalogPackageItemStoryblok>,
): SBProduct => ({
	name: product.content?.name,
	description: product.content?.description,
	additionalInfo: product.content?.additionalInfo,
	linkButtons: product.content?.linkButtons?.map((btn) => ({ ...LinkButton.blokProps({ blok: btn }) })),
	readMoreButton: product.content?.readMoreButton?.map((btn) => ({ ...LinkButton.blokProps({ blok: btn }) })),
	hideFrom: product.content?.hideFrom,
	slug: product.slug,
	price: product.content?.price,
	priceUnit: product.content?.priceUnit,
	priceInterval: product.content?.priceInterval,
	component: product.content?.component,
	customPriceText: product.content?.customPriceText,
});

export const buildSBPackageProduct = (product: ISbStoryData<ProductCatalogPackageItemStoryblok>): SBPackageProduct => ({
	name: product.content?.name,
	description: product.content?.description ?? '',
	additionalInfo: product.content?.additionalInfo,
	linkButtons: product.content?.linkButtons?.map((btn) => ({ ...LinkButton.blokProps({ blok: btn }) })),
	readMoreButton: product.content?.readMoreButton?.map((btn) => ({ ...LinkButton.blokProps({ blok: btn }) })),
	hideFrom: product.content?.hideFrom,
	products: product.content?.products,
	slug: product.slug,
	price: product.content?.price,
	priceUnit: product.content?.priceUnit,
	priceInterval: product.content?.priceInterval,
	component: product.content?.component,
	customPriceText: product.content?.customPriceText,
});
