import { PageLayout } from '@/atoms';
import DynamicComponent from '@/bloks/DynamicComponent';
import { CardFiveGroupStoryblok, NavigationPageBlogStoryblok } from '@/components';
import { ArticleGrid } from '@/metabloks';
import { ISbStoryData } from '@/types/storyblok';

interface Props {
	blok: NavigationPageBlogStoryblok;
	meta: Record<string, any>;
}

/**
 *
 * Used as a navigation page for blog posts.
 *
 * Renders ArticleGrid with serverside data from blogposts.
 *
 * Data for ArticleGrid will only be fetched on:
 * - /blogg
 * - /developer/blog
 *
 */
export function NavigationPageBlog({ blok, meta }: Props) {
	const contact = blok?.contact as unknown as ISbStoryData<CardFiveGroupStoryblok>;
	const { header, title, intro } = blok;
	return (
		<PageLayout exits={contact} meta={meta}>
			{header?.map((blok) => <DynamicComponent blok={blok} key={blok._uid} />)}
			<ArticleGrid title={title} intro={intro} titleAsH1={!header?.length} meta={meta} />
		</PageLayout>
	);
}
