import React, { useState } from 'react';
import { Radiobutton, RadiobuttonProps } from './Radiobutton';

interface Props {
	options: RadiobuttonProps[];
	name?: string;
	required?: boolean;
}
/**
 *
 * @param options - Array of radiobutton data
 */
export const RadiobuttonGroup: React.FC<Props> = ({ options, required, name = 'radiobuttons' }) => {
	const initialActiveButton = options.find((option) => option.checked);
	const [activeValue, setActiveValue] = useState(initialActiveButton?.value ?? null);

	return (
		<>
			{options?.map((radiobutton, index) => (
				<Radiobutton
					key={index}
					className={radiobutton.className}
					testID={radiobutton.testID}
					name={radiobutton.name ?? name}
					value={radiobutton.value}
					label={radiobutton.label}
					id={radiobutton.id}
					checked={activeValue === radiobutton.value ? true : undefined}
					onChange={(event) => {
						setActiveValue(event.target.value);
						if (radiobutton.onChange) radiobutton.onChange(event);
					}}
					tooltip={radiobutton.tooltip}
					required={required}
				/>
			))}
		</>
	);
};
