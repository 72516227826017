import { Icon, Text } from '@/atoms';
import { cls } from '@/utils';
import React from 'react';
import { t } from 'ttag';
import styles from './SectionComparisonTable.module.scss';

interface Props {
	availableOn: string[];
}

export const Availability: React.FC<Props> = ({ availableOn }) => {
	const isDesktop = availableOn.includes('desktop');
	const isMobile = availableOn.includes('mobile');

	return (
		<div className={styles.availabiltyContainer}>
			<div className={cls(styles.availabiltyContent, isDesktop && styles.greenBackground)}>
				<Icon
					name={isDesktop ? 'circle-check' : 'circle-xmark'}
					color={isDesktop ? '--primary-green' : '--danger-color'}
				/>
				<Icon name="laptop" color={isMobile ? '--read-only' : undefined} className={styles.marginLeft} />
				<Text size="small" className={cls(styles.marginLeft, !isDesktop && styles.disabledColor)} marginBottom="none">
					{t`Dator`}
				</Text>
			</div>
			<div className={cls(styles.availabiltyContent, isMobile && styles.greenBackground)}>
				<Icon
					name={isMobile ? 'circle-check' : 'circle-xmark'}
					color={isMobile ? '--primary-green' : '--danger-color'}
				/>
				<Icon name="mobile" color={isDesktop ? '--read-only' : undefined} className={styles.marginLeft} />
				<Text size="small" className={cls(styles.marginLeft, !isMobile && styles.disabledColor)} marginBottom="none">
					{t`Mobilapp`}
				</Text>
			</div>
		</div>
	);
};
