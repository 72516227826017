import { ConversionPageStoryblok } from '@/components';
import { editableBlok } from '@/utils/storyblok';
import DynamicComponent from '../DynamicComponent';

interface Props {
	blok: ConversionPageStoryblok;
}

export const ConversionPage = ({ blok }: Props) => {
	const sections = [...(blok.header || []), ...(blok.sections || [])];
	return (
		<main {...editableBlok(blok)}>
			{sections?.map((blok, index) => <DynamicComponent blok={blok} key={blok._uid} meta={{ zone: index + 1 }} />)}
			{blok?.zone1?.map((blok) => <DynamicComponent blok={blok} key={blok._uid} meta={{ zone: 1 }} />)}
			{blok?.zone2?.map((blok) => <DynamicComponent blok={blok} key={blok._uid} meta={{ zone: 2 }} />)}
			{blok?.zone3?.map((blok) => (
				<DynamicComponent
					blok={blok}
					key={blok._uid}
					meta={{ parentHasPadding: true, containImageSize: true, padding: 'no-padding', zone: 3 }}
				/>
			))}
			{blok?.zone4?.map((blok) => <DynamicComponent blok={blok} key={blok._uid} meta={{ zone: 4 }} />)}
			{blok?.zone5?.map((blok) => {
				return <DynamicComponent key={blok._uid} blok={blok} meta={{ zone: 5 }} />;
			})}
		</main>
	);
};
