import { SelectOption } from '@/atoms';
import React, { ReactNode, createContext, useContext, useState } from 'react';

interface SearchContextType {
	searchResult: any;
	setSearchResult: React.Dispatch<React.SetStateAction<any>>;
	searchValue: string;
	setSearchValue: React.Dispatch<React.SetStateAction<string>>;
	currentPage: number;
	setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
	sortBy: string;
	setSortBy: React.Dispatch<React.SetStateAction<string>>;
	sortOrder: string;
	setSortOrder: React.Dispatch<React.SetStateAction<string>>;
	selectedCategory: SelectOption;
	setCategory: React.Dispatch<React.SetStateAction<SelectOption>>;
	selectedCompany: SelectOption;
	setCompanyName: React.Dispatch<React.SetStateAction<SelectOption>>;
	categoryOptions: SelectOption[];
	setCategoryOptions: React.Dispatch<React.SetStateAction<SelectOption[]>>;
	companyNameOptions: SelectOption[];
	setCompanyNameOptions: React.Dispatch<React.SetStateAction<SelectOption[]>>;
	hasMoreHits: Boolean;
	setHasMoreHits: React.Dispatch<React.SetStateAction<Boolean>>;
}

const SearchIntegrationsContext = createContext<SearchContextType | undefined>(undefined);

export const SearchIntegationsProvider = ({ children }: { children: ReactNode }) => {
	const [searchResult, setSearchResult] = useState<any>({ hits: [] });
	const [searchValue, setSearchValue] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [sortBy, setSortBy] = useState('relevance');
	const [sortOrder, setSortOrder] = useState('asc');
	const [selectedCategory, setCategory] = useState({ id: '', value: '', label: '' });
	const [selectedCompany, setCompanyName] = useState({ id: '', value: '', label: '' });
	const [categoryOptions, setCategoryOptions] = useState<SelectOption[]>([]);
	const [companyNameOptions, setCompanyNameOptions] = useState<SelectOption[]>([]);
	const [hasMoreHits, setHasMoreHits] = useState<Boolean>(true);

	return (
		<SearchIntegrationsContext.Provider
			value={{
				searchResult,
				setSearchResult,
				searchValue,
				setSearchValue,
				currentPage,
				setCurrentPage,
				sortBy,
				setSortBy,
				sortOrder,
				setSortOrder,
				selectedCategory,
				setCategory,
				selectedCompany,
				setCompanyName,
				categoryOptions,
				setCategoryOptions,
				companyNameOptions,
				setCompanyNameOptions,
				hasMoreHits,
				setHasMoreHits,
			}}
		>
			{children}
		</SearchIntegrationsContext.Provider>
	);
};

export const useSearchIntegrations = () => {
	const context = useContext(SearchIntegrationsContext);

	if (context === undefined) {
		throw new Error('useSearchIntegrations must be used within a SearchProvider');
	}
	return context;
};
