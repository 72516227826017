import { Button, Grid, Heading, Icon, Section, Text } from '@/atoms';
import { CardFiveGroupStoryblok, JobAdvertisementPageStoryblok } from '@/components';
import { useSingleJob, useSingleJobLocations } from '@/contexts/teamtailor/TeamtailorProvider';
import { CONTACT_PERSON_FIELD_ID, JobAdvertisement } from '@/contexts/teamtailor/types';
import { ISbStoryData } from '@/types/storyblok';
import { renderHtmlAsRichtext } from '@/utils/transformHtml';
import { t } from 'ttag';
import { LinkButton, SocMeLinkGroup } from '../Button';
import { SocMeLinkProps } from '../Button/SocMeLink/SocMeLink';
import DynamicComponent from '../DynamicComponent';
import { HeaderImage } from '../Header';
import styles from './JobAdvertisementPage.module.scss';

interface Props {
	blok: JobAdvertisementPageStoryblok;
}

export const JobAdvertisementPage: React.FC<Props> = ({ blok }) => {
	const { job } = useSingleJob();
	const jobLocations = useSingleJobLocations();
	const {
		id,
		body,
		title,
		picture,
		applyButtonText,
		recruiterEmail,
		careersiteJobApplyUrl,
		pitch,
		customFields,
	}: JobAdvertisement = job;
	const contact = blok?.contact as unknown as ISbStoryData<CardFiveGroupStoryblok>;

	const iconButtons: SocMeLinkProps[] = [
		{
			linkProps: {
				href: 'https://www.facebook.com/Fortnoxab/',
			},
			icon: 'facebook-f',
		},
		{
			linkProps: {
				href: 'https://twitter.com/fortnoxab',
			},
			icon: 'twitter',
		},
		{
			linkProps: {
				href: 'https://www.linkedin.com/company/fortnox/',
			},
			icon: 'linkedin-in',
		},
	];

	const copyUrlToClipboard = async () => {
		const url = window.location.href;
		if ('clipboard' in navigator) {
			await navigator.clipboard.writeText(url);
		} else {
			document.execCommand('copy', true, url);
		}
	};

	return (
		<>
			<HeaderImage
				label={t`Ledig tjänst - ansök idag`}
				h1Part1=""
				h1Part2={title}
				shortText={pitch}
				image={{
					id: parseInt(id),
					filename: picture,
					name: title,
				}}
				imageLayout="right"
			/>
			<Section>
				<Grid columns={{ base: '1fr', lg: '6fr 3fr' }} colGap="3xl" className={styles.container}>
					<Grid.Item>
						<div className={styles.body}>{body && renderHtmlAsRichtext(body)}</div>
						{applyButtonText && careersiteJobApplyUrl && (
							<LinkButton href={careersiteJobApplyUrl} variant="filled">
								{applyButtonText}
							</LinkButton>
						)}
					</Grid.Item>
					<Grid.Item>
						<div className={styles.cardContainer}>
							<div className={styles.infoCard}>
								<Heading as="h2" size="h5" data-testid="job-advertisement-heading-h5" title={t`Bra att veta`} />
								{jobLocations && (
									<Text>
										<b>{t`Arbetsort: `}</b>
										{jobLocations?.map((location, index: number) => {
											if (index + 1 < jobLocations?.length) {
												return location?.concat(', ');
											}

											return location;
										})}
									</Text>
								)}
								{customFields?.map((field) => {
									const isContactPersonField = field?.id === CONTACT_PERSON_FIELD_ID;
									return (
										<Text key={field?.id}>
											<b>{`${field?.name}: `}</b> {field?.value}{' '}
											{recruiterEmail && isContactPersonField && (
												<a href={`mailto:${recruiterEmail}`}>{recruiterEmail}</a>
											)}
										</Text>
									);
								})}
								<div className={styles.iconContainer}>
									<SocMeLinkGroup iconColor="--text-dark" icons={iconButtons} />
									<div className={styles.mailIcon}>
										<a href={`mailto:${recruiterEmail}`}>
											<Icon className={styles.iconBtn} name="envelope" color="--text-dark" size="2x" />
										</a>
									</div>
								</div>
							</div>
							{applyButtonText && careersiteJobApplyUrl && (
								<LinkButton href={careersiteJobApplyUrl} className={styles.infoBtn} variant="filled">
									{applyButtonText}
								</LinkButton>
							)}
							<Button className={styles.infoBtn} onClick={copyUrlToClipboard}>{t`Kopiera länken`}</Button>
						</div>
					</Grid.Item>
				</Grid>
			</Section>

			{contact && <DynamicComponent blok={contact.content} />}
		</>
	);
};
