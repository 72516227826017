import { Grid, Heading, Text } from '@/atoms';
import { StoryColumnsStoryblok } from '@/components';
import styles from './StoryColumns.module.scss';

interface Props {
	blok: StoryColumnsStoryblok;
}

/**
 * Columns component.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=1600%3A20915
 */
export const StoryColumns: React.FC<Props> = ({ blok }) => {
	const { titleColumn1, titleColumn2, textColumn1, textColumn2 } = blok;

	return (
		<Grid className={styles.container} columns={{ base: 1, lg: 2 }} colGap="2xl">
			<Grid.Item className={styles.column}>
				{titleColumn1 && <Heading as="div" size="h5" title={titleColumn1} />}
				{textColumn1 && <Text>{textColumn1}</Text>}
			</Grid.Item>
			<Grid.Item className={styles.column}>
				{titleColumn2 && <Heading as="div" size="h5" title={titleColumn2} />}
				{textColumn2 && <Text>{textColumn2}</Text>}
			</Grid.Item>
		</Grid>
	);
};
