import { CardLeadsStoryblok, UpsalesFormStoryblok } from '@/components';
import { ISbStoryData } from '@/types/storyblok';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { useState } from 'react';
import { LinkButton } from '../Button';
import DynamicComponent from '../DynamicComponent';
import { UpsalesForm } from '../Form/UpsalesForm/UpsalesForm';
import common from './CardContact.module.scss';
import styles from './CardLeads.module.scss';

interface Props {
	blok: CardLeadsStoryblok;
	meta?: { zone?: number | undefined };
}

/**
 * Card with form to collect leads to upsales
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=94%3A3501
 *
 */
export const CardLeads: React.FC<Props> = ({ blok, meta }) => {
	const { header, theme, link } = blok;
	const form = blok?.form as unknown as ISbStoryData<UpsalesFormStoryblok>;
	const [showText, setShowText] = useState(true);

	return (
		<div className={styles.card}>
			<div
				{...editableBlok(blok)}
				className={cls(common.container, common[`theme--${theme?.[0]?.theme}`])}
				data-testid="card-leads-container"
			>
				{showText &&
					header &&
					header.map((head) => {
						return <DynamicComponent key={head._uid} blok={head} />;
					})}
				{form && (
					<UpsalesForm
						{...UpsalesForm.blokProps({
							story: form,
							meta: {
								...meta,
								theme: theme?.[0]?.theme,
								layout: blok?.formLayout,
								afterSubmit: () => setShowText(false),
							},
						})}
					/>
				)}
				{link && link?.map((button) => <LinkButton key={button._uid} {...LinkButton.blokProps({ blok: button })} />)}
			</div>
		</div>
	);
};
