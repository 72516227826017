import { Button, Icon } from '@/atoms';
import { useSearchV2 } from '@/contexts/search/AddSearchProviderV2';
import { hostnameWithoutSubdomain } from '@/utils';
import { Hit } from 'addsearch-js-client';
import { useKeyPress } from 'hooks/useKeyPress';
import classNames from 'classnames';
import { t } from 'ttag';
import styles from './SearchWidgetV2.module.scss';
import { SearchField } from './components/SearchField';
import { SearchResultItem } from './components/SearchResultItem';

const hostname = global.window?.location?.hostname;
const domain = hostnameWithoutSubdomain(hostname);

export const SearchWidgetV2: React.FC = () => {
	const { searchTerm, addSearchClient, search, searchResult, isSearchOpen, setSearchOpen, animation, setAnimation } =
		useSearchV2();
	const isProdEnvironment = domain === 'fortnox.se';

	useKeyPress('Escape', undefined, () => {
		setSearchOpen(false);
		setAnimation(null);
	});

	useKeyPress('Enter', undefined, () => {
		search();
	});

	const handleClick = (hit: Hit, index: number) => {
		if (isProdEnvironment && addSearchClient)
			addSearchClient.sendStatsEvent('click', searchTerm, { documentId: hit.id, position: index });
	};

	const closeBtn = (
		<div className={styles.closeBtnWrapper}>
			<Button
				variant="link"
				onClick={() => {
					setSearchOpen(false);
					setAnimation('slideOut');
				}}
				type="button"
			>
				<div className={styles.closeBtnInner}>
					<Icon name="xmark" className={styles.closeIcon} />
					<span>{t`Till menyn`}</span>
				</div>
			</Button>
		</div>
	);

	return (
		<div
			className={classNames(
				styles.container,
				isSearchOpen && !animation && styles.open,
				animation === 'slideIn' && styles.slideIn,
				animation === 'slideOut' && styles.slideOut,
			)}
			role="search"
		>
			<div className={styles.mobile}>{closeBtn}</div>
			<SearchField className={styles.mobile} />
			<div className={styles.desktop}>{closeBtn}</div>

			<div className={styles.searchResult}>
				{searchResult?.map((hit: Hit, index: number) => (
					<SearchResultItem key={hit.id} hit={hit} onClick={() => handleClick(hit, index + 1)} />
				))}
			</div>
		</div>
	);
};
