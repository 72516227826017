import { Heading, Icon, Text } from '@/atoms';
import { TestimonialStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import styles from './Testimonial.module.scss';

/**
 * Testimonial comopnent
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=158%3A6693
 */

interface Props {
	blok: TestimonialStoryblok;
	meta?: {
		testimonialCount?: number;
	};
}

export const Testimonial: React.FC<Props> = ({ blok, meta = {} }) => {
	return (
		<div {...editableBlok(blok)} className={cls(styles.container, styles[`testimonial--${meta.testimonialCount}`])}>
			{meta.testimonialCount === 1 && blok?.quote ? (
				<Heading as="div" size="h2" className={styles.quote} marginBottom="xl" title={blok?.quote} />
			) : (
				<Text size="ingress">{blok?.quote}</Text>
			)}
			<div>
				{meta.testimonialCount === 1 ? (
					<Text className={styles.singleDetails}>
						{blok.name}, {blok.jobTitle}
					</Text>
				) : (
					<div className={styles.details}>
						<Icon name="user" size="2x" className={styles.icon} />
						<div>
							{blok?.name && (
								<Heading as="div" size="h5" title={blok?.name} className={styles.name} marginBottom="sm" />
							)}
							<span>{blok.jobTitle}</span>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
