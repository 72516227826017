import React, { createContext, useContext } from 'react';
import { WordPost } from './types';

export const useFilteredWordPosts = (searchValue: string) => {
	const { posts } = useContext(Context);

	if (searchValue === '') {
		return posts;
	}

	return (
		posts?.filter((post) => {
			const matchedBySearchValue = post?.title?.toLowerCase()?.includes(searchValue.toLowerCase());
			if (matchedBySearchValue || !searchValue) {
				return post;
			}
		}) || []
	);
};

interface ContextProps {
	posts?: WordPost[] | [];
}

const Context = createContext<ContextProps>({
	posts: [],
});

interface Props {
	children: React.ReactNode;
	posts?: WordPost[];
}

export const WordListProvider: React.FC<Props> = ({ children, posts }) => {
	const value = {
		posts,
	};

	return <Context.Provider value={value}>{children}</Context.Provider>;
};
