import { BrandLine, ContainerFluid, Grid, Heading, Section, ShowMoreButton, Text } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { StoryPeopleGroupStoryblok } from '@/components';
import { ImageAsset } from '@/metabloks';
import { cls } from '@/utils';
import { editableBlok, normalizeFullSlug } from '@/utils/storyblok';
import React, { useState } from 'react';
import { t } from 'ttag';
import styles from './StoryPeopleGroup.module.scss';

/**
 * Group that renders all posts in FortnoxFolk.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=2926%3A45029
 */

interface Props {
	blok: StoryPeopleGroupStoryblok;
}
const renderContent = (items: any) => {
	return (
		<>
			{items?.map((item: any, index: number) => {
				return (
					<Grid
						key={index}
						{...editableBlok(item)}
						columns={{ base: 1, lg: 2 }}
						className={styles.container}
						colGap="3xl"
					>
						{item.content?.cardImage?.filename && (
							<Grid.Item>
								<div className={styles.image}>
									<ImageAsset absolute asset={item.content?.cardImage} objectFit="cover" fill filters="smart" />
								</div>
							</Grid.Item>
						)}
						<Grid.Item className={cls(styles.card, index % 2 === 0 ? styles.right : styles.left)}>
							<BrandLine />
							<Heading
								as="div"
								size="h5"
								marginBottom="xl"
								title={item.content?.title}
								data-testid="story-people-group-header-h5"
							/>
							<Text className={styles.text} data-testid="story-people-group-text">
								{item.content?.longText}
							</Text>
							<LinkButton className={styles.linkButton} href={normalizeFullSlug(item?.full_slug)!} variant="text">
								{t`Läs hela artikeln`}
							</LinkButton>
						</Grid.Item>
					</Grid>
				);
			})}
		</>
	);
};

export const StoryPeopleGroup: React.FC<Props> = ({ blok }) => {
	const [showMore, setShowMore] = useState(false);

	const firstItems = blok.items?.slice(0, 4);
	const remainingItems = blok.items?.slice(4);

	return (
		<ContainerFluid>
			<Section paddingX={{ base: 'md', md: '5xl' }} paddingY={{ base: 'xl' }}>
				{renderContent(firstItems)}
				{showMore && renderContent(remainingItems)}
				<ShowMoreButton onClick={() => setShowMore(!showMore)} icon={showMore ? 'chevron-up' : 'chevron-down'}>
					{showMore ? t`Visa färre` : t`Ladda fler`}
				</ShowMoreButton>
			</Section>
		</ContainerFluid>
	);
};
