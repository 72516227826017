import { t } from 'ttag';
import { isValidOrgNumber, orgNumberValidator } from './organizationnumber';
import { isEmpty, isNumeric, isValidEmail, isValidPhoneNumber, isValidZipCode } from './validation';

export interface Validator {
	(value: string | null): true | string;
}
export type Validators = Validator | Validator[];

export const validateEmailField: Validator = (value) =>
	isEmpty(value) || isValidEmail(value) || t`Ogiltig e-postadress`;
export const validateNumberField: Validator = (value) =>
	isEmpty(value) || isNumeric(value) || t`Fältet får endast innehålla siffror`;
export const validateZipField: Validator = (value) => isEmpty(value) || isValidZipCode(value) || t`Ogiltigt postnummer`;
export const validateOrgNumberField: Validator = (value) =>
	isEmpty(value) || isValidOrgNumber(value) || orgNumberValidator(value);
export const validatePhoneField: Validator = (value) =>
	isEmpty(value) || isValidPhoneNumber(value) || t`Ogiltigt telefonnummer`;
export const validateRequiredField: Validator = (value) => !isEmpty(value) || t`Fältet är obligatoriskt`;
