import { Heading, Section, Text } from '@/atoms';
import { CisionModuleStoryblok } from '@/components';
import { useServerPageConfig } from '@/contexts/config';
import { Env } from '@/server/config/types';
import { editableBlok } from '@/utils/storyblok';
import React, { CSSProperties, useRef } from 'react';
import styles from './CisionModule.module.scss';

interface Props {
	blok: CisionModuleStoryblok;
}

const useType = (type: string) => {
	switch (type) {
		case 'ownership':
			return { path: 'ownership.html', elementHeight: '1540px' };
		case 'sharegraph':
			return { path: 'sharegraph.html', elementHeight: '1200px' };
		case 'insider':
			return { path: 'insiders.html', elementHeight: '50vh' };
		default:
			return { path: null, elementHeight: null };
	}
};

const useBaseUrl = (env: Env) => {
	switch (env) {
		case Env.Prod:
			return 'https://cision.fortnox.se';
		case Env.Acce:
			return 'https://acce-cision.fortnox.se';
		default:
			return 'https://dev-cision.fnox.se';
	}
};

/**
 * Cison IR modules
 */
export const CisionModule: React.FC<Props> = ({ blok }) => {
	const { title, description, type } = blok;
	const { env } = useServerPageConfig();
	const iFrameRef = useRef<HTMLIFrameElement>(null);
	const moduleData = useType(type);

	const baseUrl = useBaseUrl(env);

	const style = {
		'--height': `${moduleData.elementHeight}`,
	} as CSSProperties;

	return (
		<Section {...editableBlok(blok)}>
			<div className={styles.textWrapper}>
				{title && <Heading as="h2" title={title} data-testid="cision-header-h2" />}
				{description && (
					<Text>
						<strong data-testid="cision-content">{description}</strong>
					</Text>
				)}
			</div>
			<iframe
				ref={iFrameRef}
				title="Fortnox financial data"
				loading="lazy"
				style={style}
				className={styles.iframe}
				src={`${baseUrl}/${moduleData.path}`}
				sandbox="allow-scripts allow-popups allow-modals allow-forms allow-downloads allow-same-origin"
			/>
		</Section>
	);
};
