import { ContainerFluid, Grid, GridProps, Section } from '@/atoms';
import { CtaTwoGroupStoryblok, ThemeStoryblok } from '@/components';
import { editableBlok } from '@/utils/storyblok';
import DynamicComponent from '../DynamicComponent';

interface Props {
	blok: CtaTwoGroupStoryblok;
	meta?: {
		theme?: ThemeStoryblok['theme'];
	};
}

const useGridColumns = (itemCount: number) => {
	const columns: GridProps['columns'] = { base: 1 };

	if (itemCount % 4 === 0) {
		columns.xl = 4;
	}

	if (itemCount % 3 === 0) {
		columns.xl = 3;
	}

	columns.md = 2;

	return columns;
};

/**
 * @deprecated Will be replaced by CardMediumGroup
 */
export const CtaTwoGroup: React.FC<Props> = ({ blok, meta = {} }) => {
	const theme = meta?.theme;
	const columns = useGridColumns(blok.cta?.length ?? 0);
	return (
		<ContainerFluid theme={theme}>
			<Section paddingY={{ base: 'none' }}>
				<Grid columns={columns} {...editableBlok(blok)} colGap="2xl" rowGap="2xl">
					{blok.cta?.map((item: any) => {
						return (
							<Grid.Item key={item._uid}>
								<DynamicComponent blok={item} meta={{ theme }} />
							</Grid.Item>
						);
					})}
				</Grid>
			</Section>
		</ContainerFluid>
	);
};
