import { Button, Card, Carousel, Heading, Icon, Image, Text } from '@/atoms';
import { Accountant } from '@/contexts/accountants/types';
import { useServerPageConfig } from '@/contexts/config';
import { cls } from '@/utils';
import React, { useState } from 'react';
import { t } from 'ttag';
import styles from './AccountantCard.module.scss';

interface Props {
	accountant: Accountant;
}
interface LogoProps {
	accountant: Accountant;
}

const urlWithProtocol = (url?: string) => {
	if (!url) {
		return null;
	}
	return url.indexOf('http://') == 0 || url.indexOf('https://') == 0
		? url.toLowerCase()
		: `https://${url.toLowerCase()}`;
};

export const Logo: React.FC<LogoProps> = ({ accountant }) => {
	const [exists, setExists] = useState(accountant.logoExists);
	const path = `/api/digital-sales-bureau/searchable-bureaus-v1/logo/${accountant.id}`;
	const { appsHost: host } = useServerPageConfig();

	return exists ? (
		<>
			<Image
				src={`${host}${path}`}
				alt={''}
				className={styles.logo}
				onError={() => setExists(false)}
				width={45}
				height={45}
				layout="responsive"
				objectFit="contain"
			/>
			<div className={styles.margin} />
		</>
	) : (
		<></>
	);
};

export const AccountantCard: React.FC<Props> = ({ accountant }) => {
	const id = `${accountant.id}_${accountant.companyName}_${accountant.city}`;
	const addressRow = accountant.address ? `${accountant.address}, ${accountant.city}` : accountant.city;
	const homepageUrl = urlWithProtocol(accountant.homepage);
	const homepage = homepageUrl && homepageUrl.replace(/^(http|https):\/\/www\./, '');

	const mapsUrl = () => {
		const address = accountant.address ? `${accountant.address}, ${accountant.city}` : `${accountant.city}`;
		const searchParams = new URLSearchParams();
		searchParams.set('query', `${accountant.companyName}, ${address}`);
		searchParams.set('api', '1');
		return `https://www.google.se/maps/search/?${searchParams}`;
	};

	const [selectedIndex, setSelectedIndex] = useState(0);
	const [showContact, setShowContact] = useState(false);
	const toggleShowContact = () => {
		if (showContact) {
			setSelectedIndex(0);
			setShowContact(false);
		} else {
			setSelectedIndex(1);
			setShowContact(true);
		}
	};

	const descriptionContainer = (
		<div className={cls(styles.descriptionContainer, showContact && styles.hidden)}>
			{accountant.description?.split('\n')?.map((descriptionRow, index) => {
				return (
					<Text key={index} className={styles.description} marginBottom="none">
						{descriptionRow}
					</Text>
				);
			})}
		</div>
	);

	const contactContainer = (
		<div className={cls(styles.contactContainer, !showContact && styles.hidden)}>
			<div className={styles.address}>
				<div className={styles.addressRow}>{addressRow}</div>
				<div className={styles.addressRow}>
					<a href={mapsUrl()} title={t`Se på karta`} rel="noopener noreferrer" target="_blank" id={`${id}_map`}>
						{t`Se på karta`}
						<Icon className={cls(styles.icon, styles.mapIcon)} name="external-link" size="1x" />
					</a>
				</div>
			</div>
			<ul className={styles.contact}>
				<li className={styles.listItem}>
					<span className={styles.iconContainer}>
						<Icon className={styles.icon} name="phone" size="1x" />
					</span>
					<span className={styles.text}>
						{accountant.phone ? (
							<a href={`tel:${accountant.phone}`} id={`${id}_phone`}>
								{accountant.phone}
							</a>
						) : (
							'-'
						)}
					</span>
				</li>

				<li className={styles.listItem}>
					<span className={styles.iconContainer}>
						<Icon className={styles.icon} name="envelope" size="1x" />
					</span>
					<span className={styles.text}>
						{accountant.email ? (
							<a
								href={`mailto:${accountant.email}`}
								title={accountant.email}
								rel="noopener noreferrer"
								target="_blank"
								id={`${id}_email`}
							>
								{accountant.email}
							</a>
						) : (
							'-'
						)}
					</span>
				</li>
				<li className={styles.listItem}>
					<span className={styles.iconContainer}>
						<Icon className={styles.icon} name="display" size="1x" />
					</span>
					<span className={styles.text}>
						{homepageUrl ? (
							<a href={homepageUrl} title={homepageUrl} rel="noopener noreferrer" target="_blank" id={`${id}_homepage`}>
								{homepage}
							</a>
						) : (
							'-'
						)}
					</span>
				</li>
			</ul>
		</div>
	);

	const header = (
		<header className={styles.header}>
			<div className={styles.logoContainer}>
				<Logo accountant={accountant} />
				<Heading marginBottom="none" title={accountant.companyName} as="div" size="h5" />
			</div>
		</header>
	);

	const footer = (
		<div className={styles.footer}>
			<Button onClick={toggleShowContact} variant="text" id={`${id}_showDescription`} className={styles.button}>
				{showContact ? t`Visa beskrivning` : t`Visa kontaktuppgifter`}
			</Button>
		</div>
	);

	return (
		<Card className={styles.container}>
			{header}
			<Carousel selectedIndex={selectedIndex} centerMode={false}>
				{[descriptionContainer, contactContainer]}
			</Carousel>
			{footer}
		</Card>
	);
};
