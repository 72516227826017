import { ContainerFluid, Grid, Heading, Section } from '@/atoms';
import { LinkButton } from '@/bloks';
import { HeaderCommon, HeaderCommonProps } from '@/bloks/Other';
import { GlobalLogoTypesStoryblok, SectionLogosSidescrollStoryblok } from '@/components';
import { useGlobalMenu } from '@/contexts/menu/GlobalMenuProvider';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { ImageAsset } from '@/metabloks';
import { getPiwikTrackingSlug } from '@/trackers/piwik';
import { gsap, useGSAP } from '@/utils/gsap';
import { editableBlok } from '@/utils/storyblok';
import { usePiwikPro } from '@piwikpro/next-piwik-pro';
import { usePathname } from 'next/navigation';
import { StoryblokStory } from 'storyblok-generate-ts';
import React, { useEffect, useRef, useState } from 'react';
import cls from 'classnames';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import styles from './SectionLogosSidescroll.module.scss';

interface Props {
	blok: SectionLogosSidescrollStoryblok;
	meta?: { zone?: number };
}

export const SectionLogosSidescroll: React.FC<Props> = ({ blok, meta }) => {
	const { menuVersionV3 } = useGlobalMenu();
	const header = blok.header?.[0] as unknown as HeaderCommonProps;
	const logos = blok.logos as unknown as StoryblokStory<GlobalLogoTypesStoryblok>[];
	const theme = 'darkGreen';
	const container = useRef<HTMLDivElement | null>(null);
	const cardContainer = useRef<HTMLDivElement | null>(null);
	const [navBarHeight, setNavBarHeight] = useState(78);
	const { hasSubMenuV1 } = useSubMenu();
	const cards = blok.logos;
	const isSmallSizeScreen = useMediaQuery('(max-width: 1024px)');
	const [isLoading, setIsLoading] = useState(true);
	const zone = meta?.zone;

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsLoading(false);
		}, 500);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	useEffect(() => {
		setNavBarHeight(Math.floor(document.querySelector('header')?.parentElement?.getBoundingClientRect().height ?? 0));
	}, []);

	useGSAP(
		() => {
			if (cards?.length === 1) {
				return;
			}

			if (!cardContainer.current || !container.current || isLoading) {
				return;
			}

			const offset = hasSubMenuV1 ? navBarHeight + 16 : navBarHeight;
			const contentBlocks = document.querySelectorAll('.scroll-content-block');
			const scrollWidth = Object.values(contentBlocks)
				.slice(isSmallSizeScreen ? 0 : 1)
				.map((block) => block.scrollWidth)
				.reduce((a, b) => a + b, 0);
			const marginWidth = isSmallSizeScreen ? window.innerWidth / 2 : 0;
			const scrollSpeed = window.innerWidth > 768 ? '' : '+=2000';

			gsap.to(cardContainer.current, {
				x: -(scrollWidth - marginWidth),
				duration: logos.length,
				ease: 'none',
				scrollTrigger: {
					trigger: container.current,
					end: scrollSpeed,
					start: `top ${offset}`,
					toggleActions: 'restart pause reverse pause',
					scrub: true,
					pin: true,
				},
			});
		},
		{ scope: container, dependencies: [cards?.length, navBarHeight, isLoading], revertOnUpdate: true },
	);

	const oneGroupOfLogos = logos.length == 1;

	// PIWIK IMPRESSION TRACKING
	const { ContentTracking, DataLayer } = usePiwikPro();
	const pathname = usePathname();
	const slug = getPiwikTrackingSlug(pathname);
	const contentName = `${zone}|SectionLogosSidescroll`;
	const contentPiece = header.titlePart2 ? `${header.titlePart1} ${header.titlePart2}` : header.titlePart1;
	const contentTarget = 'multiple interactions possible';

	function trackContentInteraction(targetText: string) {
		DataLayer.push({
			event: 'contentInteraction',
			contentName: `${slug}|${contentName}`,
			contentPiece,
			contentTarget,
			contentInteraction: 'Click',
			data: { clickElementText: targetText },
		});
		ContentTracking.trackContentInteraction(
			'contentInteraction',
			`${slug}|${contentName}`,
			contentPiece ?? 'no available title',
			contentTarget,
		);
	}

	return (
		<ImpressionTrackingProvider
			value={{
				contentName,
				contentPiece,
				contentTarget,
			}}
		>
			<div ref={container}>
				<ContainerFluid className={cls(styles.container, menuVersionV3 && styles.menuVersionV3)} theme={theme}>
					<Section {...editableBlok(blok)} paddingX={{ base: '2xl' }} paddingY={{ base: 'xl', md: '3xl' }}>
						{header && <HeaderCommon {...header} marginBottom="3xl" layout="center" className={styles.headerCommon} />}
						<div
							ref={cardContainer}
							className={cls(styles.logosContainer, 'scroll-container', oneGroupOfLogos && styles.centerContent)}
						>
							{logos?.map((logos) => {
								return (
									<div key={logos.content._uid} className={cls(styles.contentBlock, 'scroll-content-block')}>
										<div className={styles.title}>
											{logos.content.titlePart1 && (
												<Heading
													as="div"
													size="h4"
													title={logos.content.titlePart1}
													marginBottom={'none'}
													className={styles.titlePart1}
												/>
											)}
											{logos.content.titlePart2 && (
												<>
													&nbsp;
													<Heading
														as="div"
														size="h4"
														title={`- ${logos.content.titlePart2}`}
														marginBottom={'none'}
														className={styles.titlePart2}
													/>
												</>
											)}
										</div>
										<div className={styles.logos}>
											<div className={styles.grid}>
												<Grid columns={{ base: oneGroupOfLogos ? 2 : 3, sm: 3, md: 3 }} colGap={'2xl'} rowGap={'xl'}>
													{logos.content.logoTypes?.map((logoType) => {
														return (
															<Grid.Item className={styles.gridItem} key={logoType._uid}>
																<div className={styles.logo}>
																	<ImageAsset asset={logoType.image} objectFit="contain" priority />
																</div>
															</Grid.Item>
														);
													})}
												</Grid>
											</div>
										</div>
										{logos.content.linkButton?.map((linkButton) => {
											return (
												<div
													key={linkButton._uid}
													className={cls(styles.linkButton, oneGroupOfLogos && styles.linkButtonMobile)}
												>
													<LinkButton
														{...LinkButton.blokProps({ blok: linkButton, meta: { ...meta, size: 'small' } })}
														onClick={() => {
															trackContentInteraction(linkButton.text ?? '');
														}}
													/>
												</div>
											);
										})}
									</div>
								);
							})}
						</div>
					</Section>
				</ContainerFluid>
			</div>
		</ImpressionTrackingProvider>
	);
};
