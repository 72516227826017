import { Tooltip } from '@/atoms';
import { cls } from '@/utils';
import { useMediaQuery } from 'hooks/useMediaQuery';
import React, { ChangeEventHandler, useState } from 'react';
import styles from './Radiobutton.module.scss';

export interface RadiobuttonProps {
	id: string;
	label: string;
	bordered?: boolean;
	className?: string;
	checked?: boolean;
	disabled?: boolean;
	name?: string;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	orientation?: 'vertical' | 'horizontal';
	style?: React.CSSProperties;
	testID?: string;
	value?: string;
	tooltip?: string;
}

type Props = React.InputHTMLAttributes<HTMLInputElement> & RadiobuttonProps;

/**
 * Radiobutton component
 * IMPORTANT - Use with RadiobuttonGroup
 */
export const Radiobutton: React.FC<Props> = ({
	id,
	label,
	bordered,
	children,
	className,
	disabled,
	name,
	onChange,
	checked = false,
	orientation,
	style,
	testID,
	value,
	tooltip,
	...rest
}) => {
	const [isChecked, setIsChecked] = useState(checked);
	const isMobile = useMediaQuery('(max-width: 1023px)');
	return (
		<div className={cls(styles.radiobutton, className)}>
			<input
				type="radio"
				aria-label={label}
				className={styles.input}
				data-testid={testID}
				disabled={disabled}
				id={id}
				name={name}
				onChange={(event) => {
					if (onChange) onChange(event);
					setIsChecked(!isChecked);
				}}
				value={value}
				checked={checked}
				{...rest}
			/>

			<label
				htmlFor={id}
				className={cls(styles.outerContainer, {
					[styles.bordered]: bordered,
					[styles.disabled]: disabled,
					[styles.horizontal]: orientation === 'horizontal',
				})}
				style={style}
			>
				<div className={styles.checkContainer}>
					<div className={styles.checkDot} />
				</div>
				<div className={styles.children}>{children || label}</div>
			</label>

			{tooltip && tooltip.length > 1 && (
				<div className={styles.tooltipWrapper}>
					<Tooltip text={tooltip} position={isMobile ? 'left' : 'right'} theme="darkGreen" />
				</div>
			)}
		</div>
	);
};
