import { useState } from 'react';
import { useEvent } from './useEvent';

enum KeyEvent {
	KeyDown = 'keydown',
	KeyUp = 'keyup',
}
export const useKeyPress = (
	targetKey: string,
	keyDown?: (_: KeyboardEvent) => void | null | undefined,
	keyUp?: (_: KeyboardEvent) => void | null | undefined,
) => {
	const [keyPressed, setKeyPressed] = useState(false);

	useEvent(KeyEvent.KeyDown, (event) => {
		if (event.key === targetKey) {
			setKeyPressed(true);
			keyDown?.(event!);
		}
	});

	useEvent(KeyEvent.KeyUp, (event) => {
		if (event.key === targetKey) {
			setKeyPressed(false);
			keyUp?.(event!);
		}
	});
	return keyPressed;
};
