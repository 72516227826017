import { BrandLine, Grid, Heading } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { StoryPostStoryblok, StoryQuoteGroupStoryblok } from '@/components';
import { ImageAsset } from '@/metabloks';
import { ISbStoryData } from '@/types/storyblok';
import { cls } from '@/utils';
import { editableBlok, normalizeFullSlug } from '@/utils/storyblok';
import { t } from 'ttag';
import styles from './StoryQuoteGroup.module.scss';

/**
 * Quote group component used on StoryPage. Fetches quote or long text from FortnoxFolk.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=2168%3A34870
 */

interface Props {
	blok: StoryQuoteGroupStoryblok;
}

export const StoryQuoteGroup: React.FC<Props> = ({ blok }) => {
	const layout = blok.imagePosition || 'right';

	const items = blok?.items as any as ISbStoryData<StoryPostStoryblok>;
	const item = items.content;

	return (
		<Grid
			{...editableBlok(blok)}
			columns={{ base: 1, lg: 2 }}
			className={cls(styles.container, styles[`layout--${layout}`])}
			colGap="3xl"
			rowGap="md"
		>
			<Grid.Item className={styles.card}>
				<BrandLine />
				{item.title && (
					<Heading as="div" size="h5" marginBottom="3xl" title={item.title} data-testid="story-quote-header-h5" />
				)}
				<span className={styles.quote}>{item?.quote}</span>
				<LinkButton href={normalizeFullSlug(items?.full_slug)} variant="text">
					{t`Läs hela artikeln`}
				</LinkButton>
			</Grid.Item>

			<Grid.Item>
				{item?.cardImage?.filename && (
					<div className={styles.image}>
						<ImageAsset absolute asset={item?.cardImage} objectFit="cover" fill />
					</div>
				)}
			</Grid.Item>
		</Grid>
	);
};
