import { BackgroundImage, BrandStripes, ContainerFluid, Heading, Section, Text } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { renderRichText } from '@/bloks/RichText';
import { HeaderStoryStoryblok, LinkButtonStoryblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import styles from './HeaderStory.module.scss';

interface Props {
	blok: HeaderStoryStoryblok;
	meta?: {
		isStoryPage?: boolean;
	};
}

export const HeaderStory: React.FC<Props> = ({ blok, meta }) => {
	const { hasSubMenuV1 } = useSubMenu();

	const getButtonVariant = (index: number) => {
		if (meta?.isStoryPage) {
			return 'text';
		}
		if (index === 0) {
			return 'filled';
		}
		return 'outlined';
	};

	return (
		<ContainerFluid theme="white" marginBottom="custom" className={cls(blok?.image?.filename && styles.marginBottom)}>
			<Section as="header" paddingY={hasSubMenuV1 ? { base: 'none' } : undefined}>
				<div
					{...editableBlok(blok)}
					className={cls(styles.container, meta?.isStoryPage && styles.storyPage, hasSubMenuV1 && styles.hasSubMenuV1)}
				>
					<BrandStripes className={styles.brandStripes} />

					{blok?.label && <Heading className={styles.label} as="div" size="h5" title={blok.label} marginBottom="xl" />}

					<Heading className={styles.title} as="h1" title={blok.h1Part1} titlePart2={blok.h1Part2} marginBottom="xl" />

					{blok?.shortText && (
						<Text as="div" className={styles.shortText} marginBottom="xl">
							{renderRichText(blok.shortText, { textSize: 'ingress' })}
						</Text>
					)}

					{!!blok?.linkButtons?.length && (
						<div className={styles.linkButtons}>
							{blok.linkButtons?.map((btn: LinkButtonStoryblok, index: number) => (
								<LinkButton key={btn._uid} {...LinkButton.blokProps({ blok: btn })} variant={getButtonVariant(index)} />
							))}
						</div>
					)}
				</div>
			</Section>
			{blok?.image?.filename && (
				<div className={styles.image}>
					<BackgroundImage
						asset={blok?.image}
						objectPosition={meta?.isStoryPage ? 'center center' : 'top center'}
						overlay={false}
						isHeader={true}
					/>
				</div>
			)}
		</ContainerFluid>
	);
};
