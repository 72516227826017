import { ContainerFluid, Heading, Section } from '@/atoms';
import { HeaderCommon } from '@/bloks/Other';
import { TabAccordion, TabTextWithImage, TabUsps, TabVideo } from '@/bloks/Section/SectionTabs';
import { TabProducts } from '@/bloks/Section/SectionTabs/TabProducts';
import {
	SectionTabsStoryblok,
	TabAccordionStoryblok,
	TabProductsStoryblok,
	TabTextWithImageStoryblok,
	TabUspsStoryblok,
	TabVideoStoryblok,
} from '@/components';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { getPiwikTrackingSlug } from '@/trackers/piwik';
import { cls } from '@/utils';
import { handleTabClickEvent } from '@/utils/piwik';
import { editableBlok } from '@/utils/storyblok';
import { usePiwikPro } from '@piwikpro/next-piwik-pro';
import { usePathname } from 'next/navigation';
import React, { useState } from 'react';
import styles from './SectionTabs.module.scss';

interface Props {
	blok: SectionTabsStoryblok & { heading?: string }; // Temporary support for heading until it is replaces in storyblok
	setActiveTab: (index: number) => void;
	meta?: { zone: number };
}

type TabGroup =
	| TabAccordionStoryblok
	| TabProductsStoryblok
	| TabTextWithImageStoryblok
	| TabUspsStoryblok
	| TabVideoStoryblok;

export const SectionTabs: React.FC<Props> = ({ blok, meta }) => {
	const [activeTab, setActiveTab] = useState(0);
	const tabGroup = (blok.tabGroup || []) as unknown as TabGroup[];
	const theme = blok.theme?.[0]?.theme ?? 'white';
	const header = blok?.header?.[0];

	// PIWIK IMPRESSION TRACKING
	const { ContentTracking, DataLayer } = usePiwikPro();
	const pathname = usePathname();
	const slug = getPiwikTrackingSlug(pathname);
	const contentName = `${meta?.zone}|SectionTabs`;
	const contentPiece = header?.titlePart2
		? `${header?.titlePart1} ${header?.titlePart2}`
		: (header?.titlePart1 ?? 'no available title');
	const contentTarget = 'multiple interactions possible';

	function trackContentInteraction(targetText: string) {
		DataLayer.push({
			event: 'contentInteraction',
			contentName: `${slug}|${contentName}`,
			contentPiece,
			contentTarget,
			contentInteraction: 'Click',
			data: { clickElementText: targetText },
		});
		ContentTracking.trackContentInteraction(
			'contentInteraction',
			`${slug}|${contentName}`,
			contentPiece,
			contentTarget,
		);
	}

	return (
		<ImpressionTrackingProvider value={{ contentName, contentPiece, contentTarget }}>
			<ContainerFluid>
				<Section {...editableBlok(blok)} paddingX={{ base: 'none', md: 'xl', lg: '5xl' }} paddingY={{ base: 'none' }}>
					<div className={styles.inner}>
						{blok.heading && !header && (
							<Heading title={blok.heading} as="h2" className={styles.tabHeader} marginBottom="xl" />
						)}
						{header && (
							<div className={styles.header}>
								<HeaderCommon {...HeaderCommon.blokProps({ blok: header })} marginBottom="3xl" />
							</div>
						)}
						<div className={cls(styles.tabNavBar, styles[`theme--${theme}`])}>
							<div className={styles.leftFade} />
							<nav className={styles.tabNavigation}>
								{tabGroup.map((tab, index) => {
									return (
										<div key={index} className={styles.item}>
											<button
												onClick={() => {
													setActiveTab(index);
													handleTabClickEvent({ zone: meta?.zone, buttonText: tab.tabName });
													trackContentInteraction(tab.tabName);
												}}
												className={styles.item}
											>
												{activeTab == index ? <strong>{tab.tabName}</strong> : tab.tabName}
											</button>
											{activeTab == index && <div className={styles.activeIndicator} />}
										</div>
									);
								})}
							</nav>
							<div className={styles.rightFade} />

							<hr className={styles.navigationBorder} />

							<div className={styles.tabPanelContainer}>
								{tabGroup.map((tab, index) => {
									switch (tab.component) {
										case 'TabTextWithImage':
											return <TabTextWithImage key={index} blok={tab} display={activeTab == index} />;
										case 'TabVideo':
											return <TabVideo key={index} blok={tab} display={activeTab == index} />;
										case 'TabAccordion':
											return <TabAccordion key={index} blok={tab} display={activeTab == index} />;
										case 'TabUsps':
											return <TabUsps key={index} blok={tab} display={activeTab == index} />;
										case 'TabProducts':
											return <TabProducts key={index} blok={tab} display={activeTab == index} />;
									}
								})}
							</div>
						</div>
					</div>
				</Section>
			</ContainerFluid>
		</ImpressionTrackingProvider>
	);
};
