import { ContainerFluid, Section } from '@/atoms';
import DynamicComponent from '@/bloks/DynamicComponent';
import { SectionTestimonialV2Storyblok, ThemeV2Storyblok } from '@/components';
import React, { ReactNode } from 'react';

interface BlokProps {
	blok: SectionTestimonialV2Storyblok;
}

const blokProps = ({ blok }: BlokProps): Props => {
	return {
		testimonialGroup: blok?.testimonialGroup?.map((group) => <DynamicComponent key={group._uid} blok={group} />),
		theme: blok.theme[0]?.theme,
		singleTestimonial: blok?.testimonialGroup && blok?.testimonialGroup.length === 1,
	};
};

interface Props {
	testimonialGroup: ReactNode[];
	theme: ThemeV2Storyblok['theme'];
	singleTestimonial: boolean;
}
export function SectionTestimonialV2({ theme, testimonialGroup, singleTestimonial }: Props) {
	return (
		<ContainerFluid theme={theme} backgroundPattern>
			<Section maxWidth={singleTestimonial ? '1440px' : undefined}>{testimonialGroup}</Section>
		</ContainerFluid>
	);
}

SectionTestimonialV2.blokProps = blokProps;
