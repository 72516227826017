import { Icon } from '@/atoms';
import { getTheme } from '@/bloks/Page/NavigationPageEducation/CardEducation/EducationTypeTheme';
import { Item, ItemFilter, filterAnyMatch } from '@/bloks/Page/NavigationPageEducation/EducationFilter/ItemFilter';
import { CardEducationStoryblok } from '@/components';
import { CustomFields, EducationType } from '@/contexts/eduadmin';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import cls from 'classnames';
import { t } from 'ttag';
import styles from './FilterContainer.module.scss';

interface Props {
	educationCards: CardEducationStoryblok[];
	subjects?: CustomFields | null;
	targetGroups?: CustomFields | null;
	educationTypes?: CustomFields | null;
	setFilteredCards: (courses: CardEducationStoryblok[]) => void;
}

enum QueryParams {
	TARGET_GROUP = 'targetGroup',
	SUBJECT = 'subject',
	EDUCATION_TYPE = 'educationType',
	PRICE = 'price',
}

const getDefinedFields = (customFields: CustomFields | null | undefined) => {
	return (
		(customFields?.value.flatMap((field) =>
			field.CustomFieldAlternatives.flatMap((value) => value.CustomFieldAlternativeValue),
		) as string[]) || []
	);
};

export const FilterContainer: React.FC<Props> = ({
	educationCards,
	subjects,
	targetGroups,
	educationTypes,
	setFilteredCards,
}) => {
	const defaultFilter: Item[] = [];
	const [showFilters, setShowFilters] = useState(false);
	const [selectedEducationType, setSelectedEducationType] = useState(defaultFilter);
	const [selectedSubjects, setSelectedSubjects] = useState(defaultFilter);
	const [selectedTargetGroups, setSelectedTargetGroups] = useState(defaultFilter);
	const [selectedPrice, setSelectedPrice] = useState(defaultFilter);

	const educationCardsRef = useRef(educationCards);
	const actualSubjects = educationCardsRef.current?.flatMap((card) => card.subjects) || [];
	const actualTargetGroups = educationCardsRef.current?.flatMap((card) => card.targetGroups) || [];

	const router = useRouter();

	const targetGroupItems = getDefinedFields(targetGroups)
		.filter((value) => actualTargetGroups.includes(value))
		.map((name) => ({
			name: name,
			themeInfo: undefined,
		}));

	const subjectItems = getDefinedFields(subjects)
		.filter((value) => actualSubjects.includes(value))
		.map((name) => ({
			name: name,
			themeInfo: undefined,
		}));

	const educationTypeItems = getDefinedFields(educationTypes).map((name) => ({
		name: name,
		themeInfo: getTheme(name as EducationType),
	}));

	const priceItems = [{ name: 'Kostnadsfri', themeInfo: undefined }];

	const getSelectedItemsFromQuery = (queryParam: string, items: Item[]): Item[] => {
		const value = new URLSearchParams(`?${router.asPath.split('?')[1]}`).get(queryParam) ?? undefined;
		const values = value?.replace(/[^a-zA-Z0-9-_&,; åäöÅÄÖ]/g, '')?.split(';');
		return values ? items.filter((item) => values.some((q) => q.toLowerCase() === item.name.toLowerCase())) : [];
	};

	useEffect(() => {
		setSelectedTargetGroups(getSelectedItemsFromQuery(QueryParams.TARGET_GROUP, targetGroupItems));
		setSelectedSubjects(getSelectedItemsFromQuery(QueryParams.SUBJECT, subjectItems));
		setSelectedEducationType(getSelectedItemsFromQuery(QueryParams.EDUCATION_TYPE, educationTypeItems));
		setSelectedPrice(getSelectedItemsFromQuery(QueryParams.PRICE, priceItems));
	}, [router.asPath]);

	useEffect(() => {
		const filteredCards = educationCardsRef.current.filter((card) => {
			return (
				filterAnyMatch(selectedEducationType, card.educationType || '') &&
				filterAnyMatch(selectedSubjects, (card.subjects || []).join(' ')) &&
				filterAnyMatch(selectedTargetGroups, (card.targetGroups || []).join(' ')) &&
				filterAnyMatch(selectedPrice, card.price === 'Kostnadsfri' ? 'Kostnadsfri' : '-')
			);
		});
		setFilteredCards(filteredCards);
	}, [selectedEducationType, selectedSubjects, selectedTargetGroups, selectedPrice, setFilteredCards]);

	return (
		<div className={styles.filterContainer}>
			<div onClick={() => setShowFilters(!showFilters)} className={styles.filterHeader}>
				{t`Filtrera utbildningar`}
				<Icon name={showFilters ? 'fa-close' : 'fa-filter'} />
			</div>

			<div className={cls(styles.filterContent, { [styles.show]: showFilters })}>
				<ItemFilter
					items={targetGroupItems}
					selectedFilters={selectedTargetGroups}
					queryParam={QueryParams.TARGET_GROUP}
					label={t`Målgrupp`}
				/>
				<ItemFilter
					items={subjectItems}
					selectedFilters={selectedSubjects}
					queryParam={QueryParams.SUBJECT}
					label={t`Ämne`}
				/>
				<ItemFilter
					items={educationTypeItems}
					selectedFilters={selectedEducationType}
					queryParam={QueryParams.EDUCATION_TYPE}
					label={t`Utbildningstyp`}
				/>
				<ItemFilter
					items={priceItems}
					selectedFilters={selectedPrice}
					queryParam={QueryParams.PRICE}
					label={t`Kostnad`}
				/>
			</div>
		</div>
	);
};
