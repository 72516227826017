import { Heading, LinkAnchor, Section, Text, TextField } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { useFilteredWordPosts } from '@/contexts/wordlist/WordListProvider';
import { normalizeFullSlug } from '@/utils/storyblok/normalizeFullSlug';
import React, { useState } from 'react';
import classNames from 'classnames';
import { t } from 'ttag';
import styles from './WordList.module.scss';

export function WordList() {
	const [searchValue, setSearchValue] = useState('');
	const alphabet = 'abcdefghijklmnopqrstuvwxyzåäö'.toUpperCase().split('');
	const filteredWordPosts = useFilteredWordPosts(searchValue);

	const headerLetters = filteredWordPosts
		?.sort((a, b) => (a.title < b.title ? -1 : 0))
		?.map((term) => term.title.charAt(0))
		.filter((firstLetter: string, index: number, arr: string[]) => {
			return arr.indexOf(firstLetter) == index;
		});

	return (
		<>
			<Section paddingY={{ base: '3xl' }}>
				<div className={styles.searchField}>
					<Heading as="div" size="h5" title={t`Sök`} />
					<TextField
						id="wordlist-search"
						type="search"
						bordered
						title={t`Vad vill du veta mer om?`}
						onChange={(e) => setSearchValue(e.target.value)}
					/>
				</div>
				<div className={styles.alphabetContainer}>
					<Heading as="div" size="h5" title={t`Välj en bokstav`} />
					<div className={styles.alphabet}>
						{alphabet.map((char, index) => {
							return (
								<a
									key={index}
									className={styles.link}
									href={headerLetters?.indexOf(char) === -1 ? undefined : `#${char}`}
								>
									{char}
								</a>
							);
						})}
					</div>
				</div>
			</Section>
			<Section data-testid="wordlist-container" paddingX={{ base: 'none', md: '3xl' }} paddingY={{ base: 'none' }}>
				{headerLetters?.map((letter, index) => {
					return (
						<div key={index} className={styles.list} id={letter}>
							<div className={styles.headerLetter}>
								<span className={styles.letter}>{letter}</span>
							</div>
							{filteredWordPosts?.map((post, index) => {
								return (
									post.title.charAt(0) === letter && (
										<div
											key={post.title}
											className={classNames(styles.content, index % 2 == 0 ? styles.itemBeige : styles.itemLightBeige)}
										>
											<LinkAnchor href={normalizeFullSlug(post.slug)!}>
												<Heading
													as="div"
													size="h5"
													title={post.title}
													className={styles.postTitle}
													data-testid="wordlist-header"
													marginBottom="none"
												/>
											</LinkAnchor>
											<Text className={styles.intro} marginBottom="custom">
												{post.intro}
											</Text>

											<div className={styles.linkButton}>
												<LinkButton href={normalizeFullSlug(post.slug)!} variant="filled">
													{t`Läs mer`}
												</LinkButton>
											</div>
										</div>
									)
								);
							})}
						</div>
					);
				})}
			</Section>
		</>
	);
}
