import { ContainerFluid, Grid, Heading, Section, Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { renderRichText } from '@/bloks/RichText';
import { SectionSupportAttractStoryblok } from '@/components';
import { ImageAsset, ImageAssetProps } from '@/metabloks';
import { editableBlok } from '@/utils/storyblok';
import styles from './SectionSupportAttract.module.scss';

interface BlokProps {
	blok: SectionSupportAttractStoryblok;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	title: blok.title,
	text: blok.text,
	profileImage: blok.profileImage,
	profileText: blok.profileText,
	profileTitle: blok.profileTitle,
	image: blok.image,
	_editable: blok._editable,
});

interface Props {
	title: string;
	text: any;
	profileImage: ImageAssetProps;
	profileText: any;
	profileTitle: string;
	image: ImageAssetProps;
	_editable?: string;
}

export const SectionSupportAttract: Blok<Props, BlokProps> = ({
	title,
	text,
	profileImage,
	profileText,
	profileTitle,
	image,
	_editable,
}) => {
	const theme = 'pink';
	return (
		<ContainerFluid theme={theme}>
			<Section {...editableBlok({ _editable })} className={styles.container}>
				<Grid columns={{ base: 1, md: '4fr 1fr 3fr', xl: '4fr 2fr 3fr' }} className={styles.grid} rowGap="md">
					<Grid.Item>
						<Heading as="div" size="h3" title={title} />
						{renderRichText(text)}
					</Grid.Item>
					<Grid.Item></Grid.Item>
					<Grid.Item>
						<div className={styles.profileContent}>
							<div className={styles.profileImage}>
								{profileImage?.filename && (
									<ImageAsset
										objectFit="cover"
										objectPosition="center"
										resize="700x700"
										filters="smart"
										asset={profileImage}
									/>
								)}
							</div>

							{renderRichText(profileText)}
							<Text>
								<b>{profileTitle}</b>
							</Text>
						</div>
					</Grid.Item>
				</Grid>
				<div className={styles.image}>
					{image?.filename && <ImageAsset absolute fill objectFit="contain" objectPosition="center" asset={image} />}
				</div>
			</Section>
		</ContainerFluid>
	);
};

SectionSupportAttract.blokProps = blokProps;
