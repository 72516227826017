import { BackgroundImage, BrandLine, ContainerFluid, Grid, Heading, Section } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { LinkButton, LinkButtonProps } from '@/bloks/Button';
import { renderRichText } from '@/bloks/RichText';
import { AssetStoryblok, HeaderLoanSliderStoryblok, RichtextStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { useState } from 'react';
import styles from './HeaderLoanSlider.module.scss';
import { LoanSlider } from './components';
import { INITIAL_AMOUNT, INITIAL_MONTHS } from './components/utils';

interface BlokProps {
	blok: HeaderLoanSliderStoryblok;
}

interface Props {
	title: string;
	shortText?: RichtextStoryblok;
	backgroundImage: AssetStoryblok;
	loanTitle: string;
	linkButton: LinkButtonProps[];
	layout: 'full' | 'right';
	_editable?: string;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	title: blok.title,
	shortText: blok.shortText,
	backgroundImage: blok.backgroundImage,
	loanTitle: blok.loanTitle,
	linkButton: blok.linkButton.map((btn) => LinkButton.blokProps({ blok: btn })),
	layout: blok.layout ?? 'full',
	_editable: blok._editable,
});

export const HeaderLoanSlider: Blok<Props, BlokProps> = ({
	title,
	shortText,
	backgroundImage,
	loanTitle,
	linkButton,
	layout,
	_editable,
}) => {
	const [amount, setAmount] = useState<number>(INITIAL_AMOUNT);
	const [months, setMonths] = useState<number>(INITIAL_MONTHS);

	const TitleContainer = () => {
		return (
			<div className={styles.titleContainer}>
				{title && (
					<>
						<BrandLine className={styles.brandline} marginBottom="xl" />
						<Heading className={styles.loanText} as="h1" marginBottom="xl" title={title} />
					</>
				)}

				{shortText && <div className={styles.loanText}>{renderRichText(shortText, { textMarginBottom: 'none' })}</div>}
			</div>
		);
	};

	const ButtonContainer = () => {
		return (
			<div className={styles.buttonContainer}>
				<div className={styles.desktop}>
					{linkButton &&
						linkButton.map((btn, index) => {
							if (layout === 'right' && linkButton && index === 0) return null;
							return (
								<LinkButton
									key={`linkbutton-desktop-${index}`}
									className={styles.linkButton}
									variant={index === 0 ? 'filled' : 'outlined'}
									{...btn}
									onClick={() => {
										localStorage.setItem('companyLoan', JSON.stringify({ amountSEK: amount, nrOfMonths: months }));
									}}
								/>
							);
						})}
				</div>
				<div className={styles.mobile}>
					<Grid columns={{ base: 1, md: layout === 'right' ? 1 : linkButton.length }} colGap="xl" rowGap="lg">
						{linkButton &&
							linkButton.map((btn, index) => {
								if (linkButton && index === 0 && layout === 'right') return null;
								return (
									<Grid.Item key={`linkbutton-mobile-${index}`} className={styles.gridItem}>
										<LinkButton
											block={layout === 'full'}
											className={styles.linkButton}
											variant={linkButton.length > 1 && index === 0 ? 'filled' : 'outlined'}
											{...btn}
											onClick={() => {
												localStorage.setItem('companyLoan', JSON.stringify({ amountSEK: amount, nrOfMonths: months }));
											}}
										/>
									</Grid.Item>
								);
							})}
					</Grid>
				</div>
			</div>
		);
	};

	return (
		<ContainerFluid
			className={cls(styles.container, styles[`layout-${layout}`])}
			{...editableBlok({ _editable })}
			theme="darkGreen"
		>
			{backgroundImage && (
				<BackgroundImage
					asset={backgroundImage}
					priority
					zIndex={0}
					className={styles.image}
					isHeader
					overlay
					overlayDirection="center"
				/>
			)}
			<Section
				as="header"
				className={styles.section}
				paddingX={layout === 'right' ? { base: 'xl', lg: 'none' } : undefined}
			>
				<div className={styles.leftColumn}>
					{layout === 'full' && (
						<>
							<TitleContainer />
							<LoanSlider
								className={styles.loanContainer}
								title={loanTitle}
								linkButtons={linkButton}
								amountCallback={setAmount}
								monthCallback={setMonths}
								layout="full"
							/>
							<ButtonContainer />
						</>
					)}
					{layout === 'right' && (
						<>
							<TitleContainer />
							<div className={styles.mobile}>
								<LoanSlider
									className={styles.loanContainer}
									title={loanTitle}
									linkButtons={linkButton}
									amountCallback={setAmount}
									monthCallback={setMonths}
									layout="right"
								/>
							</div>
							<ButtonContainer />
						</>
					)}
				</div>
				{layout === 'right' && (
					<div className={styles.rightColumn}>
						<div className={styles.desktop}>
							<LoanSlider
								className={styles.loanContainer}
								title={loanTitle}
								linkButtons={linkButton}
								amountCallback={setAmount}
								monthCallback={setMonths}
								layout="right"
							/>
						</div>
					</div>
				)}
			</Section>
		</ContainerFluid>
	);
};

HeaderLoanSlider.blokProps = blokProps;
