import { Grid, GridProps } from '@/atoms';
import { CardKeyFigures } from '@/bloks/Card';
import { CardKeyFiguresGroupStoryblok } from '@/components';

interface Props {
	blok: CardKeyFiguresGroupStoryblok;
	className?: string;
	meta?: Record<string, any>;
}

const useGridColumns = (itemCount: number) => {
	const columns: GridProps['columns'] = { base: 1 };

	columns.lg = 3;
	columns.md = 2;

	if (itemCount % 5 === 0) {
		columns.xl = 5;
	}

	if (itemCount % 3 === 0) {
		columns.xl = 3;
	}

	if (itemCount % 2 === 0) {
		columns.xl = 2;
		columns.lg = 2;
	}

	return columns;
};

export function CardKeyFiguresGroup({ blok, meta, className }: Props) {
	const columns = useGridColumns(blok?.cards?.length ?? 0);

	return (
		<Grid className={className} columns={columns} rowGap="xl">
			{blok?.cards?.map((card) => {
				const props = { blok: card, meta };
				return (
					<Grid.Item key={card._uid}>
						<CardKeyFigures {...CardKeyFigures.blokProps(props)} />
					</Grid.Item>
				);
			})}
		</Grid>
	);
}
