import { Text } from '@/atoms';
import { DateTextStoryblok, ThemeStoryblok } from '@/components';
import { cls } from '@/utils';
import styles from './DateText.module.scss';

interface BlokProps {
	blok: DateTextStoryblok;
	meta?: {
		theme?: ThemeStoryblok['theme'];
	};
	className?: string;
}

const blokProps = ({ blok, meta, className }: BlokProps): Props => {
	const props: Props = {
		text: blok.text,
		date: blok.date,
		className,
	};

	if (meta) {
		const { theme } = meta;
		Object.assign(props, { theme });
	}
	return props;
};

interface Props {
	text: string;
	date: string;
	theme?: string;
	className?: string;
}

export function DateText({ text, date, theme, className }: Props) {
	const dateString = date.slice(0, 10);
	return (
		<Text className={cls(className, styles.container, styles[`theme--${theme}`])}>
			{text}: <span className={styles.date}>{dateString}</span>
		</Text>
	);
}

DateText.blokProps = blokProps;
