import { PageLayout, Section, Select, SelectOption, ShowMoreButton, Text } from '@/atoms';
import {
	CardFiveGroupStoryblok,
	CareerContactStoryblok,
	NavigationPageCareerStoryblok,
	SubMenuStoryblok,
} from '@/components';
import { useFilteredJobs, useJobs } from '@/contexts/teamtailor/TeamtailorProvider';
import { Department, JobAdvertisement, JobLocation } from '@/contexts/teamtailor/types';
import { JobItem } from '@/metabloks/JobItem';
import { ISbStoryData } from '@/types/storyblok';
import { editableBlok } from '@/utils/storyblok';
import { useState } from 'react';
import { t } from 'ttag';
import { CareerContact } from '../../metabloks/CareerContact';
import DynamicComponent from '../DynamicComponent';
import { HeaderCommon } from '../Other';
import styles from './NavigationPageCareer.module.scss';

interface Props {
	blok: NavigationPageCareerStoryblok;
	meta: Record<string, any>;
}

export const NavigationPageCareer: React.FC<Props> = ({ blok, meta }) => {
	const { header, zone1, zone2, zone3, zone4 } = blok;
	const { locations, departments } = useJobs();
	const [selectedLocation, setSelectedLocation] = useState<string | null>('');
	const [selectedDepartment, setSelectedDepartment] = useState<string | null>('');
	const filteredJobs: JobAdvertisement[] = useFilteredJobs({
		location: selectedLocation,
		department: selectedDepartment,
	});
	const [currentPage, setCurrentPage] = useState(1);
	const pageSize = 8;
	const totalPages = filteredJobs ? Math.ceil(filteredJobs.length / pageSize) : 0;
	const contact = blok?.contact as unknown as ISbStoryData<CardFiveGroupStoryblok>;
	const subMenu = blok?.subMenu as unknown as ISbStoryData<SubMenuStoryblok>;
	const careerContact = blok?.careerContact as unknown as ISbStoryData<CareerContactStoryblok>;

	const onShowMore = () => {
		setCurrentPage(currentPage < totalPages ? currentPage + 1 : currentPage);
	};

	const locationOptions: SelectOption[] | undefined = locations?.map((location: JobLocation) => ({
		value: location.id,
		label: location.name,
		id: location.id,
	}));

	const selectedLocationOption = locationOptions?.filter((option) => option.value === selectedLocation)?.[0] || null;

	const deparmentOptions: SelectOption[] | undefined = departments?.map((department: Department) => ({
		value: department.id,
		label: department.name,
		id: department.id,
	}));

	const selectedDepartmentOption =
		deparmentOptions?.filter((option) => option.value === selectedDepartment)?.[0] || null;

	return (
		<PageLayout subMenu={subMenu} exits={contact} meta={meta} {...editableBlok(blok)}>
			{header?.map((blok) => <DynamicComponent key={blok._uid} blok={blok} meta={{ zone: 1 }} />)}

			<Section paddingX={{ base: 'md', md: '3xl' }} paddingY={{ base: '3xl' }} maxWidth="1040px">
				{zone1?.map((blok) => (
					<HeaderCommon key={blok._uid} {...HeaderCommon.blokProps({ blok })} layout="center" marginBottom="3xl" />
				))}

				<div className={styles.filterContainer}>
					{!!locationOptions?.length && (
						<Select
							onSelect={(value) => setSelectedLocation(value)}
							testID="location"
							className={styles.filterField}
							data={locationOptions}
							title={t`Ort`}
							aria-label={t`Välj ort`}
							id="job-location"
							selected={selectedLocationOption}
							onClear={() => setSelectedLocation(null)}
						/>
					)}

					{!!deparmentOptions?.length && (
						<Select
							onSelect={(value) => setSelectedDepartment(value)}
							testID="department"
							className={styles.filterField}
							data={deparmentOptions}
							title={t`Avdelning`}
							aria-label={t`Välj avdelning`}
							id="job-department"
							selected={selectedDepartmentOption}
							onClear={() => setSelectedDepartment(null)}
						/>
					)}
				</div>

				<div className={styles.jobList}>
					{filteredJobs?.length ? (
						filteredJobs.slice(0, pageSize * currentPage).map((job) => {
							return (
								<JobItem
									key={job.id}
									title={job.title}
									id={job.id}
									departmentId={job.departmentId}
									locationIds={job.locationIds}
								/>
							);
						})
					) : (
						<Text muted>{t`Tyvärr finns det inga jobb här just nu.`}</Text>
					)}
					{totalPages > 1 && currentPage < totalPages && (
						<ShowMoreButton onClick={onShowMore}>{t`Ladda fler lediga tjänster`}</ShowMoreButton>
					)}
				</div>
				{careerContact?.content && <CareerContact {...CareerContact.blokProps({ blok: careerContact.content })} />}
			</Section>

			{zone2?.map((blok: any) => <DynamicComponent blok={blok} key={blok._uid} />)}

			{zone3?.map((blok: any) => <DynamicComponent blok={blok} key={blok._uid} />)}

			{zone4?.map((blok: any) => <DynamicComponent blok={blok} key={blok._uid} />)}
		</PageLayout>
	);
};
