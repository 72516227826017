import { ContainerFluid, Grid, Heading, Section, Text } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { SectionFortnoxPepoleStoryblok, StoryPostStoryblok } from '@/components';
import { ImageAsset } from '@/metabloks';
import { ISbStoryData } from '@/types/storyblok';
import { cls } from '@/utils';
import { editableBlok, normalizeFullSlug } from '@/utils/storyblok';
import { useGridColumns } from 'hooks/useGridColumns';
import { t } from 'ttag';
import styles from './SectionFortnoxPepole.module.scss';

interface Props {
	blok: SectionFortnoxPepoleStoryblok;
}

/**
 * A section with group of cards of Fortnoxfolk used in story
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=308%3A9777
 */
export const SectionFortnoxPepole: React.FC<Props> = ({ blok }) => {
	const { title } = blok;
	const items = blok.items as unknown as ISbStoryData<StoryPostStoryblok>[];
	const columns = useGridColumns(blok.items?.length ?? 0);
	const theme = blok.theme?.[0]?.theme ?? 'light-green';

	if (items?.length < 1) {
		return null;
	}

	return (
		<ContainerFluid theme={theme}>
			<Section paddingX={{ base: 'md', md: '3xl' }} paddingY={{ base: '3xl' }}>
				{title && <Heading as="div" size="h4" title={title} marginBottom="3xl" />}
				<Grid {...editableBlok(blok)} columns={columns} className={cls(styles.container)} colGap="3xl" rowGap="xl">
					{items?.map((item) => {
						const { uuid, full_slug } = item;
						const { title, cardImage, shortText } = item.content;
						return (
							<Grid.Item key={uuid} className={styles.card}>
								<div>
									{cardImage?.filename && (
										<div className={styles.image}>
											<ImageAsset absolute asset={cardImage} objectFit="cover" fill />
										</div>
									)}
									{title && <Heading as="div" size="h5" title={title} />}
									<Text>{shortText}</Text>
								</div>
								<LinkButton href={normalizeFullSlug(full_slug)!} variant="text">
									{t`Läs mer`}
								</LinkButton>
							</Grid.Item>
						);
					})}
				</Grid>
			</Section>
		</ContainerFluid>
	);
};
