import { ContainerFluid, Grid, GridProps, Section } from '@/atoms';
import { CardOneGroupStoryblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { editableBlok } from '../../utils/storyblok';
import DynamicComponent from '../DynamicComponent';
import styles from './CardOneGroup.module.scss';

interface Props {
	blok: CardOneGroupStoryblok;
}

const useGridColumns = (itemCount: number) => {
	const columns: GridProps['columns'] = { base: 1 };
	const { hasSubMenuV1 } = useSubMenu();

	if (itemCount > 4) {
		columns.xxl = 3;
		columns.lg = 2;
	}
	if (itemCount % 4 === 0) {
		if (hasSubMenuV1) {
			columns.xxl = 2;
		} else {
			columns.xxl = 4;
		}

		columns.lg = 2;
	}
	if (itemCount % 3 === 0) {
		columns.xl = 3;
	}
	if (itemCount % 2 === 0) {
		columns.md = 2;
	}
	return columns;
};

/**
 * @deprecated Will be replaced by CardSmallBrandline
 *
 * Group of Cards
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=326%3A11065
 */
export const CardOneGroup: React.FC<Props> = ({ blok }) => {
	const { cards } = blok;
	const nrOfCards = cards?.length;
	const columns = useGridColumns(nrOfCards ?? 0);
	const theme = blok.theme[0]?.theme ?? 'white';

	return (
		<ContainerFluid theme={theme}>
			<Section padding={{ base: 'md', lg: '3xl' }} className={styles[`theme--${theme}`]}>
				<div {...editableBlok(blok)} className={styles.container}>
					<Grid columns={columns} className={styles.gridContainer}>
						{cards?.map((card) => (
							<Grid.Item key={card._uid}>
								<DynamicComponent blok={card} meta={{ theme }} />
							</Grid.Item>
						))}
					</Grid>
				</div>
			</Section>
		</ContainerFluid>
	);
};
