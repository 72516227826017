import React, { PropsWithChildren, createContext, useContext } from 'react';
import { Integrator } from './types';

/**
 * List of integration categories from App Market
 */
export const useIntegrator = () => {
	return useContext(Context);
};

interface ContextProps {
	integrator: Integrator;
	integrators: Integrator[];
}

const Context = createContext<ContextProps>({
	integrator: {
		tenantId: '',
		companyName: '',
		about: '',
		successStory: '',
		contactEmail: '',
		contactPhone: '',
		contactHomepage: '',
		companyLogoKey: '',
		companyLogoUrl: '',
		companyNameSlug: '',
	},
	integrators: [],
});

interface Props {
	integrator: Integrator;
	integrators: Integrator[];
}

export const IntegrationCompanyProvider: React.FC<PropsWithChildren<Props>> = ({
	children,
	integrator,
	integrators,
}) => {
	const value = {
		integrator,
		integrators,
	};
	return <Context.Provider value={value}>{children}</Context.Provider>;
};
