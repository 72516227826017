import { ServerPageConfig } from '@/server/config';

/**
 * Normalize a story slug to
 *
 * 1. Have 1 leading slash
 * 2. Strip leading sv or en when there is no second argument
 * 3. Force leading en if given second argument is 'en'
 * 4. Remove any trailing slash
 * 5. Never link to /home or /home2, link to / instead
 * 6. Return undefined if given undefined
 */
export const normalizeFullSlug =
	// https://stackoverflow.com/a/67640634/2037928
	<T extends string | undefined>(fullSlug: T, locale?: ServerPageConfig['locale']) => {
		let result = fullSlug
			// Strip leading /sv or /en, making sure there is 1 leading slash
			?.replace(/^(\/*(sv|en)\/+|\/*)/g, '/')
			// Strip any trailing slashes
			.replace(/\/+$/, '');

		if (result === '/home' || result === '/home2') {
			result = '/';
		}

		if (locale === 'en') {
			result = `/${locale}${result}`;
		}

		return result as T extends string ? `/${string}` : undefined;
	};
