import { Colors, getColor } from '@/colors';
import { cls } from '@/utils';
import { CSSProperties } from 'react';
import styles from './Divider.module.scss';

interface Props {
	/**
	 * Sets margin top and bottom of divider
	 */
	margin?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | 'custom';
	size?: 'thin' | 'medium';
	color?: Colors;
	className?: string;
}
export const Divider: React.FC<Props> = ({ margin = 'md', size = 'thin', color, className }) => {
	const style = {
		'--background': color ? getColor(color) : getColor('--border-color'),
		'--divider-height': size === 'medium' ? '2px' : '1px',
	} as CSSProperties;

	return <div style={style} className={cls(styles.divider, styles[`margin--${margin}`], className)} />;
};
