import { MultilinkStoryblok } from '@/components';
import { Integration } from '@/contexts/Integration';
import { Breadcrumb } from '@/contexts/seo/SeoProvider';
import { ProductSchemaData } from '@/contexts/seo/types';
import type { ImageAssetProps } from '@/metabloks';
import { ISbStoryData } from '@/types/storyblok';
import { FAQPageJsonLdProps } from 'next-seo';

export interface ServerPageConfig {
	preview: boolean;
	siteBaseUrl: string;
	appsHost: string;

	openGraph?: {
		title?: string;
		description?: string;
		image?: ImageAssetProps;
	};
	meta?: {
		title?: string;
		description?: string;
		noIndex?: boolean;
		noFollow?: boolean;
		canonical?: MultilinkStoryblok;
		publishEnglishVersion?: boolean;
	};
	product?: ProductSchemaData | null | undefined;
	integration?: Integration | null | undefined;
	breadcrumbs?: Breadcrumb[] | null;
	captchaSiteKey: string;
	env: Env;
	englishVersion: boolean;
	breadcrumbsTheme: 'light' | 'dark';
	faqMeta?: FAQPageJsonLdProps['mainEntity'] | undefined;
	locale?: 'en' | 'sv' | string;
	internalVersionTag?: string;
	piwikTracking: boolean;
	customerType?: string;
	offertaEnquiryCreateFormScriptUrl: string;
}

export enum Env {
	Prod = 'prod',
	Acce = 'acce',
	Dev = 'dev',
}

export interface CanonicalStoryData {
	full_slug: ISbStoryData['full_slug'];
	name: ISbStoryData['name'];
	content?: {
		component?: string;
		header?: any[];
		intro?: string;
		meta?: {
			canonical?: {
				cached_url?: string;
				url?: string;
			};
			title?: string;
			description: string;
			noIndex?: boolean;
			noFollow?: boolean;
			publishEnglishVersion?: boolean;
		}[];
		openGraph?: {
			title?: string;
			image?: {
				alt?: string;
				copyright?: string;
				id: number;
				filename: string;
				name: string;
				title?: string;
			};
			description?: string;
		}[];
		piwikTracking?: boolean;
	};
}
