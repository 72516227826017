/* Solitary product box that gets all it's content from the product catalog  */
import { CustomIcon, Heading, Price, Text } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { renderRichText } from '@/bloks/RichText';
import { LinkButtonStoryblok, ThemeStoryblok } from '@/components';
import { useProduct } from '@/contexts/products';
import { handlePriceCardClickEvent } from '@/utils/piwik';
import { t } from 'ttag';
import styles from './ProductBox.module.scss';

interface ProductHeaderProps {
	name: string;
	titleSize: 'h6' | 'h5';
}

const ProductHeader: React.FC<ProductHeaderProps> = ({ name, titleSize }) => {
	return (
		<div className={styles.productBoxHeaderWrapper}>
			<span className={styles.iconWrapper}>
				<CustomIcon className={styles.icon} name="angles-right" size="lg" />
			</span>

			<Heading as={titleSize} title={name} marginBottom="none" />
		</div>
	);
};
interface ProductBoxWithPriceProps {
	product: any;
	testID?: string;
	meta?: { zone: number | undefined };
}

export const ProductBoxWithPrice: React.FC<ProductBoxWithPriceProps> = ({ product, testID, meta }) => {
	const { slug, content } = product;
	const productCatalogData = useProduct(slug);
	const buttons = product?.content?.linkButtons;

	return (
		<div className={styles.productBoxContainer} data-test-id={testID}>
			<ProductHeader name={content.name} titleSize="h5" />
			<div className={styles.productBoxContent}>
				<Text as="div" marginBottom="lg">
					{renderRichText(content?.description)}
				</Text>
				<div className={styles.footer}>
					<div className={styles.priceBox}>
						{productCatalogData?.priceStructure ? (
							<Price
								priceStructure={productCatalogData?.priceStructure}
								priceText={t`kr/mån`}
								size="h3"
								showFrom={slug != 'lagerbolag'}
							/>
						) : (
							<Price
								priceText={product.content.customPriceText}
								priceStructure={{ '12': { '0': parseFloat(product.content.price) }, '3': { '0': 0 } }}
								unit={product.content.priceUnit}
								interval={product.content.priceInterval}
								size="h3"
								showFrom={slug != 'lagerbolag'}
							/>
						)}
					</div>
					<div className={styles.buttonContainer}>
						{buttons?.map?.((button: LinkButtonStoryblok, index: number) => (
							<LinkButton
								key={button._uid}
								{...LinkButton.blokProps({ blok: button })}
								variant="text"
								onClick={() => {
									handlePriceCardClickEvent({
										productName: product.name,
										productId: product.slug,
										zone: meta?.zone,
										buttonVariant: index === 0 ? 'filled' : 'outlined',
										buttonText: button.text,
									});
								}}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

interface ProductBoxProps extends ProductBoxWithPriceProps {
	linkButtons?: LinkButtonStoryblok[];
	theme?: ThemeStoryblok['theme'];
}

export const ProductBox: React.FC<ProductBoxProps> = ({ product, linkButtons, testID, meta }) => {
	const { content } = product;

	return (
		<div className={styles.productBoxContainer} data-test-id={testID}>
			{content?.name && <ProductHeader name={content.name} titleSize="h5" />}
			<div className={styles.productBoxContent}>
				{content?.description && <Text marginBottom="lg">{content.description}</Text>}
				<div className={styles.buttonContainer}>
					{linkButtons?.map((button: LinkButtonStoryblok) => (
						<LinkButton
							key={button._uid}
							{...LinkButton.blokProps({ blok: button })}
							variant="text"
							onClick={() => {
								handlePriceCardClickEvent({
									productName: product.name,
									productId: product.slug,
									zone: meta?.zone,
									buttonVariant: 'text',
									buttonText: button.text,
								});
							}}
						/>
					))}
				</div>
			</div>
		</div>
	);
};
