import { useEffect, useRef } from 'react';
import { EnquiryCreateScript } from './EnquiryCreateScript';

declare global {
	// eslint-disable-next-line @typescript-eslint/no-namespace
	namespace JSX {
		interface IntrinsicElements {
			'enquiry-create-form': EnquiryCreateFormProps;
		}
	}
}

interface EnquiryCreateFormProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
	['work-type-id']: string;
}

interface EnquiryCreateFormWebComponent extends HTMLElement {
	workTypeId: string;
	campaignSessions?: CampaignSession[];
}

interface CampaignSession {
	entryUrl: string;
	createdAt: string;
}

type Props = {
	categoryId: string;
	entryUrl: string;
};

export const OffertaForm = (props: Props) => {
	const { categoryId, entryUrl } = props;
	const webComponentRef = useRef<EnquiryCreateFormWebComponent>(null);

	useEffect(() => {
		const campaignSessions = [
			{
				entryUrl: entryUrl,
				createdAt: new Date().toISOString(),
			},
		];
		const currentComponent = webComponentRef.current;
		if (!currentComponent) {
			return;
		}
		currentComponent.campaignSessions = campaignSessions;
	}, [webComponentRef, entryUrl]);

	return (
		<>
			<EnquiryCreateScript />
			<enquiry-create-form ref={webComponentRef} work-type-id={categoryId} />
		</>
	);
};
