import { BrandLine, Heading, Text as TextAtom } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { renderRichText } from '@/bloks/RichText';
import { LinkButtonStoryblok, RichtextStoryblok, TextStoryblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { cls } from '@/utils';
import React from 'react';
import styles from './Text.module.scss';

interface BlokProps {
	blok: TextStoryblok;
}

interface TextProps {
	title?: string;
	text: RichtextStoryblok;
	linkButton?: LinkButtonStoryblok[];
	buttonVariant?: boolean;
	brandline?: boolean;
}

const blokProps = ({ blok }: BlokProps): TextProps => ({
	title: blok.title,
	text: blok.text,
	linkButton: blok.linkButton,
	buttonVariant: blok.buttonVariant,
	brandline: blok.brandline,
});

export const Text = ({ title, text, linkButton, buttonVariant = false, brandline = true }: TextProps) => {
	const { hasSubMenuV1 } = useSubMenu();
	return (
		<div className={styles.contentContainer}>
			{title && (
				<>
					{brandline && <BrandLine className={styles.brandLine} />}
					<Heading as="div" size="h4" title={title} marginBottom="xl" />
				</>
			)}
			<div className={cls(hasSubMenuV1 ? styles.subMenuTextContent : styles.textContent)}>
				<TextAtom as="div" marginBottom={buttonVariant ? 'lg' : 'md'}>
					{renderRichText(text)}
				</TextAtom>
				{linkButton?.map((link) => (
					<LinkButton
						key={link._uid}
						{...LinkButton.blokProps({ blok: link })}
						variant={buttonVariant ? 'filled' : 'text'}
					>
						{link.text}
					</LinkButton>
				))}
			</div>
		</div>
	);
};

Text.blokProps = blokProps;
