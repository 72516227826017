/**
 * Type-safe way to filter out null values from an array
 *
 * `things.filter(isNotNullish)`
 */
export const isNotNullish = <T>(item: T | null | undefined): item is T => item != null;

/**
 * Type-safe way to filter out null values from an array
 *
 * `things.filter(isNotNullish)`
 */
export const isNotString = <T>(item: T | string): item is T => typeof item !== 'string';
