import { ContainerFluid, Section, ShowMoreButton, Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { HeaderCommon, HeaderCommonProps } from '@/bloks/Other';
import { CareerContactStoryblok, SectionJobListStoryblok } from '@/components';
import { useFilteredJobs } from '@/contexts/teamtailor/TeamtailorProvider';
import { CareerContactProps } from '@/metabloks/CareerContact/CareerContact';
import { JobItem } from '@/metabloks/JobItem';
import { ISbStoryData } from '@/types/storyblok';
import { editableBlok } from '@/utils/storyblok';
import { useState } from 'react';
import { t } from 'ttag';
import { CareerContact } from '../../../metabloks/CareerContact';
import styles from './SectionJobList.module.scss';

interface BlokProps {
	blok: SectionJobListStoryblok;
}

const blokProps = ({ blok }: BlokProps): Props => {
	const contactStory = blok?.contact as unknown as ISbStoryData<CareerContactStoryblok>;
	return {
		header: blok.header?.[0] && HeaderCommon.blokProps({ blok: blok.header[0] }),
		location: blok.location,
		department: blok.department,
		emptyJobListText: blok.emptyJobListText,
		contact: contactStory?.content && CareerContact.blokProps({ blok: contactStory?.content }),
		_editable: blok._editable,
	};
};

interface Props {
	header?: HeaderCommonProps;
	location?: string;
	department?: string;
	emptyJobListText?: string;
	contact?: CareerContactProps;
	_editable?: string;
}

export const SectionJobList: Blok<Props, BlokProps> = ({
	header,
	location,
	department,
	emptyJobListText,
	contact,
	_editable,
}) => {
	const jobList = useFilteredJobs({ department, location, slice: !department && !location ? 4 : undefined });
	const pageSize = 4;
	const [currentPage, setCurrentPage] = useState(1);
	const totalPages = jobList ? Math.ceil(jobList.length / pageSize) : 0;
	const onShowMore = () => {
		setCurrentPage(currentPage < totalPages ? currentPage + 1 : currentPage);
	};

	return (
		<ContainerFluid {...editableBlok({ _editable })} marginBottom="none">
			<Section padding={{ base: 'xl', md: '5xl' }} maxWidth="1040px">
				{header && <HeaderCommon {...header} layout="center" marginBottom="3xl" />}
				<div className={styles.jobList}>
					{jobList?.length > 0
						? jobList
								.slice(0, pageSize * currentPage)
								.map((job) => (
									<JobItem
										title={job.title}
										departmentId={job.departmentId}
										locationIds={job.locationIds}
										key={job.id}
										id={job.id}
									/>
								))
						: emptyJobListText && (
								<Text muted className={styles.emptyJobListText}>
									{emptyJobListText}
								</Text>
							)}
					{totalPages > 1 && currentPage < totalPages && (
						<ShowMoreButton onClick={onShowMore}>{t`Ladda fler lediga tjänster`}</ShowMoreButton>
					)}
				</div>
				{contact && <CareerContact {...contact} />}
			</Section>
		</ContainerFluid>
	);
};

SectionJobList.blokProps = blokProps;
