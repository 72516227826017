import { cls } from '@/utils';
import styles from './TextField.module.scss';

interface LabelProps {
	title?: string;
	isActive: boolean;
	isEmpty: boolean;
	htmlFor?: string;
	size?: string;
	required?: boolean;
}

export const FieldLabel: React.FC<React.PropsWithChildren<LabelProps>> = ({
	title,
	isActive,
	isEmpty,
	htmlFor,
	size,
	required = false,
}) => {
	const classNames = cls(
		styles[size === 'small' ? 'no-label' : 'label'],
		styles[isActive ? 'active' : 'inactive'],
		styles[isEmpty ? 'empty' : 'not-empty'],
	);

	if (!title) {
		return null;
	}

	const formattedTitle = required ? `${title} *` : title;

	return (
		<label htmlFor={htmlFor} className={classNames}>
			{formattedTitle}
		</label>
	);
};
