import React, { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { Integration, IntegrationCategory } from './types';

/**
 * List of integration categories from App Market
 */
export const useIntegrationCategories = () => {
	return useContext(Context);
};

interface ContextProps {
	categories?: IntegrationCategory[];
	featuredIntegrations?: Integration[];
	integrations?: Integration[];
	category?: IntegrationCategory;
}

const Context = createContext<ContextProps>({
	categories: undefined,
	featuredIntegrations: [],
	integrations: undefined,
	category: undefined,
});

interface Props {
	categories: IntegrationCategory[];
	featuredIntegrations?: Integration[];
	integrations: Integration[];
	category?: IntegrationCategory;
}

export const IntegrationCategoryProvider: React.FC<PropsWithChildren<Props>> = ({
	children,
	categories,
	featuredIntegrations,
	integrations,
	category,
}) => {
	const value = useMemo(
		() => ({
			categories,
			featuredIntegrations,
			integrations,
			category,
		}),
		[categories, featuredIntegrations, integrations, category],
	);

	return <Context.Provider value={value}>{children}</Context.Provider>;
};
