import { TextTwoColumnsGroupStoryblok, TextTwoColumnsStoryblok, ThemeGreenStoryblok } from '@/components';
import React from 'react';
import { TextTwoColumns } from '../TextTwoColumn/TextTwoColumns';

interface BlokProps {
	blok: TextTwoColumnsGroupStoryblok;
	meta: {
		theme: ThemeGreenStoryblok['theme'];
	};
}

interface TextTwoColumnsGroupProps {
	group: TextTwoColumnsStoryblok[];
	theme: ThemeGreenStoryblok['theme'];
}

const blokProps = ({ blok, meta }: BlokProps): TextTwoColumnsGroupProps => ({
	group: blok.textTwoColumn,
	theme: meta?.theme,
});

export const TextTwoColumnsGroup = ({ group, theme }: TextTwoColumnsGroupProps) => {
	return (
		<>
			{group?.map((text) => (
				<TextTwoColumns
					key={text._uid}
					theme={theme}
					title={text.title}
					leftColumn={text.leftColumn}
					rightColumn={text.rightColumn}
					linkButton={text.linkButton}
					buttonVariant={text.buttonVariant}
				/>
			))}
		</>
	);
};

TextTwoColumnsGroup.blokProps = blokProps;
