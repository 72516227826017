import { Colors, getColor } from '@/colors';
import { cls } from '@/utils';
import styles from './RoundedSquare.module.scss';

interface Props {
	height: number;
	color?: Colors;
	className?: string;
}

export const RoundedSquare: React.FC<Props> = ({ height, className, color = '--primary-dark-green' }) => {
	return (
		<div
			className={cls(styles.roundedSquare, className)}
			style={{
				height: `${height}rem`,
				width: `${height}rem`,
				minWidth: `${height}rem`,
				backgroundColor: getColor(color),
			}}
		/>
	);
};
