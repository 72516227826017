import { addLocale, useLocale } from 'ttag';
import en from '../../i18n/en.po.json';

const defaultLocale = process.env.LOCALE || 'sv'; // Default source language is Swedish
addLocale('en', en as any);

export const initLocales = async (locale = defaultLocale): Promise<'en' | 'sv' | null> => {
	/**
	 * We want to add and use a locale only if it's different from the default swedish.
	 */
	if (locale === 'en') {
		useLocale(locale); // eslint-disable-line
		return locale;
	}

	if (locale === 'sv') {
		useLocale(locale); // eslint-disable-line
		return locale;
	}

	return null;
};
