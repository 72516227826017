import { ContainerFluid, Heading, Section } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { IconLinkGroup, IconLinkGroupProps } from '@/bloks/Button/IconLinkGroup/IconLinkGroup';
import { SectionLinkIconsStoryblok } from '@/components';
import { editableBlok } from '@/utils/storyblok';
import styles from './SectionLinkIcons.module.scss';

interface BlokProps {
	blok: SectionLinkIconsStoryblok;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	title: blok.title,
	iconLinkGroup: blok.iconLinkGroup?.[0] && { ...IconLinkGroup.blokProps({ blok: blok.iconLinkGroup[0] }) },
	_editable: blok._editable,
});

interface Props {
	title: string;
	iconLinkGroup: IconLinkGroupProps;
	_editable?: string;
}

export const SectionLinkIcons: Blok<Props, BlokProps> = ({ title, iconLinkGroup, _editable }) => {
	return (
		<ContainerFluid theme="darkGreen">
			<Section {...editableBlok({ _editable })}>
				<Heading as="div" size="h4" title={title} marginBottom="3xl" className={styles.header} />
				{iconLinkGroup && <IconLinkGroup {...iconLinkGroup} />}
			</Section>
		</ContainerFluid>
	);
};

SectionLinkIcons.blokProps = blokProps;
