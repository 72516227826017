import React, { createContext, useContext } from 'react';
import { CisionPost } from '.';

export const usePost = () => {
	return useContext(NewsfeedContext);
};

interface NewsfeedState {
	post: CisionPost | null;
}

const NewsfeedContext = createContext<NewsfeedState>({
	post: null,
});

interface Props {
	children: React.ReactNode;
	post: CisionPost | null;
}
export const NewsProvider: React.FC<Props> = ({ children, post }) => {
	const value = {
		post,
	};

	return <NewsfeedContext.Provider value={value}>{children}</NewsfeedContext.Provider>;
};
