import classNames from 'classnames';
import styles from './Datepicker.module.scss';

interface SelectOptionProps {
	title: string;
	value: string | number;
}

export const SelectOption: React.FC<SelectOptionProps> = ({ title, value }) => {
	return (
		<option value={value} className={styles.option}>
			{title}
		</option>
	);
};

interface SelectProps {
	label?: string;
	fieldId?: string;
	onChange?: (e: React.ChangeEvent<any>) => void;
	title?: string;
	value?: string | number;
	className?: string;
	bordered?: true;
	min?: Date;
	max?: Date;
	type?: 'date' | 'month';
	testID?: string;
}

/**
 * Select component
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=398%3A10641
 */
export const Datepicker: React.FC<SelectProps> = ({
	className,
	bordered,
	onChange,
	min,
	max,
	value,
	type = 'date',
	testID,
}) => {
	const dateProps = {
		min: min ? min.toISOString().split('T')[0] : undefined,
		max: max ? max.toISOString().split('T')[0] : undefined,
	};

	return (
		<div className={classNames(styles.container, className)}>
			<input
				className={classNames(styles.input, bordered && styles.bordered)}
				onChange={onChange}
				value={value}
				type={type}
				id="start"
				name="date-picker"
				data-testid={testID}
				{...dateProps}
				max="9999-12-31"
			/>
		</div>
	);
};
