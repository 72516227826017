import { RichtextStoryblok } from '@/components';

export function removeStyleFromRichtext(document: RichtextStoryblok, stylesToRemove = ['textStyle']) {
	if (!document || !document?.content || !Array.isArray(document?.content)) return document;

	function traverse(value: unknown): unknown {
		if (value === null) {
			return value;
		}

		if (Array.isArray(value)) {
			return value.map(traverse);
		}

		if (typeof value === 'object') {
			if ('marks' in value && Array.isArray((value as any).marks)) {
				const newMarks = (value as any).marks.filter((mark: any) => !stylesToRemove.includes(mark?.type));
				return { ...value, marks: newMarks };
			}

			return Object.fromEntries(Object.entries(value).map(([key, val]) => [key, traverse(val)]));
		}

		return value;
	}

	return {
		...document,
		content: traverse(document.content),
	};
}
