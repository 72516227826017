import { IconButton } from '@/atoms';
import { cls } from '@/utils';
import { t } from 'ttag';
import styles from './Modal.module.scss';

interface Props {
	children: React.ReactNode;
	className?: string;
	show: boolean;
	onClose: () => void;
	dataContentName?: string;
}

export function Modal({ className, children, show, onClose, dataContentName }: Props) {
	return (
		<div className={styles.container} hidden={!show}>
			<div className={cls(styles.modal, className)} data-content-name={dataContentName}>
				<IconButton
					iconName="xmark"
					iconColor="--border-hovered-color"
					className={styles.icon}
					onClick={onClose}
					aria-label={t`Stäng`}
				/>
				<div className={styles.content}>{children}</div>
			</div>
		</div>
	);
}
