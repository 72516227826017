import { RefObject } from 'react';
import { useEvent } from './useEvent';

export function useOnClickOutside<T extends Element>(ref: RefObject<T> | null, handler: any) {
	useEvent('mouseup', (event) => {
		if (!ref || !ref.current || ref.current.contains(event.target)) {
			return;
		}
		handler(event);
	});
}
