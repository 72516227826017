import { ContainerFluid, Section } from '@/atoms';
import DynamicComponent from '@/bloks/DynamicComponent';
import { HeaderCommon, HeaderCommonProps } from '@/bloks/Other';
import {
	GlobalUspSmallGroupStoryblok,
	SectionUspStoryblok,
	ThemeGreenStoryblok,
	ThemeMintGreenStoryblok,
	ThemeYellowStoryblok,
} from '@/components';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { ISbStoryData } from '@/types/storyblok';
import React, { ReactNode } from 'react';

interface BlokProps {
	blok: SectionUspStoryblok;
	meta?: { zone?: number };
}

const blokProps = ({ blok, meta }: BlokProps): Props => ({
	header: blok.header?.[0] && HeaderCommon.blokProps({ blok: blok.header?.[0] }),
	uspGroup: blok?.uspGroup?.map((uspGroup) => (
		<DynamicComponent
			key={uspGroup._uid}
			blok={uspGroup}
			meta={{ ...meta, theme: blok?.theme?.[0]?.theme, hideBrandline: !!blok?.header?.length }}
		/>
	)),
	globalUspGroup: blok?.globalUspGroup as unknown as ISbStoryData<GlobalUspSmallGroupStoryblok>,
	theme: blok?.uspGroup?.[0]?.component === 'UspMediumImageGroup' ? 'white' : blok?.theme?.[0]?.theme,
	zone: meta?.zone,
});

interface Props {
	header?: HeaderCommonProps;
	uspGroup?: ReactNode[];
	globalUspGroup?: ReactNode[] | any;
	theme?: ThemeGreenStoryblok['theme'] | ThemeYellowStoryblok['theme'] | ThemeMintGreenStoryblok['theme'];
	zone?: number;
}

export const SectionUsp = ({ header, uspGroup, globalUspGroup, theme, zone }: Props) => {
	const contentPiece = header?.titlePart2 ? `${header.titlePart1} ${header.titlePart2}` : header?.titlePart1;
	return (
		<ImpressionTrackingProvider
			value={{ contentName: `${zone}|SectionUsp`, contentPiece, contentTarget: 'no interactions possible' }}
		>
			<ContainerFluid theme={theme}>
				<Section>
					{header && <HeaderCommon {...header} marginBottom="3xl" />}
					{uspGroup && uspGroup?.length > 0 ? (
						uspGroup
					) : (
						<DynamicComponent blok={globalUspGroup?.content} meta={{ layout: 'center' }} />
					)}
				</Section>
			</ContainerFluid>
		</ImpressionTrackingProvider>
	);
};

SectionUsp.blokProps = blokProps;
