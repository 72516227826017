import { Heading, LinkAnchor, Section, Text } from '@/atoms';
import { BlogPostStoryblok, CompanyGuidePostStoryblok, NavigationPageCompanyGuideStoryblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { ImageAsset } from '@/metabloks';
import { ISbStoryData } from '@/types/storyblok';
import { truncateButWholeWords } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { normalizeFullSlug } from '@/utils/storyblok/normalizeFullSlug';
import classNames from 'classnames';
import { t } from 'ttag';
import { LinkButton } from '../Button';
import styles from './CardTwoArticleGrid.module.scss';

/** Takes any number of possible assets and returns the first with a filename */
const getAssetOrFallback = <T extends { filename?: string } | undefined>(...assets: T[]): T | undefined =>
	assets.find((a) => a?.filename);

interface ItemProps {
	className?: string;
	story: ISbStoryData<BlogPostStoryblok | CompanyGuidePostStoryblok>;
}
const CardTwoArticle: React.FC<ItemProps> = ({ className, story }) => {
	const blok = story.content || {};
	const title = blok.cardTitle || blok.header[0].h1Part1;
	const shortText = blok.cardShortText || truncateButWholeWords(blok.intro!);
	const image = getAssetOrFallback(blok.cardImage) || blok.header[0].image;

	return (
		<article {...editableBlok(blok)} className={classNames(styles.card, className)}>
			{image?.filename && (
				<div className={styles.image}>
					<ImageAsset absolute asset={image} fill objectFit="cover" />
				</div>
			)}
			<div className={styles.content}>
				<LinkAnchor href={normalizeFullSlug(story.full_slug)}>
					<Heading as="h2" size="custom" title={title} className={styles.heading} />
				</LinkAnchor>
				<Text>{shortText}</Text>
				<LinkButton href={normalizeFullSlug(story.full_slug)} variant="filled">
					{t`Läs hela artikeln`}
				</LinkButton>
			</div>
		</article>
	);
};

interface Props {
	blok: NavigationPageCompanyGuideStoryblok;
}

/**
 * Grid of cards with content from articles
 * Meant to be used for Företagsguiden
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=169%3A7587
 */
export const CardTwoArticleGrid: React.FC<Props> = ({ blok }) => {
	const { hasSubMenuV1 } = useSubMenu();
	const posts = blok.articles as ISbStoryData<BlogPostStoryblok | CompanyGuidePostStoryblok>[];
	const topArticles = posts.slice(0, 4);
	const remainingArticles = posts.slice(4);

	const [link1, link2] = blok.links?.slice(0, 2) ?? [];

	return (
		<Section
			paddingY={{ base: 'none', md: hasSubMenuV1 ? '3xl' : '5xl' }}
			paddingX={{ base: 'none', md: hasSubMenuV1 ? 'none' : '5xl' }}
		>
			<div className={styles.group1}>
				{topArticles.map((post, index) => (
					<CardTwoArticle key={post.id} story={post} className={styles[`article${index + 1}`]} />
				))}
				{link1 && (
					<div {...editableBlok(blok)} className={styles.linkCard1}>
						<LinkButton {...LinkButton.blokProps({ blok: link1 })} variant="text" />
					</div>
				)}
				{link2 && (
					<div {...editableBlok(blok)} className={styles.linkCard2}>
						<LinkButton {...LinkButton.blokProps({ blok: link2 })} variant="text" />
					</div>
				)}
			</div>

			<div className={styles.group2}>
				{remainingArticles.map((post) => (
					<CardTwoArticle key={post.id} story={post} />
				))}
			</div>
		</Section>
	);
};
