import { Card, Heading, Icon, Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { LinkButton, LinkButtonProps } from '@/bloks/Button';
import { AssetStoryblok, CardMediumUspStoryblok, UspSmallStoryblok } from '@/components';
import { ThemeProvider } from '@/contexts/theme';
import { ImageAsset } from '@/metabloks';
import { getCorrespondingLegacyTheme } from '@/themes';
import { Themes } from '@/types';
import React from 'react';
import styles from './CardMediumUsp.module.scss';

interface BlokProps {
	blok: CardMediumUspStoryblok;
	meta?: { theme?: Themes['theme'] };
}

const blokProps = ({ blok, meta }: BlokProps): Props => {
	const blokTheme = blok.theme?.[0]?.theme;
	const theme = blokTheme ?? meta?.theme;
	const linkButton = blok?.readMoreLink?.[0];
	return {
		antiUsp: blok.antiUsp,
		description: blok.description,
		footnote: blok.footnote,
		image: blok.image,
		subTitle: blok.subTitle,
		theme,
		title: blok.title,
		usp: blok.usp,
		readMoreLink: linkButton && LinkButton.blokProps({ blok: linkButton }),
	};
};

interface Props {
	antiUsp?: UspSmallStoryblok[];
	description?: string;
	footnote?: string;
	image?: AssetStoryblok;
	subTitle?: string;
	theme?: Themes['theme'];
	title?: string;
	usp?: UspSmallStoryblok[];
	readMoreLink?: LinkButtonProps;
}

export const CardMediumUsp: Blok<Props, BlokProps> = ({
	antiUsp,
	description,
	footnote,
	image,
	subTitle,
	theme,
	title,
	usp,
	readMoreLink,
}) => {
	return (
		<ThemeProvider theme={getCorrespondingLegacyTheme(theme)}>
			<Card className={styles.card} padding="none" border={theme === 'white'}>
				<div className={styles.cardContentGrid}>
					{image?.filename && (
						<div className={styles.image}>
							<ImageAsset absolute asset={image} fill objectFit="cover" />
						</div>
					)}
					<div className={styles.cardBody}>
						{subTitle && <Heading as="div" size="h6" title={subTitle} marginBottom="md" smallTitle />}
						{title && <Heading as="h3" size="h5" title={title} marginBottom="xl" />}
						<div className={styles.uspContainer}>
							{!antiUsp?.length ? (
								<>
									{usp?.map((usp) => {
										return (
											<Text key={usp._uid} as="div" marginBottom="sm">
												{usp.icon && (
													<Icon
														name="circle-check"
														size="lg"
														color={theme !== 'green' ? '--text-primary-green' : undefined}
														className={styles.cardUspIcon}
													/>
												)}
												<div>{usp.title}</div>
											</Text>
										);
									})}
								</>
							) : (
								<>
									{usp?.map((usp) => {
										return (
											<Text key={usp._uid} marginBottom="sm" className={styles.usp} as="div">
												{usp.icon && (
													<Icon
														name="circle-check"
														size="lg"
														color={theme !== 'green' ? '--text-primary-green' : undefined}
														className={styles.cardUspIcon}
													/>
												)}
												<div>{usp.title}</div>
											</Text>
										);
									})}

									{antiUsp?.map((antiUsp) => {
										return (
											<Text
												key={antiUsp._uid}
												className={(styles.cardAntiUsp, styles.antiUsp)}
												as="div"
												marginBottom="sm"
											>
												{antiUsp.icon && (
													<Icon name="circle-xmark" size="lg" color="--border-color" className={styles.cardUspIcon} />
												)}
												<div>{antiUsp.title}</div>
											</Text>
										);
									})}
								</>
							)}
						</div>
						<Text marginBottom="none">{description}</Text>
						{readMoreLink && (
							<div className={styles.readMore}>
								<LinkButton variant="text" {...readMoreLink} />
							</div>
						)}
					</div>
					{footnote && (
						<div className={styles.cardFootnote}>
							<Text marginBottom="none">{footnote}</Text>
						</div>
					)}
				</div>
			</Card>
		</ThemeProvider>
	);
};

CardMediumUsp.blokProps = blokProps;
