import { Grid } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { CardLargeGraphicGroupStoryblok } from '@/components';
import { editableBlok } from '@/utils/storyblok';
import { CardLargeGraphic, CardLargeGraphicProps } from '../../CardLarge/CardLargeGraphic';
import { getLayout } from '../CardLargeGroup';
import styles from './CardLargeGraphicGroup.module.scss';

interface BlokProps {
	blok: CardLargeGraphicGroupStoryblok;
	meta?: { zone?: number };
}

const blokProps = ({ blok, meta }: BlokProps): Props => ({
	cards: blok.cards?.map((card) => CardLargeGraphic.blokProps({ blok: card, meta })),
	layout: blok.layout,
	staticLayout: blok.staticLayout,
	_editable: blok._editable,
	zone: meta?.zone,
});

interface Props {
	cards: CardLargeGraphicProps[];
	layout?: 'image-right' | 'image-left';
	staticLayout?: boolean;
	_editable?: string;
	zone?: number;
}

export const CardLargeGraphicGroup: Blok<Props, BlokProps> = ({
	cards,
	layout = 'image-right',
	staticLayout = false,
	_editable,
	zone,
}) => {
	return (
		<Grid className={styles.grid} {...editableBlok({ _editable })}>
			{cards.map((card, i) => (
				<Grid.Item key={i}>
					<CardLargeGraphic {...card} layout={staticLayout ? layout : getLayout(layout, i)} zone={zone} />
				</Grid.Item>
			))}
		</Grid>
	);
};

CardLargeGraphicGroup.blokProps = blokProps;
