import { BackgroundImage, BrandLine, BrandStripes, ContainerFluid, Heading, Section } from '@/atoms';
import { LinkButton, LinkButtonProps } from '@/bloks/Button/LinkButton';
import { OffertaPopUpButton } from '@/bloks/Button/OffertaPopUpButton';
import { PromotorBubble, PromotorBubbleProps } from '@/bloks/Other/PromotorBubble';
import { renderRichText } from '@/bloks/RichText';
import { AssetStoryblok, HeaderImageStoryblok, OffertaPopUpButtonStoryblok } from '@/components';
import { useGlobalMenu } from '@/contexts/menu/GlobalMenuProvider';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { ImageAsset } from '@/metabloks';
import { getPiwikTrackingSlug } from '@/trackers/piwik';
import { cls } from '@/utils';
import { usePiwikPro } from '@piwikpro/next-piwik-pro';
import { usePathname } from 'next/navigation';
import React from 'react';
import styles from './HeaderImage.module.scss';

interface BlokProps {
	blok: HeaderImageStoryblok;
}

interface HeaderImageProps {
	label?: string;
	h1Part1: string;
	h1Part2?: string;
	shortText?: any;
	linkButtons?: LinkButtonProps[];
	image: AssetStoryblok;
	imageLayout?: 'right' | 'full';
	promotorBubble?: PromotorBubbleProps;
	textAlignLeft?: boolean;
	offertaPopUpButton?: OffertaPopUpButtonStoryblok[];
}

const blokProps = ({ blok }: BlokProps): HeaderImageProps => ({
	label: blok.label,
	h1Part1: blok.h1Part1,
	h1Part2: blok.h1Part2,
	shortText: blok.shortText,
	linkButtons: blok.linkButtons?.map((btn) => LinkButton.blokProps({ blok: btn })),
	image: blok.image,
	imageLayout: blok.imageLayout,
	promotorBubble: blok.promotorBubble?.[0] && PromotorBubble.blokProps({ blok: blok.promotorBubble[0] }),
	textAlignLeft: blok.textAlignLeft,
	offertaPopUpButton: blok.offertaPopUpButton,
});

/**
 * Header block with background image
 */

export const HeaderImage = ({
	label,
	h1Part1,
	h1Part2,
	shortText,
	linkButtons,
	image,
	imageLayout = 'full',
	promotorBubble,
	textAlignLeft,
	offertaPopUpButton,
}: HeaderImageProps) => {
	const { menuVersionV3 } = useGlobalMenu();
	const { hasSubMenu, hasSubMenuV1, hasSubMenuV2 } = useSubMenu();

	const isFullImage = imageLayout === 'full';

	// PIWIK IMPRESSION TRACKING
	const { ContentTracking, DataLayer } = usePiwikPro();
	const contentName = `1|HeaderImage`;
	const contentPiece = h1Part2 ? `${h1Part1} ${h1Part2}` : h1Part1;
	const contentTarget = linkButtons?.length ? 'multiple interactions possible' : 'no interactions possible';
	const pathname = usePathname();
	const slug = getPiwikTrackingSlug(pathname ?? '');

	function trackContentInteraction(targetText: string) {
		DataLayer.push({
			event: 'contentInteraction',
			contentName: `${slug}|${contentName}`,
			contentPiece,
			contentTarget,
			contentInteraction: 'Click',
			data: { clickElementText: targetText },
		});
		ContentTracking.trackContentInteraction('contentInteraction', `${slug}|${contentName}`, contentPiece, targetText);
	}

	return (
		<ImpressionTrackingProvider
			value={{
				contentName,
				contentPiece,
				threshold: 0,
				contentTarget,
			}}
		>
			<ContainerFluid
				theme="darkGreen"
				className={cls(
					styles.container,
					menuVersionV3 && styles.menuVersionV3,
					hasSubMenuV1 && styles.hasSubMenuV1,
					hasSubMenuV2 && styles.hasSubMenuV2,
					!textAlignLeft && styles.textShadow,
				)}
			>
				<div className={styles.contentContainer}>
					{image?.filename && isFullImage && (
						<BackgroundImage
							asset={image}
							overlay
							overlayDirection={textAlignLeft ? 'to-right' : 'center'}
							objectPosition="center top"
							priority
							isHeader
							borderRadius={hasSubMenu}
						/>
					)}
					<Section
						as="header"
						className={cls(
							styles.innerContainer,
							styles[`layout--${imageLayout}`],
							imageLayout === 'full' && textAlignLeft && styles.textAlignLeft,
						)}
						padding={{ base: 'none' }}
					>
						{image?.filename && imageLayout === 'right' && (
							<div className={styles.imageContainer}>
								<ImageAsset absolute fill objectFit="cover" objectPosition="center" asset={image} priority />
							</div>
						)}
						<div className={styles.content}>
							<div className={styles.inner}>
								<div className={styles.brandlineContainer}>
									{isFullImage ? <BrandLine className={styles.brandline} marginBottom="xl" /> : <BrandStripes />}
								</div>
								<div className={styles.text}>
									{label && <Heading as="div" size="h6" title={label} marginBottom="lg" className={styles.label} />}
									<Heading as="h1" title={h1Part1} titlePart2={h1Part2} marginBottom="2xl" className={styles.title} />

									{shortText &&
										shortText.content?.[0]?.content &&
										renderRichText(shortText, {
											textSize: 'ingress',
											textMarginBottom: linkButtons && linkButtons?.length > 0 ? '2xl' : 'none',
										})}

									{!!linkButtons?.length && (
										<div className={styles.linkButtons}>
											{linkButtons?.map((btn, index: number) => (
												<LinkButton
													key={index}
													{...btn}
													variant={index === 0 ? 'filled' : 'outlined'}
													onClick={() => {
														trackContentInteraction(btn.children as string);
													}}
												/>
											))}
										</div>
									)}
									{!!offertaPopUpButton?.length && (
										<div className={styles.linkButtons}>
											{offertaPopUpButton?.map((btn, index: number) => (
												<OffertaPopUpButton
													{...btn}
													key={index}
													onClick={() => {
														trackContentInteraction(btn.buttonText as string);
													}}
												/>
											))}
										</div>
									)}
								</div>

								{promotorBubble && imageLayout === 'full' && (
									<div className={styles.bubbleWrapper}>
										<PromotorBubble {...promotorBubble} />
									</div>
								)}
							</div>
						</div>
					</Section>
				</div>
			</ContainerFluid>
		</ImpressionTrackingProvider>
	);
};

HeaderImage.blokProps = blokProps;
