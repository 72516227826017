import { searchIds } from '@/bloks/Page/SearchPage/searchIds';
import AddSearchClient from 'addsearch-js-client';
import AddSearchUI from 'addsearch-search-ui';
// import 'addsearch-search-ui/dist/addsearch-search-ui.min.css';
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

/**
 * Provider of blog posts
 */
export const useSearch = () => {
	return useContext(Context);
};

interface AddSearchContextNotReady {
	ready: false;
	initialize: () => void;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface AddSearchContextReady {
	ready: true;
	initialize: () => void;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	addSearchClient: AddSearchClient;
	addSearchUI: AddSearchUI;
}

export type AddSearchContext = AddSearchContextReady | AddSearchContextNotReady;

const initialContext: AddSearchContext = {
	ready: false,
	initialize: () => {},
	open: false,
	setOpen: () => {},
};

const Context = createContext<AddSearchContext>(initialContext);

export const useAddSearchProvider = (): AddSearchContext => {
	const [ready, setReady] = useState(false);
	const [open, setOpen] = useState(false);

	const addSearchClient = useRef<AddSearchClient | null>(null);
	const addSearchUI = useRef<AddSearchUI | null>(null);

	const initialize = useCallback(() => {
		if (ready) {
			return;
		}

		if (!addSearchClient.current) {
			addSearchClient.current = new AddSearchClient('c0925c3c5d699c8506c71c7a9f7b5c3f');
		}

		if (!addSearchUI.current) {
			addSearchUI.current = new AddSearchUI(addSearchClient.current, {
				searchParameter: 's',
				debug: true,
			});
			const value = new URLSearchParams(location.search).get('s');
			if (value) {
				// https://github.com/AddSearch/search-ui/blob/master/src/reducers/keyword.js
				addSearchUI.current.reduxStore.dispatch({
					type: 'KEYWORD',
					value,
					skipAutocomplete: true,
					searchFieldContainerId: searchIds.fieldId,
				});
			}
		}

		addSearchUI.current.start();

		setReady(true);
	}, [ready]);

	useEffect(() => {
		if (open && !ready) {
			initialize();
		}
	}, [open, ready, initialize]);

	const common = {
		initialize,
		open,
		setOpen,
	};

	if (!ready) {
		return { ...initialContext, ...common, ready: false };
	}

	return {
		...initialContext,
		...common,
		ready: true,
		addSearchClient: addSearchClient.current!,
		addSearchUI: addSearchUI.current!,
	};
};

interface Props {
	children: React.ReactNode;
	value: AddSearchContext;
}

export const AddSearchProvider: React.FC<Props> = ({ value, children }) => {
	return <Context.Provider value={value}>{children}</Context.Provider>;
};
