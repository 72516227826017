import { GridProps } from '@/atoms/Grid';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { isOdd } from '@/utils/isOdd';

export const useCardMediumGridColumns = (itemCount: number, group: boolean, cardMediumListGroup?: boolean) => {
	const { hasSubMenuV1 } = useSubMenu();
	const columns: GridProps['columns'] = { base: 1 };

	if (group) {
		if (itemCount === 3) {
			columns.xxl = 3;
			columns.xl = 3;
			columns.lg = 2;
			columns.md = 2;

			return columns;
		}

		if (isOdd(itemCount)) {
			columns.xxl = 4;
			columns.xl = 4;
			columns.lg = 4;
			columns.md = 2;

			return columns;
		}

		columns.xxl = 2;
		columns.xl = 2;
		columns.lg = 2;
		columns.md = 2;

		return columns;
	}

	columns.xxl = hasSubMenuV1 ? 2 : 4;
	columns.xl = hasSubMenuV1 ? 2 : 4;

	if (itemCount === 7) {
		columns.xxl = 3;
		columns.xl = 3;
		columns.xl = 3;
		columns.lg = 3;
		columns.md = 2;

		return columns;
	}

	if ((cardMediumListGroup && itemCount === 5) || (cardMediumListGroup && itemCount % 3 === 0)) {
		columns.xxl = 3;
		columns.xl = 3;
		columns.xl = 3;
	}

	if (cardMediumListGroup && itemCount === 4) {
		columns.xxl = 4;
		columns.xl = 4;
		columns.xl = 4;
	}

	if (itemCount === 2) {
		columns.xxl = 2;
		columns.xl = 2;
	}

	if (isOdd(itemCount) && !cardMediumListGroup) {
		if (itemCount === 5) {
			columns.xxl = 6;
			columns.xl = hasSubMenuV1 ? 2 : 6;
		}

		if (itemCount % 3 === 0) {
			columns.xxl = 3;
			columns.xl = hasSubMenuV1 ? 2 : 3;
		}

		columns.lg = 4;
		columns.md = 4;

		return columns;
	}

	columns.lg = 2;

	return columns;
};
