const pino = require('pino');

/***
 * Pino is a server-side logging library for logging as JSON in production
 * and prettily during development (using pino-pretty.
 *
 * This configuration is used in two ways:
 *
 * 1. The next-config package (which uses pino) will call this function to
 * create the pino logger instance it will use for all console.log,
 * console.debug, console.warn etc that it detects and patches to use pino
 * instead. This takes care of formatting all the Next.js internal logging.
 *
 * 2. But we are also using pino directly for our intentional server side
 * logging, by having a serverLogger instead, and this one also uses this
 * config. See src/server/serverLogger.ts for more info.
 *
 * @param {import('pino').LoggerOptions} defaultConfig
 */
const logger = (nextLoggerConfig, pretty) => {
	/** @type {import('pino').LoggerOptions} */
	const options = {
		...nextLoggerConfig,
		messageKey: 'message',
		formatters: {
			// Output level as INFO instead of 30
			level(label) {
				return { level: label.toUpperCase() };
			},
			// Skip pid and hostname not needed
			bindings(_bindings) {
				return {};
			},
		},
		// https://getpino.io/#/docs/redaction
		redact: {
			remove: true,
			paths: ['response'],
		},
	};

	const isProd = process.env.NODE_ENV === 'production';

	options.level = process.env.LOG_LEVEL || (isProd ? 'info' : 'debug');

	if (pretty && !isProd) {
		options.transport = {
			target: 'pino-pretty',
			options: {
				colorize: true,
				messageKey: 'message',
				ignore: 'time',
			},
		};
	}

	return pino(options);
};

module.exports = { logger };
