import React, { useEffect, useRef } from 'react';

interface Props {
	children: React.ReactNode;
	callback?: () => void;
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useClickOutsideAlerter = (ref: any, callback: any) => {
	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event: any) {
			if (ref.current && !ref.current.contains(event.target) && callback) {
				callback();
			}
		}
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref, callback]);
};

/**
 * Component that alerts if you click outside of it
 */
export const ClickOutsideAlerter: React.FC<Props> = ({ children, callback }) => {
	const wrapperRef = useRef(null);
	useClickOutsideAlerter(wrapperRef, callback);

	return <div ref={wrapperRef}>{children}</div>;
};
