import { ContainerFluid, Grid, Heading, Section, Text } from '@/atoms';
import { Video } from '@/bloks';
import { LinkButton } from '@/bloks/Button';
import { LinkButtonStoryblok, SectionVideoStoryblok, VideoStoryblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import styles from './SectionVideo.module.scss';

interface Props {
	blok: SectionVideoStoryblok;
	meta?: { zone?: number };
}

export const SectionVideo: React.FC<Props> = ({ blok, meta }) => {
	const { titleEmphasis, title, text, link, layout } = blok;
	const columns = layout === 'vertical' ? { base: 1, lg: 1 } : { base: 1, lg: 2 };
	const { hasSubMenuV1 } = useSubMenu();
	const contentName = `${meta?.zone}|SectionVideo`;
	const contentPiece = title;
	const contentTarget = link ? 'multiple interactions available' : 'one interactions available';
	return (
		<ImpressionTrackingProvider value={{ contentName, contentPiece, contentTarget }}>
			<ContainerFluid>
				<Section {...editableBlok(blok)} data-testid="video-section">
					<Grid columns={columns} rowGap="xl" colGap="xl">
						<Grid.Item className={styles.gridItem}>
							{layout === 'vertical' ? (
								<VideoBlok video={blok?.video} link={link} layout={layout} />
							) : (
								<TextBlok
									titleEmphasis={titleEmphasis}
									title={title}
									text={text}
									layout={layout}
									className={cls(hasSubMenuV1 && styles.hasSubMenuV1)}
								/>
							)}
						</Grid.Item>
						<Grid.Item>
							{layout === 'vertical' ? (
								<TextBlok
									titleEmphasis={titleEmphasis}
									title={title}
									text={text}
									layout={layout}
									className={cls(hasSubMenuV1 && styles.hasSubMenuV1)}
								/>
							) : (
								<VideoBlok video={blok?.video} link={link} layout={layout} />
							)}
						</Grid.Item>
					</Grid>
				</Section>
			</ContainerFluid>
		</ImpressionTrackingProvider>
	);
};

interface TextBlokProps {
	titleEmphasis?: string;
	title?: string;
	text?: string;
	layout?: 'vertical' | 'horizontal';
	className?: string;
}

const TextBlok: React.FC<TextBlokProps> = ({ titleEmphasis, title, text, className }) => (
	<div className={cls(styles.textContainer, styles.verticalLayout, className)}>
		{titleEmphasis && (
			<Heading
				as="div"
				size="h5"
				data-testid="video-section-header-h5"
				title={<span className={styles.titleEmphasis}>{titleEmphasis}</span>}
				titlePart2={title}
			/>
		)}
		{!titleEmphasis && title && <Heading as="div" size="h5" data-testid="video-section-header-h5" title={title} />}
		<Text data-testid="video-section-text">{text}</Text>
	</div>
);

interface VideoBlokProps {
	video?: any;
	link?: LinkButtonStoryblok[] | undefined;
	layout?: 'vertical' | 'horizontal';
}

const VideoBlok: React.FC<VideoBlokProps> = ({ video, link, layout }) => (
	<div
		className={cls(styles.videoContainer, layout === 'vertical' && styles.vertical)}
		data-testid="section-video-container"
	>
		{video?.map((video: VideoStoryblok) => <Video key={video._uid} {...Video.blokProps({ blok: video })} />)}
		<footer className={styles.videoFooter} data-testid="section-video-footer">
			{link?.map((linkButton: LinkButtonStoryblok) => (
				<LinkButton
					key={linkButton._uid}
					{...LinkButton.blokProps({ blok: linkButton })}
					variant="text"
					rel="noopener noreferrer"
					target="_blank"
				/>
			))}
		</footer>
	</div>
);
