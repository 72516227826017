/**
 * Whether Piwik Pro is disabled or not
 *
 */
export const isPiwikDisabled = (() => {
	if (!global.window) {
		return true;
	}

	const query = new URLSearchParams(location.search);

	// Disable Piwik Pro when running inside Storyblok
	if (query.has('_storyblok')) {
		return true;
	}
	if (process.env.NODE_ENV !== 'production') {
		// Skip disable if ?piwik query param is set
		if ((global.window as any).Cypress && !query.has('piwik')) {
			return true;
		}
	}
	return false;
})();
