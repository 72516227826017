import { Grid, Heading } from '@/atoms';
import { LinkButtonStoryblok, TextLinkGroupStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { Blok } from '../../Blok';
import { LinkButton } from '../LinkButton';
import styles from './TextLinkGroup.module.scss';

interface BlokProps {
	blok: TextLinkGroupStoryblok;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	linkButtons: blok.linkButtons,
	title: blok?.title,
	_editable: blok._editable,
});

interface Props {
	title?: string;
	linkButtons: LinkButtonStoryblok[];
	layout?: 'vertical' | 'horizontal';
	_editable?: string | undefined;
}

export const TextLinkGroup: Blok<Props, BlokProps> = ({ title, linkButtons, layout = 'horizontal', _editable }) => {
	return (
		<div className={styles.className} {...editableBlok({ _editable })}>
			{title && <Heading className={styles.title} as="div" size="h6" title={title} marginBottom="xl" />}
			<Grid
				columns={layout === 'horizontal' ? { base: 1, md: 2, lg: 3, xl: 3 } : { base: 1, md: 1, lg: 1, xl: 1 }}
				rowGap="sm"
				colGap="md"
			>
				{linkButtons?.map((linkButton) => (
					<Grid.Item key={linkButton._uid} className={cls(layout === 'vertical' && styles.linkButtons)}>
						<LinkButton {...LinkButton.blokProps({ blok: linkButton })} variant="text" />
					</Grid.Item>
				))}
			</Grid>
		</div>
	);
};

TextLinkGroup.blokProps = blokProps;
