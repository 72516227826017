import { Icon } from '../Icon';
import styles from './Rating.module.scss';

interface Props {
	rating: number;
	testID?: string;
}

interface StarProps {
	icon: 'star' | 'star-half';
}

const Star: React.FC<StarProps> = ({ icon }) => {
	return <Icon name={icon} type="solid" size="1x" className={styles.icon} />;
};

const getStars = (rating: number): any[] => {
	const wholeStars = Math.floor(rating);
	const halfStar = Math.round(rating * 2) % 2;
	const starElements: any[] = [];

	for (let i = 0; i < wholeStars; i++) {
		starElements.push(<Star key={i} icon="star" />);
	}
	if (halfStar) {
		starElements.push(<Star key="half" icon="star-half" />);
	}

	return starElements;
};

export const Rating: React.FC<Props> = ({ rating, testID }) => {
	const stars = getStars(rating);

	return (
		<div className={styles.ratingDisplay} data-test-id={testID}>
			{stars}
		</div>
	);
};
