import React, { createContext, useContext } from 'react';

/**
 * Educations from EduAdmin
 */
export enum EducationType {
	InPlace = 'På plats',
	Remote = 'Distans',
	Recorded = 'Inspelad',
	Package = 'Paket',
	Customized = 'Anpassad',
}

export const EducationTypesWithOccasion: EducationType[] = [EducationType.Remote, EducationType.InPlace];

const filterValidCourses = () => {
	return (course: Course) =>
		(course.EducationType &&
			[EducationType.Recorded].includes(course.EducationType) &&
			course.VimeoId &&
			course.VimeoId.length > 0) ||
		course.UpcomingEvents?.length > 0;
};

export const useCategories = (): CategoriesState => {
	const { categories, courses } = useContext(CategoriesContext);
	const categoriesShowOnWeb = categories?.value?.filter((course) => course.ShowOnWeb == true) ?? [];
	const allCourses: Course[] = courses?.value?.map(transformCourseFields).filter(filterValidCourses()) ?? [];
	return {
		categories: {
			value: categoriesShowOnWeb,
		},
		courses: {
			value: allCourses,
		},
	};
};

export const useCourses = (): CoursesState => {
	const { courses, subjects, targetGroups, educationTypes } = useContext(CoursesContext);
	const allCourses: Course[] = courses?.value?.map(transformCourseFields).filter(filterValidCourses()) ?? [];
	return {
		courses: {
			value: allCourses,
		},
		subjects,
		targetGroups,
		educationTypes,
	};
};

export const useCategory = (courses: Course[] | undefined): Category | undefined => {
	const course = courses?.length ? courses[0] : undefined;
	const { categories } = useContext(CategoriesContext);
	const categoryId = course?.CategoryId || undefined;
	return categories?.value?.find((category) => category.CategoryId == categoryId);
};

export const useCourse = (): CourseState | undefined => {
	const { course, event, events, eventHasCoupon } = useContext(CourseContext);

	if (!course) {
		return;
	}

	const postToZendesk = course?.CustomFields?.some(
		(field) => field?.CustomFieldName === 'Zendesk' && field?.CustomFieldChecked,
	);

	return {
		event,
		events,
		course: transformCourseFields(course),
		postToZendesk,
		eventHasCoupon,
	};
};

const transformCourseFields = (course: Course): Course => {
	const translateToEnglish = course?.CustomFields?.some(
		(field) => field?.CustomFieldName === 'Engelska' && field?.CustomFieldChecked,
	);

	const isSpecialDietAvailable = course?.CustomFields?.some(
		(field) => field?.CustomFieldName === 'Specialkost' && field?.CustomFieldChecked,
	);

	const vimeoId = course?.CustomFields?.find(
		(field) => field?.CustomFieldName === 'Vimeo id' && field.CustomFieldValue,
	)?.CustomFieldValue;

	const educationType = course?.CustomFields?.find(
		(field) => field?.CustomFieldName === 'Utbildningstyp' && field?.CustomFieldAlternativeValue,
	)?.CustomFieldAlternativeValue as EducationType;

	const targetGroupsShort = course?.CustomFields?.filter(
		(field) => field?.CustomFieldName === 'Målgrupp' && field?.CustomFieldAlternativeValue,
	)?.map((field) => field?.CustomFieldAlternativeValue);

	const subjects = course?.CustomFields?.filter(
		(field) => field?.CustomFieldName === 'Ämne' && field?.CustomFieldAlternativeValue,
	)?.map((field) => field?.CustomFieldAlternativeValue);

	const upcomingEvents = course.Events?.filter((event) => new Date(event.StartDate) > new Date());

	return {
		...course,
		TranslateToEnglish: translateToEnglish,
		IsSpecialDietAvailable: isSpecialDietAvailable,
		TargetGroupsShort: targetGroupsShort,
		EducationType: educationType,
		VimeoId: vimeoId,
		Subjects: subjects,
		UpcomingEvents: upcomingEvents,
	};
};

export interface Categories {
	value: Category[];
}

export interface Category {
	CategoryId: number;
	ParentCategoryId: number;
	CategoryName: string | '';
	ShowOnWeb: boolean;
	ImageUrl: string | null;
	CategoryNotes: string | '';
}

export interface Courses {
	value: Course[];
}

export interface CustomFields {
	value: CustomField[];
}

export interface CustomField {
	CustomFieldId: number;
	CustomFieldName: string;
	CustomFieldChecked: boolean;
	CustomFieldValue: string;
	CustomFieldAlternativeValue: string;
	CustomFieldAlternatives: {
		CustomFieldAlternativeValue: any;
		sortIndex: number;
	}[];
}

export interface Course {
	CourseTemplateId: number;
	CourseName: string;
	CourseDescription: string | null;
	CourseDescriptionShort: string | null;
	CourseGoal: string | '';
	TargetGroup: string | null;
	Prerequisites: string | null;
	Quote: string | null;
	Notes: string | null;
	ShowOnWeb: boolean;
	CategoryId: number;
	CategoryName: string;
	ImageUrl: string | null;
	Department: string | '';
	SortIndex: number | null;
	CustomFields: CustomField[];
	TranslateToEnglish: boolean;
	EducationType?: EducationType;
	VimeoId: string | undefined;
	Subjects: string[];
	TargetGroupsShort: string[];
	IsSpecialDietAvailable: boolean;
	Events: Event[];
	UpcomingEvents: Event[];
	Categories: Category[];
	PriceNames: PriceName[];
}

export interface Events {
	value: Event[];
}

export interface Event {
	EventId: number;
	EventName: string;
	CourseTemplateId: number;
	CourseName: string;
	CategoryId: number;
	ShowOnWeb: boolean;
	City: string | null;
	AddressName: string | null;
	StartDate: string;
	EndDate: string;
	MinParticipantNumber: number;
	MaxParticipantNumber: number;
	NumberOfBookedParticipants: number;
	ParticipantNumberLeft: number;
	StatusId: number;
	StatusText: string;
	ApplicationOpenDate: string;
	LastApplicationDate: string;
	Sessions: Session[];
	PriceNames: PriceName[];
	OnDemand: boolean;
	WebinarUrl: string | null;
}

export interface EventHasCoupon {
	HasCoupon: boolean;
}

export interface Session {
	SessionId: number;
	SessionTemplateId: number;
	SessionName: string;
	StartDate: string;
	EndDate: string;
	MaxParticipantNumber: number;
	NumberOfBookedParticipants: number;
	ParticipantNumberLeft: number;
	SelectedByDefault: boolean;
	MandatoryParticipation: boolean;
}

export interface PriceName {
	Price: number;
}

export interface Booking {
	EventId: number;
	CouponCode: string;
	ContactPerson: {
		AddAsParticipant: boolean | true;
		FirstName: string;
		LastName: string;
		Email: string;
		Mobile: string;
		Sessions: { SessionId: number }[];
		CustomFields: { CustomFieldId: number; CustomFieldValue: any }[];
	};
	Customer: {
		CustomerNumber?: string;
		OrganisationNumber: string;
		CustomerName: string;
		Address: string;
		Zip: string;
		City: string;
	};
	SendConfirmationEmail: {
		SendToParticipants: boolean | false;
		SendToCustomer: boolean | false;
		SendToCustomerContact: boolean | false;
	};
	Options: {
		SkipDuplicateMatchOnCustomer: boolean | false;
		IgnoreRemainingSpots: boolean | false;
		SkipDuplicateMatchOnPersons: boolean | false;
		IgnoreIfPersonAlreadyBooked: boolean | false;
		IgnoreMandatoryQuestions: boolean | true;
		ForceUsePostedPriceName: boolean | false;
	};
}

export interface BookingBadRequest {
	Message: string | null;
	ErrorMessages: string[] | null;
}

export interface BookingForbidden {
	Errors: [
		{
			ErrorCode: number;
			ErrorText: string;
		},
	];
}

const CategoriesContext = createContext<CategoriesState>({});
const CourseContext = createContext<CourseState>({});
const CoursesContext = createContext<CoursesState>({});

interface CategoriesState {
	categories?: Categories;
	courses?: Courses;
}

interface CourseState {
	course?: Course;
	events?: Events;
	event?: Event;
	eventHasCoupon?: EventHasCoupon;
	postToZendesk?: boolean;
}

interface CoursesState {
	courses?: Courses;
	subjects?: CustomFields | null;
	targetGroups?: CustomFields | null;
	educationTypes?: CustomFields | null;
}

interface CategoryProps extends CategoriesState {
	children: React.ReactNode;
}

export const EducationProvider: React.FC<CategoryProps> = ({ children, categories, courses }) => {
	return <CategoriesContext.Provider value={{ categories, courses }}>{children}</CategoriesContext.Provider>;
};

interface CourseProps extends CourseState {
	children: React.ReactNode;
}

export const EducationCourseProvider: React.FC<CourseProps> = ({ children, course, events, event, eventHasCoupon }) => {
	return <CourseContext.Provider value={{ course, events, event, eventHasCoupon }}>{children}</CourseContext.Provider>;
};

interface CoursesProps extends CoursesState {
	children: React.ReactNode;
}

export const EducationCoursesProvider: React.FC<CoursesProps> = ({
	children,
	courses,
	subjects,
	targetGroups,
	educationTypes,
}) => {
	return (
		<CoursesContext.Provider value={{ courses, subjects, targetGroups, educationTypes }}>
			{children}
		</CoursesContext.Provider>
	);
};
