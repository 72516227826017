import { ContainerFluid, Grid, Section } from '@/atoms';
import DynamicComponent from '@/bloks/DynamicComponent';
import { SectionCommonTwoComponentsStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import styles from './SectionCommonTwoComponents.module.scss';

interface Props {
	blok: SectionCommonTwoComponentsStoryblok;
	meta: Record<string, any>;
}

/**
 * @deprecated Will be removed
 *
 * Section component that takes two bloks and renders them 50/50
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=158%3A6693
 */
export const SectionCommonTwoComponents: React.FC<Props> = ({ blok, meta }) => {
	const theme = blok.theme?.[0]?.theme ?? 'white';

	return (
		<ContainerFluid theme={theme}>
			<Section
				{...editableBlok(blok)}
				className={cls(styles.container, styles[`theme--${theme}`])}
				padding={{ base: 'xl', md: '3xl' }}
			>
				<Grid columns={{ base: 1, lg: 2 }} rowGap="xl" colGap="xl">
					{blok.body?.map((item) => {
						return (
							<Grid.Item key={item._uid}>
								<DynamicComponent blok={item} meta={{ ...meta, isParent: false }} />
							</Grid.Item>
						);
					})}
				</Grid>
			</Section>
		</ContainerFluid>
	);
};
