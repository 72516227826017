import { Grid } from '@/atoms';
import { CardKeyFigures } from '@/bloks/Card';
import { CardKeyFiguresEquationGroupStoryblok } from '@/components';
import { cls } from '@/utils';
import styles from './CardKeyFiguresEquationGroup.module.scss';

interface Props {
	blok: CardKeyFiguresEquationGroupStoryblok;
	className?: string;
}

export function CardKeyFiguresEquationGroup({ blok, className }: Props) {
	const { values, sum } = blok;

	return (
		<Grid className={cls(className, styles.equationGrid)} columns={{ base: 1, md: 3, lg: 3, xl: 3 }} rowGap="3xl">
			{values?.map((card, index) => {
				let operator;
				switch (index) {
					case 0:
						operator = '+';
						break;
					case 1:
						operator = '=';
						break;
					default:
						operator = '';
				}
				const props = { blok: card, meta: { operator } };

				return (
					<Grid.Item key={card._uid} className={styles.gridItem}>
						<CardKeyFigures {...CardKeyFigures.blokProps(props)} />
					</Grid.Item>
				);
			})}
			{sum?.map((card) => {
				const props = { blok: card };
				return (
					<Grid.Item key={card._uid} className={styles.gridItem}>
						<CardKeyFigures {...CardKeyFigures.blokProps(props)} />
					</Grid.Item>
				);
			})}
		</Grid>
	);
}
