import React, { createContext, useContext } from 'react';
import { Department, JobAdvertisement, JobAdvertisementInitialState, JobLocation } from './types';

export const useJobs = () => useContext(Context);

export const useSingleJob = (): any => useContext(SingleJobContext);

interface FilterProps {
	location?: string | null;
	department?: string | null;
	searchValue?: string;
	slice?: number;
}

export const useFilteredJobs = ({ location, department, slice }: FilterProps): JobAdvertisement[] | [] => {
	const { jobs } = useContext(Context);

	return (
		jobs
			?.filter((job) => {
				const matchedByLocation = job?.locationIds?.some((locationId: any) => locationId === location);
				const matchedByDepartment = job?.departmentId === department;

				if ((matchedByLocation || !location) && (matchedByDepartment || !department)) {
					return job;
				}
			})
			.slice(0, slice) || []
	);
};

export const useJobLocations = (locationIds: string[]) => {
	const { locations } = useContext(Context);

	const jobLocations = locations
		?.map((location) => {
			if (locationIds?.includes(location.id)) {
				return location.name;
			}
		})
		.filter(Boolean);

	return jobLocations ?? [];
};

export const useJobDepartment = (departmentId: string) => {
	const { departments } = useContext(Context);

	const jobDepartment = departments
		?.map((department) => {
			if (department.id === departmentId) {
				return department.name;
			}
		})
		.filter(Boolean);

	return jobDepartment ?? [];
};

export const useSingleJobLocations = () => {
	const { job, locations } = useContext(SingleJobContext);

	const singleJobLocations = locations
		?.map((location) => {
			if (job?.locationIds?.includes(location.id)) {
				return location.name;
			}
		})
		.filter(Boolean);

	return singleJobLocations ?? [];
};
interface ContextProps {
	jobs?: JobAdvertisement[] | [];
	locations?: JobLocation[];
	departments?: Department[];
}

const Context = createContext<ContextProps>({
	jobs: [],
	locations: [],
	departments: [],
});

interface Props {
	children: React.ReactNode;
	value: ContextProps;
}

export const TeamtailorProvider: React.FC<Props> = ({ children, value }) => {
	return <Context.Provider value={value}>{children}</Context.Provider>;
};

interface SingleJobContextProps {
	job?: JobAdvertisement;
	locations: JobLocation[];
}

const SingleJobContext = createContext<SingleJobContextProps>({
	job: JobAdvertisementInitialState,
	locations: [],
});

interface SingleJobProps {
	children: React.ReactNode;
	job: JobAdvertisement;
	locations: JobLocation[];
}

export const TeamtailorSingleJobProvider: React.FC<SingleJobProps> = ({ children, job, locations }) => {
	const value = {
		job,
		locations,
	};
	return <SingleJobContext.Provider value={value}>{children}</SingleJobContext.Provider>;
};
