import { Grid, Section } from '@/atoms';
import { StorySectionSmallStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import DynamicComponent from '../DynamicComponent';
import styles from './StorySectionSmall.module.scss';

interface Props {
	blok: StorySectionSmallStoryblok;
}

/**
 * General Section component that sets columns based on number of items. Used for example Usp's but can be used for any blok.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=184%3A10432
 */
export const StorySectionSmall: React.FC<Props> = ({ blok }) => {
	const { theme, items } = blok;
	const numberOfItems = items?.length;

	return (
		<Section
			maxWidth="925px"
			{...editableBlok(blok)}
			className={cls(styles.container, styles[`theme--${theme?.[0]?.theme}`])}
		>
			<Grid columns={{ base: 1 }} colGap="2xl" rowGap="md" className={styles.grid}>
				{items?.map((item) => (
					<Grid.Item key={item._uid} className={cls(numberOfItems === 1 && styles.singleItem)}>
						<DynamicComponent blok={item} meta={{ theme }} />
					</Grid.Item>
				))}
			</Grid>
		</Section>
	);
};
