import { BackgroundImage, ContainerFluid, Grid, Section } from '@/atoms';
import { HeaderCommon } from '@/bloks';
import DynamicComponent from '@/bloks/DynamicComponent';
import { SectionAttractStoryblok } from '@/components';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { ImageAsset } from '@/metabloks';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok/editableBlok';
import React from 'react';
import { LinkButton } from '../../Button';
import styles from './SectionAttract.module.scss';

interface Props {
	blok: SectionAttractStoryblok;
	meta: Record<string, unknown>;
}

export function SectionAttract({ blok, meta }: Props) {
	const { header, content, backgroundImage, layoutCenter } = blok;
	const theme = blok.theme[0]?.theme ?? 'white';
	const alignCenter = content && content[0]?.component !== 'ShortText';
	let contentTarget = blok.header[0].linkButtons ? 'multiple interactions possible' : 'no interactions possible';
	if (blok?.header[0]?.linkButtons?.length === 1) contentTarget = 'one interaction possible';

	return (
		<ImpressionTrackingProvider value={{ contentName: `${meta?.zone}|SectionAttract`, contentTarget }}>
			<ContainerFluid
				noBackgroundColor={
					backgroundImage?.filename !== null &&
					backgroundImage?.filename !== undefined &&
					backgroundImage?.filename !== ''
				}
				className={styles.outerContainer}
				theme={theme}
			>
				{backgroundImage?.filename && (
					<BackgroundImage
						className={styles.backgroundImage}
						asset={backgroundImage}
						objectPosition="center top"
						zIndex={-1}
					/>
				)}
				<Section className={styles.innerContainer} {...editableBlok(blok)}>
					<Grid
						className={styles.gridContainer}
						columns={layoutCenter ? { base: 1 } : { base: 1, md: 2 }}
						colGap="2xl"
						rowGap="2xl"
					>
						<Grid.Item className={cls(styles.gridItem, alignCenter, layoutCenter && styles.layoutCenter)}>
							<div className={styles.headerContainer}>
								{header?.map((header) => (
									<HeaderCommon
										key={header._uid}
										smallTitle={header.smallTitle}
										titlePart1={header.titlePart1}
										titlePart2={header.titlePart2}
										titleSize={header.titleSize}
										text={header.text}
										linkButtons={header.linkButtons?.map((btn) => ({ ...LinkButton.blokProps({ blok: btn, meta }) }))}
										linebreak
										layout={layoutCenter ? 'center' : 'left'}
										info={header.info}
									/>
								))}
							</div>
						</Grid.Item>
						{!layoutCenter && (
							<Grid.Item className={cls(styles.gridItem, alignCenter && styles.alignCenter)}>
								{content?.map((content: any) => {
									if (content.image) {
										return (
											<div key={content._uid} className={styles.imageContainer}>
												<ImageAsset absolute objectFit="cover" fill asset={content.image} />
											</div>
										);
									}
									return (
										<DynamicComponent key={content._uid} blok={content} meta={{ ...meta, theme, maxWidth: '260px' }} />
									);
								})}
							</Grid.Item>
						)}
					</Grid>
				</Section>
			</ContainerFluid>
		</ImpressionTrackingProvider>
	);
}
