import { Grid, getHeadingAnchorId } from '@/atoms';
import { ExpansionPanel as Panel } from '@/atoms/ExpansionPanel/ExpansionPanel';
import { ExpansionPanelsProvider } from '@/atoms/ExpansionPanel/ExpansionPanelContext';
import { LinkItem } from '@/bloks/Other/LinkItem';
import { AccordionLinkPanelStoryblok, AccordionLinkStoryblok, LinkItemStoryblok } from '@/components';
import { Themes } from '@/types';
import { editableBlok } from '@/utils/storyblok';
import { Blok } from '../../Blok';
import styles from './AccordionLink.module.scss';

interface BlokProps {
	blok: AccordionLinkStoryblok;
	meta: { theme: Themes['theme']; compact?: boolean };
}

const blokProps = ({ blok, meta }: BlokProps): Props => ({
	_editable: blok._editable,
	panels: blok.panels ?? [],
	theme: meta?.theme ? meta.theme : 'white',
	compact: meta.compact,
});

interface Props {
	_editable?: string;
	panels: AccordionLinkPanelStoryblok[];
	theme: Themes['theme'];
	compact?: boolean;
}

/**
 * Accordion for grouping multiple links
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=1704%3A31382
 */
export const AccordionLink: Blok<Props, BlokProps> = ({ _editable, panels, theme, compact }) => {
	return (
		<div className={styles.accordionContainer} {...editableBlok({ _editable })}>
			<ExpansionPanelsProvider>
				{panels?.map((panel: AccordionLinkPanelStoryblok, index: number) => {
					const links = panel.links;
					const half = Math.ceil(links.length / 2);
					const firstHalf = links.length > 1 ? links.slice(0, half) : links;
					const secondHalf = links.length > 1 ? links.slice(firstHalf.length) : [];
					const panelId = getHeadingAnchorId(panel.title);

					return (
						<Panel panelId={panelId} key={index} theme={theme} compact={compact}>
							<Panel.Header
								panelId={panelId}
								title={panel.title}
								dataIdNr={index}
								compact={compact}
								titleSize={compact ? 'h6' : 'h5'}
							/>
							<Panel.Content panelId={panelId} dataIdNr={index} compact={compact}>
								<Grid columns={{ base: 1, md: 2 }} colGap="3xl">
									<Grid.Item>
										<ul className={styles.list}>
											{firstHalf.map((link: LinkItemStoryblok, index: number) => (
												<li key={index} className={styles.listItem}>
													<LinkItem {...LinkItem.blokProps({ blok: link })} />
												</li>
											))}
										</ul>
									</Grid.Item>
									{secondHalf && (
										<Grid.Item>
											<ul className={styles.list}>
												{secondHalf.map((link: LinkItemStoryblok, index: number) => (
													<li key={index} className={styles.listItem}>
														<LinkItem {...LinkItem.blokProps({ blok: link })} />
													</li>
												))}
											</ul>
										</Grid.Item>
									)}
								</Grid>
							</Panel.Content>
						</Panel>
					);
				})}
			</ExpansionPanelsProvider>
		</div>
	);
};

AccordionLink.blokProps = blokProps;
