import { Grid, Heading, Price, RoundedSquare, Text } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import DynamicComponent from '@/bloks/DynamicComponent';
import {
	LinkButtonStoryblok,
	ListStoryblok,
	PriceCardLargeMainProductStoryblok,
	ProductCatalogItemStoryblok,
} from '@/components';
import { useProduct } from '@/contexts/products';
import { ISbStoryData } from '@/types/storyblok';
import { handlePriceCardClickEvent } from '@/utils/piwik';
import { StoryblokStory } from 'storyblok-generate-ts';
import React from 'react';
import { t } from 'ttag';
import styles from './PriceCardLarge.module.scss';

interface BlokProps {
	blok: PriceCardLargeMainProductStoryblok;
	meta: {
		zone: number | undefined;
	};
}

interface PriceCardMainProps {
	title?: string;
	subTitle?: string;
	description?: string;
	list: ListStoryblok[];
	product: string | StoryblokStory<ProductCatalogItemStoryblok>;
	zone: number | undefined;
}

const blokProps = ({ blok, meta }: BlokProps): PriceCardMainProps => ({
	title: blok.title,
	subTitle: blok.subTitle,
	description: blok.description,
	list: blok.list,
	product: blok.product,
	zone: meta.zone,
});

export function PriceCardMainProduct({ zone, title, subTitle, description, list, product }: PriceCardMainProps) {
	const mainProduct = product as any as ISbStoryData<ProductCatalogItemStoryblok>;
	const buttons = mainProduct.content?.linkButtons;
	const productCatalogData = useProduct(mainProduct.slug);
	return (
		<Grid columns={{ lg: 2, base: 1 }} colGap="3xl" rowGap="xl" className={styles.productsHeader}>
			<Grid.Item className={styles.priceCardGridItem}>
				<div className={styles.heading}>
					<RoundedSquare height={2} color="--primary-yellow" />
					{title && <Heading as="h3" size="h4" data-testid="product-card-header" title={title} />}
				</div>
				{subTitle && <Heading as="div" size="h6" title={subTitle} />}
				<Text marginBottom="xl">{description}</Text>
				<div className={styles.priceColumn}>
					{productCatalogData && (
						<div className={styles.priceBox}>
							<Price priceStructure={productCatalogData?.priceStructure} priceText={t`kr/mån`} />
						</div>
					)}
					<div className={styles.buttonContainer}>
						{buttons &&
							buttons.map((button: LinkButtonStoryblok, index: number) => (
								<LinkButton
									onClick={() => {
										handlePriceCardClickEvent({
											productName: mainProduct.name,
											productId: mainProduct.slug,
											zone: zone,
											buttonVariant: index === 0 ? 'filled' : 'outlined',
											buttonText: button.text,
										});
									}}
									key={button._uid}
									{...LinkButton.blokProps({ blok: button })}
									variant={index === 0 ? 'filled' : 'outlined'}
									className={styles.btn}
								/>
							))}
					</div>
				</div>
			</Grid.Item>
			<Grid.Item className={styles.listContainer}>
				{list.map((li: ListStoryblok) => (
					<DynamicComponent key={li._uid} blok={li} meta={{ bold: true, iconColor: '--primary-green' }} />
				))}
			</Grid.Item>
		</Grid>
	);
}

PriceCardMainProduct.blokProps = blokProps;
