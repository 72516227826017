import { CardFaqStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { AccordionFaq } from '../Accordion';
import { LinkButton } from '../Button';
import DynamicComponent from '../DynamicComponent';
import common from './CardContact.module.scss';
import styles from './CardFaq.module.scss';

interface Props {
	blok: CardFaqStoryblok;
	meta?: Record<string, any>;
}

export const CardFaq: React.FC<Props> = ({ blok, meta }) => {
	const theme = meta?.theme ?? blok.theme?.[0]?.theme;

	return (
		<div className={styles.card}>
			<div
				{...editableBlok(blok)}
				className={cls(common.container, common[`theme--${theme}`])}
				data-testid="card-faq-container"
			>
				{blok.header &&
					blok.header.map((head) => {
						return <DynamicComponent key={head._uid} blok={head} meta={meta} />;
					})}
				<div className={common.text}>
					{blok?.faq.length && (
						<AccordionFaq blok={blok?.faq[0]} meta={{ ...meta, compact: true, theme: 'lightGreen' }} />
					)}
				</div>
				{blok.link?.map((button) => <LinkButton key={button._uid} {...LinkButton.blokProps({ blok: button })} />)}
			</div>
		</div>
	);
};
