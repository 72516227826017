import React, { createContext, useContext, useState } from 'react';

export const useFormValidation = (): ContextProps => {
	const context = useContext(ValidationContext);

	if (context === undefined) {
		throw new Error('CaptchaForm is missing a wrapping provider.');
	}

	const validationValues = [...context.validations.values()].filter(Boolean);
	return { ...context, isFormValid: validationValues.length < 1 };
};
interface ContextProps {
	validations: Map<string, string>;
	setValidations: ((prevState: Map<string, string>) => void) | any;
	isFormValid: boolean;
}

export const ValidationContext = createContext<ContextProps>({
	validations: new Map(),
	setValidations: () => {},
	isFormValid: false,
});

interface FormProps {
	children: React.ReactNode;
}

export const FormValidationProvider: React.FC<FormProps> = ({ children }) => {
	const [validations, setValidations] = useState(new Map());
	const isFormValid = false;

	const value = {
		validations,
		setValidations,
		isFormValid,
	};

	return <ValidationContext.Provider value={value}>{children}</ValidationContext.Provider>;
};

export const useFieldId = (): FieldContextProps => useContext(FieldContext);
interface FieldContextProps {
	fieldId: string | undefined;
	setFieldId: (fieldVId: string) => void;
}

export const FieldContext = createContext<FieldContextProps>({
	fieldId: '',
	setFieldId: () => {},
});

interface FieldProps {
	children: any[] | any;
	id: string;
}

export const FieldValidationProvider: React.FC<FieldProps> = ({ children, id }) => {
	const [fieldId, setFieldId] = useState<string | undefined>(id);

	const value = {
		fieldId,
		setFieldId,
	};

	return <FieldContext.Provider value={value}>{children}</FieldContext.Provider>;
};
