import footer from './footer.module.scss';
import infoBannerInfo from './infoBannerInfo.module.scss';
import infoBannerMajor from './infoBannerMajor.module.scss';
import infoBannerMinor from './infoBannerMinor.module.scss';
import menu from './menu.module.scss';
import menuContent from './menuContent.module.scss';
import menuV3 from './menuV3.module.scss';
import submenu from './submenu.module.scss';
import submenuV2 from './submenuV2.module.scss';

export { footer, submenu, menu, menuV3, menuContent, infoBannerInfo, infoBannerMinor, infoBannerMajor, submenuV2 };
