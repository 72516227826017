import { Icon, Text } from '@/atoms';
import { cls } from '@/utils';
import React, { BaseSyntheticEvent, useState } from 'react';
import { ErrorCode, useDropzone } from 'react-dropzone';
import { t } from 'ttag';
import { Grid } from '../Grid';
import styles from './FileUpload.module.scss';

interface Props {
	onUpload: (files: any[]) => void;
}

export function FileUpload({ onUpload }: Props) {
	const [filesToUpload, setFilesToUpload] = useState<File[]>([]);

	const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
		onDrop(acceptedFiles) {
			setFilesToUpload(acceptedFiles);
			onUpload(acceptedFiles);
		},
		accept: {
			'text/*': [],
			'image/*': [],
			'video/*': [],
			'application/msword': [],
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
			'application/vnd.oasis.opendocument.spreadsheet': [],
			'application/vnd.ms-excel': [],
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
			'application/pdf': [],
			'application/zip': [],
			'application/gzip': [],
			'application/json': [],
			'application/xml': [],
		},
		maxSize: 20971520,
	});

	const removeFile = (e: BaseSyntheticEvent, index: number) => {
		e.preventDefault();
		const newFiles = [...filesToUpload];
		newFiles.splice(index, 1);
		setFilesToUpload(newFiles);
		onUpload(newFiles);
	};

	const list = (files: File[], title: string) => {
		return (
			<>
				<Text>
					<strong>{title}</strong>
				</Text>
				<ul className={styles.list}>
					{files.map((file: File, index: number) => (
						<li key={index} className={styles.listItem}>
							<div className={styles.leftColumn}>
								<Icon className={styles.icon} name="chevron-right" color="--primary-green" />
								<div className={styles.textContainer}>{file.name}</div>
							</div>
							<button
								className={styles.deleteFile}
								title={t`Ta bort uppladdad fil`}
								aria-label={t`Ta bort uppladdad fil`}
								onClick={(event) => {
									removeFile(event, index);
								}}
							>
								<Icon name="trash-can" />
							</button>
						</li>
					))}
				</ul>
			</>
		);
	};

	const getErrorMsg = (code: ErrorCode): string => {
		switch (code) {
			case ErrorCode.FileInvalidType:
				return t`Otillåten filtyp`;
			case ErrorCode.FileTooLarge:
				return t`Filen är för stor`;
			case ErrorCode.FileTooSmall:
				return t`Filen är för liten`;
			case ErrorCode.TooManyFiles:
				return t`För många filer`;
			default:
				return t`Något gick fel när filen lades till`;
		}
	};

	return (
		<>
			<div {...getRootProps()}>
				<div className={styles.dropzone}>
					<input {...getInputProps()} />
					{isDragActive
						? t`Släpp filerna här`
						: t`Släpp filer här eller klicka för att ladda upp. Max storlek 20MB/fil.`}
				</div>
			</div>
			<div className={styles.uploadedFiles}>
				<Grid columns={{ base: 1, lg: 2 }} rowGap="xl">
					<Grid.Item>{filesToUpload.length > 0 && <>{list(filesToUpload, t`Dina uppladdade filer`)}</>}</Grid.Item>
					<Grid.Item>
						{fileRejections.length > 0 && (
							<>
								<Text>
									<strong>{t`Avvisade filer`}</strong>
								</Text>
								<ul className={styles.list}>
									{fileRejections.map((rejection: any, index: number) => (
										<li key={index} className={cls(styles.listItem, styles.rejected)}>
											<div className={styles.leftColumn}>
												<Icon className={styles.icon} name="close" color="--danger-color" />
												<div className={styles.textContainer}>{rejection.file.name}</div>
											</div>

											<ul className={styles.list}>
												{rejection.errors.map((error: any, index: number) => (
													<li key={index} className={styles.error}>
														{getErrorMsg(error.code as ErrorCode)}
													</li>
												))}
											</ul>
										</li>
									))}
								</ul>
							</>
						)}
					</Grid.Item>
				</Grid>
			</div>
		</>
	);
}
