import classNames from 'classnames';
import { t } from 'ttag';
import { Icon } from '../Icon';
import styles from './Pagination.module.scss';

interface Props {
	currentPage: number;
	totalPages: number;
	onClick: (event: any) => void;
	className?: string;
}

export const Pagination: React.FC<Props> = ({ currentPage, totalPages, onClick, className }) => {
	return (
		<div className={classNames(styles.pagination, className)}>
			<button aria-label={t`Föregående sida`} value="back" onClick={onClick} data-testid="pagination-left">
				<Icon className={styles.icon} name={'chevron-right'} rotation={180} size={'1x'} />
			</button>
			<span className={styles.pageCounter} data-testid="counter">
				{currentPage} / {totalPages}
			</span>
			<button aria-label={t`Nästa sida`} value="forward" onClick={onClick} data-testid="pagination-right">
				<Icon className={styles.icon} name={'chevron-right'} size={'1x'} />
			</button>
		</div>
	);
};
