import { Card, Grid, Heading, Image, Link, Text } from '@/atoms';
import { IntegrationCardDecoration } from '@/bloks/Page/NavigationPageIntegrations/IntegrationCard/IntegrationCardDecoration/IntegrationCardDecoration';
import { UserInsights } from '@/bloks/Page/NavigationPageIntegrations/IntegrationCard/IntegrationCardDecoration/UserInsights';
import { ImageType, Integration, IntegrationTransform } from '@/contexts/Integration';
import { useServerPageConfig } from '@/contexts/config';
import React from 'react';
import classNames from 'classnames';
import { t } from 'ttag';
import { CategoryList } from '../../IntegrationPage/Components/IntegrationHeader';
import styles from './IntegrationCard.module.scss';

interface IntegrationCardProps {
	integration: Integration | IntegrationTransform;
	size?: 'small' | 'medium' | 'large';
	type?: 'search';
}

export const IntegrationCard: React.FC<IntegrationCardProps> = ({ integration, size = 'small', type }) => {
	const { appName, urlSlug } = integration;
	const { appsHost: host } = useServerPageConfig();
	const icon = integration.images.find((image) => image.type === ImageType.ICON);

	const iconPath = type == 'search' && icon ? icon.path : `${host}${icon?.path}`;
	const url = type == 'search' ? integration.readMoreUrl : `/integrationer/integration/${urlSlug}`;

	const buyableApp = (() => {
		return integration.priceModel.clientType === 'BuyableApp';
	})();

	const imageSize = { small: 76, medium: 108, large: 160 }[size] || 76;

	return (
		<Link href={url} className={styles.integrationCardLink}>
			<Card className={styles.fullAppCard} padding="lg">
				{buyableApp && (
					<IntegrationCardDecoration
						text={t`Köp hos Fortnox`}
						testID={`app-card-buyable-app-decoration-${integration.urlSlug}`}
					/>
				)}
				<div className={styles.fullAppCardTop}>
					<Grid columns={{ base: 12 }} colGap={'md'} rowGap={'sm'}>
						<Grid.Item colSpan={{ base: 12 }}>
							<div className={styles.header}>
								<div className={styles.iconContainer}>
									{iconPath && (
										<Image
											width={imageSize}
											height={imageSize}
											className={styles.icon}
											src={iconPath}
											alt={`${appName}-icon`}
											title={appName}
										/>
									)}
								</div>
								<div className={styles.integrationCardHeading}>
									<Heading
										as={'h3'}
										size={'h5'}
										marginBottom="none"
										title={integration.appName}
										className={styles.integrationCardHead}
									/>
									<div className={styles.insightsBlock}>
										<UserInsights integration={integration} iconSize="1x" />
										<CategoryList
											categories={integration.categories}
											asLink={false}
											className={styles.categories}
											iconSize="1x"
										/>
									</div>
								</div>
							</div>
						</Grid.Item>
						<Grid.Item colSpan={{ base: 12 }}>
							<Text
								as={'div'}
								className={classNames({
									[styles.summary]: true,
								})}
							>
								{integration.summary}
							</Text>
							<Text as={'div'} size={'small'} marginBottom={'none'} className={styles.companyName}>
								{integration.contactInformation.companyName}
							</Text>
						</Grid.Item>
					</Grid>
				</div>
			</Card>
		</Link>
	);
};
