import { Grid, GridProps, ShowMoreButton } from '@/atoms';
import { ThemeGreenStoryblok, UspLargeGroupStoryblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { useState } from 'react';
import { t } from 'ttag';
import { UspLarge, UspLargeProps } from '../UspLarge';
import styles from './UspLargeGroup.module.scss';

interface BlokProps {
	blok: UspLargeGroupStoryblok;
	meta?: {
		theme?: ThemeGreenStoryblok['theme'];
	};
}

const blokProps = ({ blok, meta }: BlokProps): Props => ({
	usps: blok?.usps?.map((usp) => ({ ...UspLarge.blokProps({ blok: usp }) })),
	theme: meta?.theme,
	_editable: blok._editable,
});

interface Props {
	usps: UspLargeProps[];
	theme?: ThemeGreenStoryblok['theme'];
	_editable?: string;
}

const useGridColumns = (itemCount: number): GridProps['columns'] => {
	const { hasSubMenu } = useSubMenu();
	const columns: GridProps['columns'] = { base: 1 };

	columns.xxl = 4;
	columns.xl = hasSubMenu ? 2 : 4;
	columns.lg = 2;

	if (itemCount === 3 || itemCount === 5) {
		columns.xxl = 3;
		columns.xl = hasSubMenu ? 2 : 3;
		columns.lg = 3;
	}

	columns.md = 2;

	return columns;
};

export function UspLargeGroup({ usps, theme = 'white', _editable }: Props) {
	const nrOfUsps = usps?.length ?? 0;
	const columns = useGridColumns(nrOfUsps);
	const uspsPerRow = nrOfUsps === 5 ? 3 : 4;
	const nrOfRows = nrOfUsps / uspsPerRow;
	const [showRows, setShowRows] = useState(1);

	const onShowMore = () => {
		setShowRows(showRows < nrOfRows ? showRows + 1 : showRows);
	};

	return (
		<>
			<Grid
				columns={columns}
				{...editableBlok({ _editable })}
				className={cls(styles.container, styles[`theme--${theme}`])}
			>
				{usps?.slice(0, uspsPerRow * showRows)?.map((usp, index) => {
					return (
						<Grid.Item key={index}>
							<UspLarge {...usp} theme={theme} />
						</Grid.Item>
					);
				})}
			</Grid>
			{nrOfUsps > 4 && showRows < nrOfRows && <ShowMoreButton onClick={onShowMore}>{t`Visa fler`}</ShowMoreButton>}
		</>
	);
}

UspLargeGroup.blokProps = blokProps;
