import { BrandLine, ContainerFluid, Grid, Heading, Section } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import DynamicComponent from '@/bloks/DynamicComponent';
import { renderRichText } from '@/bloks/RichText';
import { LinkButtonStoryblok, SectionLargeWithUspStoryblok } from '@/components';
import { ImageAsset } from '@/metabloks';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import styles from './SectionLargeWithUsp.module.scss';

interface Props {
	blok: SectionLargeWithUspStoryblok;
}

/**
 *
 * @deprecated Will be removed
 */
export const SectionLargeWithUsp: React.FC<Props> = ({ blok }) => {
	const { image, smallTitle, titlePart1, titlePart2, text, linkButtons, usps } = blok;
	const theme = blok?.theme?.[0]?.theme ?? 'white';
	const layout = blok.imagePosition ?? 'right';

	return (
		<ContainerFluid theme={theme}>
			<Section {...editableBlok(blok)}>
				<div className={cls(styles.container, styles[`layout--${layout}`], styles[`theme--${theme}`])}>
					<Grid
						columns={{ base: 1, lg: image?.filename ? 2 : 1 }}
						colGap="3xl"
						className={styles.imageAndTextContainer}
					>
						<Grid.Item className={cls(styles.content, !image?.filename && styles.contentWithoutImage)}>
							<BrandLine />
							{smallTitle && <Heading as="div" size="h6" className={styles.smallTitle} title={smallTitle} />}
							<div className={styles.titles}>
								{titlePart1 && <Heading as="h2" title={titlePart1} titlePart2={titlePart2} />}
							</div>
							{text && <div className={styles.text}>{renderRichText(text)}</div>}

							{linkButtons && linkButtons?.length > 0 && (
								<div className={styles.linkButtons}>
									{linkButtons.map((button: LinkButtonStoryblok) => (
										<LinkButton
											key={button._uid}
											{...LinkButton.blokProps({ blok: button })}
											variant="filled"
											className={styles.btn}
										/>
									))}
								</div>
							)}
						</Grid.Item>

						{image?.filename && (
							<Grid.Item className={styles.image}>
								<ImageAsset absolute asset={image} objectFit="cover" fill objectPosition="center" filters="smart" />
							</Grid.Item>
						)}
					</Grid>

					{usps?.map((usp) => {
						return <DynamicComponent key={usp._uid} blok={usp} meta={{ theme, isParent: false }} />;
					})}
				</div>
			</Section>
		</ContainerFluid>
	);
};
