import { ErrorPageStoryblok } from '@/components';
import { StoryExact } from '@/types';

// This type helps make sure that the JSON below is up to date
interface ErrorPageFallbackStory extends StoryExact {
	content: ErrorPageStoryblok;
}

// GENERATED BY generateFallbackStoryData.js
export const errorPageFallbackStory: ErrorPageFallbackStory = {
	name: 'Sidan kunde inte hittas',
	created_at: '2023-07-17T11:14:58.726Z',
	published_at: '2023-08-22T09:13:56.880Z',
	id: 340848350,
	uuid: 'f0f7f409-fd11-4b09-af5a-bc2b0c80c698',
	content: {
		_uid: 'dbbaa006-9f18-4bba-b53f-4f3fa6efe539',
		meta: [
			{
				_uid: '76f3016d-f497-46c4-b543-1e0c0c834ca7',
				noIndex: true,
				noFollow: false,
				canonical: {
					id: '',
					url: '',
					linktype: 'story',
					fieldtype: 'multilink',
					cached_url: '',
				},
				component: 'Meta',
			},
		],
		image: {
			id: 3735897,
			alt: 'Vit rykande kaffekopp mot mörkgrön bakgrund',
			name: '',
			title: 'Vit rykande kaffekopp mot mörkgrön bakgrund',
			filename: 'https://a.storyblok.com/f/134143/1920x1080/78a21d9a1e/fortnox-404-1.jpg',
			copyright: '',
			fieldtype: 'asset',
		},
		links: [
			{
				_uid: '2ed7452e-e91c-4158-80a1-0dd7472b6ee8',
				links: [
					{
						_uid: 'a13e1a73-6a43-44a9-a94b-3c64202d1923',
						link: {
							id: '2e9867be-8f5f-4f2c-98f6-cd61850ea570',
							url: '',
							anchor: '',
							linktype: 'story',
							fieldtype: 'multilink',
							cached_url: 'home',
						},
						text: 'Till Startsidan',
						component: 'LinkButton',
					},
					{
						_uid: '889900bd-6f99-4877-a61e-fcf3faaf848c',
						link: {
							id: '05685e4a-b742-43dc-aec9-0a4c477ea01e',
							url: '',
							linktype: 'story',
							fieldtype: 'multilink',
							cached_url: 'paket/',
						},
						text: 'Kom igång med Fortnox',
						component: 'LinkButton',
					},
					{
						_uid: 'a19f3bec-fc71-42a1-b493-ab4f74990d04',
						link: {
							id: '2f600971-4eac-4875-9e40-2e115b85dea5',
							url: '',
							anchor: '',
							linktype: 'story',
							fieldtype: 'multilink',
							cached_url: 'lagerbolag/',
						},
						text: 'Starta bolag',
						component: 'LinkButton',
					},
					{
						_uid: '9d744144-b654-4914-a4cc-1308b54dd650',
						link: {
							id: '5757c876-522e-44a8-b05a-e6ff14d24cd4',
							url: '',
							anchor: '',
							linktype: 'story',
							fieldtype: 'multilink',
							cached_url: 'byra/',
						},
						text: 'För redovisningsbyrån',
						component: 'LinkButton',
					},
					{
						_uid: '586e45fa-15d5-48b9-8909-87a20a7ead3c',
						link: {
							id: '8306aa80-7325-40a8-a844-80dc1f4d97f0',
							url: '',
							linktype: 'story',
							fieldtype: 'multilink',
							cached_url: 'kunskap/',
						},
						text: 'Besök vår kunskapsbank',
						component: 'LinkButton',
					},
					{
						_uid: 'bd9d4e28-6065-473d-9ee7-c85714ffd534',
						link: {
							id: '60b1044f-1faa-4812-ab98-6b152408d870',
							url: '',
							linktype: 'story',
							fieldtype: 'multilink',
							cached_url: 'om-fortnox/',
						},
						text: 'Support & kontakt',
						component: 'LinkButton',
					},
				],
				component: 'LinksCommon',
			},
		],
		h1Part1: 'Vi kan inte hitta sidan som du söker',
		component: 'ErrorPage',
		linkTitle: 'Kanske kan detta hjälpa istället',
		shortText: 'Sidan du söker finns tyvärr inte längre eller har blivit flyttad.',
	},
	slug: 'not-found',
	full_slug: 'global/not-found',
	sort_by_date: null,
	position: 150,
	tag_list: [],
	is_startpage: false,
	parent_id: 84359844,
	meta_data: null,
	group_id: '0e1514f1-bb79-4441-9743-5b2a0bbd0bbf',
	first_published_at: '2022-01-28T15:03:14.000Z',
	lang: 'default',
	alternates: [],
};
