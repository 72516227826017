import { BrandLine, Heading, Text } from '@/atoms';
import { CardOneStoryblok, LinkButtonStoryblok, ThemeStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '../../utils/storyblok';
import { LinkButton } from '../Button';
import styles from './CardOne.module.scss';

interface Props {
	blok: CardOneStoryblok;
	meta: {
		theme: ThemeStoryblok['theme'];
	};
}

/**
 * @deprecated Will be replaced by CardSmallBrandline
 */
export const CardOne: React.FC<Props> = ({ blok, meta }) => {
	const { label, title, linkButton } = blok;
	const theme = meta?.theme ?? 'default';

	return (
		<article {...editableBlok(blok)} className={cls(styles.container, styles[`theme--${theme}`])}>
			<div className={label ? styles.content : styles.contentLift}>
				<BrandLine />
				{label && (
					<Heading as="div" size="h6" title={label} className={styles.smallTitle} data-testid="card-one-header-h6" />
				)}
				<Heading as="div" size="h3" title={title} className={styles.title} data-testid="card-one-header-h3" />
				<Text marginBottom="2xl" data-testid="card-one-content">
					{blok.shortText}
				</Text>
				<footer className={styles.footer}>
					{linkButton?.map((button: LinkButtonStoryblok) => (
						<LinkButton
							key={button._uid}
							{...LinkButton.blokProps({ blok: button })}
							variant="outlined"
							className={styles.btn}
						/>
					))}
				</footer>
			</div>
		</article>
	);
};
