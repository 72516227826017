import { Button } from '@/atoms';
import { LinkButtonProps } from '@/bloks/Button';
import DynamicComponent from '@/bloks/DynamicComponent';
import { ContainerFoldoutStoryblok, HeaderContainerFoldoutStoryblok } from '@/components';
import { cls } from '@/utils';
import { ScrollTrigger } from '@/utils/gsap';
import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './ContainerFoldout.module.scss';

interface ContainerFoldoutProps {
	blok: ContainerFoldoutStoryblok | HeaderContainerFoldoutStoryblok;
	meta?: {
		zone?: number;
	};
}

const containerFoldoutButton = (
	text: string,
	callback: () => any,
	variant: LinkButtonProps['variant'],
	applyLightness?: boolean,
) => {
	return (
		<Button onClick={callback} variant={variant} applyLightnessTheme={applyLightness}>
			{text}
		</Button>
	);
};

export function ContainerFoldout({ blok, meta }: ContainerFoldoutProps) {
	const contentRef = useRef<HTMLDivElement | null>(null);
	const foldoutRef = useRef<HTMLDivElement | null>(null);
	const applyLightness = blok.applyLightness ?? false;

	const [navBarHeight, setNavBarHeight] = useState(78);
	const [showFoldout, setShowFoldout] = useState(false);
	const [scrollDown, setScrollDown] = useState(false);
	const [scrollUp, setScrollUp] = useState(false);

	const isHeaderComponent = blok.component == 'HeaderContainerFoldout';

	useEffect(() => {
		setNavBarHeight(Math.floor(document.querySelector('header')?.parentElement?.getBoundingClientRect().height ?? 0));
	}, []);

	const scrollToElement = useCallback(
		(element: HTMLDivElement | null, scrollUp: boolean = false, fromTop: number = 0) => {
			if (element) {
				const y = element.getBoundingClientRect().top + window.pageYOffset - navBarHeight - fromTop;
				window.scrollTo({ top: y, behavior: 'smooth' });

				const timer = setTimeout(() => {
					const body = document.querySelector('body');
					body?.classList.remove(styles.disableScroll);
					if (scrollUp) {
						setShowFoldout(false);
					}
				}, 800);

				return () => {
					clearTimeout(timer);
				};
			}
		},
		[navBarHeight],
	);

	useEffect(() => {
		if (!showFoldout) {
			ScrollTrigger.refresh();
		}
	}, [showFoldout]);

	useEffect(() => {
		if (scrollUp) {
			const body = document.querySelector('body');
			body?.classList.add(styles.disableScroll);
			setScrollUp(false);
			scrollToElement(contentRef.current, true, window.innerHeight / 2);
		}
	}, [scrollUp, contentRef, showFoldout, scrollToElement]);

	useEffect(() => {
		if (scrollDown) {
			const body = document.querySelector('body');
			body?.classList.add(styles.disableScroll);
			setScrollDown(false);
			ScrollTrigger.refresh();
			scrollToElement(foldoutRef.current);
		}
	}, [scrollDown, foldoutRef, scrollToElement]);

	const containerShowFoldoutButton = () => {
		const button = containerFoldoutButton(
			blok?.contentButtonText,
			() => {
				setShowFoldout(true);
				setScrollDown(true);
			},
			blok.contentButtonVariant,
			applyLightness,
		);

		return <div>{button}</div>;
	};

	const containerHideFoldoutButton = (variant?: LinkButtonProps['variant']) =>
		containerFoldoutButton(
			blok?.foldoutButtonText,
			() => {
				setScrollUp(true);
				const body = document.querySelector('body');
				body?.classList.add(styles.disableScroll);
			},
			variant ?? 'filled',
			applyLightness,
		);

	const ContentSectionButton = () => {
		return (
			<div ref={contentRef}>
				{showFoldout ? containerHideFoldoutButton(blok.contentButtonVariant) : containerShowFoldoutButton()}
			</div>
		);
	};

	return (
		<div>
			{blok?.contentSection?.map((section, index) => {
				return (
					<div
						key={index}
						className={cls(
							styles.container,
							!isHeaderComponent && styles.contentSection,
							showFoldout && !isHeaderComponent && styles.contentSectionExpanded,
						)}
					>
						<DynamicComponent
							blok={section}
							meta={{
								...meta,
								containerFoldoutButton: ContentSectionButton(),
							}}
						/>
					</div>
				);
			})}
			{blok?.foldoutSection?.map((section, index) => {
				return (
					<div
						key={index}
						className={cls(
							styles.container,
							!isHeaderComponent && styles.foldOutSection,
							showFoldout ? undefined : styles.hidden,
						)}
						ref={foldoutRef}
					>
						<DynamicComponent
							blok={section}
							meta={{
								...meta,
								containerFoldoutButton: containerHideFoldoutButton('outlined'),
							}}
						/>
					</div>
				);
			})}
		</div>
	);
}
