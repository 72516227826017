/* eslint-disable jsx-a11y/alt-text */
import { Heading, Icon } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import {
	IconStoryblok,
	ImageStoryblok,
	LinkButtonStoryblok,
	RichtextStoryblok,
	ThemeStoryblok,
	UspStoryblok,
} from '@/components';
import { cls, slugifyName } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { Blok } from '../Blok';
import { Image } from '../Other';
import { renderRichText } from '../RichText';
import styles from './Usp.module.scss';

interface BlokProps {
	blok: UspStoryblok;
	meta?: {
		theme?: ThemeStoryblok['theme'];
		isInsideUspGroup?: boolean;
	};
}

const blokProps = ({ blok, meta }: BlokProps): Props => {
	return {
		_editable: blok._editable,
		title: blok.title,
		text: blok.text,
		linkButtons: blok.linkButtons,
		theme: meta?.theme ?? 'white',
		icon: blok?.icons?.[0],
		image: blok.image?.[0],
		isInsideUspGroup: meta?.isInsideUspGroup ?? false,
	};
};

interface Props {
	_editable?: string;
	title?: string;
	text?: RichtextStoryblok;
	linkButtons?: LinkButtonStoryblok[];
	theme: ThemeStoryblok['theme'];
	icon?: IconStoryblok;
	image?: ImageStoryblok;
	isInsideUspGroup: boolean;
}

/**
 * @deprecated Will be replaced by UspMedium
 *
 * TODO: Remove GREEN-791
 *
 * Usp component.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=2021%3A32815
 */
export const Usp: Blok<Props, BlokProps> = ({
	_editable,
	title,
	text,
	linkButtons,
	theme,
	icon,
	image,
	isInsideUspGroup,
}) => {
	return (
		<div
			{...editableBlok({ _editable })}
			className={cls(
				styles.container,
				image && styles[`layout--image`],
				styles[`theme--${theme}`],
				isInsideUspGroup && styles.padding,
			)}
		>
			<div className={styles.imageContainer}>
				{image && (
					<div className={styles.image}>
						<Image {...Image.blokProps({ blok: image })} />
					</div>
				)}
			</div>

			<div className={styles.content}>
				<div className={styles.header}>
					{icon?.name && (
						<Icon
							name={icon.name}
							className={styles.icon}
							color={theme === 'dark-green' ? '--primary-yellow' : '--primary-green'}
							size="lg"
						/>
					)}
					{title && (
						<Heading
							as="div"
							size="h5"
							title={title}
							className={styles.heading}
							data-testid={`usp-header-${slugifyName(title)}`}
							marginBottom="none"
						/>
					)}
				</div>
				{text && <div className={styles.text}>{renderRichText(text)}</div>}
				{!!linkButtons?.length && (
					<div className={styles.linkButtons}>
						{linkButtons?.map((button) => (
							<LinkButton {...LinkButton.blokProps({ blok: button })} key={button._uid} variant="text" />
						))}
					</div>
				)}
			</div>
		</div>
	);
};

Usp.blokProps = blokProps;
