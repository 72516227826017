import { LinkAnchor } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { CareerButtonStoryblok, MultilinkStoryblok, ThemeCareerStoryblok } from '@/components';
import { ThemeProvider } from '@/contexts/theme';
import { getThemeInfo } from '@/themes';
import { cls } from '@/utils';
import React from 'react';
import { buildLinkProps } from '../LinkButton';
import styles from './CareerButton.module.scss';

interface BlokProps {
	blok: CareerButtonStoryblok;
}

export interface CareerButtonProps {
	text: string;
	link: MultilinkStoryblok;
	theme: ThemeCareerStoryblok['theme'];
	size?: 'small' | 'large';
}

const blokProps = ({ blok }: BlokProps): CareerButtonProps => ({
	text: blok.text,
	link: blok.link,
	theme: blok?.theme[0]?.theme,
});

export const CareerButton: Blok<CareerButtonProps, BlokProps> = ({ text, link, size = 'large', theme = 'white' }) => {
	const href = buildLinkProps(link ?? '').href;
	const themeStyles = getThemeInfo(theme);
	return (
		<ThemeProvider theme={theme}>
			<LinkAnchor href={href} className={cls(styles.btn, styles[`size--${size}`], themeStyles.styles?.careerButton)}>
				{text}
			</LinkAnchor>
		</ThemeProvider>
	);
};

CareerButton.blokProps = blokProps;
