import { BackgroundImage, BrandLine, ContainerFluid, Heading, Section, Text } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import DynamicComponent from '@/bloks/DynamicComponent';
import { renderRichText } from '@/bloks/RichText';
import {
	AssetStoryblok,
	CardSmallIconGroupStoryblok,
	HeaderNavigationStoryblok,
	LinkButtonStoryblok,
	ThemeDarkGreenWhiteStoryblok,
} from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { cls } from '@/utils';
import React from 'react';
import styles from './HeaderNavigation.module.scss';

interface BlokProps {
	blok: HeaderNavigationStoryblok;
}

interface HeaderNavigationProps {
	backgroundImage?: AssetStoryblok;
	smallTitle?: string;
	h1Part1: string;
	h1Part2?: string;
	shortText?: any;
	linkButtons?: LinkButtonStoryblok[];
	cards: CardSmallIconGroupStoryblok[];
	theme?: ThemeDarkGreenWhiteStoryblok[];
}

const blokProps = ({ blok }: BlokProps): HeaderNavigationProps => ({
	backgroundImage: blok.backgroundImage,
	smallTitle: blok.smallTitle,
	h1Part1: blok.h1Part1,
	h1Part2: blok.h1Part2,
	shortText: blok.shortText,
	linkButtons: blok.linkButtons,
	cards: blok.cards,
	theme: blok.theme,
});

export const HeaderNavigation = ({
	backgroundImage,
	smallTitle,
	h1Part1,
	h1Part2,
	shortText,
	linkButtons,
	cards,
	theme,
}: HeaderNavigationProps) => {
	const headerTheme = theme?.[0]?.theme ?? 'dark-green';
	const backgroundImageTheme = backgroundImage?.filename ? 'darkGreen' : headerTheme;
	const headerCards = cards ? cards[0] : null;
	const { hasSubMenuV1 } = useSubMenu();

	return (
		<ContainerFluid theme={backgroundImageTheme} className={cls(styles.container, hasSubMenuV1 && styles.hasSubMenuV1)}>
			<Section as="header" className={styles.content}>
				<div className={styles.headerContent}>
					<BrandLine />
					{smallTitle && <Heading as="div" size="h6" title={smallTitle} />}
					<Heading as="h1" title={h1Part1} titlePart2={h1Part2} />
					{shortText && (
						<Text as="div" size="ingress" className={styles.shortText} marginBottom="none">
							{renderRichText(shortText, { textSize: 'ingress' })}
						</Text>
					)}
					{!!linkButtons?.length && (
						<div className={styles.linkButtons}>
							{linkButtons?.map((btn: LinkButtonStoryblok, index: number) => (
								<LinkButton
									key={btn._uid}
									{...LinkButton.blokProps({ blok: btn })}
									variant={index === 0 ? 'filled' : 'outlined'}
								/>
							))}
						</div>
					)}
				</div>
				{headerCards && (
					<DynamicComponent
						blok={headerCards}
						meta={{ theme: backgroundImageTheme, layout: 'center', buttonVariant: 'text', padding: true }}
					/>
				)}
			</Section>
			{backgroundImage?.filename && (
				<BackgroundImage asset={backgroundImage} overlay={false} className={styles.backgroundImage} />
			)}
		</ContainerFluid>
	);
};

HeaderNavigation.blokProps = blokProps;
