import { ContainerFluid, Grid, Heading, Section, Text } from '@/atoms';
import DynamicComponent from '@/bloks/DynamicComponent';
import { renderRichText } from '@/bloks/RichText';
import { SectionPriceListStoryblok } from '@/components';
import { editableBlok } from '@/utils/storyblok';
import styles from './SectionPriceList.module.scss';

interface Props {
	blok: SectionPriceListStoryblok;
}

export function SectionPriceList({ blok }: Props) {
	const { title, shortText, pricelistGroups, hideMonthlyPrice, _editable } = blok;
	return (
		<ContainerFluid>
			<Section
				paddingX={{ base: 'md', md: '3xl', lg: '5xl' }}
				paddingY={{ base: '3xl', md: '4xl', lg: '3xl' }}
				{...editableBlok({ _editable })}
			>
				<Heading title={title} as="div" size="h3" />
				{shortText && (
					<Text as="div" marginBottom="3xl" className={styles.shortText}>
						{renderRichText(shortText)}
					</Text>
				)}
				<Grid columns={{ base: 1, lg: 2 }} colGap="3xl" rowGap="3xl">
					{pricelistGroups?.map((group) => (
						<Grid.Item key={group._uid} className={styles.gridItem}>
							<DynamicComponent blok={group} meta={{ hideMonthlyPrice }} />
						</Grid.Item>
					))}
				</Grid>
			</Section>
		</ContainerFluid>
	);
}
