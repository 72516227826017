import { BrandLine, ContainerFluid, Grid, Heading, Section } from '@/atoms';
import { renderRichText } from '@/bloks/RichText';
import { HeaderImageLowStoryblok, RichtextStoryblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { ImageAsset, ImageAssetProps } from '@/metabloks';
import { cls } from '@/utils';
import styles from './HeaderImageLow.module.scss';

/**
 * Temporary to support name changes of fields
 */
interface HeaderBlok extends HeaderImageLowStoryblok {
	title: string;
	intro?: string;
}

interface BlokProps {
	blok: HeaderBlok;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	smallTitle: blok.smallTitle,
	titlePart1: blok.titlePart1 ?? blok.title,
	titlePart2: blok.titlePart2,
	textLeft: blok.textLeft ?? blok.intro,
	textRight: blok.textRight,
	image: blok.image,
});

interface Props {
	smallTitle: string;
	titlePart1: string;
	titlePart2?: string;
	textLeft: RichtextStoryblok;
	textRight?: RichtextStoryblok;
	image?: ImageAssetProps;
}

/**
 * Header with a low image that appears below the text content of the header.
 */
export function HeaderImageLow({ smallTitle, titlePart1, titlePart2, textLeft, textRight, image }: Props) {
	const { hasSubMenuV1 } = useSubMenu();
	const isTwoTextColumns = !!textRight?.content?.[0]?.content?.[0]?.text;

	return (
		<ContainerFluid marginBottom={image?.filename ? '3xl' : 'none'} theme="white">
			<Section
				paddingY={image?.filename ? { base: 'xl', md: '3xl' } : { base: 'xl', md: '3xl', xl: '5xl' }}
				className={cls(styles.container, hasSubMenuV1 && styles.hasSubMenuV1)}
			>
				<div className={styles.inner}>
					<BrandLine marginBottom="xl" />
					{smallTitle && <Heading title={smallTitle} as="div" size="h6" marginBottom="xl" />}
					<Heading
						title={titlePart1}
						titlePart2={titlePart2}
						as="h1"
						size="h2"
						marginBottom={isTwoTextColumns ? '3xl' : 'xl'}
						className={styles.title}
						linebreak
					/>
					<Grid
						columns={{ base: 1, md: isTwoTextColumns ? 2 : 1 }}
						colGap="3xl"
						rowGap="lg"
						className={styles.gridContainer}
					>
						<Grid.Item className={cls(!isTwoTextColumns && styles.centeredText)}>{renderRichText(textLeft)}</Grid.Item>
						{textRight && <Grid.Item>{renderRichText(textRight)}</Grid.Item>}
					</Grid>
				</div>
			</Section>
			{image?.filename && (
				<div className={styles.imageContainer}>
					<ImageAsset absolute asset={image} fill objectFit="cover" priority />
				</div>
			)}
		</ContainerFluid>
	);
}

HeaderImageLow.blokProps = blokProps;
