import { isPlayableVimeoVideo } from '@/bloks/Page/NavigationPageEducation/CardEducation/CardEducation';
import { CardEducationStoryblok, LinkButtonStoryblok } from '@/components';
import { Course, EducationType, PriceName } from '@/contexts/eduadmin';
import { t } from 'ttag';

const getPrice = (course: Course) => {
	if (isPlayableVimeoVideo(course.EducationType as EducationType, course.VimeoId)) {
		return t`Kostnadsfri`;
	}

	const sortedPrices = (course.PriceNames as PriceName[])
		.map((p) => p.Price)
		.filter((price) => price !== undefined)
		.sort((a, b) => a - b);

	if (!sortedPrices || sortedPrices.length === 0) {
		return '-';
	}

	if (sortedPrices[0] === 0) {
		return t`Kostnadsfri`;
	}
	const formattedPrice = new Intl.NumberFormat('sv-SE').format(sortedPrices[0]) + t` kr`;
	return sortedPrices.length === 1 ? formattedPrice : t`från ` + formattedPrice;
};

const getLinkButtonText = (course: Course) => {
	const vimeoRecording = isPlayableVimeoVideo(course.EducationType as EducationType, course.VimeoId);
	if (vimeoRecording) {
		return t`Till utbildningen`;
	}
	if (course.EducationType === EducationType.Recorded) {
		return t`Läs mer & köp`;
	}
	return t`Läs mer & anmäl dig`;
};

export const toCardEducation: (course: Course) => CardEducationStoryblok = (course) => {
	const price = getPrice(course);
	const link: LinkButtonStoryblok[] = [
		{
			_uid: course.CourseTemplateId.toString(),
			component: 'LinkButton',
			text: getLinkButtonText(course),
			link: {
				url: '/utbildning/' + course.CourseTemplateId,
				linktype: 'story',
				target: '_self',
			},
		},
	];
	const cardEducationStoryblok: CardEducationStoryblok = {
		title: course.CourseName,
		educationType: course.EducationType as EducationType,
		text: course.Quote ?? '',
		targetGroups: course.TargetGroupsShort,
		subjects: course.Subjects,
		vimeoId: course.VimeoId,
		price: price?.toString(),
		link: link,
		_uid: course.CourseTemplateId.toString(),
		component: 'CardEducation',
	};
	return cardEducationStoryblok;
};
