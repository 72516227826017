import { Grid } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { CardLargeInvertedGroupStoryblok } from '@/components';
import { CardLargeInverted, CardLargeInvertedProps } from '../../CardLarge/CardLargeInverted/CardLargeInverted';

interface BlokProps {
	blok: CardLargeInvertedGroupStoryblok;
	meta?: { zone?: number };
}

const blokProps = ({ blok, meta }: BlokProps): Props => ({
	cards: blok?.cards?.map((card) => CardLargeInverted.blokProps({ blok: card, meta })),
	zone: meta?.zone,
});

interface Props {
	cards: CardLargeInvertedProps[];
	zone?: number;
}

export const CardLargeInvertedGroup: Blok<Props, BlokProps> = ({ cards, zone }) => {
	return (
		<Grid>
			{cards.map((card, i) => (
				<Grid.Item key={i}>
					<CardLargeInverted {...card} zone={zone} />
				</Grid.Item>
			))}
		</Grid>
	);
};

CardLargeInvertedGroup.blokProps = blokProps;
