/**
 * Check if the value is a phone number, only allow specific
 * characters that make up a phone number.
 */
const isValidPhoneNumber = (value: string | null): boolean =>
	value != null ? /^[0-9+()\- ]{7,15}$/.test(value) : false;

export { isValidPhoneNumber };

/**
 * Check if the value is an email.
 */
const isValidEmail = (value: string | null): boolean => {
	// Regex from https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#basic_validation
	const filter =
		/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
	return value != null ? filter.test(value) : false;
};

export { isValidEmail };

/**
 * Check if the value is zip number
 */
const isValidZipCode = (value: string | null): boolean => {
	const filter = /^[1-9][0-9]{4}$/;
	return value != null ? filter.test(value) : false;
};

export { isValidZipCode };

/**
 * Check if the value is empty ('', undefined or null).
 */
const isEmpty = (value: string | null | undefined): boolean => ['', undefined, null].includes(value);

export { isEmpty };

/**
 * Make sure we only allow digits here.
 */
const isNumeric = (value: string | null): boolean => (value != null ? /^-?\d+([.,]\d+)?$/.test(value) : false);

export { isNumeric };
