import { Card, Grid, Heading, Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { LinksCommon } from '@/bloks/Other/LinksCommon';
import { renderRichText } from '@/bloks/RichText';
import { CardLargeLinksLowStoryblok } from '@/components';
import { editableBlok } from '@/utils/storyblok';
import React, { ReactElement } from 'react';
import styles from './CardLargeLinksLow.module.scss';

interface BlokProps {
	blok: CardLargeLinksLowStoryblok;
}
const blokProps = ({ blok }: BlokProps): Props => ({
	title: blok?.title,
	smallTitle: blok?.smallTitle,
	text: blok?.text,
	links: <LinksCommon links={blok?.links} noPadding />,
	_editable: blok._editable,
});

interface Props {
	_editable?: string;
	title?: string;
	smallTitle?: string;
	text?: string;
	links?: ReactElement;
	className?: string;
}

export const CardLargeLinksLow: Blok<Props, BlokProps> = ({ _editable, title, smallTitle, text, links }) => {
	return (
		<Card {...editableBlok({ _editable })} border={false}>
			<div className={styles.inner}>
				{title && <Heading className={styles.title} as="div" size="h4" title={title} marginBottom="xl" />}
				<Grid columns={{ base: 1, lg: '6fr 4fr' }} colGap="xl" rowGap="xl" className={styles.grid}>
					<Grid.Item>
						<div className={styles.contentWrapper}>
							{smallTitle && (
								<Heading className={styles.smallTitle} marginBottom="md" as="div" size="h5" title={smallTitle} />
							)}
							{text && (
								<Text className={styles.text} as="div">
									{renderRichText(text)}
								</Text>
							)}
						</div>
					</Grid.Item>
					{links && <Grid.Item className={styles.linksColumn}>{links}</Grid.Item>}
				</Grid>
			</div>
		</Card>
	);
};

CardLargeLinksLow.blokProps = blokProps;
