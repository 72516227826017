import classNames from 'classnames';
import styles from './VerticalBrandLine.module.scss';

interface Props {
	className?: string;
}

export const VerticalBrandLine: React.FC<Props> = ({ className }) => {
	return <div className={classNames(styles.line, className)} />;
};
