import { LandingPageStoryblok } from '@/components';
import React from 'react';
import { LandingPage } from './LandingPage';

interface Props {
	blok: LandingPageStoryblok;
	meta: Record<string, any>;
}

// TODO: Remove
export const PageDeprecated: React.FC<Props> = ({ blok, meta }) => {
	return <LandingPage blok={blok} meta={meta} />;
};
