import { ContainerFluid, Grid, Section } from '@/atoms';
import DynamicComponent from '@/bloks/DynamicComponent';
import { UpsalesForm } from '@/bloks/Form';
import { HeaderCommon } from '@/bloks/Other';
import { SectionUpsalesFormStoryblok, UpsalesFormStoryblok } from '@/components';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { ISbStoryData } from '@/types/storyblok';
import { useState } from 'react';
import cls from 'classnames';
import styles from './SectionUpsalesForm.module.scss';

interface Props {
	blok: SectionUpsalesFormStoryblok;
	meta?: {
		containerFoldoutButton?: JSX.Element;
		zone?: number;
	};
}

export const SectionUpsalesForm: React.FC<Props> = ({ blok, meta }) => {
	const { cardContact, faq } = blok;
	const form = blok?.form as unknown as ISbStoryData<UpsalesFormStoryblok>;
	const [showText, setShowText] = useState(true);
	const header = blok.header.find((h) => h.titlePart1);
	const contentPiece = header?.titlePart2 ? `${header?.titlePart1} ${header.titlePart2}` : `${header?.titlePart1}`;

	return (
		<ImpressionTrackingProvider
			value={{ contentName: `${meta?.zone}|SectionUpsalesForm`, contentPiece, contentTarget: 'Skicka' }}
		>
			<ContainerFluid lightness="light">
				<Section>
					<div className={cls(styles.container, !meta?.containerFoldoutButton && styles.marginBottom)}>
						{showText && header && (
							<HeaderCommon
								key={header._uid}
								{...HeaderCommon.blokProps({ blok: header })}
								layout="center"
								titleSize="h3"
								marginBottom="3xl"
							/>
						)}

						{form && (
							<UpsalesForm
								{...UpsalesForm.blokProps({
									story: form,
									meta: { ...meta, layout: 'twoCols', afterSubmit: () => setShowText(false) },
								})}
							/>
						)}
						{meta?.containerFoldoutButton && <div className={styles.center}>{meta.containerFoldoutButton}</div>}
					</div>
					<Grid columns={{ base: 1, lg: 2 }} colGap={'xl'} rowGap={'xl'} className={styles.gridContainer}>
						{cardContact?.map((cardContact) => (
							<Grid.Item key={cardContact._uid}>
								<DynamicComponent blok={cardContact} meta={{ theme: 'light-green' }} />
							</Grid.Item>
						))}
						{faq?.map((faqCard) => (
							<Grid.Item key={faqCard._uid}>
								<DynamicComponent blok={faqCard} meta={{ theme: 'light-green' }} />
							</Grid.Item>
						))}
					</Grid>
				</Section>
			</ContainerFluid>
		</ImpressionTrackingProvider>
	);
};
