import { Heading } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { Colors } from '@/colors';
import { SocMeLinkGroupStoryblok } from '@/components';
import { useImpressionTracking } from '@/contexts/piwik/ImpressionTrackingProvider';
import { SocMeLink } from '../SocMeLink';
import { SocMeLinkProps } from '../SocMeLink/SocMeLink';
import styles from './SocMeLinkGroup.module.scss';

interface BlokProps {
	blok: SocMeLinkGroupStoryblok;
	meta?: {
		iconColor?: Colors;
	};
}

const blokProps = ({ blok, meta }: BlokProps): Props => ({
	title: blok.title,
	icons: blok.icons.map((btn) => ({ ...SocMeLink.blokProps({ blok: btn }) })),
	iconColor: meta?.iconColor,
});

interface Props {
	title?: string;
	icons: SocMeLinkProps[];
	iconColor?: Colors;
}

export const SocMeLinkGroup: Blok<Props, BlokProps> = ({ title, icons, iconColor }) => {
	const { setTargetInteraction } = useImpressionTracking();

	return (
		<>
			{title && <Heading as="div" size="h5" title={title} marginBottom="lg" />}
			<div className={styles.iconContainer}>
				{icons.map((btn, i) => (
					<SocMeLink
						key={i}
						{...btn}
						className={styles.icon}
						iconColor={iconColor && iconColor}
						onClick={() => {
							setTargetInteraction({ contentTarget: `SocMe: ${btn.icon}` });
						}}
					/>
				))}
			</div>
		</>
	);
};

SocMeLinkGroup.blokProps = blokProps;
