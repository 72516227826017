import { CareerPageStoryblok } from '@/components';
import React from 'react';
import DynamicComponent from '../../DynamicComponent';

interface Props {
	blok: CareerPageStoryblok;
	meta: Record<string, any>;
}

export const CareerPage: React.FC<Props> = ({ blok }) => {
	const { header, sections } = blok;
	return (
		<>
			{header?.map((head) => <DynamicComponent blok={head} key={head._uid} />)}

			{sections?.map((section) => <DynamicComponent blok={section} key={section._uid} />)}
		</>
	);
};
