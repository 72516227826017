import { BrandLine, ContainerFluid, Grid, GridProps, Heading, Section, ShowMoreButton, Text } from '@/atoms';
import { LinkButtonStoryblok, SectionCommonStoryblok, ThemeStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { useState } from 'react';
import { t } from 'ttag';
import { LinkButton } from '../../Button';
import DynamicComponent from '../../DynamicComponent';
import styles from './SectionCommon.module.scss';

const useGridColumns = (itemCount: number) => {
	const columns: GridProps['columns'] = { base: 1 };

	if (itemCount % 5 === 0) {
		columns.xxl = 4;
		columns.xl = 3;
		columns.lg = 2;
		columns.md = 1;
	}

	if (itemCount % 4 === 0) {
		columns.xxl = 4;
		columns.xl = 4;
	}

	if (itemCount % 3 === 0) {
		columns.md = 2;
		columns.lg = 3;
		columns.xl = 3;
	}

	if (itemCount % 2 === 0) {
		columns.md = 2;
	}

	if (itemCount > 4) {
		columns.xxl = 4;
	}
	return columns;
};

interface Props {
	blok: SectionCommonStoryblok;
	meta?: {
		zone?: number;
		isParent?: boolean;
	};
}

/**
 * @deprecated Will be removed
 *
 * General Section component that sets columns based on number of items. Used for example Usp's but can be used for any blok.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=184%3A10432
 */
export const SectionCommon: React.FC<Props> = ({ blok, meta }) => {
	const { items, smallTitle, text, titlePart1, titlePart2, linkButtons } = blok;
	const titleSize = blok?.titleSize ? blok?.titleSize : 'h2';
	const theme = blok.theme?.map((t: ThemeStoryblok) => t.theme)?.[0] || 'white';
	const [showMore, setShowMore] = useState(false);
	const zone = meta?.zone ?? false;

	const columns = useGridColumns(items?.length ?? 0);
	const multipleItems = (items?.length ?? 0) > 4;

	const firstItems = blok.items?.slice(0, 4).map((item: any, index: number) => (
		<Grid.Item key={index}>
			<DynamicComponent blok={item} meta={{ ...meta, theme, isParent: false }} />
		</Grid.Item>
	));

	const remainingItems = blok.items?.slice(4).map((item: any, index: number) => (
		<Grid.Item key={index}>
			<DynamicComponent blok={item} meta={{ ...meta, theme, isParent: false }} />
		</Grid.Item>
	));

	return (
		<ContainerFluid theme={theme}>
			<Section
				{...editableBlok(blok)}
				paddingX={meta?.isParent === false ? { base: 'none' } : { base: 'xl', md: '3xl', lg: '5xl' }}
				className={styles[`theme--${theme}`]}
			>
				<div className={styles.container}>
					{(smallTitle || titlePart1 || text) && (
						<div className={styles.header}>
							<div className={styles.textContainer}>
								{smallTitle && (
									<>
										<BrandLine />
										<Heading
											as="div"
											size="h6"
											className={styles.smallTitle}
											marginBottom="md"
											data-testid="section-common-header-h6"
											title={smallTitle}
										/>
									</>
								)}

								{titlePart1 && (
									<Heading
										as="h3"
										size={titleSize}
										data-testid="section-common-header-h2"
										title={titlePart1}
										titlePart2={titlePart2}
									/>
								)}

								{text && (
									<Text className={styles.text} data-testid="section-common-text" marginBottom="none">
										{text}
									</Text>
								)}
							</div>

							{linkButtons && (
								<div className={styles.buttons}>
									{linkButtons.map((button: LinkButtonStoryblok) => (
										<LinkButton
											key={button._uid}
											{...LinkButton.blokProps({ blok: button })}
											variant="text"
											className={styles.linkButton}
										/>
									))}
								</div>
							)}
						</div>
					)}

					<Grid columns={columns} className={cls(styles.grid, zone === 2 && styles.zone2Grid)}>
						<>{firstItems}</>
						{showMore && <>{remainingItems} </>}
					</Grid>
					{multipleItems && (
						<ShowMoreButton onClick={() => setShowMore(!showMore)} icon={showMore ? 'chevron-up' : 'chevron-down'}>
							{showMore ? t`Visa färre` : t`Ladda fler`}
						</ShowMoreButton>
					)}
				</div>
			</Section>
		</ContainerFluid>
	);
};
