import { BrandLine, ContainerFluid, Grid, Heading, Section, Text } from '@/atoms';
import { CtaTwoImageStoryblok } from '@/components';
import { ImageAsset } from '@/metabloks';
import { editableBlok } from '@/utils/storyblok';
import { LinkButton } from '../Button';
import styles from './CtaTwoImage.module.scss';

interface Props {
	blok: CtaTwoImageStoryblok;
}

/**
 * @deprecated Will be replaced by CardMedium
 */
export const CtaTwoImage: React.FC<Props> = ({ blok }) => {
	return (
		<ContainerFluid>
			<Section paddingY={{ base: 'none' }}>
				<Grid
					columns={{ base: 1, md: 2 }}
					{...editableBlok(blok)}
					className={styles.container}
					colGap="2xl"
					rowGap="2xl"
				>
					{blok.image?.filename && (
						<div className={styles.image} data-testid="cta-two-image-container">
							<BrandLine />
							<ImageAsset absolute asset={blok.image} objectFit="cover" data-testid="cta-two-image" />
						</div>
					)}

					<div className={styles.content}>
						{blok?.title && <Heading as="h3" title={blok.title} data-testid="cta-two-image-header-h3" />}
						<Text>{blok.text}</Text>
						{blok.linkButtons?.map((button) => {
							return (
								<div className={styles.footer} key={button._uid}>
									<LinkButton {...LinkButton.blokProps({ blok: button })} />;
								</div>
							);
						})}
					</div>
				</Grid>
			</Section>
		</ContainerFluid>
	);
};
