import { PageLayout } from '@/atoms';
import {
	CardFiveGroupStoryblok,
	NavigationPageCompanyGuideStoryblok,
	SectionTextLinkStoryblok,
	SubMenuStoryblok,
} from '@/components';
import { ISbStoryData } from '@/types/storyblok';
import { CardTwoArticleGrid } from '../Card/CardTwoArticleGrid';
import DynamicComponent from '../DynamicComponent';
import { SectionTextLink } from '../Section';

interface Props {
	blok: NavigationPageCompanyGuideStoryblok;
	meta: Record<string, any>;
}

export const NavigationPageCompanyGuide: React.FC<Props> = ({ blok, meta }) => {
	const { header } = blok;
	const subMenu = blok?.subMenu as unknown as ISbStoryData<SubMenuStoryblok>;
	const contact = blok?.contact as unknown as ISbStoryData<CardFiveGroupStoryblok>;

	const sectionTextLink = blok?.sectionTextLink?.length
		? (blok?.sectionTextLink[0] as any as ISbStoryData<SectionTextLinkStoryblok>)
		: (blok?.sectionTextLink as any as ISbStoryData<SectionTextLinkStoryblok>);

	return (
		<PageLayout subMenu={subMenu} exits={contact} meta={meta}>
			{header?.map((blok: any) => <DynamicComponent key={blok._uid} blok={blok} />)}

			<CardTwoArticleGrid blok={blok} />

			{sectionTextLink?.content && <SectionTextLink blok={sectionTextLink.content} />}
		</PageLayout>
	);
};
