import { PageLayout } from '@/atoms';
import { CardFiveGroupStoryblok, NewsPostStoryblok } from '@/components';
import { ISbStoryData } from '@/types/storyblok';
import { ArticleLayout } from './ArticleLayout/';

interface Props {
	blok: NewsPostStoryblok;
	meta: Record<string, any>;
}

export const NewsPost: React.FC<Props> = ({ blok, meta }) => {
	const contact = blok?.contact as unknown as ISbStoryData<CardFiveGroupStoryblok>;

	return (
		<PageLayout exits={contact} meta={meta}>
			<ArticleLayout data-test-id="news-article" blok={blok} meta={meta} />
		</PageLayout>
	);
};
