import useOnScreen from '@/hooks/useOnScreen';
import { getPiwikTrackingSlug } from '@/trackers/piwik';
import { usePiwikPro } from '@piwikpro/next-piwik-pro';
import { usePathname } from 'next/navigation';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useServerPageConfig } from '../config';

export interface InteractionTarget {
	contentTarget: string | null;
}

interface ContextProps {
	/**
	 * The name you give to the content block you’re tracking.
	 */
	contentName: string;
	/**
	 * This represents a part of the content block you’re tracking, like a creative, banner or video.
	 */
	contentPiece?: string;
	/**
	 * Set content piece name if title is different from parent impression element.
	 */
	setContentPiece: (contentPiece: string) => void;
	/**
	 * This is the specific thing within the content block that you’re tracking, like a link in the content block.
	 */
	contentTarget?: string;
	/**
	 * State to trigger content interaction event
	 */
	interactionTarget?: InteractionTarget;
	/**
	 * State to trigger content interaction event
	 */
	setTargetInteraction: (interactionTarget: InteractionTarget) => void;
	/**
	 * The threshold is the percentage of the element that must be visible for the callback to be invoked. 0.8 by default.
	 */
	threshold?: number;
}

const Context = createContext<ContextProps>({
	contentName: 'default',
	setContentPiece: () => {},
	setTargetInteraction: () => {},
});

export const useImpressionTracking = () => {
	return useContext(Context);
};

interface Props {
	children: React.ReactNode;
	value: Omit<ContextProps, 'setContentPiece' | 'setTargetInteraction' | 'targetInteraction'>;
}

/**
 *
 * Wrap elements that should be tracked for impression tracking.
 *
 */
export function ImpressionTrackingProvider({ children, value }: Props) {
	const { ContentTracking, DataLayer } = usePiwikPro();
	const [tracked, setTracked] = useState(false);
	const [targetInteraction, setTargetInteraction] = useState({
		contentTarget: null,
	} as InteractionTarget);

	const ref = useRef<HTMLDivElement>(null);
	const threshold = value.threshold ?? 0.4;
	const isVisible = useOnScreen(ref, threshold);

	// Event data
	const pathname = usePathname();
	const slug = getPiwikTrackingSlug(pathname);
	const contentName = `${slug}|${value.contentName}`;
	const [contentPiece, setContentPiece] = useState(value?.contentPiece ?? 'no available title');
	const contentTarget = value?.contentTarget ?? 'multiple interactions possible';

	// Trigger event true/false
	const { piwikTracking } = useServerPageConfig();

	useEffect(() => {
		if (!piwikTracking) return;
		if (value.threshold === 0 && !tracked) {
			ContentTracking.trackContentImpression(contentName, contentPiece, contentTarget);
			setTracked(true);
		}
		if (isVisible && !tracked) {
			setTracked(true);
			ContentTracking.trackContentImpression(contentName, contentPiece, contentTarget);
		}
	}, [ContentTracking, contentName, contentPiece, contentTarget, isVisible, piwikTracking, tracked, value.threshold]);

	useEffect(() => {
		if (!piwikTracking) return;
		if (!targetInteraction.contentTarget) return;

		ContentTracking.trackContentInteraction('contentInteraction', contentName, contentPiece, contentTarget);
		DataLayer.push({
			event: 'contentInteraction',
			contentName,
			contentPiece,
			contentTarget,
			contentInteraction: 'Click',
			data: { clickElementText: targetInteraction.contentTarget },
		});
		setTargetInteraction((prevState) => ({ ...prevState, contentTarget: null }));
	}, [targetInteraction.contentTarget]);

	return (
		<Context.Provider
			value={{
				contentName,
				contentPiece,
				setContentPiece,
				contentTarget,
				setTargetInteraction,
			}}
		>
			<div ref={ref}>{children}</div>
		</Context.Provider>
	);
}
