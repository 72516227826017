import { Chip, Grid, Text } from '@/atoms';
import { LinkButton, LinkButtonProps } from '@/bloks/Button';
import { PriceListItemCustomStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { t } from 'ttag';
import styles from './PriceListItemCustom.module.scss';

interface BlokProps {
	blok: PriceListItemCustomStoryblok;
	meta: {
		subTitle1?: string;
		subTitle2?: string;
		hideMonthlyPrice?: boolean;
	};
}

const blokProps = ({ blok, meta }: BlokProps): PriceListItemProps => ({
	productName: blok.productName,
	montlyPrice: blok.montlyPrice ?? '',
	yearlyPrice: blok.yearlyPrice ?? null,
	linkButton: blok.linkButton?.[0]?.link && { ...LinkButton.blokProps({ blok: blok.linkButton[0] }) },
	subTitle1: meta?.subTitle1,
	subTitle2: meta?.subTitle2,
	priceUnit: blok.priceUnit,
	priceInterval: blok.priceInterval,
	additionalInfo: blok.additionalInfo,
	showNewPriceChip: blok.showNewPriceChip,
	_editable: blok._editable,
	hideMonthlyPrice: meta?.hideMonthlyPrice,
});

export interface PriceListItemProps {
	productName: string;
	montlyPrice: number | string;
	yearlyPrice: number | string | null;
	linkButton?: LinkButtonProps;
	subTitle1?: string;
	subTitle2?: string;
	additionalInfo?: string;
	priceUnit?: string;
	priceInterval?: string;
	showNewPriceChip?: boolean;
	_editable?: string;
	hideMonthlyPrice?: boolean;
}

interface AdditionalInfoProps {
	additionalInfo: string;
}

const AdditionalInfo = ({ additionalInfo }: AdditionalInfoProps) => {
	return (
		<Text muted size="small" className={styles.additionalInfo}>
			{additionalInfo}
		</Text>
	);
};
export function PriceListItemCustom({
	productName,
	montlyPrice,
	yearlyPrice,
	subTitle1,
	subTitle2,
	additionalInfo,
	linkButton,
	priceUnit,
	priceInterval,
	showNewPriceChip = false,
	_editable,
	hideMonthlyPrice,
}: PriceListItemProps) {
	const columns = hideMonthlyPrice
		? { base: '6fr 6fr', xs: 3, md: '6fr 3fr 3fr', lg: '6fr 3fr 3fr' }
		: { base: 1, xs: '4fr 3fr 3fr 2fr', md: 4, lg: '5fr 3fr 3fr 2fr' };
	return (
		<>
			<Grid
				columns={columns}
				className={cls(styles.priceItem, additionalInfo && styles.noBorder, hideMonthlyPrice && styles.noMonthlyPrice)}
				{...editableBlok({ _editable })}
			>
				<Grid.Item className={styles.productItem}>
					<Text marginBottom="custom" className={styles.productName}>
						<strong>{productName}</strong>
					</Text>
				</Grid.Item>
				<Grid.Item>
					{yearlyPrice && (
						<Text marginBottom="custom" className={styles.priceText}>
							{!hideMonthlyPrice && <span className={styles.subTitle}>{subTitle1}</span>}
							<span className={styles.price}>
								{yearlyPrice} {priceUnit}
								{priceUnit && priceInterval ? '/' : ''}
								{priceInterval}
							</span>
						</Text>
					)}
				</Grid.Item>
				{!hideMonthlyPrice && (
					<Grid.Item>
						{montlyPrice && (
							<Text marginBottom="custom" className={styles.priceText}>
								<span className={styles.subTitle}>{subTitle2}</span>{' '}
								<span className={styles.price}>
									{montlyPrice} {priceUnit}
									{priceUnit && priceInterval ? '/' : ''}
									{priceInterval}
								</span>
							</Text>
						)}
					</Grid.Item>
				)}

				<Grid.Item className={styles.mobileInfo}>
					{additionalInfo && <AdditionalInfo additionalInfo={additionalInfo} />}
				</Grid.Item>
				<Grid.Item className={styles.gridItemBtn}>
					{!showNewPriceChip && linkButton && <LinkButton {...linkButton} variant="text" className={styles.btn} />}
					{showNewPriceChip && <Chip text={t`Nytt pris`} color="--accent-pink" />}
				</Grid.Item>
			</Grid>
			<div className={styles.desktopInfo}>{additionalInfo && <AdditionalInfo additionalInfo={additionalInfo} />}</div>
		</>
	);
}

PriceListItemCustom.blokProps = blokProps;
