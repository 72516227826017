import { Card, Heading, Text } from '@/atoms';
import { LinkButton, LinkButtonProps } from '@/bloks/Button';
import { CardMiniStoryblok } from '@/components';
import { ImageAsset, ImageAssetProps } from '@/metabloks';
import styles from './CardMini.module.scss';

interface BlokProps {
	blok: CardMiniStoryblok;
}

const blokProps = ({ blok }: BlokProps): CardMiniProps => ({
	image: blok.image,
	header: blok.header,
	text: blok.text,
	link: blok?.link?.[0]?.link && LinkButton.blokProps({ blok: blok.link[0] }),
});

export interface CardMiniProps {
	image: ImageAssetProps;
	header: string;
	text: string;
	link: LinkButtonProps;
}

export function CardMini({ image, header, text, link }: CardMiniProps) {
	return (
		<Card className={styles.card} border={false}>
			<div className={styles.imageContainer}>
				<ImageAsset absolute asset={image} resize={'140x140'} />
			</div>

			<div className={styles.textContainer}>
				<Heading as="div" size="h6" title={header} />
				<Text marginBottom="md">{text}</Text>
				<LinkButton {...link} variant={'text'} />
			</div>
		</Card>
	);
}

CardMini.blokProps = blokProps;
