import { Grid, ShowMoreButton } from '@/atoms';
import { ThemeProvider } from '@/contexts/theme';
import { getCorrespondingLegacyTheme } from '@/themes';
import { Themes } from '@/types';
import { cls } from '@/utils';
import { isOdd } from '@/utils/isOdd';
import { editableBlok } from '@/utils/storyblok';
import { ReactNode, useState } from 'react';
import { t } from 'ttag';
import styles from './CardSmallGroup.module.scss';
import { useCardSmallGridColumns } from './useCardSmallGridColumns';

interface Props {
	cards: ReactNode[];
	className?: string;
	group?: boolean;
	buttonVariant?: 'filled' | 'outlined' | 'text';
	layout?: 'default' | 'center';
	padding?: boolean;
	theme?: Themes['theme'];
	_editable?: string;
}

export type { Props as CardSmallGroupCommonProps };

/**
 * @deprecated Remove when CardSmallBranlineGroup and CardSmallImageGroup are removed
 */
export const CardSmallGroupCommon: React.FC<Props> = ({ cards, className, group = false, theme, _editable }) => {
	const nrOfCards = cards?.length ?? 0;
	const columns = useCardSmallGridColumns(nrOfCards, group);
	let cardsPerRow;
	if (group) {
		cardsPerRow = 4;
	} else {
		cardsPerRow = nrOfCards === 5 ? 3 : 4;
	}
	const nrOfRows = nrOfCards / cardsPerRow;
	const [showRows, setShowRows] = useState(2);

	const onShowMore = () => {
		setShowRows(showRows < nrOfRows ? showRows + 1 : showRows);
	};

	return (
		<div>
			<Grid
				columns={columns}
				rowGap="2xl"
				className={cls(
					nrOfCards === 2 && styles.center,
					isOdd(nrOfCards) && styles.oddNrOfCards,
					styles[`nr-of-grid-items--${nrOfCards}`],
					nrOfCards === 4 && group && styles.group,
					styles.grid,
					className,
				)}
				{...editableBlok({ _editable })}
			>
				{cards?.slice(0, cardsPerRow * showRows).map((card, index) => (
					<Grid.Item key={index} className={styles.gridItem}>
						{card}
					</Grid.Item>
				))}
			</Grid>
			{nrOfCards > 8 && showRows < nrOfRows && (
				<ThemeProvider theme={getCorrespondingLegacyTheme(theme)}>
					<ShowMoreButton onClick={onShowMore}>{t`Visa fler`}</ShowMoreButton>
				</ThemeProvider>
			)}
		</div>
	);
};
