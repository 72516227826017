import { PageLayout } from '@/atoms';
import DynamicComponent from '@/bloks/DynamicComponent';
import { CardFiveGroupStoryblok, NavigationPageDictionaryStoryblok, SubMenuStoryblok } from '@/components';
import { ISbStoryData } from '@/types/storyblok';
import { WordList } from './WordList/WordList';

interface Props {
	blok: NavigationPageDictionaryStoryblok;
	meta: Record<string, any>;
}

/**
 *
 * Used for Economic dictionary and Bookkeeping tip dictionary
 *
 * Renders WordList with serverside data from WordPosts or BookkeepingPosts
 * Data for WordList will only be fetched on:
 * - /fortnox-foretagsguide/ekonomisk-ordlista/ fetches stories of type WordPost
 * - /fortnox-foretagsguide/bokforingstips/ fetches stories of type BookkeepingPost
 *
 */
export function NavigationPageDictionary({ blok, meta }: Props) {
	const subMenu = blok?.subMenu as unknown as ISbStoryData<SubMenuStoryblok>;
	const contact = blok?.contact as unknown as ISbStoryData<CardFiveGroupStoryblok>;
	return (
		<PageLayout subMenu={subMenu} exits={contact} meta={meta}>
			{blok.header?.map((blok) => <DynamicComponent blok={blok} key={blok._uid} />)}
			<WordList />
			{contact && <DynamicComponent blok={contact.content} />}
		</PageLayout>
	);
}
