import { ContainerFluid, Grid, Section } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { UpsalesForm } from '@/bloks/Form';
import { HeaderCommon } from '@/bloks/Other';
import {
	HeaderCommonStoryblok,
	LinkButtonStoryblok,
	SectionContactFormStoryblok,
	UpsalesFormStoryblok,
} from '@/components';
import { ISbStoryData } from '@/types/storyblok';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { renderRichText } from '../../RichText';
import styles from './SectionContactForm.module.scss';

interface Props {
	blok: SectionContactFormStoryblok;
	meta?: {
		isParent?: boolean;
		zone?: number | undefined;
	};
}
export const SectionContactForm: React.FC<Props> = ({ blok, meta }) => {
	const form = blok?.form as unknown as ISbStoryData<UpsalesFormStoryblok>;
	const formHeader = blok?.formHeader as unknown as HeaderCommonStoryblok[];
	return (
		<ContainerFluid>
			<Section
				{...editableBlok(blok)}
				className={cls(styles.container, meta?.isParent === false && styles.borderRadius)}
			>
				<Grid columns={{ base: 1, lg: 1, xl: '7fr 5fr' }} colGap="3xl" rowGap="2xl">
					<Grid.Item className={styles.textCol}>
						{blok.text && (
							<div className={styles.textContainer} data-testid="section-contact-form-textcontainer">
								{renderRichText(blok.text)}
							</div>
						)}
						{!!blok?.buttons?.length && (
							<div className={styles.buttonContainer}>
								{blok?.buttons?.map((link: LinkButtonStoryblok) => (
									<LinkButton
										key={link._uid}
										className={styles.button}
										{...LinkButton.blokProps({ blok: link })}
										variant="outlined"
									/>
								))}
							</div>
						)}
						{blok.additionalText && (
							<div className={styles.additionalTextContainer}>{renderRichText(blok.additionalText)}</div>
						)}
					</Grid.Item>
					<Grid.Item className={styles.formCol}>
						<div className={styles.formContainer}>
							{formHeader?.map((header: HeaderCommonStoryblok) => (
								<HeaderCommon key={header._uid} {...HeaderCommon.blokProps({ blok: header })} />
							))}
							{form && (
								<UpsalesForm
									{...UpsalesForm.blokProps({
										story: form,
										meta: { ...meta, parent: blok.component, layout: 'twoCols' },
									})}
								/>
							)}
						</div>
					</Grid.Item>
				</Grid>
			</Section>
		</ContainerFluid>
	);
};
