import { BrandLine, Card, Heading, Text } from '@/atoms';
import { LinkButton, LinkButtonProps } from '@/bloks/Button';
import { CardSmallBrandlineStoryblok } from '@/components';
import { useImpressionTracking } from '@/contexts/piwik/ImpressionTrackingProvider';
import { useThemeContext } from '@/contexts/theme';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import styles from './Common.module.scss';

interface BlokProps {
	blok: CardSmallBrandlineStoryblok;
	meta?: {
		buttonVariant?: 'filled' | 'outlined';
		padding?: boolean;
		layout?: 'center';
	};
}

export type CardSmallBrandlineMeta = BlokProps['meta'];

const blokProps = ({ blok, meta }: BlokProps): CardSmallBrandlineProps => ({
	smallTitle: blok.smallTitle,
	title: blok.title,
	text: blok.text,
	linkButton: blok.linkButton?.[0]?.link && LinkButton.blokProps({ blok: blok.linkButton[0] }),
	buttonVariant: meta?.buttonVariant ?? 'outlined',
	padding: meta?.padding,
	layout: meta?.layout,
	_editable: blok?._editable,
});

export interface CardSmallBrandlineProps {
	smallTitle?: string;
	title: string;
	text: string;
	linkButton?: LinkButtonProps;
	buttonVariant?: 'filled' | 'outlined';
	padding?: boolean;
	layout?: 'center';
	_editable?: string;
}

/**
 * @deprecated Will be replaced by CardSmall
 *
 * CardSmallBrandline is a card with a branline, small title ,title, text and button
 *
 * Used in CardSmallBranlineGroup which can be inserted into SectionCard
 */
export function CardSmallBrandline({
	smallTitle,
	title,
	text,
	linkButton,
	buttonVariant = 'filled',
	padding = false,
	layout,
	_editable,
}: CardSmallBrandlineProps) {
	const { applyChildTheme } = useThemeContext();
	const { setTargetInteraction } = useImpressionTracking();
	return (
		<Card
			className={cls(
				styles.card,
				styles.cardSmallBrandline,
				layout === 'center' && styles.layoutCenter,
				(padding || applyChildTheme) && styles.padding,
			)}
			border={false}
			padding="custom"
			{...editableBlok({ _editable })}
		>
			<BrandLine className={styles.brandline} />
			{smallTitle && <Heading as="div" size="h6" title={smallTitle} />}
			<Heading as="div" size="h4" title={title} marginBottom="xl" />
			<Text marginBottom="xl">{text}</Text>
			{linkButton?.href && (
				<LinkButton
					{...linkButton}
					variant={buttonVariant}
					onClick={() => {
						setTargetInteraction({ contentTarget: linkButton.children as string });
					}}
				/>
			)}
		</Card>
	);
}

CardSmallBrandline.blokProps = blokProps;
