import { PageLayout } from '@/atoms';
import { CardFiveGroupStoryblok, CompanyGuidePostStoryblok, SubMenuStoryblok } from '@/components';
import { useRelatedArticles } from '@/contexts/articles';
import { ISbStoryData } from '@/types/storyblok';
import { ArticleLayout } from './ArticleLayout';

interface Props {
	blok: CompanyGuidePostStoryblok;
	meta: Record<string, any>;
}

export const CompanyGuidePost: React.FC<Props> = ({ blok, meta }) => {
	const contact = blok?.contact as unknown as ISbStoryData<CardFiveGroupStoryblok>;
	const subMenu = blok?.subMenu as unknown as ISbStoryData<SubMenuStoryblok>;
	const { relatedArticles } = useRelatedArticles();

	return (
		<PageLayout subMenu={subMenu} exits={contact} relatedArticles={relatedArticles} meta={meta}>
			<ArticleLayout data-test-id="company-guide-article" blok={blok} meta={meta} />
		</PageLayout>
	);
};
