interface Props {
	children: React.ReactNode;
	/**
	 * Any value or data type that is not null, undefined of false.
	 * Can be an array, in which case all elements in the array have
	 * to evaluate to a truthy value in order for the children to be rendered.
	 */
	truthy: any;
}

const falsyValues = [undefined, null, false];
const isTruthyValue = (t: any): boolean => !falsyValues.includes(t);
const isTruthy = (truthy: any): boolean => (Array.isArray(truthy) ? truthy.every((e) => isTruthyValue(e)) : truthy);

/**
 * Useful for rendering conditional blocks with truthy condition.
 * @example
 * <When truthy={evaluateStuff()}> ...children... </When>
 * <When truthy={[isLoggedIn, shouldDisplayChildren]}> ...children... </When>
 */
export const When: React.FC<Props> = ({ truthy, children }) => (isTruthy(truthy) ? <>{children}</> : null);
