import { Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { LinkButtonWithLabelStoryblok } from '@/components';
import { editableBlok } from '@/utils/storyblok';
import { LinkButton, LinkButtonProps } from '../LinkButton/LinkButton';

interface BlokProps {
	blok: LinkButtonWithLabelStoryblok;
	meta?: {
		variant?: 'text';
	};
}

const blokProps = ({ blok, meta }: BlokProps): LinkButtonWithLabelProps => ({
	linkButton: blok.linkButton.map((btn) => LinkButton.blokProps({ blok: btn })),
	label: blok.label,
	variant: meta?.variant,
	_editable: blok._editable,
});

export interface LinkButtonWithLabelProps {
	linkButton: LinkButtonProps[];
	variant?: 'text';
	label: string;
	_editable?: string;
}

export const LinkButtonWithLabel: Blok<LinkButtonWithLabelProps, BlokProps> = ({
	linkButton,
	label,
	variant,
	_editable,
}) => {
	return (
		<div {...editableBlok({ _editable })}>
			<Text marginBottom="xs">{label}</Text>
			<LinkButton {...linkButton[0]} variant={variant} />
		</div>
	);
};

LinkButtonWithLabel.blokProps = blokProps;
