import { Heading, Text } from '@/atoms';
import { CardFiveUpsalesFormStoryblok, ThemeStoryblok, UpsalesNewsFormStoryblok } from '@/components';
import { ISbStoryData } from '@/types/storyblok';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { UpsalesNewsForm } from '../Form';
import styles from './CardFive.module.scss';

interface Props {
	blok: CardFiveUpsalesFormStoryblok;
	meta?: {
		theme?: ThemeStoryblok['theme'];
		zone?: number | undefined;
	};
}

export function CardFiveUpsalesForm({ blok, meta }: Props) {
	const { smallTitle, title, text } = blok;
	const theme = meta?.theme ?? 'dark-yellow';
	const form = blok?.form as unknown as ISbStoryData<UpsalesNewsFormStoryblok>;

	return (
		<div
			{...editableBlok(blok)}
			className={cls(styles.container, styles.upsalesFormContainer, styles.card, styles[`theme--${theme}`])}
		>
			{smallTitle && (
				<Heading
					as="div"
					size="h6"
					title={smallTitle}
					marginBottom="xl"
					data-testid="card-five-upsales-form-small-title"
				/>
			)}
			{title && (
				<Heading as="div" size="h4" title={title} marginBottom="xl" data-testid="card-five-upsales-form-header" />
			)}
			{text && <Text marginBottom="lg">{text}</Text>}
			{form && <UpsalesNewsForm story={form} />}
		</div>
	);
}
