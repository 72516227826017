import { Card, Heading, Icon } from '@/atoms';
import { ThemeProvider, useTheme } from '@/contexts/theme';
import { getChildTheme, getCorrespondingLegacyTheme, getThemeInfo } from '@/themes';
import { Themes } from '@/types';
import { cls } from '@/utils';
import { ReactElement } from 'react';
import styles from './ExpansionPanel.module.scss';
import { useExpansionPanels } from './ExpansionPanelContext';

export interface ExpansionPanelProps {
	/**
	 * Id of panel
	 * Must be a url safe anchor generated by
	 * ```typescript
	 * const panelId = getHeadingAnchorId('Title of panel')
	 * ```
	 *
	 * Pass in the same id as `panelId` to Panel.Header and Panel.Content.
	 */
	panelId: string;
	as?: string | React.ComponentType<any>;
	className?: string;
	theme?: Themes['theme'];
	children: ReactElement[];
	/**
	 * Applies a compact style to the accordion
	 */
	compact?: boolean;
}

type ExpansionPanelLayout<P> = React.FC<P> & {
	Header: typeof Header;
	Content: typeof Content;
};
interface ContentProps {
	panelId: string;
	as?: string | React.ComponentType<any>;
	children: React.ReactNode;
	dataIdNr?: number;
	compact?: boolean;
}
interface HeaderProps {
	panelId: string;
	as?: string | React.ComponentType<any>;
	title: string;
	titleSize?: 'h5' | 'h6';
	dataIdNr?: number;
	theme?: Themes['theme'];
	compact?: boolean;
}

const Header: React.FC<HeaderProps> = ({
	as = 'div',
	panelId,
	title = '',
	titleSize = 'h5',
	dataIdNr,
	theme,
	compact = false,
}) => {
	const { isOpen, toggleOpen } = useExpansionPanels();
	const Component = as as any;
	const border = !compact && theme === 'white';

	return (
		<Card border={border} padding={compact ? 'none' : 'xl'}>
			<Component
				onClick={() => {
					toggleOpen(panelId);
				}}
				className={styles.header}
				aria-controls={`content-${panelId}`}
				data-testid={`expansion-panel-${dataIdNr}`}
			>
				<Heading as="div" size={titleSize} id={false} title={title} marginBottom="none" />
				<Icon name="chevron-down" size="1x" className={cls(styles.icon, { [styles.iconOpen]: isOpen(panelId) })} />
			</Component>
		</Card>
	);
};

const Content: React.FC<ContentProps> = ({ as = 'div', panelId, children, dataIdNr, compact }) => {
	const { isOpen } = useExpansionPanels();
	const Component = as as any;

	const parentThemeInfo = useTheme();
	const childTheme = compact ? parentThemeInfo.name : getChildTheme(parentThemeInfo.name);
	const themeInfo = getThemeInfo(childTheme);

	return (
		<ThemeProvider theme={childTheme}>
			<Component
				id={`content-${panelId}`}
				data-testid={`expansion-content-${dataIdNr}`}
				className={cls(
					styles.content,
					isOpen(panelId) ? styles.open : styles.closed,
					themeInfo.styles.bgColor,
					styles.closed,
				)}
			>
				{children}
			</Component>
		</ThemeProvider>
	);
};

/**
 * Abstracted Expansion Panel atom component.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=398%3A11765
 */
export const ExpansionPanel: ExpansionPanelLayout<ExpansionPanelProps> = ({
	as = 'div',
	children,
	panelId,
	className,
	theme,
	compact = false,
}) => {
	const Component = as as any;

	return (
		<ThemeProvider theme={getCorrespondingLegacyTheme(theme)}>
			<Component id={panelId} className={cls(styles.panel, className, compact && styles.compact)}>
				{children}
			</Component>
		</ThemeProvider>
	);
};

ExpansionPanel.Header = Header;
ExpansionPanel.Content = Content;
