import { ContainerFluid, Grid, Section } from '@/atoms';
import DynamicComponent from '@/bloks/DynamicComponent';
import { CalendarStoryblok, SectionCalendarStoryblok, ThemeStoryblok } from '@/components';
import { ISbStoryData } from '@/types/storyblok';
import React from 'react';

interface Props {
	blok: SectionCalendarStoryblok;
	meta: { zone?: number; theme: ThemeStoryblok['theme'] };
}

export const SectionCalendar: React.FC<Props> = ({ blok, meta }) => {
	const calendar = blok.calendar as unknown as ISbStoryData<CalendarStoryblok>;

	return (
		<ContainerFluid>
			<Section>
				{blok.header?.map((header) => <DynamicComponent blok={header} key={header._uid} />)}
				<Grid columns={{ base: 1, lg: 2 }} colGap={'3xl'} rowGap={'3xl'}>
					<Grid.Item key={calendar.uuid}>
						<DynamicComponent blok={calendar.content} meta={meta} />
					</Grid.Item>

					{blok.groups?.map((groups, index) => (
						<Grid.Item key={index}>
							<DynamicComponent blok={groups} key={groups._uid} />
						</Grid.Item>
					))}
				</Grid>
			</Section>
		</ContainerFluid>
	);
};
