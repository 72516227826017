import { Grid } from '@/atoms';
import { VideoGroupStoryblok } from '@/components';
import { TextFooter } from '../TextFooter';
import { Video } from '../Video/Video';
import styles from './VideoGroup.module.scss';

interface Props {
	blok: VideoGroupStoryblok;
	meta: Record<string, any>;
	className?: string;
}

export const VideoGroup: React.FC<Props> = ({ blok, meta, className }) => {
	const { text } = blok;

	const video = Array.isArray(blok.video) ? blok.video[0] : undefined;

	return (
		<Grid className={className} columns={{ base: 1, md: 2 }} colGap="xl" rowGap="xl">
			{text && (
				<Grid.Item className={styles.gridItemText}>
					<div className={styles.textContainer}>{text && <TextFooter blok={text[0]} meta={{ ...meta }} />}</div>
				</Grid.Item>
			)}
			{video?.id && <Grid.Item>{video && <Video {...Video.blokProps({ blok: video })} />}</Grid.Item>}
		</Grid>
	);
};
