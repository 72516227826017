import { BlogPostStoryblok } from '@/components';
import type { ImageAssetProps } from '@/metabloks';
import { ArticleCardProps } from '@/types/article';
import { ISbStoryData } from '@/types/storyblok';
import { truncateButWholeWords } from '@/utils/string';
import { StoryblokStory } from 'storyblok-generate-ts';

export function buildArticleCardProps(
	story: ISbStoryData<BlogPostStoryblok> | StoryblokStory<BlogPostStoryblok>,
): ArticleCardProps {
	const { full_slug: slug, tag_list: tags } = story;
	const firstPublishedAt = story.first_published_at ?? '';
	const publishedAt = story.published_at ?? '';
	const blok = story.content;
	const header = blok.header ? blok.header[0] : null;
	const title = blok.cardTitle || header?.h1Part1 || '';
	const shortText = blok.cardShortText || truncateButWholeWords(blok.intro!);
	const image = (getAssetOrFallback(header?.image, blok?.cardImage) as ImageAssetProps) ?? null;

	return { slug, title, shortText, image, tags, publishedAt, firstPublishedAt };
}

/** Takes any number of possible assets and returns the first with a filename */
function getAssetOrFallback<T extends { filename?: string } | undefined>(...assets: T[]): T | undefined {
	return assets.find((a) => a?.filename);
}
