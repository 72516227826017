import { TableBlok, TableHead } from '@/bloks/RichText/TableBlok';
import { CustomTableStoryblok } from '@/components';

interface Props {
	blok: CustomTableStoryblok;
	meta: Record<string, any>;
}

export const CustomTable: React.FC<Props> = ({ blok }) => {
	const theme = blok?.theme?.[0]?.theme ?? 'default';
	const { leftTdIsLabel } = blok;

	return blok.table ? (
		<TableBlok
			table={{
				value: { thead: blok.table.thead as unknown as TableHead[], tbody: blok?.table?.tbody, fieldtype: 'table' },
			}}
			theme={theme}
			leftTdIsLabel={leftTdIsLabel}
			showBorderRight={false}
		/>
	) : null;
};
