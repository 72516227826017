import { BrandSymbol, Heading, Icon, LinkAnchor, Logo } from '@/atoms';
import {
	GlobalMenuFooterStoryblok,
	GlobalMenuGroupStoryblok,
	GlobalMenuItemGroupStoryblok,
	GlobalMenuItemStoryblok,
	GlobalMenuV2Storyblok,
} from '@/components';
import { useGlobalMenu } from '@/contexts/menu/GlobalMenuProvider';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { t } from 'ttag';
import { ClickOutsideAlerter } from './../../hooks/useClickOutsideAlerter';
import { GlobalMenuGroupItem, GlobalMenuItem, GlobalMenuItemWithSubLinks } from './GlobalMenu/GlobalMenuItems/';
import styles from './GlobalMenuV2.module.scss';

interface Props {
	blok: GlobalMenuV2Storyblok;
	meta: { enableLogin?: boolean };
}

/*
 * Main menu
 */
export const GlobalMenuV2: React.FC<Props> = ({ blok, meta }) => {
	const [hasScrollBar, setHasScrollBar] = useState(false);
	const { showFirstPanel, showSecondPanel, setShowFirstPanel, setShowSecondPanel, setMobileView, mobileView } =
		useGlobalMenu();
	const [currentSecondPanelMainItem, setCurrentSecondPanelMainItem] = useState<
		GlobalMenuItemStoryblok | GlobalMenuItemGroupStoryblok | null
	>(null);
	const groups = blok.content;
	const secondPanelItems =
		groups?.flatMap((group) => group.items).filter((groupItem) => groupItem?.component === 'GlobalMenuItemGroup') ?? [];
	const menuRef = useRef<HTMLElement | null>(null);
	const router = useRouter();
	const [currentSecondPanelItems, setCurrentSecondPanelItems] = useState<any[]>(
		secondPanelItems?.flatMap((p: any) => p?.items) ?? [],
	);

	const { enableLogin } = meta;

	useEffect(() => {
		setShowFirstPanel(false);
	}, [router, setShowFirstPanel]);

	useEffect(() => {
		const menu = menuRef.current as HTMLElement;
		const closeButton = menuRef.current?.getElementsByClassName('closeButton')[0] as HTMLButtonElement;
		const panelContainer = menuRef.current?.getElementsByClassName('groupContainer')[0] as HTMLElement;
		closeButton.focus();
		if (panelContainer && menu) {
			setHasScrollBar(panelContainer?.scrollHeight > panelContainer?.offsetHeight);
		}
	}, [menuRef, showFirstPanel]);

	useEffect(() => {
		if (!window) return;
		const { innerWidth: width } = window;
		setMobileView(width < 768);
	}, [setMobileView]);

	const handleSecondPanel = (itemTitle: string) => {
		if (!secondPanelItems) {
			return;
		}
		const currentItem = secondPanelItems.find((item) => item?.title === itemTitle);
		if (currentSecondPanelMainItem && currentSecondPanelMainItem.title === itemTitle && showSecondPanel) {
			setShowSecondPanel(false);
			return;
		}

		if (!currentItem) {
			return;
		}

		setCurrentSecondPanelMainItem(currentItem);
		setShowSecondPanel(true);

		if (currentItem.component === 'GlobalMenuItemGroup' && currentItem.items) {
			setCurrentSecondPanelItems(currentItem.items);
		}
	};

	const handleClosePanels = () => {
		if (showSecondPanel && !mobileView) {
			setShowSecondPanel(false);
			return;
		}
		setShowFirstPanel(false);
	};

	const handleKeyDown = (event: any) => {
		if (event.key !== 'Escape') {
			return;
		}

		handleClosePanels();
	};

	return (
		<>
			{showFirstPanel && <div className={styles.backdrop} />}
			<nav
				lang="sv"
				ref={menuRef}
				className={cls(styles.menu, !showFirstPanel && styles.hidden)}
				{...editableBlok(blok)}
				onKeyDown={handleKeyDown}
			>
				<ClickOutsideAlerter
					callback={() => {
						setShowFirstPanel(false);
					}}
				>
					<div className={styles.header}>
						<div className={styles.logoContainer}>
							<button
								aria-label={t`Stäng meny`}
								className={styles.closeMenuButton}
								onClick={() => setShowFirstPanel(!setShowFirstPanel)}
							>
								<Icon name="xmark" size="1x" color="--text-light" />
							</button>
							<LinkAnchor href="/" className={styles.logo}>
								<Logo size="138x30.14" />
							</LinkAnchor>
						</div>
						{<button className={cls(styles.closeButton, 'closeButton')} onClick={handleClosePanels}>{t`Stäng`}</button>}
					</div>

					<div className={styles.panelContainer} role="menubar">
						<div className={styles.firstPanel}>
							<div className={cls('groupContainer', styles.groupContainer)}>
								{groups?.map((group: GlobalMenuGroupStoryblok) => (
									<ul key={group._uid} className={styles.linkGroup}>
										{group.items?.map((groupItem: any) => {
											return (
												<li key={groupItem._uid}>
													{groupItem.component === 'GlobalMenuItemGroup' ? (
														<GlobalMenuGroupItem
															blok={groupItem}
															onClick={() => {
																handleSecondPanel(groupItem.title);
															}}
														/>
													) : (
														<div className={styles.linkWrapper}>
															<GlobalMenuItem blok={groupItem} />
														</div>
													)}
												</li>
											);
										})}
									</ul>
								))}
								{enableLogin && (
									<ul className={styles.linkGroup}>
										<li>
											<div className={styles.linkWrapper}>
												<GlobalMenuItem
													blok={{
														_uid: '',
														title: t`Logga in`,
														component: 'GlobalMenuItem',
														link: { url: 'https://apps.fortnox.se/fs/fs/login.php', linktype: 'url' },
													}}
												/>
											</div>
										</li>
									</ul>
								)}
								{blok.footer &&
									blok.footer?.map((footer: GlobalMenuFooterStoryblok) => {
										return (
											<div key={footer._uid} className={styles.footer}>
												<ul className={styles.footerLinks}>
													{footer.items?.map((footerItem: GlobalMenuItemStoryblok) => (
														<li key={footerItem._uid} className={styles.listItem}>
															<div className={styles.linkWrapper}>
																<GlobalMenuItem blok={footerItem} />
															</div>
														</li>
													))}
												</ul>
											</div>
										);
									})}
							</div>
						</div>

						<div className={cls(styles.secondPanel, !showSecondPanel && styles.hidden)}>
							{secondPanelItems && (
								<div
									className={cls(
										'groupContainer',
										styles.groupContainer,
										!hasScrollBar && !mobileView && styles.addBorder,
									)}
								>
									{mobileView && (
										<button
											className={styles.backButton}
											aria-label={t`Gå tillbaka`}
											onClick={() => {
												setShowSecondPanel(false);
											}}
										>
											<Icon className={styles.icon} name="arrow-right" rotation={180} size="1x" />
										</button>
									)}

									{currentSecondPanelMainItem &&
										(currentSecondPanelMainItem?.link ? (
											<div className={cls(styles.linkWrapper, styles.secondPanelHeaderLink, styles.secondPanelHeader)}>
												<GlobalMenuItem blok={currentSecondPanelMainItem} className={styles.secondPanelHeader} />
											</div>
										) : (
											<div className={cls(styles.linkWrapper, styles.secondPanelHeaderLink, styles.secondPanelHeader)}>
												<Heading
													className={styles.secondPanelHeader}
													as="div"
													size="h5"
													title={currentSecondPanelMainItem?.title}
												/>
											</div>
										))}

									<ul className={styles.secondPanelList}>
										{currentSecondPanelItems?.map((groupItem: any) => {
											return (
												<li key={groupItem._uid}>
													{groupItem.component === 'GlobalMenuItemWithSubLinks' ? (
														<div className={styles.linkWrapper}>
															<GlobalMenuItemWithSubLinks
																blok={groupItem}
																onClick={() => {
																	handleSecondPanel(groupItem.title);
																}}
															/>
														</div>
													) : (
														<div className={styles.linkWrapper}>
															<GlobalMenuItem blok={groupItem} />
														</div>
													)}
												</li>
											);
										})}
									</ul>
								</div>
							)}
						</div>

						{showSecondPanel && !mobileView && (
							<div className={styles.brandSymbol}>
								<BrandSymbol />
							</div>
						)}
					</div>
				</ClickOutsideAlerter>
			</nav>
		</>
	);
};
