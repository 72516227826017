import { PageLayout } from '@/atoms';
import DynamicComponent from '@/bloks/DynamicComponent';
import { CardFiveGroupStoryblok, SupportPageStoryblok } from '@/components';
import { ISbStoryData } from '@/types/storyblok';

interface Props {
	blok: SupportPageStoryblok;
	meta: Record<string, any>;
}

export const SupportPage: React.FC<Props> = ({ blok, meta }) => {
	const { header, sections } = blok;
	const contact = blok?.contact as unknown as ISbStoryData<CardFiveGroupStoryblok>;
	return (
		<PageLayout exits={contact} meta={meta}>
			{header?.map((header) => <DynamicComponent blok={header} key={header._uid} />)}

			{sections?.map((section) => (
				<DynamicComponent blok={section} key={section._uid} meta={{ accentColor: 'pink' }} />
			))}
		</PageLayout>
	);
};
