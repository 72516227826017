import { Heading, Text } from '@/atoms';
import { CtaThreeStoryblok } from '@/components';
import { editableBlok } from '@/utils/storyblok';
import { LinkButton } from '../Button';
import styles from './CtaThree.module.scss';

interface Props {
	blok: CtaThreeStoryblok;
}

/**
 * @deprecated Will be removed
 */
export const CtaThree: React.FC<Props> = ({ blok }) => {
	return (
		<div {...editableBlok(blok)} className={styles.container}>
			<section className={styles.textContainer}>
				{blok?.title && <Heading as="div" size="h4" title={blok.title} data-testid="cta-three-header" />}
				<Text className={styles.text}>{blok.text}</Text>
				{blok.linkButtons?.map((button) => (
					<LinkButton {...LinkButton.blokProps({ blok: button })} key={button._uid} />
				))}
			</section>
		</div>
	);
};
