import { ContainerFluid, Section } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { LinkButton, LinkButtonProps } from '@/bloks/Button';
import { CardMedium, CardMediumProps } from '@/bloks/Card/CardMedium';
import { HeaderCommon, HeaderCommonProps } from '@/bloks/Other/HeaderCommon';
import { CardMediumStoryblok, SectionCareerCardSidescrollStoryblok, ThemeCareerStoryblok } from '@/components';
import { gsap, useGSAP } from '@/utils/gsap';
import { editableBlok } from '@/utils/storyblok';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useEffect, useRef, useState } from 'react';
import styles from './SectionCareerCardSidescroll.module.scss';

interface BlokProps {
	blok: SectionCareerCardSidescrollStoryblok;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	header: blok.header?.[0] && HeaderCommon.blokProps({ blok: blok.header[0] }),
	cards: blok.cardGroup?.[0].cards.map((card) =>
		CardMedium.blokProps({ blok: card as unknown as CardMediumStoryblok, meta: { noBackground: true } }),
	),
	linkButton: blok.linkButton?.[0] && LinkButton.blokProps({ blok: blok.linkButton[0] }),
	theme: blok.theme?.[0]?.theme,
	_editable: blok._editable,
});

interface Props {
	header?: HeaderCommonProps;
	cards?: CardMediumProps[];
	linkButton?: LinkButtonProps;
	theme?: ThemeCareerStoryblok['theme'];
	_editable?: string;
}

export const SectionCareerCardSidescroll: Blok<Props, BlokProps> = ({
	header,
	cards,
	linkButton,
	theme = 'white',
	_editable,
}) => {
	const container = useRef<HTMLDivElement | null>(null);
	const cardContainer = useRef<HTMLDivElement | null>(null);
	const [navBarHeight, setNavBarHeight] = useState<number | null>();
	const isLargeScreen = useMediaQuery('(min-width: 1920px)');

	useEffect(() => {
		setNavBarHeight(Math.floor(document.querySelector('header')?.parentElement?.getBoundingClientRect().height ?? 0));
	}, []);

	useGSAP(
		() => {
			if (!cards || cards?.length === 2) {
				return;
			}

			if (!cardContainer.current || !container.current || !navBarHeight) {
				return;
			}

			const cardElemWidth = document.querySelectorAll('.animated-card')[0].getBoundingClientRect().width;
			const cardContainerWidth = cardContainer.current.getBoundingClientRect().width;
			const visibleCards = cardContainerWidth / cardElemWidth;
			const hiddenCardContainerWidth = (cards?.length - visibleCards) * cardElemWidth;

			gsap.to(cardContainer.current, {
				x: -hiddenCardContainerWidth,
				duration: 3,
				ease: 'none',
				scrollTrigger: {
					trigger: isLargeScreen ? container.current : cardContainer.current,
					start: () => `top ${navBarHeight}`,
					toggleActions: 'restart pause reverse pause',
					scrub: true,
					pin: true,
				},
			});
		},
		{ scope: container, dependencies: [cards?.length, navBarHeight], revertOnUpdate: true },
	);

	return (
		<div ref={container}>
			<ContainerFluid theme={theme} className={styles.container}>
				<Section {...editableBlok({ _editable })} paddingX={{ base: 'none', md: '3xl', lg: '5xl' }}>
					{header && <HeaderCommon {...header} layout="center" className={styles.header} />}
					<div className={styles.cardGroup} ref={cardContainer}>
						{cards?.map((card, index) => (
							<div key={index} className="animated-card">
								<CardMedium {...card} className={styles.card} largeImage />
							</div>
						))}
					</div>
					{linkButton && (
						<div className={styles.btnContainer}>
							<LinkButton {...linkButton} />
						</div>
					)}
				</Section>
			</ContainerFluid>
		</div>
	);
};

SectionCareerCardSidescroll.blokProps = blokProps;
