import { Heading, Text } from '@/atoms';
import calcStyles from '@/bloks/Calculator/CalculatorStyling.module.scss';
import { GlobalCalculationTextStoryblok } from '@/components';
import { getStoryContent } from '@/utils/storyblok';
import { StoryblokStory } from 'storyblok-generate-ts';
import React from 'react';

interface CalculatorDescriptionProps {
	blok: {
		descriptionHeader?: string;
		descriptionText?: string;
		calculationText?: StoryblokStory<GlobalCalculationTextStoryblok> | string;
	};
}

export const CalculatorDescription: React.FC<CalculatorDescriptionProps> = ({ blok }) => {
	const calculationText = getStoryContent(blok.calculationText);
	const descriptionHeader = blok.descriptionHeader || calculationText?.calculationHeader;
	const descriptionText = blok.descriptionText || calculationText?.calculationDescription;

	return (
		<div className={calcStyles.description}>
			{descriptionHeader && (
				<span className={calcStyles.calculationHeader}>
					<Heading as="h3" title={`${descriptionHeader}`} size="h6" />
				</span>
			)}
			<Text>{descriptionText}</Text>
		</div>
	);
};
