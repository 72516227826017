import { Heading, LinkAnchor, Tags, Text } from '@/atoms';
import { ImageAsset } from '@/metabloks';
import { ArticleCardProps } from '@/types/article';
import { cls } from '@/utils';
import { editableBlok, normalizeFullSlug } from '@/utils/storyblok';
import React from 'react';
import { t } from 'ttag';
import { LinkButton } from '../Button';
import styles from './ArticleLarge.module.scss';

interface ItemProps {
	article: ArticleCardProps;
	className?: string;
	meta?: { indexPage: boolean };
	_editable?: string;
	/* Whether the image should have loading priority */
	priority?: boolean;
}

/**
 * Large Article with content from blog posts. Used in ArticleGroup and ArticleGrid.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=398%3A10641
 */
export const ArticleLarge: React.FC<ItemProps> = ({ article, meta, className, priority, _editable }) => {
	const { slug, title, shortText, image, tags, firstPublishedAt, publishedAt } = article;

	return (
		<article
			className={cls(styles.card, styles[`${className}`], meta?.indexPage && styles.indexPage)}
			{...editableBlok({ _editable })}
		>
			{image?.filename && (
				<div className={styles.image}>
					<ImageAsset absolute asset={image} objectFit="cover" fill priority={priority} />
				</div>
			)}
			<div className={styles.content}>
				<div>
					<Tags tags={tags} published={firstPublishedAt} modified={publishedAt} />
					<LinkAnchor href={normalizeFullSlug(slug) ?? '#'}>
						<Heading as="div" size="h5" className={styles.title} title={title} data-testid="article-large-header-h5" />
					</LinkAnchor>
					<Text>{shortText}...</Text>
				</div>

				{slug && (
					<footer>
						<LinkButton href={normalizeFullSlug(slug) ?? '#'} variant="text">
							{t`Läs hela artikeln`}
						</LinkButton>
					</footer>
				)}
			</div>
		</article>
	);
};
