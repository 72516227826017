import { ContainerFluid, Section } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { HeaderCommon, HeaderCommonProps, Video, VideoProps } from '@/bloks/Other';
import { SectionVideoLargeStoryblok, ThemeLightnessStoryblok } from '@/components';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import styles from './SectionVideoLarge.module.scss';

interface BlokProps {
	blok: SectionVideoLargeStoryblok;
	meta?: {
		accentColor?: 'pink';
		zone?: number;
	};
}

const blokProps = ({ blok, meta }: BlokProps): Props => ({
	header: blok.header?.[0] && { ...HeaderCommon.blokProps({ blok: blok.header[0] }) },
	video: blok.video?.[0] && { ...Video.blokProps({ blok: blok.video[0] }) },
	lightness: blok.lightness?.[0] && blok.lightness[0].lightness,
	accentColor: meta?.accentColor,
	_editable: blok._editable,
	zone: meta?.zone,
});

interface Props {
	header?: HeaderCommonProps;
	video: VideoProps;
	lightness?: ThemeLightnessStoryblok['lightness'];
	accentColor?: 'pink' | 'yellow';
	_editable?: string;
	zone?: number;
}

/**
 * A section with a background video.
 */
export const SectionVideoLarge: Blok<Props, BlokProps> = ({
	header,
	video,
	lightness = 'dark',
	accentColor,
	_editable,
	zone,
}) => {
	const contentName = `${zone}|SectionVideoLarge`;
	const contentPiece = header && header.titlePart2 ? `${header.titlePart1} ${header.titlePart2}` : header?.titlePart1;

	return (
		<ImpressionTrackingProvider value={{ contentName, contentPiece, contentTarget: 'no interactions possible' }}>
			<ContainerFluid
				{...editableBlok({ _editable })}
				className={cls(styles.container, !header && styles.noHeader, styles[`accent-color--${accentColor}`])}
				theme="white"
				lightness={lightness}
			>
				<Section padding={{ base: 'none' }} className={styles.section}>
					{header && (
						<>
							<div className={styles.overlay} />
							<div className={styles.headerWrapper}>
								<HeaderCommon {...header} className={cls(styles.header, styles.mobileHeader)} linebreak />
								<HeaderCommon
									{...header}
									applyLightnessTheme
									className={cls(styles.header, styles.desktopHeader)}
									linebreak
								/>
							</div>
						</>
					)}
					{video && (
						<div className={styles.videoWrapper}>
							<Video {...video} autoplay hideControls loop autoPause={false} width="100" />
						</div>
					)}
				</Section>
			</ContainerFluid>
		</ImpressionTrackingProvider>
	);
};

SectionVideoLarge.blokProps = blokProps;
