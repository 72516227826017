import { ContainerFluid, Grid, Heading, LargeSticker, Section } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { LinkButtonStoryblok, SectionLollipopStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { renderRichText } from '../../RichText';
import styles from './SectionLollipop.module.scss';

interface Props {
	blok: SectionLollipopStoryblok;
}
export const SectionLollipop: React.FC<Props> = ({ blok }) => {
	const { title, text, linkButtons, stickerPosition, sticker } = blok;

	const layout = stickerPosition || 'left';

	return (
		<ContainerFluid theme="lightGreen">
			<Section>
				<Grid
					rowGap="2xl"
					colGap="2xl"
					{...editableBlok(blok)}
					className={cls(styles.container, styles[`layout--${layout}`])}
					data-testid="section-lollipop"
				>
					<Grid.Item className={styles.sticker}>
						{sticker &&
							sticker?.length > 0 &&
							sticker.map((sticker) => {
								const icon = sticker.icon?.[0];
								return (
									<LargeSticker key={sticker._uid} title={sticker.text} icon={icon?.name} className={styles.sticker} />
								);
							})}
					</Grid.Item>
					<Grid.Item className={styles.content}>
						{title && <Heading as="div" size="h4" title={title} data-testid="section-lollipop-header-h4" />}
						<div>{renderRichText(text)}</div>
						{linkButtons &&
							linkButtons?.map((button: LinkButtonStoryblok) => (
								<LinkButton {...LinkButton.blokProps({ blok: button })} key={button._uid} variant="text" />
							))}
					</Grid.Item>
				</Grid>
			</Section>
		</ContainerFluid>
	);
};
