import { Divider, Heading, LinkAnchor, Text } from '@/atoms';
import { ArticleCardProps } from '@/types/article';
import { editableBlok, normalizeFullSlug } from '@/utils/storyblok';
import { t } from 'ttag';
import { LinkButton } from '../Button';
import styles from './ArticleSmall.module.scss';

/**
 * Small Article with content from blog posts. Used in ArticleGroupSmall.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=161%3A6596
 */

interface Props {
	article: ArticleCardProps;
	_editable?: string;
}
export const ArticleSmall: React.FC<Props> = ({ article, _editable }) => {
	return (
		<article {...editableBlok({ _editable })} className={styles.container}>
			<div>
				<Divider size="medium" color="--text-heading-color" className={styles.divider} />
				{article.title && (
					<LinkAnchor href={normalizeFullSlug(article.slug)!}>
						<Heading as="div" size="h5" title={article.title} />
					</LinkAnchor>
				)}
				<Text className={styles.date}>
					<time dateTime={article.firstPublishedAt}>{article.firstPublishedAt?.split('T')[0]}</time>
				</Text>
				{article.shortText && <Text className={styles.text}>{article.shortText}</Text>}
			</div>

			{article.slug && (
				<footer>
					<LinkButton variant="text" href={normalizeFullSlug(article.slug)!}>
						{t`Läs mer`}
					</LinkButton>
				</footer>
			)}
		</article>
	);
};
