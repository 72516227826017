import { Divider, Grid, Heading, Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { IconLink, LinkButton, LinkButtonProps } from '@/bloks/Button';
import { IconLinkProps } from '@/bloks/Button/IconLink/IconLink';
import { CareerContactStoryblok } from '@/components';
import styles from './CareerContact.module.scss';

interface BlokProps {
	blok: CareerContactStoryblok;
}

const blokProps = ({ blok }: BlokProps): CareerContactProps => ({
	linkButton: blok.linkButton?.[0] && { ...LinkButton.blokProps({ blok: blok.linkButton[0] }) },
	jobConnectTitle: blok.jobConnectTitle,
	jobConnectText: blok.jobConnectText,
	jobConnectLinkButton: blok.jobConnectLinkButton?.[0] && {
		...LinkButton.blokProps({ blok: blok.jobConnectLinkButton[0] }),
	},
	jobContactTitle: blok.jobContactTitle,
	jobContactLinkButtons: blok.jobContactLinkButtons?.map((iconLink) => IconLink.blokProps({ blok: iconLink })),
});

export interface CareerContactProps {
	linkButton?: LinkButtonProps;
	jobConnectTitle?: string;
	jobConnectText?: string;
	jobConnectLinkButton?: LinkButtonProps;
	jobContactTitle?: string;
	jobContactLinkButtons?: IconLinkProps[];
}

/**
 * Contact information used in CareerPages
 */
export const CareerContact: Blok<CareerContactProps, BlokProps> = ({
	linkButton,
	jobConnectTitle,
	jobConnectText,
	jobConnectLinkButton,
	jobContactTitle,
	jobContactLinkButtons,
}) => {
	return (
		<>
			{linkButton && (
				<div className={styles.btnContainer}>
					<LinkButton {...linkButton} />
				</div>
			)}
			<Divider color="--line-color" margin="3xl" />
			<Grid columns={{ base: 1, md: 2 }} colGap="3xl" rowGap="3xl">
				<Grid.Item>
					{jobConnectTitle && <Heading as="div" size="h5" title={jobConnectTitle} />}
					<Text>{jobConnectText}</Text>
					{jobConnectLinkButton && <LinkButton {...jobConnectLinkButton} variant="text" />}
				</Grid.Item>
				<Grid.Item>
					{jobContactTitle && <Heading as="div" size="h5" title={jobContactTitle} className={styles.jobContactTitle} />}
					<div className={styles.iconLinkContainer}>
						{jobContactLinkButtons?.map((iconLink) => (
							<IconLink {...iconLink} iconSize="sm" key={iconLink.text} className={styles.iconLink} />
						))}
					</div>
				</Grid.Item>
			</Grid>
		</>
	);
};

CareerContact.blokProps = blokProps;
