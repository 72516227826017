import { Section } from '@/atoms';
import { FileUploadFormStoryblok, ZendeskFormStoryblok } from '@/components';
import { ISbStoryData } from '@/types/storyblok';
import { ZendeskForm } from '../ZendeskForm';

interface Props {
	blok: FileUploadFormStoryblok;
	meta?: {
		isParent: boolean;
	};
}

export const FileUploadForm: React.FC<Props> = ({ blok, meta }) => {
	const form = blok.form as unknown as ISbStoryData<ZendeskFormStoryblok>;
	return (
		<Section maxWidth="925px" padding={meta?.isParent === false ? { base: 'none' } : { base: 'xl', lg: '3xl' }}>
			<ZendeskForm {...ZendeskForm.blokProps({ blok: form.content })} />
		</Section>
	);
};
