import { AccordionFaq } from '@/bloks';
import DynamicComponent from '@/bloks/DynamicComponent';
import sectionTabsStyles from '@/bloks/Section/SectionTabs/SectionTabs.module.scss';
import { TabAccordionStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import styles from './TabAccordion.module.scss';

interface Props {
	blok: TabAccordionStoryblok;
	display: boolean;
}
export const TabAccordion: React.FC<Props> = ({ blok, display }) => {
	return (
		<div className={styles.accordionContent}>
			<div
				className={cls(display ? sectionTabsStyles.display : sectionTabsStyles.hidden, sectionTabsStyles.panel)}
				{...editableBlok(blok)}
			>
				<div className={styles.container}>
					{blok.header?.map((header) => <DynamicComponent key={header._uid} blok={header} />)}
					{blok.accordion?.map((accordion) => {
						return <AccordionFaq key={accordion._uid} blok={accordion} />;
					})}
				</div>
			</div>
		</div>
	);
};
