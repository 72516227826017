import { cls } from '@/utils';
import styles from './Grid.module.scss';

type ColStart = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type ColEnd = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13;
type RowStart = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type RowEnd = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13;

type ColSpan = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type GapSize = '3xs' | '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl';

const sizes = ['xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'base'] as const;

/** Grid columns or rows */
const normalizeSize = (value: string | number | undefined) =>
	typeof value === 'number' ? `repeat(${value}, 1fr)` : value;

/** Grid item width or height */
const normalizeSpan = (value: string | number | undefined) => value && `span ${value}`;

/** Grid item start or end, vertical or horizontal */
const normalizePos = (value: string | number | undefined) => value;

export interface GridItemProps {
	children?: React.ReactNode;
	/** Render as a specific element, default div */
	as?: string | React.ComponentType<any>;
	colStart?: {
		base: ColStart | 'auto';
		xs?: ColStart | 'auto';
		sm?: ColStart | 'auto';
		md?: ColStart | 'auto';
		lg?: ColStart | 'auto';
		xl?: ColStart | 'auto';
		xxl?: ColStart | 'auto';
	};
	colEnd?: {
		base: ColEnd | 'auto';
		xs?: ColEnd | 'auto';
		sm?: ColEnd | 'auto';
		md?: ColEnd | 'auto';
		lg?: ColEnd | 'auto';
		xl?: ColEnd | 'auto';
		xxl?: ColEnd | 'auto';
	};
	rowStart?: {
		base: RowStart | 'auto';
		xs?: RowStart | 'auto';
		sm?: RowStart | 'auto';
		md?: RowStart | 'auto';
		lg?: RowStart | 'auto';
		xl?: RowStart | 'auto';
		xxl?: RowStart | 'auto';
	};
	rowEnd?: {
		base: RowEnd | 'auto';
		xs?: RowEnd | 'auto';
		sm?: RowEnd | 'auto';
		md?: RowEnd | 'auto';
		lg?: RowEnd | 'auto';
		xl?: RowEnd | 'auto';
		xxl?: RowEnd | 'auto';
	};
	colSpan?: {
		base: ColSpan | 'auto';
		xs?: ColSpan | 'auto';
		sm?: ColSpan | 'auto';
		md?: ColSpan | 'auto';
		lg?: ColSpan | 'auto';
		xl?: ColSpan | 'auto';
		xxl?: ColSpan | 'auto';
	};
	rowSpan?: {
		base: number | 'auto';
		xs?: number | 'auto';
		sm?: number | 'auto';
		md?: number | 'auto';
		lg?: number | 'auto';
		xl?: number | 'auto';
		xxl?: number | 'auto';
	};
	className?: string;
	testID?: string;
}
const Item: React.FC<GridItemProps> = ({
	as = 'div',
	children,
	className,
	colSpan: colSpanValues = {},
	rowSpan: rowSpanValues = {},
	colStart: colStartValues = {},
	colEnd: colEndValues = {},
	rowStart: rowStartValues = {},
	rowEnd: rowEndValues = {},
	testID,
}) => {
	const Component = as as any;

	const style: Record<string, string | number | null | undefined> = {};

	for (const size of sizes) {
		const colSpan = normalizeSpan(colSpanValues[size]);
		const colStart = normalizePos(colStartValues[size]);
		const colEnd = normalizePos(colEndValues[size]);
		style[`--griditem-colstart-${size}`] = colStart;
		style[`--griditem-colend-${size}`] = colEnd || colSpan;

		const rowSpan = normalizeSpan(rowSpanValues[size]);
		const rowStart = normalizePos(rowStartValues[size]);
		const rowEnd = normalizePos(rowEndValues[size]);
		style[`--griditem-rowstart-${size}`] = rowStart;
		style[`--griditem-rowend-${size}`] = rowEnd || rowSpan;
	}
	return (
		<Component data-testid={testID} className={cls(styles.item, className)} style={style}>
			{children}
		</Component>
	);
};

export interface GridProps {
	children: React.ReactNode;
	/** Render as a specific element, default div */
	as?: string | React.ComponentType<any>;
	className?: string;
	maxVH?: boolean;
	maxVW?: boolean;
	columns?: {
		base: number | string;
		xs?: number | string;
		sm?: number | string;
		md?: number | string;
		lg?: number | string;
		xl?: number | string;
		xxl?: number | string;
	};
	rows?: {
		base: number | string;
		xs?: number | string;
		sm?: number | string;
		md?: number | string;
		lg?: number | string;
		xl?: number | string;
		xxl?: number | string;
	};
	/** Gap between rows, default 0 */
	rowGap?: GapSize;
	/** Gap between columns, default 0 */
	colGap?: GapSize;
}
type GridLayout<P> = React.FC<P> & {
	Item: typeof Item;
};
export const Grid: GridLayout<GridProps> = ({
	as = 'div',
	children,
	className,
	columns = { base: 1 },
	maxVH,
	maxVW,
	rows = {},
	rowGap,
	colGap,
}) => {
	const Component = as as any;

	const classNames = cls(
		className,
		styles.container,
		{ [styles[`container__maxVHeight`]]: maxVH },
		{ [styles[`container__maxVWidth`]]: maxVW },
		{ [styles[`container__rowGap-${rowGap}`]]: rowGap },
		{ [styles[`container__colGap-${colGap}`]]: colGap },
	);

	const style: Record<string, string | number | null | undefined> = {};

	for (const size of sizes) {
		style[`--grid-cols-${size}`] = normalizeSize(columns[size]);
		style[`--grid-rows-${size}`] = normalizeSize(rows[size]);
	}

	return (
		<Component className={classNames} style={style}>
			{children}
		</Component>
	);
};

Grid.Item = Item;
