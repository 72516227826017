import { Button, Modal } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { UpsalesForm } from '@/bloks/Form';
import { HeaderCommon } from '@/bloks/Other';
import { HeaderCommonStoryblok, PopUpButtonStoryblok, UpsalesFormStoryblok } from '@/components';
import { ISbStoryData } from '@/types/storyblok';
import { StoryblokStory } from 'storyblok-generate-ts';
import { useState } from 'react';
import styles from './PopUpButton.module.scss';

interface BlokProps {
	blok: PopUpButtonStoryblok;
}

const blokProps = ({ blok }: BlokProps): PopUpButtonProps => {
	const props: PopUpButtonProps = {
		buttonText: blok?.buttonText,
		form: blok?.form,
		header: blok.header,
		size: blok?.size,
	};

	return props;
};

export interface PopUpButtonProps {
	buttonText: string;
	form: StoryblokStory<UpsalesFormStoryblok> | string;
	header?: HeaderCommonStoryblok[];
	size?: 'large';
	testID?: string;
	className?: string;
}

export const PopUpButton: Blok<PopUpButtonProps, BlokProps> = ({
	buttonText,
	form,
	header,
	testID,
	size,
	className,
}) => {
	const [showModal, setShowModal] = useState(false);

	const onClose = () => {
		setShowModal(false);
	};

	return (
		<>
			<Button
				onClick={() => {
					setShowModal(true);
				}}
				testID={testID}
				size={size}
				className={className}
			>
				{buttonText}
			</Button>

			<Modal className={styles.modal} show={showModal} onClose={onClose}>
				{header?.map((item) => (
					<>
						<HeaderCommon key={item._uid} {...HeaderCommon.blokProps({ blok: item })} />
					</>
				))}
				<UpsalesForm
					{...UpsalesForm.blokProps({
						story: form as unknown as ISbStoryData<UpsalesFormStoryblok>,
						meta: { layout: 'twoCols', zone: 'popup' },
					})}
				/>
			</Modal>
		</>
	);
};

PopUpButton.blokProps = blokProps;
