import { Grid, ShowMoreButton } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { GlobalUspSmallGroupStoryblok } from '@/components';
import { useState } from 'react';
import { t } from 'ttag';
import { UspSmall, UspSmallProps } from '../UspSmall/UspSmall';
import styles from './GlobalUspSmallGroup.module.scss';

interface BlokProps {
	blok: GlobalUspSmallGroupStoryblok;
	meta: {
		layout?: UspSmallProps['layout'];
	};
}

const blokProps = ({ blok, meta }: BlokProps) => ({
	usps: blok.usps.map((usp) => UspSmall.blokProps({ blok: usp, meta })),
});

interface Props {
	usps: UspSmallProps[];
}

/**
 * Group component for small global usps.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=184%3A10432
 */
export const GlobalUspSmallGroup: Blok<Props, BlokProps> = ({ usps }) => {
	const [showText, setShowText] = useState(false);
	const showTextBtn = usps.some((usp) => usp.text);
	const showBtnConfig = !showText
		? { text: t`Läs mer`, icon: 'chevron-down', iconPosition: 'bottom' }
		: { text: t`Visa mindre`, icon: 'chevron-up', iconPosition: 'top' };

	return (
		<div>
			<Grid className={styles.grid} columns={{ base: 2, lg: 4 }}>
				{usps?.map((usp) => (
					<Grid.Item key={usp._uid}>
						<UspSmall {...usp} showText={showText} layout={showTextBtn ? 'center' : usp.layout} />
					</Grid.Item>
				))}
			</Grid>
			{showTextBtn && (
				<ShowMoreButton
					onClick={() => setShowText(!showText)}
					icon={showBtnConfig.icon}
					iconPosition={showBtnConfig.iconPosition as 'bottom' | 'top'}
				>
					{showBtnConfig.text}
				</ShowMoreButton>
			)}
		</div>
	);
};

GlobalUspSmallGroup.blokProps = blokProps;
