export const editableBlok = ({ _editable }: { _editable?: string } | { _editable?: string; [k: string]: any } = {}) => {
	if (typeof _editable === 'undefined') {
		return {};
	}

	try {
		const options = JSON.parse(_editable.replace(/^<!--#storyblok#/, '').replace(/-->$/, ''));

		return {
			'data-blok-c': JSON.stringify(options),
			'data-blok-uid': options.id + '-' + options.uid,
		};
	} catch {
		return {};
	}
};
