import { Icon } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { GlobalMenuLinkGroupStoryblok } from '@/components';
import { useGlobalMenu } from '@/contexts/menu/GlobalMenuProvider';
import { useSearchV2 } from '@/contexts/search/AddSearchProviderV2';
import { ThemeProvider } from '@/contexts/theme';
import { SearchWidgetV2 } from '@/metabloks';
import { getThemeInfo } from '@/themes';
import { cls } from '@/utils';
import React, { useState } from 'react';
import { t } from 'ttag';
import styles from './MenuContentMobile.module.scss';

interface Props {
	linkGroups?: GlobalMenuLinkGroupStoryblok[];
	className?: string;
}

export const MenuContentMobile: React.FC<Props> = ({ linkGroups, className }) => {
	const search = useSearchV2();
	const { isMenuOpen } = useGlobalMenu();
	const firstLinkGroup = linkGroups?.[0];
	const [openSubGroupId, setOpenSubGroupId] = useState<string[]>([firstLinkGroup?._uid || '']);
	const [exitingSubGroupId, setExitingSubGroupId] = useState<string | null>(null);
	const theme = 'menuContent';
	const themeInfo = getThemeInfo(theme);
	const tabIndex = isMenuOpen && !search.isSearchOpen ? 0 : -1;

	const handleClick = (_uid: string) => {
		if (openSubGroupId.includes(_uid)) {
			setExitingSubGroupId(_uid);
			setTimeout(() => {
				setOpenSubGroupId(openSubGroupId.filter((id) => id !== _uid));
				setExitingSubGroupId(null);
			}, 500);
		} else {
			setOpenSubGroupId([...openSubGroupId, _uid]);
		}
	};

	return (
		<ThemeProvider theme={theme}>
			<div className={cls(styles.menuContent, themeInfo.styles.bgColor, isMenuOpen && styles.isMenuOpen, className)}>
				{linkGroups?.map((group, index) => (
					<div className={styles[`group${index + 1}`]} key={group._uid}>
						<div className={styles.group}>
							<button
								className={styles.titleWrapper}
								onClick={() => handleClick(group._uid)}
								aria-label={t`Öppna menynivå`}
								tabIndex={tabIndex}
							>
								<span className={styles.title}>{group.title}</span>
								<Icon
									name="chevron-right"
									size="1x"
									color="--text-dark"
									className={cls(styles.icon, openSubGroupId.includes(group._uid) && styles.rotate)}
								/>
							</button>

							<div
								className={cls(
									styles.links,
									openSubGroupId.includes(group._uid) && styles.open,
									exitingSubGroupId === group._uid && styles.exit,
								)}
							>
								<div className={styles.mainLinks}>
									{group.mainLinks?.map((link) => (
										<LinkButton
											key={link._uid}
											{...LinkButton.blokProps({ blok: link })}
											variant="link"
											size="large"
											tabIndex={tabIndex}
										/>
									))}
								</div>
								<div className={styles.subLinks}>
									{group.links?.map((item) => {
										if (item.component === 'GlobalMenuSpace') {
											return <div className={styles.space} key={item._uid} />;
										}

										return (
											<LinkButton
												key={item._uid}
												{...LinkButton.blokProps({ blok: item })}
												variant="link"
												tabIndex={tabIndex}
											/>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				))}

				<div>{search.ready && <SearchWidgetV2 />}</div>
			</div>
		</ThemeProvider>
	);
};
