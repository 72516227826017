import { useServerPageConfig } from '@/contexts/config/ConfigProvider';
import Script from 'next/script';

export const EnquiryCreateScript = () => {
	const { offertaEnquiryCreateFormScriptUrl } = useServerPageConfig();

	if (!offertaEnquiryCreateFormScriptUrl) {
		return <></>;
	}

	return <Script id={'enquiryCreateScript'} src={offertaEnquiryCreateFormScriptUrl} strategy={'afterInteractive'} />;
};
