import { Icon, IconButton, Text } from '@/atoms';
import { cls } from '@/utils';
import React, { Fragment, useState } from 'react';
import { t } from 'ttag';
import { Availability } from './Availability';
import styles from './SectionComparisonTable.module.scss';

export interface SegmentDescriptionsProps {
	description?: string;
	availability?: string[];
	_uid: string;
	_editable?: string;
}

interface Props {
	isLastRow: boolean;
	row: any;
	primary?: string;
	secondaryThemeInfo: any;
	childThemeInfo: any;
	theme: string;
	descriptions: SegmentDescriptionsProps[];
	tableSlug: string;
}

export const SegmentRow: React.FC<Props> = ({
	isLastRow,
	row,
	primary,
	childThemeInfo,
	secondaryThemeInfo,
	descriptions,
	theme,
	tableSlug,
}) => {
	const [isExtended, setIsExtended] = useState(false);
	const hasDescription = descriptions
		?.filter((segmentDescription: any) => segmentDescription.full_slug.includes(tableSlug))
		.some((desc: any) => desc.name === row.name);

	if (!descriptions) return null;

	const renderDescription = (segmentDescription: any, index: number) => {
		if (segmentDescription.full_slug.includes(tableSlug) && segmentDescription.name === row.name && isExtended) {
			const { availability, description } = segmentDescription.content;

			return (
				<div key={index}>
					<div onClick={() => setIsExtended(!isExtended)}>
						<Text size="small">{description}</Text>
					</div>
					{availability?.length > 0 && (
						<>
							<Text size="small" marginBottom="xs">
								{t`Tillgänglighet`}:
							</Text>
							<Availability availableOn={availability} />
						</>
					)}
				</div>
			);
		}
		return null;
	};

	const getColumnTheme = (index: number) => {
		if (theme === 'primary' && index.toString() != primary) {
			return secondaryThemeInfo;
		}
		return childThemeInfo;
	};

	return (
		<Fragment>
			<div className={cls(styles.row, styles.hideOnDesktop, styles.featureName)}>
				<div className={cls(styles.col)}>
					<div
						className={cls(
							styles.content,
							!isLastRow && styles.borderBottom,
							hasDescription && styles.cursorPointer,
							styles.iconAndSegment,
						)}
						onClick={() => setIsExtended(!isExtended)}
					>
						{hasDescription && (
							<IconButton
								iconName={isExtended ? 'minus' : 'plus'}
								aria-label={'Mer information'}
								iconColor="--text-dark"
							/>
						)}
						{row?.name}
					</div>

					<div>{hasDescription && descriptions.map(renderDescription)}</div>
				</div>
			</div>
			<div className={cls(styles.row, theme != 'primary' && styles.stickyRowNeutral)}>
				<div className={cls(styles.col, styles.hideOnMobile)}>
					<div className={cls(styles.content, !isLastRow && styles.borderBottom)}>
						<div
							className={cls(styles.iconAndSegment, hasDescription && styles.cursorPointer)}
							onClick={() => setIsExtended(!isExtended)}
						>
							{row?.name}
							{hasDescription && (
								<IconButton
									iconName={isExtended ? 'minus' : 'plus'}
									aria-label={'Mer information'}
									iconColor="--text-dark"
								/>
							)}
						</div>
						<div className={styles.description}>{hasDescription && descriptions.map(renderDescription)}</div>
					</div>
				</div>
				{row?.columns?.map((col: string, index: number) => {
					const columnTheme = getColumnTheme(index);
					const isPrimaryTheme = theme === 'primary';
					const themeBorderStyle = columnTheme.styles.borderColor;
					return (
						<div
							key={index}
							className={cls(styles.col, isPrimaryTheme ? columnTheme.styles.column : columnTheme.styles.bgColor)}
						>
							<div
								className={cls(
									styles.content,
									!isLastRow && styles.borderBottom,
									!themeBorderStyle && primary == '' && styles.borderBottomWhite,
									!isLastRow && themeBorderStyle,
									styles.textAlignCenter,
									isLastRow && styles.lastRow,
								)}
							>
								<div className={styles.icons}>{getColumnContent(col)}</div>
							</div>
						</div>
					);
				})}
			</div>
		</Fragment>
	);
};

const getColumnContent = (value: string) => {
	if (value === 'true') {
		return <Icon name="circle-check" color="--primary-green" size="1x" className={styles.icon} type={'solid'} />;
	}
	if (value === 'false') {
		return <Icon name="xmark" color="--accent-orange" size="1x" className={styles.icon} type={'solid'} />;
	}
	return value;
};
