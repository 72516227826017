import { LinkButton } from '@/bloks/Button/LinkButton';
import { PhoneLinkStoryblok } from '@/components';
import { useImpressionTracking } from '@/contexts/piwik/ImpressionTrackingProvider';
import { cls } from '@/utils';
import { formTypes, handleFormSubmitEvent } from '@/utils/piwik';
import * as Sentry from '@sentry/nextjs';
import React, { ReactElement, useEffect, useState } from 'react';
import { t } from 'ttag';
import { Grid, Heading, TextField } from '..';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { Text } from '../Text/Text';
import styles from './CaptchaForm.module.scss';
import { useFormValidation } from './FormValidationContext';
import { FriendlyCaptcha } from './FriendlyCaptcha';

interface Props {
	onSubmit: (e: React.BaseSyntheticEvent, honeypotTriggered?: boolean) => () => Promise<void>;
	children: ReactElement[] | ReactElement;
	formId?: string;
	testID?: string;
	buttonText?: string;
	feedbackTitle?: string;
	feedbackText?: string;
	className?: string;
	name?: string;
	integrityMessage?: ReactElement;
	meta?: {
		zone?: number | 'popup' | undefined;
		type: formTypes;
		compactFields?: boolean;
		phoneLink?: PhoneLinkStoryblok[];
		inCard?: boolean;
		centerFeedbackText?: boolean;
	};
}

/**
 * Contact form that uses captcha validation
 */
export const CaptchaForm: React.FC<Props> = ({
	onSubmit,
	children,
	formId,
	testID,
	buttonText,
	feedbackTitle,
	feedbackText,
	className,
	name,
	integrityMessage,
	meta,
}) => {
	const [error, setError] = useState(false);
	const [captchaIsValid, setCaptchaIsValid] = useState(false);
	const [startVerify, setStartVerify] = useState(false);
	const [formIsSent, setFormIsSent] = useState(false);
	const [firstName, setFirstName] = useState<string>(); // Används som honeypot
	const [loading, setLoading] = useState(false);
	const [request, setRequest] = useState<null | (() => Promise<void>)>(null);
	const { isFormValid } = useFormValidation();
	const compactFields = meta?.compactFields as boolean;
	const inCard = meta?.inCard as boolean;

	const handleOnSubmit = (e: React.BaseSyntheticEvent) => {
		e.preventDefault();
		if (firstName) {
			const honeypotTriggered = true;
			setRequest(() => onSubmit(e, honeypotTriggered));
			return;
		}

		if (isFormValid === false) {
			return;
		}
		setRequest(() => onSubmit(e));

		handleFormSubmitEvent({ type: meta?.type, zone: meta?.zone, formId });
	};

	useEffect(() => {
		if (!request) {
			setLoading(false);
			return;
		}

		setError(false);
		(async () => {
			try {
				await request();
				setFormIsSent(true);
				setLoading(false);
			} catch (error) {
				Sentry.captureException(error);
				setFormIsSent(true);
				setLoading(false);
				setError(true);
			}
		})();
	}, [request]);

	const refreshForm = () => {
		setError(false);
		setCaptchaIsValid(true);
		setFormIsSent(false);
		setStartVerify(false);
	};

	const getColumns = () => {
		if (inCard) {
			return { base: 2, md: 1, lg: 1, xl: '5fr 2fr' };
		}

		return { base: 1, md: '5fr 2fr', lg: '5fr 2fr', xl: '5fr 2fr' };
	};

	const { setTargetInteraction } = useImpressionTracking();

	return (
		<div className={cls(styles.form, compactFields && styles.compact, inCard && styles.inCard, className)}>
			{formIsSent && !error && captchaIsValid && (
				<div className={cls(styles.feedbackContainer, meta?.centerFeedbackText && styles.centerFeedbackText)}>
					<div className={styles.iconContainer}>
						<Icon name="star" size="4x" />
						<Heading
							as="div"
							size="h4"
							className={styles.feedbackTitle}
							title={feedbackTitle ?? t`Tack för ditt meddelande, vi kontaktar dig så fort vi kan!`}
							marginBottom="none"
						/>
					</div>
					{feedbackText && <Text>{feedbackText}</Text>}
				</div>
			)}
			{!formIsSent && (!error || captchaIsValid) && (
				<form
					method="POST"
					onSubmit={(event) => {
						handleOnSubmit(event);
						setLoading(true);
					}}
					onFocus={() => setStartVerify(true)}
					className="contact-form"
					id={formId}
					data-testid={testID}
					name={name}
				>
					{children}

					<div className={styles.textFieldContainer}>
						<TextField
							id={`firstName-${formId}`}
							className={styles.inputFirstName}
							title={t`First name`}
							autoComplete="off"
							type="text"
							onChange={(e) => setFirstName(e.target.value)}
							value={firstName}
						/>
					</div>

					<Grid
						columns={getColumns()}
						rowGap="md"
						colGap="md"
						className={cls(styles.endOfFormContent, inCard && styles.inCard)}
					>
						<Grid.Item className={styles.gridItem}>
							{integrityMessage ? (
								integrityMessage
							) : (
								<Text className={styles.consentText} size="small" muted marginBottom="none">
									{t`Genom att kontakta Fortnox kommer dina personuppgifter behandlas enligt `}
									<LinkButton
										href="/om-fortnox/integritet-och-sakerhet/avtal-och-villkor/integritetsmeddelande"
										variant="text"
										className={styles.integrityMsgBtn}
										arrow={false}
										target="_blank"
									>
										{t`Fortnox Integritetsmeddelande`}
									</LinkButton>
								</Text>
							)}
						</Grid.Item>
						<Grid.Item className={styles.gridItem}>
							<FriendlyCaptcha className={styles.captcha} verify={startVerify} setCaptchaIsValid={setCaptchaIsValid} />
						</Grid.Item>
						<Grid.Item className={styles.gridItem}>
							<div className={styles.phoneContainer}>
								{meta?.phoneLink &&
									meta.phoneLink.map((phone: PhoneLinkStoryblok, index: number) => {
										const hrefString = `tel:+46${phone.phoneNumber.replace(/^0+/, '')}`;
										return (
											<a key={index} href={hrefString}>
												{phone.text}
											</a>
										);
									})}
							</div>
						</Grid.Item>
						<Grid.Item className={cls(styles.gridItem, styles.btnContainer)}>
							<Button
								aria-label={t`Skicka`}
								type="submit"
								disabled={!captchaIsValid}
								isLoading={loading}
								onClick={() => {
									setTargetInteraction({ contentTarget: buttonText ?? t`Skicka` });
								}}
							>
								{buttonText ?? t`Skicka`}
							</Button>
						</Grid.Item>
					</Grid>
				</form>
			)}
			{formIsSent && (error || !captchaIsValid) && (
				<div className={styles.errorMsgContainer}>
					<div className={styles.iconContainer}>
						<Icon name="exclamation-triangle" size="3x" />
						<Heading
							as="div"
							size="h4"
							className={styles.feedbackTitle}
							marginBottom="none"
							title={t`Något gick snett`}
						/>
					</div>
					<Text>{t`Något gick snett. Försök igen eller kontakta supporten om felet kvarstår.`}</Text>
					<div className={styles.btnWrapper}>
						<LinkButton href="/kontakt" variant="text">
							{t`Kontakta vår support`}
						</LinkButton>
						<Button
							aria-label={t`Försök igen`}
							className={styles.refreshBtn}
							onClick={() => {
								refreshForm();
							}}
						>{t`Försök igen`}</Button>
					</div>
				</div>
			)}
		</div>
	);
};
