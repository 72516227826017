export const LOAN_MIN = 10000;
export const LOAN_MAX = 5000000;
export const MONTHS_MIN = 6;
export const MONTHS_MAX = 48;

const calculateSteps = (array: { step: number; increment?: number }[]) => {
	let i = 0;
	let val = array[0].step;
	let out: number[] = [array[0].step];
	while (i < array.length) {
		const step = array[i];
		const nextStep = array[i + 1];
		if (!nextStep) break;
		if (step.step > nextStep.step) break;
		if (!step.increment) break;

		val += step.increment;
		out = [...out, val];

		if (val === nextStep.step) {
			i++;
		}
	}
	return out;
};

export const loanSekSteps = calculateSteps([
	{ step: LOAN_MIN, increment: 5000 },
	{ step: 100000, increment: 25000 },
	{ step: 200000, increment: 50000 },
	{ step: 500000, increment: 100000 },
	{ step: 1000000, increment: 250000 },
	{ step: LOAN_MAX },
]);
export const loanMonthSteps = calculateSteps([
	{ step: MONTHS_MIN, increment: 6 },
	{ step: 12, increment: 6 },
	{ step: 18, increment: 6 },
	{ step: 24, increment: 12 },
	{ step: 36, increment: 12 },
	{ step: MONTHS_MAX },
]);
