import { CustomIcon, Heading, Text } from '@/atoms';
import { LinkButton, LinkButtonProps } from '@/bloks/Button';
import { renderRichText } from '@/bloks/RichText';
import { UspMediumStoryblok } from '@/components';
import { slugifyName } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import styles from './UspMedium.module.scss';

interface BlokProps {
	blok: UspMediumStoryblok;
}

const blokProps = ({ blok }: BlokProps): UspMediumProps => ({
	title: blok.title,
	text: blok.text,
	linkButton: blok.linkButton?.[0] && LinkButton.blokProps({ blok: blok.linkButton?.[0] }),
	_editable: blok._editable,
});

export interface UspMediumProps {
	title: string;
	text: string;
	linkButton?: LinkButtonProps;
	_editable?: string;
}

export function UspMedium({ title, text, linkButton, _editable }: UspMediumProps) {
	return (
		<div {...editableBlok({ _editable })} className={styles.container}>
			<div className={styles.content}>
				<div className={styles.header}>
					<CustomIcon name="angles-right" className={styles.icon} size="lg" />
					<Heading
						as="div"
						size="h5"
						title={title}
						className={styles.title}
						data-testid={`usp-header-${slugifyName(title)}`}
						marginBottom="none"
					/>
				</div>
				{text && (
					<Text as="div" className={styles.text}>
						{renderRichText(text)}
					</Text>
				)}
				{linkButton && (
					<div className={styles.linkButtons}>
						<LinkButton {...linkButton} variant="text" />
					</div>
				)}
			</div>
		</div>
	);
}

UspMedium.blokProps = blokProps;
