import { Grid } from '@/atoms';
import { CardContactGlobalStoryblok, CardContactStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import DynamicComponent from '../DynamicComponent';
import { renderRichText } from '../RichText';
import styles from './CardContact.module.scss';

interface Props {
	blok: CardContactStoryblok;
	meta?: {
		theme?: string;
	};
}

/**
 * Card with HeaderCommon and richText. At the moment only used in contact section in zone 5.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=158%3A6693
 */
export const CardContact: React.FC<Props> = ({ blok, meta }) => {
	const globalCards = blok?.content as unknown as CardContactGlobalStoryblok;
	const theme = meta?.theme ?? blok.theme?.[0]?.theme;

	return (
		<div
			{...editableBlok(blok)}
			className={cls(styles.container, styles[`theme--${theme}`])}
			data-testid="card-contact-container"
		>
			<Grid columns={{ base: 1, lg: 1, xl: 1 }} rowGap="3xl">
				{Array.isArray(globalCards) &&
					globalCards?.map((card) => {
						const header = card?.content?.header?.[0];
						return (
							<Grid.Item key={card.uuid}>
								{header && <DynamicComponent key={header._uid} blok={header} data-testid="card-contact-container2" />}
								<div key={card.uuid} className={styles.text}>
									{renderRichText(card?.content?.text)}
								</div>
							</Grid.Item>
						);
					})}
			</Grid>
		</div>
	);
};
