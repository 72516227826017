import { Grid, Heading, Tags, Text } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { ImageAsset } from '@/metabloks';
import { normalizeFullSlug } from '@/utils/storyblok';
import { t } from 'ttag';
import styles from './NewsCard.module.scss';

interface Props {
	post: {
		title: string;
		text: string;
		slug: string;
		tags: string[];
		firstPublished: string;
		lastModified: string;
		image?: any;
	};
	fallbackImage: any;
}

export const NewsCard: React.FC<Props> = ({ post, fallbackImage }) => {
	const image = post.image?.filename ? post.image : fallbackImage;

	return (
		<div className={styles.container}>
			<Grid columns={{ base: 1, md: 2, lg: '5fr 7fr', xl: '3fr 9fr' }}>
				<Grid.Item>
					<div className={styles.image}>
						<ImageAsset absolute asset={image} objectFit="cover" fill objectPosition="top center" />
					</div>
				</Grid.Item>
				<Grid.Item>
					<div className={styles.textContainer}>
						<div className={styles.tagsAndPublishedDate}>
							<Tags tags={post.tags} published={post.firstPublished} modified={post?.lastModified} />
						</div>

						<Heading as="div" size="h4" className={styles.heading} title={post.title} />
						<Text>{post.text ? `${post.text}...` : ''}</Text>
						<div className={styles.linkContainer}>
							<LinkButton href={normalizeFullSlug(post.slug)} variant="text" title={post.title}>
								{t`Läs hela artikeln`}
							</LinkButton>
						</div>
					</div>
				</Grid.Item>
			</Grid>
		</div>
	);
};
