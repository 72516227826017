import { Heading, Icon, Price, Text } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { IntegrationClientType, useIntegrationCurrent } from '@/contexts/Integration';
import { useServerPageConfig } from '@/contexts/config';
import { useProduct } from '@/contexts/products';
import { t } from 'ttag';
import styles from '../IntegrationPage.module.scss';

const IntegrationPrice = () => {
	const integration = useIntegrationCurrent();
	const { appsHost: host } = useServerPageConfig();
	const productCatalogData = useProduct(15);

	const { priceModel, readMoreUrl, urlSlug } = integration;

	const isBuyable = priceModel.clientType === IntegrationClientType.BuyableApp;
	return (
		<aside data-testid="single-integration-sidebar">
			<div className={styles.priceContainer}>
				{isBuyable ? (
					<>
						<Price
							priceStructure={{ '12': { '0': Number(integration.priceModel.price) } }}
							priceText={t`kr/mån`}
							data-testid="integration-price-details"
							showFrom={false}
						/>
						<Heading as="h3" size="h5" title={t`Integrationen köps hos Fortnox`} />
						<Text
							as="p"
							size="small"
						>{t`Ett köp hos Fortnox ger dig alltid 30 dagars öppet köp. Du får också en smidig betalning då kostnaden läggs till på din vanliga faktura från Fortnox.`}</Text>
					</>
				) : (
					<>
						<Heading className={styles.priceHeading} marginBottom="custom" as="h3" size="h5" title={t`Pris`} />
						<Text>{priceModel.price}</Text>
						<Heading as="h3" size="h5" title={t`Integrationen köps hos integratör`} />
						<Text as="p">{t`Köp som görs direkt hos tredje part har avtal endast mellan köpare och säljare. Fortnox är inte en part i detta avtal. Köper du en integration hos integratör behöver du också en integrationslicens för koppling till ditt Fortnox.`}</Text>
					</>
				)}
				<div className={styles.linkContainer}>
					<a href={readMoreUrl} rel="noopener noreferrer">
						{t`Fullständig prislista`}
						<Icon className={styles.linkIcon} name="external-link" />
					</a>
				</div>

				{!isBuyable && (
					<Text className={styles.mutedText}>
						{t`Från`} {productCatalogData?.priceStructure[12][0]}{' '}
						{t`kr/mån tillkommer för licensen Fortnox Integration (inkluderar obegränsat antal integrationer)`}
					</Text>
				)}

				<div className={styles.infoContainer}>
					{isBuyable && (
						<LinkButton
							className={styles.button}
							data-testid="integration-order-button"
							href={`${host}/fs/fs/login.php?relay=%23%2Fintegration-partner%2Fapp-market%2F${encodeURIComponent(
								urlSlug,
							)}`}
							variant="filled"
						>
							{t`Beställ`}
						</LinkButton>
					)}
					{readMoreUrl && (
						<LinkButton
							className={styles.button}
							data-testid="read-more-button"
							href={readMoreUrl}
							variant={isBuyable ? 'outlined' : 'filled'}
						>
							{t`Läs mer`}
						</LinkButton>
					)}
				</div>
			</div>
		</aside>
	);
};

export default IntegrationPrice;
