import { _fetchApiHandlers as handlers } from './fetchApiHandlers';

export { FetchApiResult } from './fetchApiRaw';

type Handlers = typeof handlers;

/**
 * Helper for making requests to each of the pages/api/fortnox-website
 * endpoints.
 *
 * Usage:
 *
 * ```typescript
 *     import { fetchApi } from '@/utils/fetchApi';
 *
 *     fetchApi('accountants-v1', 'foobar').then(
 *       (res) => {
 *     	   // { data: AccountantStoryblok[], response: Response }
 *       },
 *       (err) => {
 *     	   // FetchApiError
 *       }
 *     )
 * ```
 *
 *	=> GET /api/fortnox-website/accountants-v1?s=foobar
 */
export const fetchApi = <H extends keyof Handlers>(
	resource: H,
	...params: Parameters<Handlers[H]>
): ReturnType<ReturnType<Handlers[H]>['call']> => {
	return (handlers[resource] as any)(...params).call();
};
