import { cls } from '@/utils';
import React, { useId, useState } from 'react';
import { Icon } from '../Icon';
import styles from './Checkbox.module.scss';

interface Props {
	/**
	 * The label displayed next to the checkbox.
	 */
	label: string | any;
	onChecked?: (event: any) => void;
	checked?: boolean;
	onClick?: (event: any) => void;
	value?: string;
	name?: string;
	required?: boolean;
	bordered?: boolean;
	labelColor?: 'dark' | 'light';
	testID?: string;
	className?: string;
}

/**
 * Checkbox component
 */
export const Checkbox: React.FC<Props> = ({
	label,
	onChecked,
	checked,
	onClick,
	value,
	name,
	required,
	bordered,
	labelColor = 'dark',
	testID = 'checkbox',
	className,
}) => {
	const id = useId();

	const [isChecked, setChecked] = useState(false);
	const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
		if (onChecked) {
			onChecked(event.target.checked);
		} else {
			setChecked(!isChecked);
		}
	};

	return (
		<div className={cls(styles.checkbox, bordered && styles.bordered, styles[`label-color--${labelColor}`], className)}>
			<input
				type="checkbox"
				className={styles.input}
				id={id}
				name={name}
				checked={checked ?? isChecked}
				onClick={onClick}
				onChange={handleChange}
				aria-checked={checked ?? isChecked}
				aria-label={label}
				data-testid={testID}
				required={required}
				value={value}
			/>
			<label htmlFor={id} className={styles.label}>
				<div
					className={cls(styles.box, {
						[styles.checked]: checked ?? isChecked,
						[styles.bordered]: bordered,
					})}
				>
					<Icon className={styles.icon} name="check" size="1x" />
				</div>
				{label && <div className={styles.labelContainer}>{label}</div>}
			</label>
		</div>
	);
};
