const nodeContentRenderer = (content: any) => content?.map(richTextToString).join(' ').replace(/  +/g, ' ');

export const richTextToString = ({ content, type, text }: any) => {
	switch (type) {
		case 'text':
			return text;
		case 'paragraph':
			return nodeContentRenderer(content);
		case 'bullet_list':
			return nodeContentRenderer(content);
		case 'ordered_list':
			return nodeContentRenderer(content);
		case 'list_item':
			return nodeContentRenderer(content);
		case 'doc':
			return content?.map(richTextToString).join(' ');
		default:
			return;
	}
};
