import { Grid } from '@/atoms';
import DynamicComponent from '@/bloks/DynamicComponent';
import { HeaderCommon } from '@/bloks/Other';
import { UspMediumImageGroupStoryblok } from '@/components';
import styles from './UspMediumImageGroup.module.scss';

interface Props {
	blok: UspMediumImageGroupStoryblok;
	meta?: {
		hideBrandline?: boolean;
	};
}

export function UspMediumImageGroup({ blok, meta }: Props) {
	const { smallTitle, title, image, text, usps } = blok;
	return (
		<Grid columns={{ base: 1, lg: 2 }} colGap="3xl" rowGap="3xl" className={styles.gridContainer}>
			<Grid.Item>
				<HeaderCommon
					smallTitle={smallTitle}
					titlePart1={title}
					image={image}
					text={text}
					titleSize="h3"
					hideBrandline={!!meta?.hideBrandline}
				/>
			</Grid.Item>
			<Grid.Item className={styles.uspColumn}>
				<Grid columns={{ base: 1, lg: 1 }} rowGap="xl">
					{usps?.map((usp) => (
						<Grid.Item key={usp._uid}>
							<DynamicComponent blok={usp} meta={{ theme: 'light-green' }} />
						</Grid.Item>
					))}
				</Grid>
			</Grid.Item>
		</Grid>
	);
}
