import DynamicComponent from '@/bloks/DynamicComponent';
import { ContainerRandomStoryblok } from '@/components';
import React, { useEffect, useState } from 'react';

interface Props {
	blok: ContainerRandomStoryblok;
}

export function ContainerRandom({ blok }: Props) {
	const group = Array.isArray(blok.group) ? blok.group[0] : null;

	//TODO: fix breadcrumbsTheme, if to be used on other pages than home.

	const [item, setItem] = useState<any>(null);
	useEffect(() => {
		group && setItem(getRandomItem(group.items));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return item && <DynamicComponent blok={item} />;
}

function getRandomItem<T>(list: T[]): T {
	return list[Math.floor(Math.random() * list.length)];
}
