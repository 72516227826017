import { Heading, Slider, Text } from '@/atoms';
import { LinkButton, LinkButtonProps } from '@/bloks';
import { useServerPageConfig } from '@/contexts/config';
import { ThemeProvider } from '@/contexts/theme';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { getThemeInfo } from '@/themes';
import { cls } from '@/utils';
import { useEffect, useState } from 'react';
import { t } from 'ttag';
import styles from './LoanSlider.module.scss';
import { INITIAL_AMOUNT, INITIAL_MONTHS, loanMonthSteps, loanSekSteps } from './utils';

interface CompanyLoan {
	amountSEK: number;
	nrOfMonths: number;
}

interface Props {
	title: string;
	linkButtons: LinkButtonProps[];
	amountCallback?: (amount: number) => void;
	monthCallback?: (months: number) => void;
	layout?: 'full' | 'right';
	className?: string;
}

export const LoanSlider = ({
	title,
	linkButtons,
	amountCallback,
	monthCallback,
	layout = 'full',
	className,
}: Props) => {
	const [amount, setAmount] = useState<number>(INITIAL_AMOUNT);
	const [months, setMonths] = useState<number>(INITIAL_MONTHS);
	const isMobile = useMediaQuery('(max-width: 1023px)');
	const themeInfo = getThemeInfo(isMobile ? 'lightMintGreen' : 'darkGreen');
	const { locale } = useServerPageConfig();

	useEffect(() => {
		const companyLoanData = localStorage.getItem('companyLoan');
		if (!companyLoanData) {
			return;
		}
		const companyLoan: CompanyLoan = JSON.parse(companyLoanData);
		setAmount(companyLoan.amountSEK);
		setMonths(companyLoan.nrOfMonths);
	}, []);

	const showLinkButton = layout === 'right';

	return (
		<ThemeProvider theme={isMobile ? 'lightMintGreen' : 'darkGreen'}>
			<div className={cls(className, styles.loanContainer, themeInfo.styles.bgColor)}>
				{title && <Heading as="div" size="h4" title={title} marginBottom="xl" className={styles.title} />}
				<div className={styles.sliderContainer}>
					<Slider
						min={0}
						max={loanSekSteps.length - 1}
						onInput={(e) => {
							setAmount(loanSekSteps[Number(e.currentTarget.value)]);
							amountCallback && amountCallback(loanSekSteps[Number(e.currentTarget.value)]);
						}}
						label={t`Belopp`}
						valueLabel={
							locale === 'sv'
								? `${Intl.NumberFormat('sv-SE', { notation: 'standard' }).format(amount)} ${t`kr`}`
								: `${t`SEK`} ${Intl.NumberFormat('sv-SE', { notation: 'standard' }).format(amount)}`
						}
						className={styles.slider}
						defaultValue={loanSekSteps.findIndex((v) => v === amount)}
					/>
					<Slider
						min={0}
						max={loanMonthSteps.length - 1}
						onInput={(e) => {
							setMonths(loanMonthSteps[Number(e.currentTarget.value)]);
							monthCallback && monthCallback(loanMonthSteps[Number(e.currentTarget.value)]);
						}}
						label={t`Låneperiod`}
						valueLabel={`${months} ${t`månader`}`}
						className={styles.slider}
						defaultValue={loanMonthSteps.findIndex((v) => v === months)}
					/>
				</div>
				{showLinkButton && linkButtons && (
					<LinkButton
						block
						key={`linkbutton-desktop-primary`}
						className={styles.linkButton}
						variant="filled"
						{...linkButtons[0]}
						onClick={() => {
							localStorage.setItem('companyLoan', JSON.stringify({ amountSEK: amount, nrOfMonths: months }));
						}}
					/>
				)}
				<Text marginBottom="none" className={styles.footerText}>{t`Powered by Capcito - En del av Fortnox`}</Text>
			</div>
		</ThemeProvider>
	);
};
