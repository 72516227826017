import { hostnameWithoutSubdomain } from './hostnameWithoutSubdomain';

/**
 * Whether the server is currently externally deployed to acce or prod
 */
export const isExternalDeployment = (() => {
	if (process.env.NEXT_PUBLIC_PREVIEW_ENABLED) {
		return false;
	}

	// When server-rendering, check if environment acce or prod
	if (process.env.ENVIRONMENT) {
		return true;
	}

	// When client-side-rendering check if hostname *.fortnox.se
	return 'fortnox.se' === hostnameWithoutSubdomain(global.window?.location?.hostname);
})();

/**
 * Whether the server is currently externally deployed to prod
 */
export const isExternalDeploymentProd = (() => {
	if (process.env.NEXT_PUBLIC_PREVIEW_ENABLED) {
		return false;
	}

	// When server-rendering, check if environment acce or prod
	if (process.env.ENVIRONMENT) {
		return 'prod' === process.env.ENVIRONMENT;
	}

	// When client-side-rendering check if hostname www.fortnox.se
	return 'www.fortnox.se' === global.window?.location?.hostname;
})();
