import { Divider, Grid, Heading, Icon, Section, Text } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { StepStoryblok, StepperStoryblok } from '@/components';
import { cls } from '@/utils';
import { HeaderCommon } from '../HeaderCommon';
import styles from './Stepper.module.scss';

interface Props {
	blok: StepperStoryblok;
}

/**
 * Stepper component
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=833%3A14176
 */
export const Stepper: React.FC<Props> = ({ blok }) => {
	const { title, labelLeft, labelRight, headingLeft, headingRight } = blok;
	const header = blok?.header?.[0];

	return (
		<Section className={styles.container}>
			{header && <HeaderCommon {...HeaderCommon.blokProps({ blok: header })} marginBottom="3xl" />}
			{title && <Heading as="h2" size="h4" title={title} marginBottom="2xl" />}
			<Grid columns={{ base: 2 }} className={styles.titleGrid}>
				<Grid.Item>{headingLeft && <Heading as="div" size="h5" title={headingLeft} />}</Grid.Item>
				<Grid.Item>{headingRight && <Heading as="div" size="h5" title={headingRight} />}</Grid.Item>
			</Grid>

			{(headingLeft || headingRight) && <Divider color="--primary-yellow" size="medium" className={styles.divider} />}
			<div className={styles.stepper}>
				{blok?.steps.map((step) => <Step key={step._uid} blok={step} meta={{ labelLeft, labelRight }} />)}
			</div>
		</Section>
	);
};

interface StepMeta {
	labelLeft?: string;
	labelRight?: string;
}
interface StepProps {
	blok: StepStoryblok;
	meta?: StepMeta;
}

const Step: React.FC<StepProps> = ({ blok, meta }) => {
	const { title, text, icon, position, highlight, linkButtons } = blok;
	const label = position === 'left' ? meta?.labelLeft : meta?.labelRight;

	return (
		<div className={cls(styles.step, styles[`position--${position}`])}>
			<StepLine position="vertical" />
			<div className={styles.iconContainer}>
				{icon?.map((icon) => (
					<Icon key={icon._uid} name={icon?.name || 'arrow-left'} size="2x" color="--primary-yellow" />
				))}
			</div>
			<StepLine position="horizontal" />
			<div className={cls(styles.stepContainer, highlight && styles.highlight)}>
				{label && <Heading as="div" size="h6" title={label} className={styles.label} data-testid="stepper-header-h6" />}
				{title && <Heading as="div" size="h5" title={title} data-testid="stepper-header-h5" />}
				<Text>{text}</Text>
				{!!linkButtons?.length && (
					<div className={styles.linkButtons}>
						{linkButtons?.map((linkButton) => (
							<LinkButton {...LinkButton.blokProps({ blok: linkButton })} variant="text" key={linkButton._uid} />
						))}
					</div>
				)}
			</div>
		</div>
	);
};

type StepLineProps = {
	position?: 'vertical' | 'horizontal';
};
const StepLine: React.FC<StepLineProps> = ({ position = 'vertical' }) => {
	return <div className={cls(styles.line, styles[`line-${position}`])} />;
};
